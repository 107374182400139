import styled from "styled-components";

export const InitiateContainer = styled.div`

  .main-container {
    margin-top: 20px;
    padding: 16px;
    background: rgb(255, 255, 255);
    border-radius: 16px;
    display: block;

    h4 {
      margin: 10px 0;
    }
  }

  .justify-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }
`; 

export const NFTDetailsContainer = styled.div`
  display: block;
  padding: 19px 24px 19px 24px;
  width: 100%;
  border: 1px solid #ebedf2;
  font-family: var(--font-gilroy);
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: -0.02em;
  color: #2d2d2d;
  border-radius: 14px;
  resize: none;
  outline: none;
  transition: all 0.2s ease;
  background-color: #fff;

  &:focus {
    border-color: #c1cbd9;
  }


  .select-nft__item {
    padding-top: 10px;
  }

  .card {
    &__article {
      display: flex;
      flex-direction: column;
      background: white;
      border-radius: 12px;
      height: 100%;

      cursor: pointer;
    }

    &__figure {
      display: block;
      width: 200px;
      height: 200px;
      background-color: #d9d9d9;
      overflow: hidden;
      border-radius: 12px;
      box-shadow: 0 1px 1px rgba(0,0,0,.075), 0 2px 2px rgba(0,0,0,.075), 2px 4px 8px 2px rgba(16, 23, 30, 0.24);
      //box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

      img, video {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;

        transition: all 0.2s ease-in-out;

        &:hover {
          transform: scale(1.05);
        }
      }
    }

    &__body {
      padding: 12px 14px 10px 14px;
      display: block;
      align-items: center;
      justify-content: space-between;
    }

    &__name {
      padding: 0 5px 3px 10px;
      font-family: var(--font-gilroy);
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      color: #999;
    }

    &__code {
      display: flex;
      align-items: center;
      justify-content: start;
      min-width: 68px;
      padding: 5px 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: var(--font-gilroy);
      font-size: 16px;
      line-height: 18px;
      font-weight: 600;
      color: #061330;
      background-color: #ebedf2;
      border-radius: 10px;

      @media only screen and (max-width: 767px) {
        min-width: 82px;
      }
    }
  }

`; 