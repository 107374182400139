import { CheckCircle, Triangle } from 'react-feather';
import styled from 'styled-components/macro';

import { useCustomWeb3React } from '../../providers/CustomWeb3ReactProvider';
import { useAllTransactions } from '../../state/transactions/hooks';
import { getExplorerLink, ExplorerDataType } from '../../utils/getExplorerLink';
import Loader from '../Loader';
import { TransactionSummary } from './TransactionSummary';

const TransactionStatusText = styled.div`
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  :hover {
    text-decoration: underline;
  }
`;

const TransactionState = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none !important;
  border-radius: 0.5rem;
  padding: 0.25rem 0rem;
  font-weight: 500;
  font-size: 0.825rem;
  color: ${({ theme }) => theme.accentAction};
`;

const IconWrapper = styled.div`
  color: ${({ pending, success, theme }) =>
    pending ? theme.accentAction : success ? theme.accentSuccess : theme.accentFailure};
`;

const RowFixed = styled.div`
  width: fit-content;
  display: flex;
  padding: 0;
  align-items: ${({ align }) => align ?? 'center'};
  justify-content: ${({ justify }) => justify ?? 'flex-start'};
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
  margin: ${({ gap }) => gap && `-${gap}`};
`;

export default function Transaction({ hash }) {
  const { chainId } = useCustomWeb3React();
  const allTransactions = useAllTransactions();

  const tx = allTransactions?.[hash];
  const info = tx?.info;
  const pending = !tx?.receipt;
  const success =
    !pending && tx && (tx.receipt?.status === 1 || typeof tx.receipt?.status === 'undefined');

  if (!chainId) return null;

  return (
    <div>
      <TransactionState
        rel="noreferrer" 
        target="_blank"
        href={getExplorerLink(chainId, hash, ExplorerDataType.TRANSACTION)}
        pending={pending}
        success={success}
      >
        <RowFixed>
          <TransactionStatusText>
            <TransactionSummary info={info} /> ↗
          </TransactionStatusText>
        </RowFixed>
        <IconWrapper pending={pending} success={success}>
          {pending ? <Loader /> : success ? <CheckCircle size="16" /> : <Triangle size="16" />}
        </IconWrapper>
      </TransactionState>
    </div>
  );
}
