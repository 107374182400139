import { CoinbaseWallet } from '@web3-react/coinbase-wallet';
import { initializeConnector, Web3ReactHooks } from '@web3-react/core';
import { GnosisSafe } from '@web3-react/gnosis-safe';
import { MetaMask } from '@web3-react/metamask';
import { Network } from '@web3-react/network';
import { Connector } from '@web3-react/types';
import { WalletConnect } from '@web3-react/walletconnect';
import { SupportedChainId } from '../constants/chains';

import FINAPE_LOGO_URL from '../assets/icons/icon.png';
import WALLET_CONNECT_ICON from '../assets/wallets/walletconnect-icon.svg'
import { RPC_URLS } from '../constants/networks';
import { RPC_PROVIDERS } from '../constants/providers';
import { isMobile, isNonIOSPhone } from '../utils/userAgent'
import { getIsCoinbaseWallet, getIsMetaMaskWallet } from './utils'

import { WalletConnectV2 } from './WalletConnectV2'
import { Connection } from './types'

export const ConnectionType = {
  INJECTED: 'INJECTED',
  COINBASE_WALLET: 'COINBASE_WALLET',
  WALLET_CONNECT_V2: 'WALLET_CONNECT_V2',
  NETWORK: 'NETWORK',
  GNOSIS_SAFE: 'GNOSIS_SAFE',
  UNISAT_CONNECT: 'UNISAT_CONNECT',
  XVERSE_CONNECT: 'XVERSE_CONNECT',
  PHANTOM_CONNECT: 'PHANTOM_CONNECT',
  SOLFLARE_CONNECT: 'SOLFLARE_CONNECT',
};

function onError(error) {
  console.debug(`web3-react error: ${error}`);
}

const getIsCoinbaseWalletBrowser = () => isMobile && getIsCoinbaseWallet()
const getIsMetaMaskBrowser = () => isMobile && getIsMetaMaskWallet()
const getIsInjectedMobileBrowser = () => getIsCoinbaseWalletBrowser() || getIsMetaMaskBrowser()

const [web3Network, web3NetworkHooks] = initializeConnector((actions) => new Network({ actions, urlMap: RPC_PROVIDERS, defaultChainId: 1 }));
export const networkConnection = {
  connector: web3Network,
  hooks: web3NetworkHooks,
  type: ConnectionType.NETWORK,
};

const [web3Injected, web3InjectedHooks] = initializeConnector((actions) => new MetaMask({ actions, onError }));
export const injectedConnection = {
  connector: web3Injected,
  hooks: web3InjectedHooks,
  type: ConnectionType.INJECTED,
};

const [web3GnosisSafe, web3GnosisSafeHooks] = initializeConnector((actions) => new GnosisSafe({ actions }));
export const gnosisSafeConnection = {
  connector: web3GnosisSafe,
  hooks: web3GnosisSafeHooks,
  type: ConnectionType.GNOSIS_SAFE,
};


const [web3WalletConnect, web3WalletConnectHooks] = initializeConnector((actions) => {
  const RPC_URLS_WITHOUT_FALLBACKS = Object.entries(RPC_URLS).reduce(
    (map, [chainId, urls]) => ({
      ...map,
      [chainId]: urls[0],
    }),
    {}
  );
  return new WalletConnect({
    actions,
    options: {
      rpc: RPC_URLS_WITHOUT_FALLBACKS,
      qrcode: true,
    },
    onError,
  });
});
export const walletConnectConnection = {
  connector: web3WalletConnect,
  hooks: web3WalletConnectHooks,
  type: ConnectionType.WALLET_CONNECT,
};


export const walletConnectV2Connection = new (class extends Connection {
  constructor() {
    super();
    this.initializer = (actions, defaultChainId = SupportedChainId.MAINNET) =>
      new WalletConnectV2({ actions, defaultChainId, onError });
    this.type = ConnectionType.WALLET_CONNECT_V2;
    this.getName = () => 'WalletConnect';
    this.getIcon = () => WALLET_CONNECT_ICON;
    this.shouldDisplay = () => !getIsInjectedMobileBrowser();

    this._connector = initializeConnector(this.initializer);
    this.overrideActivate = (chainId) => {
      // Always re-create the connector, so that the chainId is updated.
      this._connector = initializeConnector((actions) => this.initializer(actions, chainId));
      return false;
    };
  }

  get connector() {
    return this._connector[0];
  }

  get hooks() {
    return this._connector[1];
  }
})();

const [web3CoinbaseWallet, web3CoinbaseWalletHooks] = initializeConnector((actions) =>
  new CoinbaseWallet({
    actions,
    options: {
      url: RPC_URLS[SupportedChainId.MAINNET][0],
      appName: 'FINAPE',
      appLogoUrl: FINAPE_LOGO_URL,
      reloadOnDisconnect: false,
    },
    onError,
  })
);
export const coinbaseWalletConnection = {
  connector: web3CoinbaseWallet,
  hooks: web3CoinbaseWalletHooks,
  type: ConnectionType.COINBASE_WALLET,
};


export const unisatConnectConnection = {
  connector: undefined,
  hooks: web3CoinbaseWalletHooks,
  type: ConnectionType.UNISAT_CONNECT,
};


export const xverseConnectConnection = {
  connector: undefined,
  hooks: web3CoinbaseWalletHooks,
  type: ConnectionType.XVERSE_CONNECT,
};

export const phantomConnectConnection = {
  connector: undefined,
  hooks: web3CoinbaseWalletHooks,
  type: ConnectionType.PHANTOM_CONNECT,
};

export const solflareConnectConnection = {
  connector: undefined,
  hooks: web3CoinbaseWalletHooks,
  type: ConnectionType.SOLFLARE_CONNECT,
};

export function getConnections() {
  return [
    injectedConnection,
    walletConnectV2Connection,
    coinbaseWalletConnection,
    gnosisSafeConnection,
    networkConnection,
    unisatConnectConnection,
    xverseConnectConnection,
    phantomConnectConnection,
  ]
}

export function getConnection(c) {
  if (c instanceof Connector) {
    const connection = getConnections().find((connection) => connection.connector === c)
    if (!connection) {
      throw Error('unsupported connector')
    }
    return connection
  } else {
    switch (c) {
      case ConnectionType.INJECTED:
        return injectedConnection
      case ConnectionType.COINBASE_WALLET:
        return coinbaseWalletConnection
      case ConnectionType.WALLET_CONNECT_V2:
        return walletConnectV2Connection
      case ConnectionType.NETWORK:
        return networkConnection
      case ConnectionType.GNOSIS_SAFE:
        return gnosisSafeConnection
      case ConnectionType.UNISAT_CONNECT:
        return unisatConnectConnection
      case ConnectionType.XVERSE_CONNECT:
        return xverseConnectConnection
      case ConnectionType.PHANTOM_CONNECT:
        return phantomConnectConnection
      case ConnectionType.SOLFLARE_CONNECT:
        return solflareConnectConnection
    }
  }
}

export function getConnectionName(connectionType, hasMetaMaskExtension = getIsMetaMaskWallet()) {
  switch (connectionType) {
    case ConnectionType.INJECTED:
      return hasMetaMaskExtension ? 'MetaMask' : 'Browser Wallet'
    case ConnectionType.COINBASE_WALLET:
      return 'Coinbase Wallet'
    case ConnectionType.WALLET_CONNECT_V2:
      return 'WalletConnect'
    case ConnectionType.NETWORK:
      return 'Network'
    case ConnectionType.GNOSIS_SAFE:
      return 'Gnosis Safe'
    case ConnectionType.UNISAT_CONNECT:
      return 'Unisat Wallet'
    case ConnectionType.XVERSE_CONNECT:
      return 'Xverse Wallet'
    case ConnectionType.PHANTOM_CONNECT:
      return 'Phantom Wallet'
    case ConnectionType.SOLFLARE_CONNECT:
      return 'Solflare Wallet'
  }
}
