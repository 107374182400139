import { useState } from "react";
import styled from "styled-components";
import { Icons } from "../../components";

const DropdownListBlock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 7px 38px 7px 7px;
  background-color: transparent;
  border-radius: 14px;
  cursor: pointer;

  &.color {
    background-color: #ebedf2;
  }

  .dropdown {
    &__link {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;

      &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background-color: #2d2d2d;
        margin-right: 7px;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__value {
        font-family: var(--font-gilroy);
        font-size: 18px;
        line-height: 21px;
        font-weight: 600;
        color: #2d2d2d;
      }

      &__arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        color: #000000;
      }
    }

    &__list {
      position: absolute;
      top: 100%;
      left: 0;
      width: 230px;
      display: none;
      z-index: 1;
      background-color: white;
      border: 1px solid #c1cbd9;
      border-radius: 14px;

      &.open {
        display: block;
      }

      &__item {
        &:not(:last-child) {
          border-bottom: 1px solid #c1cbd9;
        }
      }

      &__article {
        display: flex;
        align-items: center;
        padding: 8px 7px;
        cursor: pointer;
      }

      &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background-color: #2d2d2d;
        margin-right: 7px;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__value {
        flex: 1;
        font-family: var(--font-gilroy);
        font-size: 18px;
        line-height: 21px;
        font-weight: 600;
        color: #2d2d2d;
      }
    }
  }
`;

const DropdownList = (props) => {
  const { data, color } = props;
  const [value, setValue] = useState(data[0]["name"]);
  const [dropdown, setDropdown] = useState(false);

  return (
    <DropdownListBlock className={color ? "color" : ""}>
      <div className="dropdown__link" onClick={() => setDropdown(!dropdown)}>
        <div className="dropdown__link__icon"></div>
        <div className="dropdown__link__value">{value}</div>
        <div className="dropdown__link__arrow">
          <Icons.ChevronDown />
        </div>
      </div>
      <div className={`dropdown__list ${dropdown ? "open" : ""}`}>
        {data?.map((item, index) => {
          return (
            <div key={index} className="dropdown__list__item">
              <div
                onClick={() => {
                  setDropdown(false);
                  setValue(item.name);
                }}
                className="dropdown__list__article"
              >
                <div className="dropdown__list__icon"></div>
                <div className="dropdown__list__value">{item.name}</div>
              </div>
            </div>
          );
        })}
      </div>
    </DropdownListBlock>
  );
};

export default DropdownList;
