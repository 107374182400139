import React, { Component, useEffect } from "react";
import styled from "styled-components";
import { useTransition, animated, useSpringRef } from "react-spring";

import { Layout, Buttons, MintSlider } from "../../components";
import Footer from "../../components/Footer";

import { usePartyModeManager } from "../../state/user/hooks";

import LolApePtw from "../LolApePtw";
import LolApeParty from "../LolApeParty";

const Content = styled.div`
  flex: 1;

  .transitionComponent {
      position: absolute;
      width: 100%;
      height: calc(100vh - 100px);
      will-change: transform, opacity;
  }
`;

const LolApeHome = () => {
  const [isPartyMode] = usePartyModeManager();

  const transRef = useSpringRef()

  const transitions = useTransition(isPartyMode, {
    ref: transRef,
    keys: null,
    initial: null,
    from: { opacity: 0, transform: isPartyMode ? 'translate3d(100%,0,0)' : "translate3d(-50%,0,0)" },
    enter: { opacity: 1, transform: isPartyMode ? 'translate3d(0%,0,0)' : "translate3d(0%,0,0)" },
    leave: { opacity: 0, transform: isPartyMode ? 'translate3d(-50%,0,0)' : "translate3d(100%,0,0)" },
  })

  useEffect(() => {
    transRef.start()
  }, [isPartyMode])

  return (
    <Layout footer={!isPartyMode && <Footer className="d-none d-md-block" />}>
      <Content>
        {transitions((styles, mode) => {
          return (
            <animated.div className="transitionComponent" style={styles}>
              {mode ? <LolApeParty /> : <LolApePtw />}
            </animated.div>
          )
        })}
      </Content>
    </Layout>
  );
};

export default LolApeHome;
