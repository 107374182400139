import React from "react";
import PropTypes from "prop-types";

const Nft = ({ color = "currentColor", className, ...props }) => {
  return (
    <svg
      width="256"
      height="256"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
	    <path stroke={color} fill={props.fill ? props.fill : "#FFFFFF"} d="M 45 90 c -1.206 0 -2.412 -0.311 -3.488 -0.931 L 8.58 70.054 c -2.152 -1.241 -3.488 -3.556 -3.488 -6.04 V 25.987 c 0 -2.485 1.337 -4.8 3.489 -6.041 L 41.512 0.932 c 2.152 -1.242 4.824 -1.243 6.977 0 L 81.42 19.945 c 2.151 1.241 3.488 3.556 3.488 6.041 v 38.027 c 0 2.485 -1.337 4.8 -3.489 6.041 L 48.488 89.068 C 47.412 89.689 46.206 90 45 90 z M 45 5.998 c -0.168 0 -0.336 0.043 -0.487 0.13 L 11.58 25.142 c -0.301 0.174 -0.488 0.498 -0.488 0.845 v 38.027 c 0 0.347 0.187 0.671 0.487 0.844 l 32.933 19.015 c 0.3 0.172 0.674 0.174 0.975 0 L 78.42 64.859 c 0.301 -0.174 0.487 -0.497 0.487 -0.845 V 25.987 c 0 -0.348 -0.187 -0.671 -0.486 -0.844 L 45.487 6.128 C 45.337 6.041 45.168 5.998 45 5.998 z" />
  		<path stroke={color} fill={props.fill ? props.fill : "#FFFFFF"} d="M 32.122 58.462 c -1.019 0 -1.992 -0.521 -2.549 -1.418 l -7.438 -11.983 v 10.401 c 0 1.657 -1.343 3 -3 3 c -1.657 0 -3 -1.343 -3 -3 V 34.538 c 0 -1.34 0.889 -2.518 2.177 -2.885 c 1.292 -0.365 2.666 0.165 3.372 1.303 l 7.438 11.983 V 34.538 c 0 -1.657 1.343 -3 3 -3 c 1.657 0 3 1.343 3 3 v 20.924 c 0 1.34 -0.889 2.518 -2.177 2.885 C 32.672 58.425 32.395 58.462 32.122 58.462 z" />
  		<path stroke={color} fill={props.fill ? props.fill : "#FFFFFF"} d="M 42.236 58.462 c -1.657 0 -3 -1.343 -3 -3 V 34.538 c 0 -1.657 1.343 -3 3 -3 h 8.321 c 1.657 0 3 1.343 3 3 s -1.343 3 -3 3 h -5.321 v 17.924 C 45.236 57.119 43.893 58.462 42.236 58.462 z" />
  		<path stroke={color} fill={props.fill ? props.fill : "#FFFFFF"} d="M 50.557 48 h -8.321 c -1.657 0 -3 -1.343 -3 -3 c 0 -1.657 1.343 -3 3 -3 h 8.321 c 1.657 0 3 1.343 3 3 C 53.557 46.657 52.214 48 50.557 48 z" />
  		<path stroke={color} fill={props.fill ? props.fill : "#FFFFFF"} d="M 70.865 37.538 H 58.974 c -1.657 0 -3 -1.343 -3 -3 s 1.343 -3 3 -3 h 11.891 c 1.657 0 3 1.343 3 3 S 72.522 37.538 70.865 37.538 z" />
  		<path stroke={color} fill={props.fill ? props.fill : "#FFFFFF"} d="M 64.919 58.462 c -1.657 0 -3 -1.343 -3 -3 V 34.538 c 0 -1.657 1.343 -3 3 -3 c 1.657 0 3 1.343 3 3 v 20.924 C 67.919 57.119 66.577 58.462 64.919 58.462 z" />
    </svg>
  );
};

Nft.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export default Nft;
