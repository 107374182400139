import styled from "styled-components";
import { Layout, Footer, Buttons, Icons } from "../../components";

const StakeRow = styled.div`
  padding-top: 20px;
  padding-bottom: 30px;

  @media only screen and (min-width: 768px) {
    padding-top: 33px;
    padding-bottom: 60px;
  }

  h1,
  .h1 {
    margin: 0 0 20px;
    font-family: var(--font-gilroy);
    font-size: 26px;
    line-height: 31px;
    font-weight: 700;
    letter-spacing: -0.02em;
    color: #2d2d2d;

    @media only screen and (min-width: 768px) {
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 32px;
    }
  }

  h2,
  .h2 {
    margin: 0 0 48px;
    font-family: var(--font-gilroy);
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    letter-spacing: -0.02em;
    color: #2d2d2d;

    @media only screen and (min-width: 768px) {
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 42px;
    }
  }

  h3,
  .h3 {
    margin: 0 0 17px;
    font-family: var(--font-gilroy);
    font-size: 18px;
    line-height: 21px;
    font-weight: 600;
    letter-spacing: -0.02em;
    color: #2d2d2d;

    @media only screen and (min-width: 768px) {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 14px;
    }
  }

  .u-btn-stake-unstake {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;

    @media only screen and (min-width: 768px) {
      width: auto;
      margin-left: auto;
    }
  }

  .stake {
    &__section {
      max-width: 1000px;
      width: 100%;
      margin: 0 auto;
    }

    &__row {
      position: relative;
    }

    &__item {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    &__article {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 20px;
      border: 1px solid #e8eaef;
      border-radius: 12px;

      @media only screen and (min-width: 768px) {
        flex-wrap: nowrap;
      }

      @media only screen and (min-width: 1024px) {
        padding-left: 32px;
        padding-right: 32px;
      }
    }

    &__name {
      display: flex;
      align-items: center;
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 15px;

      @media only screen and (min-width: 768px) {
        flex: 0 0 29%;
        max-width: 29%;
        margin-bottom: 0;
        padding-right: 10px;
      }

      &__figure {
        padding: 0;
        margin: 0 24px 0 0;
        width: 60px;
        height: 60px;
        background-color: #0d2360;
        border-radius: 50%;

        @media only screen and (min-width: 1024px) {
          margin-right: 60px;
          width: 80px;
          height: 80px;
        }

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .stake__text {
        @media only screen and (max-width: 767px) {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }

    &__label {
      font-family: var(--font-gilroy);
      font-size: 18px;
      line-height: 21px;
      font-weight: 600;
      color: #848994;
    }

    &__text {
      font-family: var(--font-gilroy);
      font-size: 18px;
      line-height: 21px;
      font-weight: 600;
      color: #2d2d2d;

      @media only screen and (min-width: 1024px) {
        font-size: 20px;
        line-height: 24px;
      }
    }

    &__apytyl {
      display: flex;
      flex-direction: column;
      flex: 0 0 100%;
      max-width: 100%;

      @media only screen and (min-width: 768px) {
        flex-direction: row;
        flex: 0 0 23%;
        max-width: 23%;
        padding-right: 10px;
      }

      &__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;

        @media only screen and (min-width: 768px) {
          align-items: normal;
          flex-direction: column;
          justify-content: normal;
          margin-bottom: 0;

          &:not(:last-child) {
            margin-right: 30px;
          }
        }
      }

      .stake__label {
        @media only screen and (min-width: 768px) {
          margin-bottom: 8px;
        }
      }
    }

    &__balance {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 28px;

      @media only screen and (min-width: 768px) {
        flex: 0 0 auto;
        max-width: none;
        margin-bottom: 0;
        padding-right: 10px;
      }

      &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media only screen and (min-width: 768px) {
          justify-content: normal;
        }

        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }

      .stake__label {
        margin-right: 18px;
      }
    }
  }
`;

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  padding-left: 15px;
  padding-right: 15px;

  @media only screen and (min-width: 1024px) {
    padding-left: 40px;
    padding-right: 40px;
  }
`;

const Stake = () => {
  return (
    <Layout footer={<Footer />}>
      <StakeRow>
        <Container>
          <h1>Stake</h1>

          <div className="stake__section">
            <h2>8 Available Staking Opportunities</h2>

            <div className="stake__row">
              {[...Array(6)].map((_, index) => {
                return (
                  <div key={index} className="stake__item">
                    <h3>Stake iBuSHD to earn rewards</h3>

                    <div className="stake__article">
                      <div className="stake__name">
                        <figure className="stake__name__figure"></figure>
                        <div className="stake__text">iBuSHD</div>
                      </div>

                      <div className="stake__apytyl">
                        <div className="stake__apytyl__item">
                          <div className="stake__label">APY</div>
                          <div className="stake__text">0.293%</div>
                        </div>

                        <div className="stake__apytyl__item">
                          <div className="stake__label">TYL</div>
                          <div className="stake__text">15.43M</div>
                        </div>
                      </div>

                      <div className="stake__balance">
                        <div className="stake__balance__item">
                          <div className="stake__label">Staked Balance</div>
                          <div className="stake__text">15.43M</div>
                        </div>

                        <div className="stake__balance__item">
                          <div className="stake__label">Earned Balance</div>
                          <div className="stake__text">15.43M</div>
                        </div>
                      </div>

                      <Buttons.Basic
                        variant="chevron-down"
                        title="Stake / Unstake"
                        className="u-btn-stake-unstake"
                        icon={<Icons.ChevronDown />}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Container>
      </StakeRow>
    </Layout>
  );
};

export default Stake;
