import React, { useEffect, useState, useRef } from "react";
import LazyLoad from "react-lazyload";
import { useNavigate } from 'react-router-dom';
import ExifReader from 'exifreader';

import useDebounce from "../../hooks/useDebounce";
import { Buttons, Layout, LoaderWithSparkles } from "../../components";
import Icons from "../../components/Icons";
import { MouseoverTooltip } from '../../components/Tooltip'
import { padNumber } from "../../utils/helpers";
import { isMobile } from '../../utils/userAgent';
import Alert from "../../components/Alert";
import { useGalleryRefreshManager } from '../../state/user/hooks';

import NFTDetails from './NftDetailsModal'

import {
  NFTExplorerRow,
  Container,
  LayoutSection,
} from "./styled";

import nft_placeholder from "../../assets/images/nft/placeholder.jpg";

const GalleryHidden = () => {
  const ref = useRef();
  const navigate = useNavigate();

  const [selectedImage, setSelectedImage] = useState(null);
  const [imgDetails, setImgDetails] = useState(null);
  const [exifData, setExifData] = useState(null);

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    //console.log(file)
    readFile(file);
  };

  const handleContainerClick = () => {
    //document.getElementById('images').click(); // Trigger file input click
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    readFile(file);
  };

  const restart = () => {
    setSelectedImage(null);
    setImgDetails(null);
    setExifData(null);
  };

  const readFile = async (file) => {
    setSelectedImage(file);
    
    try {
      // Read the file information
      const { name, type } = file;
      const img = new Image();

      img.onload = function () {
        const { width, height } = this;
        setImgDetails({name, type, width, height, src: img.src}); 
      };
      img.src = URL.createObjectURL(file);

      // Read the file as an ArrayBuffer
      const data = await ExifReader.load(file);
      const userComment = data['UserComment']?.description;
      const unprocessedTagValue = data['UserComment']?.value;

      //console.log(data)

      if (userComment) {
        // Extract the content from the data by removing the first 8 bytes
        const content = unprocessedTagValue.slice(8);

        const utf16String = String.fromCharCode.apply(null, content);

        let parsedContent;
        try {
          parsedContent = JSON.parse(utf16String);
        } catch (error) {
          console.error('Error parsing content as JSON:', error);
          parsedContent = null;
        }

        setExifData(parsedContent);
      }
    } catch (e) {
      
    }
  };

  return (
    <Layout>
      <NFTExplorerRow>
        <Container>
          <h1>NFT Reader</h1>
          {!selectedImage ? (
            <div className="drop-container" onDragOver={handleDragOver} onDrop={handleDrop} onClick={handleContainerClick}>

              <span className="drop-title">
                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="22" width="22" xmlns="http://www.w3.org/2000/svg"><polyline points="16 16 12 12 8 16"></polyline><line x1="12" y1="12" x2="12" y2="21"></line><path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path><polyline points="16 16 12 12 8 16"></polyline></svg>
                &nbsp;&nbsp;
                Drop file here
              </span>
              or
              <input type="file" id="images" accept="image/*" onInput={handleFileSelect} />
              <span className="drop-subtitle">Accepted file types: WEBP (recommended), JPEG, PNG, SVG, and GIF.</span>
            </div>
          ) : (
            <div>
              <Buttons.BasicV2
                variant="back"
                title="⬅&nbsp;&nbsp;Start Over"
                style={{width: 120, fontSize: 14, marginRight: 10, marginBottom: 10}}
                onClick={restart}
              />
              <NFTDetails imgDetails={imgDetails} exifData={exifData} />

              {exifData && (
                <div>
                  <h3>Raw NFT metadata:</h3>
                  <pre>{JSON.stringify(exifData, null, 2)}</pre>
                </div>
              )}

            </div>
          )}
        </Container>
      </NFTExplorerRow>
    </Layout>
  );
};

export default GalleryHidden;
