import React from "react";
import PropTypes from "prop-types";

const ChevronDown = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1 1.5L6 6.5L11 1.5"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

ChevronDown.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export default ChevronDown;
