import styled, { css, keyframes } from "styled-components";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import Icons from "../../../components/Icons";

const sparkle = keyframes`
  0% {
    transform: scale(0.1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0.1);
  }
`;

export const SparklesIcon = styled(Icons.Sparkles)`
  animation: ${sparkle} 1s ease-in-out infinite;
  position: absolute; 
  top: -7px;
  right: 0px;
  width: 36px;
  height: 42px;
  margin-top: 0;
`;

export const StyledModal = styled(Modal)`
  position: relative;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  overflow: visible;
  outline: none;
  max-width: 420px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border-radius: 10px;

  @media only screen and (max-width: 767px) {
    width: 80vw;
  }

  @media only screen and (min-width: 768px) {
    max-width: 700px;
  }
  @media only screen and (min-width: 1024px) {
    max-width: 900px;
  }
  @media only screen and (min-width: 1280px) {
    max-width: 1160px;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto; 
  max-height: 80vh; 

  @media screen and (min-width: 768px) {
    flex-direction: row;
    max-height: 348px; 
  }

  @media screen and (min-width: 1024px) {
    max-height: 448px;
  }

  @media only screen and (min-width: 1280px) {
    max-height: 578px;
  }
`;

export const LeftBox = styled.div`
  flex: 1; 

  @media screen and (min-width: 768px) {
    height: 348px;
    width: 50%;
  }

  @media screen and (min-width: 1024px) {
    height: 448px;
  }

  @media only screen and (min-width: 1280px) {
    height: 578px;
  }

  .minted-label {
    position: absolute;
    top: 20px;
    left: 20px;
    color: white;
    font-weight: bold;
    font-size: 16px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    padding: 10px; 
  }
`;

export const RightBox = styled.div`
  flex: 1; 
  overflow-y: scroll;
  overflow-x: hidden;

  @media screen and (min-width: 768px) {
    height: 348px;
    width: 50%;
  }

  @media screen and (min-width: 1024px) {
    height: 448px;
  }

  @media only screen and (min-width: 1280px) {
    height: 578px;
  }
`;

export const Card = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: auto;
  text-align: left;
  position: relative;

  .imageHolder {
    padding: 0;

    .nftImage {
      width: 100%;
      border-top-left-radius: 10px;

      ${(props) =>
        props.mintButtonHidden &&
        css`
          border-bottom-left-radius: 10px;
        `}

      @media screen and (max-width: 768px) {
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .video-container {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 100%; /* 16:9 aspect ratio */
    }

    .video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;

      border-top-left-radius: 10px;

      ${(props) =>
        props.mintButtonHidden &&
        css`
          border-bottom-left-radius: 10px;
        `}

      @media screen and (max-width: 768px) {
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .attributeContainer {
    margin: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .attributeDiv {
      width: 50%;
      padding: 4px;
      flex: 0 0 calc(100% / 2);

      .attributeBox {
        border: 1px solid #15b2e5;
        background-color: rgba(21, 178, 229, 0.06);
        height: 73px;

        .title {
          color: #666;
          font-size: 11px;
          text-transform: uppercase;
          margin: 5px 10px;
        }

        .attribute {
          color: #000;
          font-size: 14px;
          margin: 5px 10px;
          text-transform: uppercase;
          font-weight: bold;
        }
      }
    }
  }
`;

export const NormalizedSpan = styled.span`
  font-weight: normal
`;

export const MintButton = styled(Link)`
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #04aa6d;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
  margin: 0;
  position: absolute; 
  bottom: 0; 
  left: 0;
  text-decoration: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  &:hover {
    opacity: 0.9;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: -14px;
  right: -14px;
  border-radius: 15px;
  border: 1px solid #999;
  color: #666;
  height: 28px;
  width: 28px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  z-index: 1;

  @media only screen and (max-width: 767px) {
    top: -20px;
    right: -20px;
    font-size: 25px;
    border-radius: 20px;
    height: 40px;
    width: 40px;
    line-height: 25px;
  }
`;

export const TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-left: 15px;
  text-align: left; 

  .label {
    color: grey;
    font-size: 14px;
  }

  .nftId {
    margin: 15px auto 10px;
    font-size: 34px;
    font-weight: bold;
  }
`;


export const Membership = styled.div`
  color: #000;
  font-size: 18px;
  padding: 10px;
  line-height: 1.2;
  border-radius: 6px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &.selected{
    border: 5px solid #000;
  }

  .Radio {
    /* Styles for the left box */
    width: 10%; /* Adjust the width as needed */
  }

  .RadioLabel {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .LeftBox {
      flex: 1;
    }

    .RightBox {
      flex: 1;
    }
  }

  /* Variant styles */
  ${({ variant }) => variant === 'Silver' && css`
    background: #F2F3F4;
    background: linear-gradient(to bottom, #F2F3F4 0%, #E3E4E5 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #F2F3F4), color-stop(100%, #E3E4E5));
    background: -webkit-linear-gradient(top, #F2F3F4 0%, #E3E4E5 100%);
    background: -moz-linear-gradient(top, #F2F3F4 0%, #E3E4E5 100%);
    background: -o-linear-gradient(top, #F2F3F4 0%, #E3E4E5 100%);
    background: -ms-linear-gradient(top, #F2F3F4 0%, #E3E4E5 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F2F3F4', endColorstr='#E3E4E5', GradientType=0);
    border: 1px solid #D4D5D6;
    text-shadow: 0 1px 0 #FFFFFF;
    -webkit-text-shadow: 0 1px 0 #FFFFFF;
    -moz-text-shadow: 0 1px 0 #FFFFFF;
    box-shadow: inset 0 1px 0 #FFFFFF;
    -webkit-box-shadow: inset 0 1px 0 #FFFFFF;
    -moz-box-shadow: inset 0 1px 0 #FFFFFF;
  `}
  ${({ variant }) => variant === 'Gold' && css`
    background: #FFDF00;
    background: linear-gradient(to bottom, #FFDF00 0%, #F0D000 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFDF00), color-stop(100%, #F0D000));
    background: -webkit-linear-gradient(top, #FFDF00 0%, #F0D000 100%);
    background: -moz-linear-gradient(top, #FFDF00 0%, #F0D000 100%);
    background: -o-linear-gradient(top, #FFDF00 0%, #F0D000 100%);
    background: -ms-linear-gradient(top, #FFDF00 0%, #F0D000 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFDF00', endColorstr='#F0D000', GradientType=0);
    border: 1px solid #E1C100;
    text-shadow: 0 1px 0 #FFEE0F;
    -webkit-text-shadow: 0 1px 0 #FFEE0F;
    -moz-text-shadow: 0 1px 0 #FFEE0F;
    box-shadow: inset 0 1px 0 #FFEE0F;
    -webkit-box-shadow: inset 0 1px 0 #FFEE0F;
    -moz-box-shadow: inset 0 1px 0 #FFEE0F;
  `}

  ${({ variant }) => variant === 'Platinum' && css`
    box-shadow:
      2px 2px 0.5em rgba(122, 122, 122, 0.55),
      inset 1px 1px 0 rgba(255, 255, 255, 0.9),
      inset -1px -1px 0 rgba(0, 0, 0, 0.5)
    ;
    border: 1px solid #cacade;
    background:
      -moz-linear-gradient(
      -72deg, #dedeff, #ffffff 16%, #dedeff 21%, #ffffff 24%, #aaaacc 27%, #dedeff 36%, #ffffff 45%, #ffffff 60%, #dedeff 72%, #ffffff 80%, #dedeff 84%, #aaaacc
    );
    background:
    -webkit-linear-gradient(
      -72deg, #dedeff, #ffffff 16%, #dedeff 21%, #ffffff 24%, #aaaacc 27%, #dedeff 36%, #ffffff 45%, #ffffff 60%, #dedeff 72%, #ffffff 80%, #dedeff 84%, #aaaacc
    );
    background:
    -o-linear-gradient(
      -72deg, #dedeff, #ffffff 16%, #dedeff 21%, #ffffff 24%, #aaaacc 27%, #dedeff 36%, #ffffff 45%, #ffffff 60%, #dedeff 72%, #ffffff 80%, #dedeff 84%, #aaaacc
    );
    background:
    linear-gradient(
      -72deg, #dedeff, #ffffff 16%, #dedeff 21%, #ffffff 24%, #aaaacc 27%, #dedeff 36%, #ffffff 45%, #ffffff 60%, #dedeff 72%, #ffffff 80%, #dedeff 84%, #aaaacc
    );
  `}

  ${({ variant }) => variant === 'Elite' && css`
    box-shadow:
      2px 2px 0.5em rgba(155, 122, 89, 0.55),
      inset 1px 1px 0 rgba(255, 255, 255, 0.9),
      inset -1px -1px 0 rgba(0, 0, 0, 0.5)
    ;
    border: 1px solid #deca45;
    background:
    -moz-linear-gradient(
      -72deg, #ffc373, #ffffff 16%, #ffc373 21%, #ffffff 24%, #a17434 27%, #ffc373 36%, #ffffff 45%, #ffffff 60%, #ffc373 72%, #ffffff 80%, #ffc373 84%, #a17434
    );
    background:
    -webkit-linear-gradient(
      -72deg, #ffc373, #ffffff 16%, #ffc373 21%, #ffffff 24%, #a17434 27%, #ffc373 36%, #ffffff 45%, #ffffff 60%, #ffc373 72%, #ffffff 80%, #ffc373 84%, #a17434
    );
    background:
    -o-linear-gradient(
      -72deg, #ffc373, #ffffff 16%, #ffc373 21%, #ffffff 24%, #a17434 27%, #ffc373 36%, #ffffff 45%, #ffffff 60%, #ffc373 72%, #ffffff 80%, #ffc373 84%, #a17434
    );
    background:
    linear-gradient(
      -72deg, #ffc373, #ffffff 16%, #ffc373 21%, #ffffff 24%, #a17434 27%, #ffc373 36%, #ffffff 45%, #ffffff 60%, #ffc373 72%, #ffffff 80%, #ffc373 84%, #a17434
    );
  `}

  .bold {
    text-shadow: 1px 1px 0 #ffffff;
    font-weight: bold; 
  }

  .sub {
    color: #000;
    font-size: 14px;
    word-break: break-all;
  }
`;

export const FormContainer = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;

  .wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 300px;
    
    & label {
      margin-left: 10px;
      color: #000;
      font-size: 16px;
      transition: all .20s ease-in-out;
    }
    
    & input {
      box-sizing: content-box;
      height: 45px;
      border-radius: 4px;
      border: 2px solid #392b58;
      padding: 0 10px;
      font-size: 16px;
      outline: none;
    }
    
    .icon-button {
      top: 27px;
      width: 30px;
      position: absolute;
      right: 14px;
      color: #392b58;
      font-size: 24px;
      opacity: .8;
      transition: all .20s ease-in-out;
      cursor: pointer;
    }
    
    & input:valid {
    }
    
    & input:focus {
      border: 2px solid #938ba3;
    }
    
    & input:focus ~ .icon-button {
      opacity: 1;
    }
    
    & input:valid ~ .icon-button {
      color: whitesmoke;
    }
  }

  .lds-container {
    top: 27px;
    position: absolute;
    right: 14px;
  }
  
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 30px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    margin: 4px;
    border: 4px solid #000;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #000 transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

`;