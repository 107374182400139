import styled from "styled-components";

export const BridgeRow = styled.div`
  padding-top: 20px;
  padding-bottom: 30px;

  @media only screen and (min-width: 768px) {
    padding-top: 33px;
    padding-bottom: 60px;
  }

  h1,
  .h1 {
    margin: 0 0 20px;
    font-family: var(--font-gilroy);
    font-size: 26px;
    line-height: 31px;
    font-weight: 700;
    letter-spacing: -0.02em;
    color: #2d2d2d;

    @media only screen and (min-width: 768px) {
      font-size: 30px;
      line-height: 36px;
    }
  }

  .bridge {
    &__section {
      max-width: 730px;
      width: 100%;
      margin: 0 auto;
    }

    &__item {
      &:not(:last-child) {
        margin-bottom: 28px;
      }
    }

    &__head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      &__dropdown {
        display: flex;
        align-items: center;

        &__label {
          min-width: 40px;
          margin-right: 12px;
          font-family: var(--font-gilroy);
          font-size: 18px;
          line-height: 21px;
          font-weight: 600;
          letter-spacing: -0.02em;
          color: #2d2d2d;
        }
      }
    }

    &__body {
      position: relative;
    }

    &__textarea {
      &__head {
        position: absolute;
        top: 20px;
        left: 23px;
        right: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &__label {
        font-family: var(--font-gilroy);
        font-size: 18px;
        line-height: 21px;
        letter-spacing: -0.02em;
        color: #848994;

        &.tdu {
          text-decoration: underline;
        }
      }

      &__value {
        display: block;
        padding: 50px 24px 0 24px;
        width: 100%;
        border: 1px solid #ebedf2;
        font-family: var(--font-gilroy);
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        letter-spacing: -0.02em;
        color: #2d2d2d;
        border-radius: 14px;
        resize: none;
        outline: none;
        transition: all 0.2s ease;

        &:focus {
          border-color: #c1cbd9;
        }
      }

      &__dropwdown {
        position: absolute;
        right: 14px;
        bottom: 12px;
        z-index: 1;
      }
    }

    &__repeat {
      margin-right: 30px;
      color: #bdc6dc;
      cursor: pointer;

      @media only screen and (max-width: 767px) {
        margin-right: 0;
      }
    }
  }

  .u-btn-transfer {
    width: 100%;
    height: 50px;
    border-radius: 14px;
    font-size: 16px;
    line-height: 17px;
    color: #969eb1;
    background-color: #ebedf2;
    border-color: #ebedf2;

    &:hover {
      background-color: #ebedf2;
      border-color: #ebedf2;
    }
  }
`;

export const Container = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  padding-left: 15px;
  padding-right: 15px;

  @media only screen and (min-width: 1024px) {
    padding-left: 40px;
    padding-right: 40px;
  }
`;


export const RoundedBoxedContainer = styled.div`
  border: 1px solid #c1cbd9;
  border-radius: 8px; 
  padding: 4px 21px;
  height: 36px;
  background-color: #fff; 
  font-size: 16px;
  display: flex; 
  flex-direction: row; 
  align-items: center;
  row-gap: 8px;
  gap: 8px;
  vertical-align: baseline;

  @media only screen and (max-width: 767px) {
    width: 100%;
    height: 36px;
  }

  &:hover {
    border-color: #244ca8;
  }

  .image {
    width: 20px; 
    height: 20px;
  }
`;
