import React from "react";
import PropTypes from "prop-types";

const Facebook = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M25 12.5C25 5.625 19.375 0 12.5 0C5.625 0 0 5.625 0 12.5C0 18.75 4.53125 23.9062 10.4688 24.8438V16.0937H7.34375V12.5H10.4688V9.6875C10.4688 6.5625 12.3438 4.84375 15.1563 4.84375C16.5625 4.84375 17.9688 5.15625 17.9688 5.15625V8.28125H16.4062C14.8438 8.28125 14.375 9.21875 14.375 10.1562V12.5H17.8125L17.1875 16.0937H14.2188V25C20.4688 24.0625 25 18.75 25 12.5Z"
        fill={color}
      />
    </svg>
  );
};

Facebook.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export default Facebook;
