import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import { ChevronDown } from "react-feather";

import TabBar from "../../components/TabBar";

import TokenChartBsc from "../../assets/images/chart.png";
import TokenChartSol from "../../assets/images/chart-solana.png";
import EthIcon from "../../assets/images/ethereum-logo.png";

import ArbIcon from "../../assets/svg/arbitrum_logo.svg";
import BaseIcon from "../../assets/svg/Base_Network_Logo.svg";
import PolygonIcon from "../../assets/svg/polygon-matic-logo.svg";


import AvaxIcon from "../../assets/svg/avalanche.svg";
import BscIcon from "../../assets/svg/bnb-logo.svg";
import SolIcon from "../../assets/svg/solana-sol-logo.svg";

const Section = styled.section`
  background-color: #000;
  padding-top: 90px;
  padding-bottom: 90px;
  color: #fff;
  text-align: center;

  h1 {
    color: #f6bb00;
  }
  h2 {
    color: #fff;
  }
`;

const TokenomicBreakdown = styled.div`
  margin: 0 auto;
  text-align: center;
  line-height: 1.8;
  font-size: 24px;
`;

const RotatingImage = styled.div`
  width: 100%;
  height: 100%;
  max-width: 270px;
  margin: 0 auto;

  @media only screen and (min-width: 600px) {
    max-width: 400px;
  }
`;

export default function FAQ() {
  const [rotation, setRotation] = useState(0);
  const [scrollLoc, setScrollLoc] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const newLoc = window.scrollY;
      const diff = scrollLoc - newLoc;
      setRotation(rotation + diff / 11);
      setScrollLoc(newLoc);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [rotation, scrollLoc]);

  const rotationStr = `rotate(${rotation}deg)`;

  return (
    <Section id="tokenomics">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-lg-12">
            <h1
              className="wow fadeInRightBig"
              data-wow-offset="80"
              data-wow-duration="2s"
            >
              Tokenomics
            </h1>
          </div>
        </div>
      </div>

      <div className="gap-2"></div>
      <div className="container" style={{ position: "relative" }}>
        <TabBar tabs={["Solana", "Cross-chain"]} defaultTab="Solana">
          <div>
            <h2><img src={SolIcon} width={26} />&nbsp;Solana Chain</h2>
            <br />
            <div className="row g-3">
              <TokenomicBreakdown className="col-sm-12 col-lg-6">
                Name:{" "}
                <span className="italic">
                  <span className="color">LOL</span>Ape
                </span>
                <br />
                Symbol: LOL
                <br />
                Decimals: 9
                <br />
                Total Supply: 69 Billion
                <br />
                Tax: 0/0/0
                <br />
                <br />
                <span className="color">Token Distribution:</span>
                <br />
                Presale - 40%
                <br />
                Liquidity - 40%
                <br />
                Marketing & CEX & Airdrop - 20%
                <br />
                <Link
                  to="https://docs.lolape.com/whitepaper/tokenomics/operations-and-funding"
                  target="_blank"
                  className="button"
                  style={{ fontSize: 16, marginTop: 10 }}
                >
                  <span>Operations and Funding</span>&nbsp;&nbsp;
                  <span>
                    <svg
                      loading="eager"
                      fill="CurrentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 48 48"
                      width="16px"
                      height="16px"
                    >
                      <path d="M 40.960938 4.9804688 A 2.0002 2.0002 0 0 0 40.740234 5 L 28 5 A 2.0002 2.0002 0 1 0 28 9 L 36.171875 9 L 22.585938 22.585938 A 2.0002 2.0002 0 1 0 25.414062 25.414062 L 39 11.828125 L 39 20 A 2.0002 2.0002 0 1 0 43 20 L 43 7.2460938 A 2.0002 2.0002 0 0 0 40.960938 4.9804688 z M 12.5 8 C 8.3826878 8 5 11.382688 5 15.5 L 5 35.5 C 5 39.617312 8.3826878 43 12.5 43 L 32.5 43 C 36.617312 43 40 39.617312 40 35.5 L 40 26 A 2.0002 2.0002 0 1 0 36 26 L 36 35.5 C 36 37.446688 34.446688 39 32.5 39 L 12.5 39 C 10.553312 39 9 37.446688 9 35.5 L 9 15.5 C 9 13.553312 10.553312 12 12.5 12 L 22 12 A 2.0002 2.0002 0 1 0 22 8 L 12.5 8 z"></path>
                    </svg>
                  </span>
                </Link>
              </TokenomicBreakdown>
              <div className="col-sm-12 col-lg-6 mx-auto d-block">
                <RotatingImage>
                  <img
                    src={TokenChartSol}
                    width="100%"
                    style={{
                      transform: rotationStr,
                      WebkitTransform: rotationStr,
                      MozTransform: rotationStr,
                    }}
                  />
                </RotatingImage>
              </div>
            </div>
            <div className="gap-2"></div>
            <div className="row g-3">
              <div className="col-sm-12 col-lg-12 wordsplit">
                Solana CA: TBA
              </div>
            </div>
          </div>

          <div>
            <h2>
              <img style={{color: "#fff", fill: "#fff"}} src={BaseIcon} width={24} />&nbsp;Base,&nbsp;
              <img src={EthIcon} width={24} />&nbsp;Ethereum,&nbsp; 
              <img src={BscIcon} width={24} />&nbsp;BSC,&nbsp;
              <br/>
              <img src={PolygonIcon} width={24} />&nbsp;Polygon,&nbsp;
              <img src={ArbIcon} width={24} />&nbsp;Arbitrum,&nbsp;
              <img src={AvaxIcon} width={24} />&nbsp;Avalanche
            </h2>
            <br />
            <div className="row g-3">
              <TokenomicBreakdown className="col-sm-12 col-lg-6">
                Name:{" "}
                <span className="italic">
                  <span className="color">LOL</span>Ape
                </span>
                <br />
                Symbol: LOL
                <br />
                Decimals: 18
                <br />
                Total Supply: 69 Billion
                <br />
                Tax: 3/0/3
                <br />
                <Link
                  to="https://docs.lolape.com/whitepaper/tokenomics/operations-and-funding"
                  target="_blank"
                  className="button"
                  style={{ fontSize: 16, marginTop: 10 }}
                >
                  <span>Operations and Funding</span>&nbsp;&nbsp;
                  <span>
                    <svg
                      loading="eager"
                      fill="CurrentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 48 48"
                      width="16px"
                      height="16px"
                    >
                      <path d="M 40.960938 4.9804688 A 2.0002 2.0002 0 0 0 40.740234 5 L 28 5 A 2.0002 2.0002 0 1 0 28 9 L 36.171875 9 L 22.585938 22.585938 A 2.0002 2.0002 0 1 0 25.414062 25.414062 L 39 11.828125 L 39 20 A 2.0002 2.0002 0 1 0 43 20 L 43 7.2460938 A 2.0002 2.0002 0 0 0 40.960938 4.9804688 z M 12.5 8 C 8.3826878 8 5 11.382688 5 15.5 L 5 35.5 C 5 39.617312 8.3826878 43 12.5 43 L 32.5 43 C 36.617312 43 40 39.617312 40 35.5 L 40 26 A 2.0002 2.0002 0 1 0 36 26 L 36 35.5 C 36 37.446688 34.446688 39 32.5 39 L 12.5 39 C 10.553312 39 9 37.446688 9 35.5 L 9 15.5 C 9 13.553312 10.553312 12 12.5 12 L 22 12 A 2.0002 2.0002 0 1 0 22 8 L 12.5 8 z"></path>
                    </svg>
                  </span>
                </Link>
                <br />
                <br />
                <span className="color">Token Distribution:</span>
                <br />
                Presale - 40%
                <br />
                Liquidity - 40%
                <br />
                Airdrop - 20%
              </TokenomicBreakdown>
              <div className="col-sm-12 col-lg-6 mx-auto d-block">
                <RotatingImage>
                  <img
                    src={TokenChartBsc}
                    width="100%"
                    style={{
                      transform: rotationStr,
                      WebkitTransform: rotationStr,
                      MozTransform: rotationStr,
                    }}
                  />
                </RotatingImage>
              </div>
            </div>
            <div className="gap-2"></div>
            <div className="row g-3">
              <div className="col-sm-12 col-lg-12 wordsplit">
                CA: 0xf4906f6e6e5d9ef0554e74ea64217cd595439f9a
                <br />
                <Link
                  to="https://coinsult.net/projects/lolape/"
                  target="_blank"
                  className="button"
                  style={{ fontSize: 16, marginTop: 10 }}
                >
                  <span>Coinsult Audit</span>&nbsp;&nbsp;
                  <span>
                    <svg
                      loading="eager"
                      fill="CurrentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 48 48"
                      width="16px"
                      height="16px"
                    >
                      <path d="M 40.960938 4.9804688 A 2.0002 2.0002 0 0 0 40.740234 5 L 28 5 A 2.0002 2.0002 0 1 0 28 9 L 36.171875 9 L 22.585938 22.585938 A 2.0002 2.0002 0 1 0 25.414062 25.414062 L 39 11.828125 L 39 20 A 2.0002 2.0002 0 1 0 43 20 L 43 7.2460938 A 2.0002 2.0002 0 0 0 40.960938 4.9804688 z M 12.5 8 C 8.3826878 8 5 11.382688 5 15.5 L 5 35.5 C 5 39.617312 8.3826878 43 12.5 43 L 32.5 43 C 36.617312 43 40 39.617312 40 35.5 L 40 26 A 2.0002 2.0002 0 1 0 36 26 L 36 35.5 C 36 37.446688 34.446688 39 32.5 39 L 12.5 39 C 10.553312 39 9 37.446688 9 35.5 L 9 15.5 C 9 13.553312 10.553312 12 12.5 12 L 22 12 A 2.0002 2.0002 0 1 0 22 8 L 12.5 8 z"></path>
                    </svg>
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </TabBar>
      </div>
    </Section>
  );
}
