import styled from 'styled-components/macro';
import { useCustomWeb3React } from '../../providers/CustomWeb3ReactProvider';

import { SupportedChainId } from '../../constants/chains';
import { useActivePopups } from '../../state/application/hooks';
import PopupItem from './PopupItem';

const MobilePopupWrapper = styled.div`
  position: relative;
  max-width: 100%;
  margin: 0 auto;
  display: none;

  @media screen and (max-width: 720px){
    display: block;
    padding-top: 20px;
  }
`;

const MobilePopupInner = styled.div`
  height: 99%;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const StopOverflowQuery = `@media screen and (min-width: 961px) and (max-width: 1460px)`;

const FixedPopupColumn = styled.div(({ extraPadding, xlPadding }) => ({
  position: 'fixed',
  top: extraPadding ? '72px' : '64px',
  right: '1rem',
  maxWidth: '355px !important',
  width: '100%',
  zIndex: 3,

  [`@media screen and (max-width: 720px)`]: {
    display: 'none',
  },

  [StopOverflowQuery]: {
    top: xlPadding ? '72px' : extraPadding ? '72px' : '64px',
  },
}));

export default function Popups() {
  // get all popups
  const activePopups = useActivePopups();

  // need extra padding if network is not L1 Ethereum
  const { chainId } = useCustomWeb3React();
  const isNotOnMainnet = Boolean(chainId && chainId !== SupportedChainId.MAINNET);

  return (
    <>
      <FixedPopupColumn gap="20px" extraPadding={true} xlPadding={isNotOnMainnet}>
        {activePopups.map((item) => (
          <PopupItem key={item.key} content={item.content} popKey={item.key} removeAfterMs={item.removeAfterMs} />
        ))}
      </FixedPopupColumn>
      {activePopups?.length > 0 && (
        <MobilePopupWrapper>
          <MobilePopupInner>
            {activePopups // reverse so new items up front
              .slice(0)
              .reverse()
              .map((item) => (
                <PopupItem key={item.key} content={item.content} popKey={item.key} removeAfterMs={item.removeAfterMs} />
              ))}
          </MobilePopupInner>
        </MobilePopupWrapper>
      )}
    </>
  );
}
