import { useWeb3React } from '@web3-react/core';
import { Connection } from "@solana/web3.js";

import { useBitcoinWallet, useSolanaWallet, useSelectedNetwork } from '../state/user/hooks';
import { SupportedChainId } from '../constants/chains';
import { RPC_URLS } from '../constants/networks';

export const useCustomWeb3React = () => {
  //const customAccount = useSelector((state) => state.customAccount); // Replace 'customAccount' with the key in your Redux store

  const { account, chainId, connector, provider, ...rest } = useWeb3React();
  const selectedNetwork = useSelectedNetwork();
  const bitcoinWallet = useBitcoinWallet(); 
  const solanaWallet = useSolanaWallet(); 

  const isBitcoin = selectedNetwork == SupportedChainId.BITCOIN; 
  const isSolana = selectedNetwork == SupportedChainId.SOLANA; 

  const getSolanaProvider = () => {
    if ('phantom' in window) {
      const provider = window.phantom?.solana;

      if (provider?.isPhantom) {
        return provider;
      }
    }

    //window.open('https://phantom.app/', 'getPhantom');
  };

  const customOverride = {
    ...rest,
    solanaConnection: isSolana ? new Connection(RPC_URLS[SupportedChainId.SOLANA][0]) : undefined,
    provider: isSolana ? getSolanaProvider() : provider,
    chainId: isBitcoin ? -1 : (isSolana ? 501424 : chainId),
    connector: isBitcoin || isSolana ? undefined : connector,
    account: isBitcoin ? bitcoinWallet?.account : (isSolana ? solanaWallet?.account : account),
  }
  //console.log(customOverride)
  return customOverride;
};
