import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-modal";

import styled, { css } from "styled-components";

import { padNumber } from "../../../utils/helpers";
import nft_placeholder from "../../../assets/images/nft/placeholder.jpg";
import { MouseoverTooltip } from '../../../components/Tooltip'
import ImageLoader from '../../../components/ImageLoader'
import { Buttons } from '../../../components'
import Alert from "../../../components/Alert";

const StyledModal = styled(Modal)`
  position: relative;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  overflow: visible;
  outline: none;
  max-width: 420px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border-radius: 10px;

  @media only screen and (max-width: 767px) {
    width: 80vw;
  }

  @media only screen and (min-width: 768px) {
    max-width: 700px;
  }
  @media only screen and (min-width: 1024px) {
    max-width: 900px;
  }
  @media only screen and (min-width: 1280px) {
    max-width: 1160px;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto; 
  max-height: 80vh; 

  @media screen and (min-width: 768px) {
    flex-direction: row;
    max-height: 348px; 
  }

  @media screen and (min-width: 1024px) {
    max-height: 448px;
  }

  @media only screen and (min-width: 1280px) {
    max-height: 578px;
  }
`;

const LeftBox = styled.div`
  flex: 1; 

  @media screen and (min-width: 768px) {
    height: 348px;
    width: 50%;
  }

  @media screen and (min-width: 1024px) {
    height: 448px;
  }

  @media only screen and (min-width: 1280px) {
    height: 578px;
  }
`;

const RightBox = styled.div`
  flex: 1; 
  overflow-y: scroll;

  @media screen and (min-width: 768px) {
    height: 348px;
    width: 50%;
  }

  @media screen and (min-width: 1024px) {
    height: 448px;
  }

  @media only screen and (min-width: 1280px) {
    height: 578px;
  }
`;

const Card = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: auto;
  text-align: left;
  position: relative;

  .imageHolder {
    padding: 0;

    .nftImage {
      width: 100%;
      border-top-left-radius: 10px;

      ${(props) =>
        props.mintButtonHidden &&
        css`
          border-bottom-left-radius: 10px;
        `}

      @media screen and (max-width: 768px) {
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .video-container {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 100%; /* 16:9 aspect ratio */
    }

    .video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;

      border-top-left-radius: 10px;

      ${(props) =>
        props.mintButtonHidden &&
        css`
          border-bottom-left-radius: 10px;
        `}

      @media screen and (max-width: 768px) {
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .attributeContainer {
    margin: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .attributeDiv {
      width: 50%;
      padding: 4px;
      flex: 0 0 calc(100% / 2);

      .attributeBox {
        border: 1px solid #15b2e5;
        background-color: rgba(21, 178, 229, 0.06);
        height: 73px;

        .title {
          color: #666;
          font-size: 11px;
          text-transform: uppercase;
          margin: 5px 10px;
        }

        .attribute {
          color: #000;
          font-size: 14px;
          margin: 5px 10px;
          text-transform: uppercase;
          font-weight: bold;
        }
      }
    }
  }
`;

const NormalizedSpan = styled.span`
  font-weight: normal
`;

const MintButton = styled(Link)`
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #04aa6d;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
  margin: 0;
  position: absolute; 
  bottom: 0; 
  left: 0;
  text-decoration: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  &:hover {
    opacity: 0.9;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: -14px;
  right: -14px;
  border-radius: 15px;
  border: 1px solid #999;
  color: #666;
  height: 28px;
  width: 28px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  z-index: 1;

  @media only screen and (max-width: 767px) {
    top: -20px;
    right: -20px;
    font-size: 25px;
    border-radius: 20px;
    height: 40px;
    width: 40px;
    line-height: 25px;
  }
`;

const TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-left: 15px;
  text-align: left; 

  .label {
    color: grey;
    font-size: 14px;
  }

  .nftId {
    margin: 15px auto 10px;
    font-size: 34px;
    font-weight: bold;
  }
`;

const customStyles = {
  /*
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    margin: "0",
    padding: "0",
    overflow: "visible",
  },
  */
  overlay: {
    backgroundColor: "rgba(32, 40, 46, 0.5)",
  },
};

const NftDetailsModal = (props) => {
  const navigate = useNavigate();

  const { 
    showBridgeButton, 
    showMintButton, 
    isOpen, 
    onRequestClose, 
    notRevealed, 
    queryBy = 'tokenId', 
    id 
  } = props;

  const [nftDetails, setNftDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!isLoading) {
        setNftDetails({})
        setIsLoading(true);

        try {
          let url = (queryBy == "tokenId") ? "https://api.finape.com/public/gallery/token/" + id : "https://api.finape.com/public/gallery/seq/" + id;
          const response = await fetch(url);
          const json = await response.json();
          setNftDetails(json.data);
        } catch (error) {
          console.log(error);
        }

        setIsLoading(false);
      }
    };

    if (!notRevealed && id >= 0 && isOpen) {
      fetchData();
    }
  }, [id, isOpen, notRevealed]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else { 
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  const closeThisModal = () => {
    setNftDetails({});
    onRequestClose();
  };

  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={closeThisModal}
      ariaHideApp={false}
      style={customStyles}
    >
      <CloseButton onClick={closeThisModal}>
        ✕
      </CloseButton>

      <Card mintButtonHidden={!showMintButton}>
        <Container>
          <LeftBox>
            <div className="imageHolder">
              {!notRevealed ? (
                <ImageLoader
                  src={nftDetails?.ipfs}
                  alt="LOLApe"
                  imgClassName="nftImage"
                />
              ) : (
                <div className="video-container">
                  <video autoPlay loop className="video">
                    <source src="https://nft.finape.com/ipfs/QmaBGV38kWntdg3UdSvTz8daSnHYm7n3PvQSsCzfbBFgyJ" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              )}
            </div>
          </LeftBox>

          <RightBox>
            <TitleSection>
              <div>
                <div className="label">LOLApe</div>
                {!notRevealed && nftDetails?.assigned_token_id != null ? (
                  <div className="nftId">#{padNumber(nftDetails.assigned_token_id, 4)}</div>
                ) : (
                  <MouseoverTooltip
                    text={
                      <span>
                        This NFT is not yet revealed / not yet minted. The final token ID will be assigned randomly after minting. More info in Provenance page.
                      </span>
                    }
                    placement="bottom"
                  >
                    <div className="nftId">#????</div>
                  </MouseoverTooltip>
                )}
              </div>
              {!notRevealed && showBridgeButton && nftDetails?.assigned_token_id != null && 
                <div>
                  <Buttons.BasicV2
                    variant="outlined"
                    title="Bridge"
                    style={{width: 120, fontSize: 14, marginRight: 10, marginTop: 10}}
                    onClick={() => navigate("/nft-bridge?tokenId=" + id)}
                  />
                </div>
              }
            </TitleSection>

            <div className="attributeContainer">
              {notRevealed && (
                <Alert variant="info">
                  Stay tuned for the NFT reveal after each stage is sold out. Follow us for event updates and exciting announcements!
                </Alert>
              )}
              {nftDetails?.rarity && (<div className="attributeDiv">
                <div className="attributeBox">
                  <p className="title">Rarity</p>
                  <p className="attribute">{nftDetails?.rarity || "..."}</p>
                </div>
              </div>
              )}
              {false && nftDetails?.head && (<div className="attributeDiv">
                <div className="attributeBox">
                  <p className="title">Head</p>
                  <p className="attribute">{nftDetails?.head || "..."}</p>
                </div>
              </div>
              )}
              {nftDetails?.background && (<div className="attributeDiv">
                <div className="attributeBox">
                  <p className="title">Background</p>
                  <p className="attribute">{nftDetails?.background || "..."}</p>
                </div>
              </div>
              )}
              {nftDetails?.background_effect && (<div className="attributeDiv">
                <div className="attributeBox">
                  <p className="title">Background Effect</p>
                  <p className="attribute">{nftDetails?.background_effect || "..."}</p>
                </div>
              </div>
              )}
              {nftDetails?.body && (<div className="attributeDiv">
                <div className="attributeBox">
                  <p className="title">Body</p>
                  <p className="attribute">{nftDetails?.body || "..."}</p>
                </div>
              </div>
              )}
              {nftDetails?.eyes && (<div className="attributeDiv">
                <div className="attributeBox">
                  <p className="title">Eyes</p>
                  <p className="attribute">{nftDetails?.eyes || "..."}</p>
                </div>
              </div>
              )}
              {nftDetails?.face && (<div className="attributeDiv">
                <div className="attributeBox">
                  <p className="title">Face</p>
                  <p className="attribute">{nftDetails?.face || "..."}</p>
                </div>
              </div>
              )}
              {nftDetails?.hair && (<div className="attributeDiv">
                <div className="attributeBox">
                  <p className="title">Hair</p>
                  <p className="attribute">{nftDetails?.hair || "..."}</p>
                </div>
              </div>
              )}
              {nftDetails?.handheld && (<div className="attributeDiv">
                <div className="attributeBox">
                  <p className="title">Handheld</p>
                  <p className="attribute">{nftDetails?.handheld || "..."}</p>
                </div>
              </div>
              )}
              {nftDetails?.head && (<div className="attributeDiv">
                <div className="attributeBox">
                  <p className="title">Head</p>
                  <p className="attribute">{nftDetails?.head || "..."}</p>
                </div>
              </div>
              )}
              {nftDetails?.mouth && (<div className="attributeDiv">
                <div className="attributeBox">
                  <p className="title">Mouth</p>
                  <p className="attribute">{nftDetails?.mouth || "..."}</p>
                </div>
              </div>
              )}
              {nftDetails?.pet && (<div className="attributeDiv">
                <div className="attributeBox">
                  <p className="title">Pet</p>
                  <p className="attribute">{nftDetails?.pet || "..."}</p>
                </div>
              </div>
              )}
              {nftDetails?.special && (<div className="attributeDiv">
                <div className="attributeBox">
                  <p className="title">Special</p>
                  <p className="attribute">{nftDetails?.special || "..."}</p>
                </div>
              </div>
              )}
              {nftDetails?.type && (<div className="attributeDiv">
                <div className="attributeBox">
                  <p className="title">Type</p>
                  <p className="attribute">{nftDetails?.type || "..."}</p>
                </div>
              </div>
              )}
            </div>
          </RightBox>
        </Container>

        {showMintButton && (
          <>
            <br />
            <br />
            <MintButton to="/minting">
              Mint a LOLApe NFT now
            </MintButton>
          </>
        )}
      </Card>
    </StyledModal>
  );
};

export default NftDetailsModal;
