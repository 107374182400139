import React from "react";
import PropTypes from "prop-types";

const Sphere = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M22 11.5C22 17.299 17.299 22 11.5 22M22 11.5C22 5.70101 17.299 1 11.5 1M22 11.5H1M11.5 22C5.70101 22 1 17.299 1 11.5M11.5 22C14.1263 19.1247 15.6189 15.3934 15.7 11.5C15.6189 7.60664 14.1263 3.87527 11.5 1M11.5 22C8.87366 19.1247 7.38111 15.3934 7.3 11.5C7.38111 7.60664 8.87366 3.87527 11.5 1M1 11.5C1 5.70101 5.70101 1 11.5 1"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

Sphere.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export default Sphere;
