import { coinbaseWalletConnection, ConnectionType, getConnectionName } from '../../connection';
import COINBASE_ICON_URL from '../../assets/svg/coinbaseWalletIcon.svg';
import Option from './Option';

const BASE_PROPS = {
  color: '#315CF5',
  icon: COINBASE_ICON_URL,
  id: 'coinbase-wallet',
};

export function OpenCoinbaseWalletOption() {
  const isActive = coinbaseWalletConnection.hooks.useIsActive();
  return (
    <Option
      {...BASE_PROPS}
      isActive={isActive}
      link="https://go.cb-w.com/mtUDhEZPy1"
      header="Open in Coinbase Wallet"
    />
  );
}

export function CoinbaseWalletOption({ tryActivation }) {
  const isActive = coinbaseWalletConnection.hooks.useIsActive();
  return (
    <Option
      {...BASE_PROPS}
      isActive={isActive}
      onClick={() => tryActivation(coinbaseWalletConnection.connector)}
      header={getConnectionName(ConnectionType.COINBASE_WALLET)}
    />
  );
}
