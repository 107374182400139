import Basic from "./Basic";
import BasicV2 from "./BasicV2";
import BackToTop from "./BackToTop";
import Connect from "./Connect";

const Buttons = {
  Basic,
  BasicV2,
  BackToTop,
  Connect,
};

export default Buttons;
