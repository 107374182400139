import React from "react";
import PropTypes from "prop-types";

const Sparkles = ({ color = "currentColor", className, ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="214" height="267" viewBox="0 0 214 267"
      className={className}
      {...props}
    >
      <g transform="translate(0.000000,267) scale(0.1,-0.1)">
        <path stroke={color ? color : "#fff909"} fill={props.fill ? props.fill : "#FFFFFF"} strokeWidth={props.strokeWidth ? props.strokeWidth : 120} d="M1067 2610 c-3 -14 -11 -77 -17 -140 -50 -506 -131 -762 -283 -895
        -110 -96 -271 -154 -571 -208 l-179 -32 79 -12 c549 -89 724 -189 832 -477 53
        -141 94 -359 123 -656 7 -69 15 -131 19 -139 4 -7 10 27 14 75 11 133 44 376
        67 493 72 361 199 522 481 613 86 27 325 78 436 93 29 4 52 8 50 10 -2 2 -59
        13 -128 24 -303 51 -456 101 -574 185 -181 129 -258 341 -320 886 -13 113 -26
        194 -29 180z"/>
      </g>
    </svg>
  );
};

Sparkles.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export default Sparkles;
