import React from "react";
import PropTypes from "prop-types";

const Link = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.6667 7.33325H3.33333C2.59695 7.33325 2 7.93021 2 8.66659V13.3333C2 14.0696 2.59695 14.6666 3.33333 14.6666H12.6667C13.403 14.6666 14 14.0696 14 13.3333V8.66659C14 7.93021 13.403 7.33325 12.6667 7.33325Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66669 7.33325V4.66659C4.66669 3.78253 5.01788 2.93468 5.643 2.30956C6.26812 1.68444 7.11597 1.33325 8.00002 1.33325C8.88408 1.33325 9.73192 1.68444 10.357 2.30956C10.9822 2.93468 11.3334 3.78253 11.3334 4.66659V7.33325"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

Link.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export default Link;
