import { getConnectionName, walletConnectConnection } from '../../connection';
import Option from './Option';
import XVERSE_CONNECT_ICON_URL from '../../assets/svg/xverse-logo.svg';

const BASE_PROPS = {
  color: '#4196FC',
  icon: XVERSE_CONNECT_ICON_URL,
  id: 'xverse-connect',
};

export function XverseOption({ tryActivation }) {
  const isActive = walletConnectConnection.hooks.useIsActive();
  return (
    <Option
      {...BASE_PROPS}
      isActive={isActive}
      onClick={() => tryActivation()}
      header={getConnectionName('XVERSE_CONNECT')}
    />
  );
}
