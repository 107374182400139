import styled from "styled-components";

const Button = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 21px;
  height: 48px;
  font-family: "Gilroy", sans-serif;
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  letter-spacing: -0.02em;
  color: inherit;
  box-shadow: none;
  background: transparent;
  text-transform: none;
  text-decoration: none;
  outline: none;
  appearance: none;
  transition: all 0.3s ease;
  border: none;
  border-radius: 18px;
  cursor: pointer;

  @media only screen and (max-width: 767px) {
    padding: 11px 16px;
  }

  &[disabled] {
    cursor: not-allowed;
    background-color: #ccc !important;
  }

  &[disabled]:hover {
    background-color: #ccc;
  }
  
  &[data-variant="contained"] {
    color: var(--color-white);
    background-color: #157bef;

    &:hover {
      background-color: #244ca8;
    }
  }

  &[data-variant="outlined"] {
    background-color: var(--color-white);
    border: 1px solid #c1cbd9;

    &:hover {
      border-color: #244ca8;
    }
  }

  &[data-variant="chevron-down"] {
    background-color: transparent;
    color: #1e7ff0;
    border: 1px solid #1e7ff0;
    border-radius: 12px;

    &:hover {
      color: #244ca8;
      border-color: #244ca8;
    }
  }

  .title-icon {
    margin-right: 10px;
  }

  .box-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
  }
`;

const Basic = ({ children, ...props}) => {
  const {
    title,
    onClick,
    disabled,
    type = "button",
    className,
    variant,
    style,
    icon,
  } = props;

  return (
    <Button
      className={`${className}`}
      style={style}
      data-variant={variant}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {icon ? (
        <>
          <span className="title-icon">{title}</span>
          <span className="box-icon">{icon}</span>
        </>
      ) : title ? (
        <span>{title}</span>
      ) : (
        {children}
      )}
    </Button>
  );
};

export default Basic;
