import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-modal";

import styled, { css } from "styled-components";

import { padNumber } from "../../../utils/helpers";
import nft_placeholder from "../../../assets/images/nft/placeholder.jpg";
import { MouseoverTooltip } from '../../../components/Tooltip'
import ImageLoader from '../../../components/ImageLoader'
import { Buttons } from '../../../components'
import Alert from "../../../components/Alert";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto; 
  gap: 5px;
  min-width: 300px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const LeftBox = styled.div`
  flex: 1; 

  @media screen and (min-width: 768px) {
    width: 50%;
  }
`;

const RightBox = styled.div`
  flex: 1; 

  @media screen and (min-width: 768px) {
    width: 50%;
  }
`;

const Card = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: auto;
  text-align: left;
  position: relative;
  min-width: 300px;

  .imageHolder {
    padding: 0;

    .nftImage {
      width: 100%;
      margin: 0 auto;
      max-width: 300px;
      border-top-left-radius: 10px;

      @media screen and (max-width: 768px) {
        border-radius: 10px;
      }
    }
  }

  .attributeContainer {
    margin: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .attributeDiv {
      width: 50%;
      padding: 4px;
      flex: 0 0 calc(100% / 2);

      .attributeBox {
        border: 1px solid #15b2e5;
        background-color: rgba(21, 178, 229, 0.06);
        height: 73px;

        .title {
          color: #666;
          font-size: 11px;
          text-transform: uppercase;
          margin: 5px 10px;
        }

        .attribute {
          color: #000;
          font-size: 14px;
          margin: 5px 10px;
          text-transform: uppercase;
          font-weight: bold;
        }
      }
    }
  }
`;

const TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-left: 15px;
  text-align: left; 

  .label {
    color: grey;
    font-size: 14px;
  }

  .nftId {
    margin: 15px auto 10px;
    font-size: 24px;
    font-weight: bold;
  }
`;

const Membership = styled.div`
  color: #000;
  font-size: 18px;
  padding: 10px 5px;
  line-height: 1.2;
  border-radius: 6px;

  /* Variant styles */
  ${({ variant }) => variant === 'Silver' && css`
    background: #F2F3F4;
    background: linear-gradient(to bottom, #F2F3F4 0%, #E3E4E5 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #F2F3F4), color-stop(100%, #E3E4E5));
    background: -webkit-linear-gradient(top, #F2F3F4 0%, #E3E4E5 100%);
    background: -moz-linear-gradient(top, #F2F3F4 0%, #E3E4E5 100%);
    background: -o-linear-gradient(top, #F2F3F4 0%, #E3E4E5 100%);
    background: -ms-linear-gradient(top, #F2F3F4 0%, #E3E4E5 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F2F3F4', endColorstr='#E3E4E5', GradientType=0);
    border: 1px solid #D4D5D6;
    text-shadow: 0 1px 0 #FFFFFF;
    -webkit-text-shadow: 0 1px 0 #FFFFFF;
    -moz-text-shadow: 0 1px 0 #FFFFFF;
    box-shadow: inset 0 1px 0 #FFFFFF;
    -webkit-box-shadow: inset 0 1px 0 #FFFFFF;
    -moz-box-shadow: inset 0 1px 0 #FFFFFF;
  `}
  ${({ variant }) => variant === 'Gold' && css`
    background: #FFDF00;
    background: linear-gradient(to bottom, #FFDF00 0%, #F0D000 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFDF00), color-stop(100%, #F0D000));
    background: -webkit-linear-gradient(top, #FFDF00 0%, #F0D000 100%);
    background: -moz-linear-gradient(top, #FFDF00 0%, #F0D000 100%);
    background: -o-linear-gradient(top, #FFDF00 0%, #F0D000 100%);
    background: -ms-linear-gradient(top, #FFDF00 0%, #F0D000 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFDF00', endColorstr='#F0D000', GradientType=0);
    border: 1px solid #E1C100;
    text-shadow: 0 1px 0 #FFEE0F;
    -webkit-text-shadow: 0 1px 0 #FFEE0F;
    -moz-text-shadow: 0 1px 0 #FFEE0F;
    box-shadow: inset 0 1px 0 #FFEE0F;
    -webkit-box-shadow: inset 0 1px 0 #FFEE0F;
    -moz-box-shadow: inset 0 1px 0 #FFEE0F;
  `}

  ${({ variant }) => variant === 'Platinum' && css`
    box-shadow:
      2px 2px 0.5em rgba(122, 122, 122, 0.55),
      inset 1px 1px 0 rgba(255, 255, 255, 0.9),
      inset -1px -1px 0 rgba(0, 0, 0, 0.5)
    ;
    border: 1px solid #cacade;
    background:
      -moz-linear-gradient(
      -72deg, #dedeff, #ffffff 16%, #dedeff 21%, #ffffff 24%, #aaaacc 27%, #dedeff 36%, #ffffff 45%, #ffffff 60%, #dedeff 72%, #ffffff 80%, #dedeff 84%, #aaaacc
    );
    background:
    -webkit-linear-gradient(
      -72deg, #dedeff, #ffffff 16%, #dedeff 21%, #ffffff 24%, #aaaacc 27%, #dedeff 36%, #ffffff 45%, #ffffff 60%, #dedeff 72%, #ffffff 80%, #dedeff 84%, #aaaacc
    );
    background:
    -o-linear-gradient(
      -72deg, #dedeff, #ffffff 16%, #dedeff 21%, #ffffff 24%, #aaaacc 27%, #dedeff 36%, #ffffff 45%, #ffffff 60%, #dedeff 72%, #ffffff 80%, #dedeff 84%, #aaaacc
    );
    background:
    linear-gradient(
      -72deg, #dedeff, #ffffff 16%, #dedeff 21%, #ffffff 24%, #aaaacc 27%, #dedeff 36%, #ffffff 45%, #ffffff 60%, #dedeff 72%, #ffffff 80%, #dedeff 84%, #aaaacc
    );
  `}

  ${({ variant }) => variant === 'Elite' && css`
    box-shadow:
      2px 2px 0.5em rgba(155, 122, 89, 0.55),
      inset 1px 1px 0 rgba(255, 255, 255, 0.9),
      inset -1px -1px 0 rgba(0, 0, 0, 0.5)
    ;
    border: 1px solid #deca45;
    background:
    -moz-linear-gradient(
      -72deg, #ffc373, #ffffff 16%, #ffc373 21%, #ffffff 24%, #a17434 27%, #ffc373 36%, #ffffff 45%, #ffffff 60%, #ffc373 72%, #ffffff 80%, #ffc373 84%, #a17434
    );
    background:
    -webkit-linear-gradient(
      -72deg, #ffc373, #ffffff 16%, #ffc373 21%, #ffffff 24%, #a17434 27%, #ffc373 36%, #ffffff 45%, #ffffff 60%, #ffc373 72%, #ffffff 80%, #ffc373 84%, #a17434
    );
    background:
    -o-linear-gradient(
      -72deg, #ffc373, #ffffff 16%, #ffc373 21%, #ffffff 24%, #a17434 27%, #ffc373 36%, #ffffff 45%, #ffffff 60%, #ffc373 72%, #ffffff 80%, #ffc373 84%, #a17434
    );
    background:
    linear-gradient(
      -72deg, #ffc373, #ffffff 16%, #ffc373 21%, #ffffff 24%, #a17434 27%, #ffc373 36%, #ffffff 45%, #ffffff 60%, #ffc373 72%, #ffffff 80%, #ffc373 84%, #a17434
    );
  `}

  .bold {
    text-shadow: 1px 1px 0 #ffffff;
    font-weight: bold; 
  }

  .sub {
    color: #000;
    font-size: 14px;
    word-break: break-all;
  }
`;

const membershipIcon = (membership) => {
  switch (membership) {
    case "Silver":
      return "🥈";
    case "Gold":
      return "🥇";
    case "Platinum":
      return "💎";
    case "Elite":
      return "🌟";
    default:
      return ""
  }
}

const NftDetailsModal = (props) => {
  const navigate = useNavigate();

  const { 
    onRequestClose, 
    imgDetails,
    exifData
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  const closeThisModal = () => {
    onRequestClose();
  };

  return (
    <div>
      <Card>
        <Container>
          <LeftBox>
            <div className="imageHolder">
              <ImageLoader
                src={imgDetails?.src}
                alt="reader"
                imgClassName="nftImage"
              />
            </div>
          </LeftBox>

          <RightBox>
            {!exifData && (
              <div style={{padding: 10}}>
                <Alert variant="info">
                  No metadata found!
                </Alert>
              </div>
            )}
            <TitleSection>
              <div>
                <div className="nftId">{exifData?.name}</div>
                <div className="label">{exifData?.description}</div>

                {exifData?.tier && <Membership variant={exifData?.tier}>
                  Tier: <span className="bold">{exifData?.tier}</span> {membershipIcon(exifData?.tier)}
                  <br/><br/>
                  <div className="sub">
                    Serial Key:<br/>
                    {exifData?.serial_key}
                  </div>
                </Membership>
                }
              </div>
            </TitleSection>
          </RightBox>
        </Container>
        <Container>
          <div className="attributeContainer">

            {exifData?.attributes.map((item, index) => (
              <div className="attributeDiv" key={index}>
                <div className="attributeBox">
                  <p className="title">{item?.trait_type}</p>
                  <p className="attribute">{item?.value || "..."}</p>
                </div>
              </div>
            ))}
          </div>
        </Container>
      </Card>
    </div>
  );
};

export default NftDetailsModal;
