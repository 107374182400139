import { getConnectionName, walletConnectConnection } from '../../connection';
import Option from './Option';
import UNISAT_CONNECT_ICON_URL from '../../assets/svg/unisat-logo.svg';

const BASE_PROPS = {
  color: '#4196FC',
  icon: UNISAT_CONNECT_ICON_URL,
  id: 'unisat-connect',
};

export function UnisatOption({ tryActivation }) {
  const isActive = walletConnectConnection.hooks.useIsActive();
  return (
    <Option
      {...BASE_PROPS}
      isActive={isActive}
      onClick={() => tryActivation()}
      header={getConnectionName('UNISAT_CONNECT')}
    />
  );
}
