import { Web3ReactHooks } from '@web3-react/core';
import { SupportedChainId } from '../constants/chains';

export var ConnectionType;
(function (ConnectionType) {
  ConnectionType["UNISWAP_WALLET"] = "UNISWAP_WALLET";
  ConnectionType["UNISWAP_WALLET_V2"] = "UNISWAP_WALLET_V2";
  ConnectionType["UNIWALLET"] = "UNIWALLET";
  ConnectionType["INJECTED"] = "INJECTED";
  ConnectionType["COINBASE_WALLET"] = "COINBASE_WALLET";
  ConnectionType["WALLET_CONNECT"] = "WALLET_CONNECT";
  ConnectionType["WALLET_CONNECT_V2"] = "WALLET_CONNECT_V2";
  ConnectionType["NETWORK"] = "NETWORK";
  ConnectionType["GNOSIS_SAFE"] = "GNOSIS_SAFE";
})(ConnectionType || (ConnectionType = {}));

export class Connection {
  constructor() {
    this.getIcon = (isDarkMode) => {
      return '';
    };
    this.shouldDisplay = () => {
      return true;
    };
  }

  getName() {
    return '';
  }
  
  overrideActivate(chainId) {
    return false;
  }
}

Connection.prototype.connector = null;
Connection.prototype.hooks = null;
Connection.prototype.type = null;
Connection.prototype.isNew = false;
