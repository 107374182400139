import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const LabelBox = styled.label`
  padding: 0;
  margin: 0;
  display: block;
  cursor: pointer;

  .input {
    display: none;

    &:checked + .section {
      .box {
        color: #2d2d2d;
      }
    }
  }

  .section {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    flex: 0 0 18px;
    width: 18px;
    height: 18px;
    border: 2px solid #2d2d2d;
    color: transparent;
    border-radius: 3px;
  }

  .name {
    flex: 1;
    font-family: var(--font-gilroy);
    font-size: 18px;
    line-height: 21px;
    font-weight: 600;
    color: #2d2d2d;
    user-select: none;
  }
`;

const Checkbox = ({
  name = "",
  title = "",
  customStyles = "",
  onChange = () => {},
  onBlur = () => {},
  onInput = () => {},
  value = "",
  type = "checkbox",
  checked = false,
}) => {
  return (
    <LabelBox className={`label ${customStyles}`}>
      <input
        className="input"
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onInput={onInput}
        type={type}
        checked={checked}
      />
      <span className="section">
        <span className={`box ${customStyles}`}>
          <svg
            width="14"
            height="9"
            viewBox="0 0 14 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.5 4L5.5 8L12.5 1"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        <span className="name">{title}</span>
      </span>
    </LabelBox>
  );
};

Checkbox.propTypes = {
  customStyles: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onInput: PropTypes.func,
  errors: PropTypes.string,
  type: PropTypes.string,
};

export default Checkbox;
