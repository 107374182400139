import { networkConnection, walletConnectConnection } from '../connection';
import { getChainInfo } from '../constants/chainInfo';
import { SupportedChainId, isSupportedChain } from '../constants/chains';
import { FALLBACK_URLS, RPC_URLS } from '../constants/networks';

function getRpcUrl(chainId) {
  switch (chainId) {
    case SupportedChainId.MAINNET:
    case SupportedChainId.GOERLI:
      return RPC_URLS[chainId][0];
    default:
      return FALLBACK_URLS[chainId][0];
  }
}

export const switchChain = async (connector, chainId) => {
  if (!isSupportedChain(chainId)) {
    throw new Error(`Chain ${chainId} not supported for connector (${typeof connector})`);
  } else if (connector === walletConnectConnection.connector || connector === networkConnection.connector) {
    await connector.activate(chainId);
  } else {
    const info = getChainInfo(chainId);
    const addChainParameter = {
      chainId,
      chainName: info.label,
      rpcUrls: [getRpcUrl(chainId)],
      nativeCurrency: info.nativeCurrency,
      blockExplorerUrls: [info.explorer],
    };
    await connector.activate(addChainParameter);
  }
};
