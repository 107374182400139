import { getConnectionName, walletConnectConnection } from '../../connection';
import Option from './Option';
import WALLET_CONNECT_ICON_URL from '../../assets/svg/walletConnectIcon.svg';

const BASE_PROPS = {
  color: '#4196FC',
  icon: WALLET_CONNECT_ICON_URL,
  id: 'wallet-connect',
};

export function WalletConnectOption({ tryActivation }) {
  const isActive = walletConnectConnection.hooks.useIsActive();
  return (
    <Option
      {...BASE_PROPS}
      isActive={isActive}
      onClick={() => tryActivation(walletConnectConnection.connector)}
      header={getConnectionName('WALLET_CONNECT')}
    />
  );
}
