import styled from "styled-components";

export const NFTExplorerRow = styled.div`
  padding-top: 20px;
  padding-bottom: 30px;

  @media only screen and (min-width: 768px) {
    padding-top: 33px;
    padding-bottom: 60px;
  }

  h1,
  .h1 {
    margin: 0 0 16px;
    font-family: var(--font-gilroy);
    font-size: 26px;
    line-height: 31px;
    font-weight: 700;
    letter-spacing: -0.02em;
    color: #2d2d2d;

    @media only screen and (min-width: 768px) {
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 20px;
    }
  }
`;

export const Container = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  padding-left: 15px;
  padding-right: 15px;

  @media only screen and (min-width: 1360px) {
    padding-left: 40px;
    padding-right: 40px;
  }

  .drop-container {
    position: relative;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
    padding: 20px;
    border-radius: 10px;
    border: 2px dashed #555;
    color: #444;
    cursor: pointer;
    transition: background .2s ease-in-out, border .2s ease-in-out;
  }

  .drop-container:hover {
    background: #eee;
    border-color: #111;
  }

  .drop-container:hover .drop-title {
    color: #222;
  }

  .drop-title {
    color: #444;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    transition: color .2s ease-in-out;
  }
  .drop-subtitle {
    color: #444;
    font-size: 12px;
    text-align: center;
    transition: color .2s ease-in-out;
  }

  input[type=file] {
    width: 350px;
    max-width: 100%;
    color: #444;
    padding: 5px;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #555;
  }

  input[type=file]::file-selector-button {
    margin-right: 20px;
    border: none;
    background: #084cdf;
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: background .2s ease-in-out;
  }

  input[type=file]::file-selector-button:hover {
    background: #0d45a5;
  }

  pre {
    font-size: 10px;
    line-height: 1.1;
    white-space: pre-wrap;
  }
`;

export const LayoutSection = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;
