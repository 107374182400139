import { AlertTriangle, CheckCircle } from 'react-feather';
import styled, { useTheme } from 'styled-components/macro';
import { useCustomWeb3React } from '../../providers/CustomWeb3ReactProvider';

import { useTransaction } from '../../state/transactions/hooks';
import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink';
import TransactionBody from '../TransactionDetails/TransactionBody';
import TransactionState from '../TransactionDetails';
import { colors } from '../../theme/colors';

const RowNoFlex = styled.div`
  flex-wrap: nowrap;
`;

function TransactionPopupContent({ tx, chainId }) {
  const theme = useTheme();

  const success = tx.receipt?.status === 1;
  const transactionState = success
      ? TransactionState.Success
      : TransactionState.Failed;

  const explorerUrl = getExplorerLink(chainId, tx.hash, ExplorerDataType.TRANSACTION);

  return (
    <RowNoFlex onClick={() => window.open(explorerUrl, '_blank')}>
      <div gap="sm">
        <RowNoFlex style={{ alignItems: 'center', display: 'flex' }}>
          <div style={{ paddingRight: 13 }}>
            { success ? (
              <CheckCircle color={colors.green200} size={24} display="flex" />
            ) : (
              <AlertTriangle color={theme.accentWarning} size={24} display="flex" />
            ) }
          </div>
          <div style={{ fontWeight: "bold" }}>
            <>Transaction Status</>
          </div>
        </RowNoFlex>
        <div style={{ marginTop: 10 }}>
          {tx.info && <TransactionBody info={tx.info} transactionState={transactionState} />}
        </div>
      </div>
    </RowNoFlex>
  );
}

export default function TransactionPopup({ hash }) {
  const { chainId } = useCustomWeb3React();

  const tx = useTransaction(hash);

  if (!chainId || !tx) return null;

  return <TransactionPopupContent tx={tx} chainId={chainId} />;
}
