import React from "react";
import PropTypes from "prop-types";

const CheckMark = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M16.6693 5.33789L7.5026 14.3175L3.33594 10.2358" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
    </svg>
  );
};

CheckMark.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export default CheckMark;