import PropTypes from "prop-types";
import styled from "styled-components";

import Header from "../../components/Header";
import MobileTabbar from "../MobileTabbar";

import { usePartyModeManager } from '../../state/user/hooks';

const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 0;
  min-width: 320px;
  margin: 0 auto;
`;

const Layout = ({ footer, children, className, id }) => {

  const [isPartyMode, ] = usePartyModeManager()

  return (
    <Wrapper className={className} id={id}>
      <Header />
      {children}
      {footer}
      <MobileTabbar />
    </Wrapper>
  );
};

Layout.defaultProps = {
  header: "",
  footer: "",
  children: "",
};

Layout.propTypes = {
  header: PropTypes.node.isRequired,
  footer: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default Layout;
