import { useCallback } from 'react';

import { useCustomWeb3React } from '../providers/CustomWeb3ReactProvider';
import { getConnection } from '../connection';
import { SupportedChainId } from '../constants/chains';
import { addPopup } from '../state/application/reducer';
import { updateConnectionError } from '../state/connection/reducer';
import { useAppDispatch } from '../state/hooks';
import { switchChain } from '../utils/switchChain';

export default function useSelectChain() {
  const dispatch = useAppDispatch();
  const { connector } = useCustomWeb3React();

  return useCallback(async (targetChain) => {
    if (!connector) return;
    if (targetChain == SupportedChainId.BITCOIN) return;
    if (targetChain == SupportedChainId.SOLANA) return;

    const connectionType = getConnection(connector).type;

    try {
      dispatch(updateConnectionError({ connectionType, error: undefined }));

      await switchChain(connector, targetChain);
    } catch (error) {
      console.log('Failed to switch networks', error);

      dispatch(updateConnectionError({ connectionType, error: error.message }));
      dispatch(addPopup({ content: { failedSwitchNetwork: targetChain }, key: `failed-network-switch` }));
    }
  }, [connector, dispatch]);
}
