import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";
import styled, { keyframes } from "styled-components";
import { ChevronDown } from "react-feather";

import Jokes from "./Jokes";

import GroupPhoto from "../../assets/images/group.png";
import KingLaugh from "../../assets/images/king-laugh.gif";

const TokenomicBreakdown = styled.div`
  margin: 0 auto;
  text-align: center;
  font-size: 40px;
  line-height: 1.2;

  .rotating-word {
    position: relative;
    line-height: inherit;

    border-radius: 12px;
    height: 50px;
    background: #fff;
    top: 0.9rem;
    font-weight: 600;

    width: 100%;
  }
  .rotating-word,
  .you-want {
    display: inline-block;
    text-align: center;
  }
  .you-want {
    margin-left: 3em;
  }
  .rotating-word span {
    position: absolute;
    top: 0;
    overflow: hidden;
    animation: word-change 7.5s ease-in-out infinite;
    opacity: 0;
    white-space: nowrap;
    text-align: center;
    left: 0;
    width: 100%;
  }

  @media only screen and (min-width: 800px) {
    font-size: 70px;

    .rotating-word {
      height: 85px;
      width: 600px;
    }
  }

  /* Animation */
  @keyframes word-change {
    0% {
      opacity: 0;
      transform: translateY(-16px);
    }
    2% {
      opacity: 1;
      transform: translateY(0px);
    }
    18% {
      opacity: 1;
      transform: translateY(0px);
    }
    20% {
      opacity: 0;
      transform: translateY(16px);
    }
    100% {
      opacity: 0;
      transform: translateY(16px);
    }
  }
  .rotating-word span:nth-child(1) {
    animation-delay: 0s;
  }
  .rotating-word span:nth-child(2) {
    animation-delay: 1.5s;
  }
  .rotating-word span:nth-child(3) {
    animation-delay: 3s;
  }
  .rotating-word span:nth-child(4) {
    animation-delay: 4.5s;
  }
  .rotating-word span:nth-child(5) {
    animation-delay: 6s;
  }
  .rotating-word span:nth-child(6) {
    animation-delay: 7.5s;
  }
`;

const ImgKingLaugh = styled.img`
  width: 100%;
  margin: 0 auto;
  display: block;

  @media only screen and (min-width: 1200px) {
    width: 360px;
  }
`;

const AccordionItem = ({ header, ...rest }) => (
  <Item
    {...rest}
    header={
      <>
        {header}
        <ChevronDown className="chevron" />
      </>
    }
    className="item"
    buttonProps={{
      className: ({ isEnter }) => `itemBtn ${isEnter && "itemBtnExpanded"}`,
    }}
    contentProps={{ className: "itemContent" }}
    panelProps={{ className: "itemPanel" }}
  />
);

export default function FAQ() {
  return (
    <section id="roadmap">
      <div className="gap-5"></div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-lg-12">
            <TokenomicBreakdown className="col-sm-12 col-lg-12">
              <div>
                <span className="rotating-word">
                  <span>Jim Carrey</span>
                  <span>Dave Chappelle</span>
                  <span>Kevin Hart</span>
                  <span>Will Ferrell</span>
                  <span>Bobby Lee</span>
                </span>
                <span>
                  {" "}
                  - he{" "}
                  <em>
                    <strong>apes.</strong>
                  </em>
                </span>
              </div>
            </TokenomicBreakdown>
          </div>
        </div>
        <div className="gap-2"></div>
        <div className="row">
          <h2 className="col-sm-12 center text-center">
            Just like these comedians, <span className="italic"><span className="white-text">LOL</span>Ape</span> know how to bring the laughs!
          </h2>
        </div>

        <div className="gap-2"></div>

        <div className="row">
          <div className="col-sm-12 col-md-8">
            <Jokes />
          </div>
          <div className="col-sm-12 col-md-4">
            <ImgKingLaugh src={KingLaugh} />
          </div>
        </div>
      </div>
      <div className="gap-4"></div>
    </section>
  );
}
