import React, { useState, useEffect } from 'react';
import styled, { keyframes } from "styled-components";

import nft_placeholder from "../../assets/images/nft/placeholder.jpg";

const ldsAnimation = keyframes`
  0% {
    top: 8px;
    height: 44px;
  }
  50%, 100% {
    top: 24px;
    height: 22px;
  }
`;

export const Lds = styled.div`
  position: absolute;
  top: 0; 
  left: 0; 
  bottom: 0; 
  right: 0; 
  width: 40px;
  height: 80px;
  margin: 0 auto;
  
  div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 10px;
    background: #fff;
    animation: ${ldsAnimation} 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    
    &:nth-child(1) {
      left: 8px;
      animation-delay: -0.24s;
    }
    
    &:nth-child(2) {
      left: 22px;
      animation-delay: -0.12s;
    }
    
    &:nth-child(3) {
      left: 36px;
      animation-delay: 0;
    }
  }
`;


const ImageLoader = ({ src, alt, className, imgClassName }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(false);
    const img = new Image();
    img.onload = () => {
      setLoaded(true);
    };
    img.src = src;
  }, [src]);

  return (
    <div style={{ position: 'relative' }} className={className}>
      {!loaded && <div style={{
          position: 'relative',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%'
        }}>
          <img
            src={nft_placeholder}
            alt={alt}
            style={{position: "relative", width: "100%"}}
            className={imgClassName}
          />
          <Lds>
            <div></div>
            <div></div>
            <div></div>
          </Lds>
        </div>}
      <img
        style={{ display: loaded ? 'block' : 'none', width: "100%" }}
        className={imgClassName}
        src={src ? src : nft_placeholder}
        alt={alt}
      />
    </div>
  );
};

export default ImageLoader;
