import React from "react";
import PropTypes from "prop-types";

const RightArrow = ({ color = "currentColor", className, ...props }) => {
  return (
    <svg
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <g>
        <path stroke={color} fill={props.fill ? props.fill : "#000"} d="M256,0C114.6,0,0,114.6,0,256s114.6,256,256,256s256-114.6,256-256S397.4,0,256,0z M227.6,126.8L421.4,256L227.6,385.2
          v-69.6h-137V196.4h137V126.8L227.6,126.8z"/>
      </g>
    </svg>
  );
};

RightArrow.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export default RightArrow;
