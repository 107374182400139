import styled from "styled-components";

import { Layout, Buttons, Icons } from "../../components";
import { useCountdown } from '../../hooks/useCountdown';
import { TARGET_DATE } from "../../constants"

const PresaleRow = styled.div`
  padding-top: 20px;
  padding-bottom: 30px;

  @media only screen and (min-width: 768px) {
    padding-top: 33px;
    padding-bottom: 60px;
  }

  h1,
  .h1 {
    margin: 0 0 31px;
    font-family: var(--font-gilroy);
    font-size: 26px;
    line-height: 31px;
    font-weight: 700;
    letter-spacing: -0.02em;
    color: #2d2d2d;

    @media only screen and (min-width: 768px) {
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 32px;
    }
  }

  .presale {
    &__section {
      max-width: 1270px;
      width: 100%;
      margin: 0 auto;
    }

    &__row {
      display: flex;
      flex-wrap: wrap;
      margin-left: -60px;
      margin-right: -60px;

      @media only screen and (max-width: 1439px) {
        margin-left: -30px;
        margin-right: -30px;
      }

      @media only screen and (max-width: 1023px) {
        margin-left: -15px;
        margin-right: -15px;
      }
    }

    &__item {
      position: relative;
      flex: 0 0 100%;
      max-width: 100%;
      padding: 55px 60px;

      @media only screen and (max-width: 1439px) {
        padding-left: 30px;
        padding-right: 30px;
      }

      @media only screen and (max-width: 1023px) {
        padding: 0 15px;
        margin-bottom: 60px;
      }

      &:not(:first-child) {
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 1px;
          background-color: #e8eaef;
        }
      }

      &.w-60 {
        @media only screen and (min-width: 1024px) {
          flex: 0 0 59%;
          max-width: 59%;
        }
      }

      &.w-40 {
        @media only screen and (min-width: 1024px) {
          flex: 0 0 41%;
          max-width: 41%;
        }
      }
    }

    &__head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 27px;
    }

    &__rca {
      display: flex;
      align-items: center;

      &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: #2d2d2d;
        margin-right: 20px;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__name {
        margin-right: 15px;
        font-family: var(--font-gilroy);
        font-size: 30px;
        line-height: 36px;
        font-weight: 700;
        color: #2d2d2d;
        text-transform: uppercase;
      }

      &__icon {
        color: #ff675e;
      }
    }

    &__sale {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px 9px;
      height: 30px;
      font-family: var(--font-gilroy);
      font-size: 15px;
      line-height: 18px;
      font-weight: 700;
      color: #1db964;
      border-radius: 7px;
      background-color: rgba(29, 185, 100, 0.1);

      svg {
        margin-right: 6px;
      }
    }
  }

  .desc__list {
    position: relative;

    &__item {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 12px 0;
      border-bottom: 1px solid #e8eaef;

      @media only screen and (max-width: 1023px) {
        margin-left: -10px;
        margin-right: -10px;
      }
    }

    &__label,
    &__title {
      font-family: var(--font-gilroy);
      font-size: 18px;
      line-height: 21px;
      font-weight: 600;
      color: #2d2d2d;

      @media only screen and (max-width: 1023px) {
        padding-left: 10px;
        padding-right: 10px;
        flex: 0 0 50%;
        max-width: 50%;
      }

      &.link {
        color: #319cff;
      }

      a {
        text-decoration: none;
      }
    }

    &__title {
      text-align: right;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .countdown-timer {
    margin-bottom: 54px;

    &__title {
      font-family: var(--font-gilroy);
      font-size: 24px;
      line-height: 29px;
      font-weight: 700;
      color: #2d2d2d;
      text-align: center;
      margin-bottom: 27px;
    }

    &__row {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__item {
      padding-left: 5px;
      padding-right: 5px;
    }

    &__article {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      height: 70px;
      font-family: var(--font-gilroy);
      font-size: 24px;
      line-height: 29px;
      font-weight: 700;
      color: #2d2d2d;
      background-color: #e8eaef;
      border-radius: 11px;
    }
  }

  .progress-bar {
    margin-bottom: 36px;

    &__line {
      width: 100%;
      height: 16px;
      border-radius: 8px;
      background-color: #e8eaef;
      margin-bottom: 10px;

      span {
        display: block;
        width: 0;
        height: 100%;
        border-radius: 8px;
        background-color: #319cff;
      }
    }

    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__name {
      font-family: "Gilroy";
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
      color: #2d2d2d;
    }
  }

  .form {
    &__amount {
      margin-bottom: 75px;

      @media only screen and (max-width: 1023px) {
        margin-bottom: 25px;
      }

      &__label {
        font-family: var(--font-gilroy);
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
        color: #2d2d2d;
        margin-bottom: 10px;
      }

      &__item {
        position: relative;

        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }

      &__input {
        padding: 15px 70px 15px 20px;
        width: 100%;
        height: 60px;
        border: 1px solid #ebedf2;
        border-radius: 14px;
        font-family: var(--font-gilroy);
        font-size: 18px;
        line-height: 21px;
        font-weight: 600;
        color: #2d2d2d;
        outline: none;
      }

      &__dt {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        font-family: var(--font-gilroy);
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
        letter-spacing: -0.02em;
        color: #1e7ff0;
        z-index: 1;
      }

      .u-btn-whitelist {
        width: 100%;
        height: 60px;
        border-radius: 14px;
        font-size: 18px;
        line-height: 21px;
        color: #969eb1;
        background-color: #ebedf2;
        border-color: #ebedf2;

        &:hover {
          background-color: #ebedf2;
          border-color: #ebedf2;
        }
      }
    }
  }
`;

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  padding-left: 15px;
  padding-right: 15px;

  @media only screen and (min-width: 1024px) {
    padding-left: 40px;
    padding-right: 40px;
  }
`;

const Presale = () => {
  const [days, hours, minutes, seconds] = useCountdown(TARGET_DATE);

  return (
    <Layout>
      <PresaleRow>
        <Container>
          <h1>Presale</h1>

          <div className="presale__section">
            <div className="presale__row">
              <div className="presale__item w-60">
                <div className="presale__head">
                  <div className="presale__rca">
                    <div className="presale__rca__logo"></div>
                    <div className="presale__rca__name">rca</div>
                    <div className="presale__rca__icon">
                      <Icons.Sphere />
                    </div>
                  </div>

                  <div className="presale__sale">
                    <Icons.Lock />
                    <span>Sale Live</span>
                  </div>
                </div>

                <div className="desc__list">
                  <div className="desc__list__item">
                    <div className="desc__list__label">
                      Private sale address
                    </div>
                    <div className="desc__list__title link">
                      953409fu34f983h9f39ifh93f30fkopfjidhei90hei0n3oe
                    </div>
                  </div>

                  <div className="desc__list__item">
                    <div className="desc__list__label">Soft Cap</div>
                    <div className="desc__list__title">10 BNB</div>
                  </div>

                  <div className="desc__list__item">
                    <div className="desc__list__label">Hard Cap</div>
                    <div className="desc__list__title">20 BNB</div>
                  </div>

                  <div className="desc__list__item">
                    <div className="desc__list__label">
                      Private Sale Start Time
                    </div>
                    <div className="desc__list__title">
                      2022. 10.06 05:43 (UTC)
                    </div>
                  </div>

                  <div className="desc__list__item">
                    <div className="desc__list__label">
                      Private Sale End Time
                    </div>
                    <div className="desc__list__title">
                      2022. 10.06 05:43 (UTC)
                    </div>
                  </div>

                  <div className="desc__list__item">
                    <div className="desc__list__label">
                      First Release For Project
                    </div>
                    <div className="desc__list__title">40%</div>
                  </div>

                  <div className="desc__list__item">
                    <div className="desc__list__label ">
                      Vesting For Project
                    </div>
                    <div className="desc__list__title">20% each 3 minutes</div>
                  </div>
                </div>
              </div>
              <div className="presale__item w-40">
                <div className="countdown-timer">
                  <div className="countdown-timer__title">
                    Private Sale Ends In
                  </div>

                  <div className="countdown-timer__row">
                    <div className="countdown-timer__item">
                      <div className="countdown-timer__article">{days}</div>
                    </div>
                    <div className="countdown-timer__item">
                      <div className="countdown-timer__article">{hours}</div>
                    </div>
                    <div className="countdown-timer__item">
                      <div className="countdown-timer__article">{minutes}</div>
                    </div>
                    <div className="countdown-timer__item">
                      <div className="countdown-timer__article">{seconds}</div>
                    </div>
                  </div>
                </div>

                <div className="progress-bar">
                  <div className="progress-bar__line">
                    <span></span>
                  </div>

                  <div className="progress-bar__title">
                    <div className="progress-bar__name">0 BNB</div>
                    <div className="progress-bar__name">20 BNB</div>
                  </div>
                </div>

                <form className="form__amount">
                  <div className="form__amount__label">
                    Amount (Max: 20 BNB)
                  </div>

                  <div className="form__amount__item">
                    <input className="form__amount__input" defaultValue="0.0" />

                    <div className="form__amount__dt">Max</div>
                  </div>

                  <div className="form__amount__item">
                    <Buttons.Basic
                      variant="outlined"
                      title="You are not in whitelist"
                      className="u-btn-whitelist"
                    />
                  </div>
                </form>

                <div className="desc__list">
                  <div className="desc__list__item">
                    <div className="desc__list__label">Status</div>
                    <div className="desc__list__title">In progress</div>
                  </div>
                  <div className="desc__list__item">
                    <div className="desc__list__label">Minimum Buy</div>
                    <div className="desc__list__title">0.2 BNB</div>
                  </div>
                  <div className="desc__list__item">
                    <div className="desc__list__label">MaximumBuy</div>
                    <div className="desc__list__title">20 BNB</div>
                  </div>
                  <div className="desc__list__item">
                    <div className="desc__list__label">You Purchased</div>
                    <div className="desc__list__title">0 BNB</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </PresaleRow>
    </Layout>
  );
};

export default Presale;
