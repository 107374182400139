
import { useWallet } from "@solana/wallet-adapter-react";

const Wallets = () => {
  const { select, wallets, publicKey, disconnect } = useWallet();

  return !publicKey ? (
    <div>
      {wallets.filter((wallet) => wallet.readyState === "Installed").length >
      0 ? (
        wallets
          .filter((wallet) => wallet.readyState === "Installed")
          .map((wallet) => (
            <button
              key={wallet.adapter.name}
              onClick={() => select(wallet.adapter.name)}
              w="64"
              size="lg"
              fontSize="md"
              leftIcon={
                <image
                  src={wallet.adapter.icon}
                  alt={wallet.adapter.name}
                  h={6}
                  w={6}
                />
              }
            >
              {wallet.adapter.name}
            </button>
          ))
      ) : (
        <div>No wallet found. Please download a supported Solana wallet</div>
      )}
    </div>
  ) : (
    <div gap={4}>
      <div>{publicKey.toBase58()}</div>
      <button onClick={disconnect}>disconnect wallet</button>
    </div>
  );
};

const Bridge = () => {
  return (
    <div>
      <h2>Solana Custom Wallet UI example (Chakra UI)</h2>

      <Wallets />
    </div>
  );
};

export default Bridge;
