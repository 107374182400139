import styled from "styled-components";
import { Layout, Buttons, Icons, DropdownList } from "../../components";

const BridgeRow = styled.div`
  padding-top: 20px;
  padding-bottom: 30px;

  @media only screen and (min-width: 768px) {
    padding-top: 33px;
    padding-bottom: 60px;
  }

  h1,
  .h1 {
    margin: 0 0 53px;
    font-family: var(--font-gilroy);
    font-size: 26px;
    line-height: 31px;
    font-weight: 700;
    letter-spacing: -0.02em;
    color: #2d2d2d;

    @media only screen and (min-width: 768px) {
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 76px;
    }
  }

  .bridge {
    &__section {
      max-width: 540px;
      width: 100%;
      margin: 0 auto;
    }

    &__item {
      &:not(:last-child) {
        margin-bottom: 28px;
      }
    }

    &__head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      &__dropdown {
        display: flex;
        align-items: center;

        &__label {
          min-width: 40px;
          margin-right: 12px;
          font-family: var(--font-gilroy);
          font-size: 18px;
          line-height: 21px;
          font-weight: 600;
          letter-spacing: -0.02em;
          color: #2d2d2d;
        }
      }
    }

    &__body {
      position: relative;
    }

    &__textarea {
      &__head {
        position: absolute;
        top: 20px;
        left: 23px;
        right: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &__label {
        font-family: var(--font-gilroy);
        font-size: 18px;
        line-height: 21px;
        font-weight: 600;
        letter-spacing: -0.02em;
        color: #848994;

        &.tdu {
          text-decoration: underline;
        }
      }

      &__value {
        display: block;
        padding: 75px 24px 19px 24px;
        width: 100%;
        height: 120px;
        border: 1px solid #ebedf2;
        font-family: var(--font-gilroy);
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        letter-spacing: -0.02em;
        color: #2d2d2d;
        border-radius: 14px;
        resize: none;
        outline: none;
        transition: all 0.2s ease;

        &:focus {
          border-color: #c1cbd9;
        }
      }

      &__dropwdown {
        position: absolute;
        right: 14px;
        bottom: 12px;
        z-index: 1;
      }
    }

    &__repeat {
      margin-right: 30px;
      color: #bdc6dc;
      cursor: pointer;

      @media only screen and (max-width: 767px) {
        margin-right: 0;
      }
    }
  }

  .u-btn-transfer {
    width: 100%;
    height: 50px;
    border-radius: 14px;
    font-size: 16px;
    line-height: 17px;
    color: #969eb1;
    background-color: #ebedf2;
    border-color: #ebedf2;

    &:hover {
      background-color: #ebedf2;
      border-color: #ebedf2;
    }
  }
`;

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  padding-left: 15px;
  padding-right: 15px;

  @media only screen and (min-width: 1024px) {
    padding-left: 40px;
    padding-right: 40px;
  }
`;

const dataDropdownList = [
  {
    name: "Ethereum Mainnet",
  },
  {
    name: "Ethereum Mainnet 2",
  },
  {
    name: "Ethereum Mainnet 3",
  },
];

const dataDropdownList2 = [
  {
    name: "DF",
  },
  {
    name: "DF 2",
  },
  {
    name: "DF 3",
  },
];

const dataDropdownList3 = [
  {
    name: "Ethereum Mainnet",
  },
  {
    name: "Ethereum Mainnet 2",
  },
  {
    name: "Ethereum Mainnet 3",
  },
];

const Bridge = () => {
  return (
    <Layout>
      <BridgeRow>
        <Container>
          <h1>Bridge</h1>

          <div className="bridge__section">
            <div className="bridge__item">
              <div className="bridge__head">
                <div className="bridge__head__dropdown">
                  <div className="bridge__head__dropdown__label">From</div>
                  <DropdownList color data={dataDropdownList} />
                </div>
              </div>
              <div className="bridge__body">
                <div className="bridge__textarea__head">
                  <div className="bridge__textarea__label">Send:</div>
                  <div className="bridge__textarea__label tdu">Max: 0</div>
                </div>
                <textarea
                  name="send"
                  className="bridge__textarea__value"
                  defaultValue="0.0"
                />

                <div className="bridge__textarea__dropwdown">
                  <DropdownList data={dataDropdownList2} />
                </div>
              </div>
            </div>

            <div className="bridge__item">
              <div className="bridge__head">
                <div className="bridge__head__dropdown">
                  <div className="bridge__head__dropdown__label">To</div>
                  <DropdownList color data={dataDropdownList3} />
                </div>

                <div className="bridge__repeat">
                  <Icons.Repeat />
                </div>
              </div>
              <div className="bridge__body">
                <div className="bridge__textarea__head">
                  <div className="bridge__textarea__label">
                    Receive (estimated):
                  </div>
                </div>
                <textarea
                  name="receive"
                  className="bridge__textarea__value"
                  defaultValue="0.0"
                />
              </div>
            </div>

            <div className="bridge__item">
              <Buttons.Basic
                variant="outlined"
                title="Transfer"
                className="u-btn-transfer"
              />
            </div>
          </div>
        </Container>
      </BridgeRow>
    </Layout>
  );
};

export default Bridge;
