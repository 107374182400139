import styled from "styled-components/macro";

import { shortenAddress } from "../../utils";
import { TransactionState } from "./index";

import { TransactionType } from "../../state/transactions/types";

const HighlightText = styled.span`
  color: ${({ theme }) => theme.textPrimary};
  font-weight: 600;
`;

const BodyWrap = styled.div`
  line-height: 20px;
`;

const Action = ({ pending, success, failed, transactionState }) => {
  switch (transactionState) {
    case TransactionState.Failed:
      return failed;
    case TransactionState.Success:
      return success;
    default:
      return pending;
  }
};

const FailedText = ({ transactionState }) =>
  transactionState === TransactionState.Failed ? <span>failed</span> : <span />;

const MintSummary = ({ info, transactionState }) => {
  const actionProps = {
    transactionState,
    pending: <span>Minting</span>,
    success: <span>Minted</span>,
    failed: <span>Mint</span>,
  };

  return (
    <BodyWrap>
      <Action {...actionProps} />{" "}
      {info.quantity && <span>{info.quantity} </span>}
      <HighlightText>FINAPE NFT</HighlightText>{" "}
      for {info.totalAmount && <span>{info.totalAmount} </span>}{" "}
      {info.symbol && <>{info.symbol}</>} in {info.network && <>{info.network}</>} 
      <FailedText transactionState={transactionState} />
    </BodyWrap>
  );
};

const BridgeNftSummary = ({ info, transactionState }) => {
  const actionProps = {
    transactionState,
    pending: <span>Bridging</span>,
    success: <span>Bridging</span>,
    failed: <span>Bridge</span>,
  };

  return (
    <BodyWrap>
      <Action {...actionProps} />{" "}
      <HighlightText>FINAPE NFT</HighlightText>{" "}
      Token ID {info.tokenId} from {info.fromChainName && <span>{info.fromChainName}</span>}{" "}
      to {info.targetChainName && <span>{info.targetChainName}</span>}
      <FailedText transactionState={transactionState} />
    </BodyWrap>
  );
};

const BridgeNftMintSummary = ({ info, transactionState }) => {
  const actionProps = {
    transactionState,
    pending: <span>Bridge-minting</span>,
    success: <span>Bridge-minting</span>,
    failed: <span>Bridge-mint</span>,
  };

  return (
    <BodyWrap>
      <Action {...actionProps} />{" "}
      <HighlightText>FINAPE NFT</HighlightText>{" "}
      Sequency ID {info.sequenceId} in {info.network && <span>{info.network}</span>}
      <FailedText transactionState={transactionState} />
    </BodyWrap>
  );
};

const TransactionBody = ({ info, transactionState }) => {
  switch (info.type) {
    case TransactionType.MINT:
      return <MintSummary info={info} transactionState={transactionState} />;
    case TransactionType.BRIDGE_NFT:
      return <BridgeNftSummary info={info} transactionState={transactionState} />;
    case TransactionType.BRIDGE_NFT_MINT:
      return <BridgeNftMintSummary info={info} transactionState={transactionState} />;
    default:
      return <span />;
  }
};

export default TransactionBody;
