import React, { useCallback, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import styled from 'styled-components/macro';

import Portal from '../Portal';
import useInterval from '../../hooks/useInterval';
import { Z_INDEX } from '../../theme/zIndex';

const PopoverContainer = styled.div(({ show }) => ({
  zIndex: Z_INDEX.popover,
  pointerEvents: 'none',
  visibility: show ? 'visible' : 'hidden',
  opacity: show ? 1 : 0,
  transition: 'visibility 150ms linear, opacity 150ms linear',
  color: ({ theme }) => theme.textSecondary,
}));

const ReferenceElement = styled.div`
  display: inline-block;
  height: inherit;
`;

const Arrow = styled.div`
  width: 8px;
  height: 8px;
  zIndex: 9998;

  &::before {
    position: absolute;
    width: 8px;
    height: 8px;
    boxSizing: 'border-box',
    zIndex: 9998;

    content: '';
    border: 1px solid ${({ theme }) => theme.backgroundInteractive};
    transform: rotate(45deg);
    background: ${({ theme }) => theme.backgroundSurface};
  }

  &.arrow-top {
    bottom: -4px;
    &::before {
      borderTop: none;
      borderLeft: none;
    }
  }

  &.arrow-bottom {
    top: -4px;
    &::before {
      borderBottom: none;
      borderRight: none;
    }
  }

  &.arrow-left {
    right: -4px;
    &::before {
      borderBottom: none;
      borderLeft: none;
    }
  }

  &.arrow-right {
    left: -4px;
    &::before {
      borderRight: none;
      borderTop: none;
    }
  }
`;

export default function Popover({
  content,
  show,
  children,
  placement = 'auto',
  offsetX = 8,
  offsetY = 8,
  hideArrow = false,
  showInline = false,
  style,
}) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  const options = useMemo(
    () => ({
      placement,
      strategy: 'fixed',
      modifiers: [
        { name: 'offset', options: { offset: [offsetX, offsetY] } },
        { name: 'arrow', options: { element: arrowElement } },
        { name: 'preventOverflow', options: { padding: 8 } },
      ],
    }),
    [arrowElement, offsetX, offsetY, placement]
  );

  const { styles, update, attributes } = usePopper(referenceElement, popperElement, options);

  const updateCallback = useCallback(() => {
    update && update();
  }, [update]);
  useInterval(updateCallback, show ? 100 : null);

  return showInline ? (
    <PopoverContainer show={show}>{content}</PopoverContainer>
  ) : (
    <>
      <ReferenceElement style={style} ref={setReferenceElement}>
        {children}
      </ReferenceElement>
      <Portal>
        <PopoverContainer show={show} ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          {content}
          {!hideArrow && (
            <Arrow
              className={`arrow-${attributes.popper?.['data-popper-placement'] ?? ''}`}
              ref={setArrowElement}
              style={styles.arrow}
              {...attributes.arrow}
            />
          )}
        </PopoverContainer>
      </Portal>
    </>
  )
}
