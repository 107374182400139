import React, { useEffect, useState, useRef } from "react";
import LazyLoad from "react-lazyload";
import { useNavigate } from 'react-router-dom';

import useDebounce from "../../hooks/useDebounce";
import { Buttons, Layout, LoaderWithSparkles } from "../../components";
import Icons from "../../components/Icons";
import { MouseoverTooltip } from '../../components/Tooltip'
import ImageGallery from "../../components/ImageGallery";
import { padNumber } from "../../utils/helpers";
import { isMobile } from '../../utils/userAgent';
import NftDetailsModal from "./NftDetailsModal"; 
import SidebarNft from "./SidebarNft"; 
import Alert from "../../components/Alert";
import { useGalleryRefreshManager } from '../../state/user/hooks';

import {
  NFTExplorerRow,
  Container,
  LayoutSection,
  Content,
  FilterMobile,
  SelectNft,
  LoaderContainer,
} from "./styled";

import nft_placeholder from "../../assets/images/nft/placeholder.jpg";

const orderOptions = [
  { id: 1, name: "ID (low to high)", apiQuery: "asc" },
  { id: 2, name: "ID (high to low)", apiQuery: "desc" },
];

const NFTExplorer = () => {
  const ref = useRef();
  const navigate = useNavigate();

  const [galleryRefreshCount, increaseGalleryRefCount] = useGalleryRefreshManager()

  const [selectTitle, setSelectTitle] = useState(orderOptions[0].name);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [burgerMenu, setBurgerMenu] = useState(false);
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [recordOrderId, setRecordOrderId] = useState(1); // refer to orderOptions id
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [error, setError] = useState(null);
  const [filterQuery, setFilterQuery] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [viewId, setViewId] = useState(0);
  const [queryBy, setQueryBy] = useState("");
  const [totalResults, setTotalResults] = useState(0);
  const [hasRun, setHasRun] = useState(false);

  const debouncedSearchTerm = useDebounce(searchTerm, 800);

  useEffect(() => {
    if (!hasRun) {
      setSearchTerm("");
      reloadData();
      increaseGalleryRefCount();

      setHasRun(true);
    }
  }, [hasRun]);

  useEffect(() => {
    setSearchTerm("");
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordOrderId, filterQuery]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      searchNftWithId(searchTerm);
    } else {
      reloadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]); // Only call effect if debounced search term changes

  const onInputChange = (e) => {
    const value = e.target.value.replace(/\D/, "");
    setSearchTerm(value);
    setCurrentPage(-1);
  };

  const handleOpenMenu = () => {
    document.body.style.overflow = "hidden";
    setBurgerMenu(true);
  };

  const handleCloseMenu = () => {
    document.body.style.overflow = "";
    setBurgerMenu(false);
  };

  const changeOrder = (id) => {
    // whenever change order, reload the page
    setCurrentPage(0);
    setRecordOrderId(id);
    setDropdownOpen(!dropdownOpen);
  };

  const searchNftWithId = (id) => {
    const fetchData = async () => {
      if (!isLoading) {
        setIsLoading(true);

        try {
          let url = "https://api.finape.com/public/gallery?page=0";
          if (id.length > 0) {
            url = url + "&assigned_token_id=" + id;
          }
          const response = await fetch(url);
          const json = await response.json();

          setData([...json.data.rows]);
          setTotalPages(json.data.totalPages);

          setCurrentPage(0);
        } catch (error) {
          setError(error);
        }

        setIsLoading(false);
      }
    };

    fetchData();
  };

  const viewNftDetails = (tokenId, sequenceId) => {
    setModalIsOpen(true);

    if (tokenId !== null) {
      setQueryBy("tokenId");
      setViewId(tokenId);
    } else {
      setQueryBy("seqId");
      setViewId(sequenceId);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const reloadData = () => {
    const fetchData = async () => {
      if (!isLoading) {
        setIsLoading(true);

        try {
          let url = "https://api.finape.com/public/gallery?page=0";
          if (filterQuery.length > 0) {
            url = url + "&" + filterQuery;
          }
          if (recordOrderId > 1) {
            // if not ASC, include order
            url =
              url +
              "&order=" +
              orderOptions.filter((option) => option.id === recordOrderId)[0]
                ?.apiQuery;
          }
          const response = await fetch(url);
          const json = await response.json();

          setData([...json.data.rows]);
          setTotalPages(json.data.totalPages);
          setTotalResults(json.data.totalItems)

          setCurrentPage(0);
        } catch (error) {
          setError(error);
        }

        setIsLoading(false);
      }
    };

    fetchData();
  };

  const fetchMoreData = () => {
    const fetchData = async () => {
      if (!isFetchingMore && searchTerm.length <= 0 && currentPage >= 0) {
        setIsFetchingMore(true);

        try {
          let url =
            "https://api.finape.com/public/gallery?page=" + (currentPage + 1);
          if (filterQuery.length > 0) {
            url = url + "&" + filterQuery;
          }
          if (recordOrderId > 1) {
            // if not ASC, include order
            url =
              url +
              "&order=" +
              orderOptions.filter((option) => option.id === recordOrderId)[0]
                ?.apiQuery;
          }
          const response = await fetch(url);
          const json = await response.json();

          setData((prevData) => [...prevData, ...json.data.rows]);
          setTotalPages(json.data.totalPages);
          setTotalResults(json.data.totalItems);

          setCurrentPage(currentPage + 1);
        } catch (error) {
          setError(error);
        }

        setIsFetchingMore(false);
      }
    };

    fetchData();
  };

  const refreshNft = (query) => {
    setFilterQuery(query);
  };

  const renderItem = (item) => {
    return (
      <div key={item.initial_sequence_id} className="select-nft__item">
        <div
          className="card__article"
          onClick={() => viewNftDetails(item.assigned_token_id, item.initial_sequence_id)}
        >
          <div className="card__figure">
            <LazyLoad
              key={item.id}
              height={200}
              once
              placeholder={
                <img
                  src={nft_placeholder}
                  alt={"pl" + item?.id}
                />
              }
            >
              <img
                src={item?.thumbnail}
                alt={item?.id}
              />
            </LazyLoad>
          </div>
          <div className="card__body">
            <div className="card__name">LOLApe</div>

            {item?.assigned_token_id !== null ? (
              <div className="card__code">
                #{padNumber(item?.assigned_token_id, 4)}
              </div>
            ) : (
              <MouseoverTooltip
                text={
                  <span>
                    This NFT is not yet minted. The final token ID will be assigned randomly after minting.
                  </span>
                }
                placement="bottom"
              >
                <div className="card__code">
                  #????
                </div>
              </MouseoverTooltip>
            )}
          </div>
        </div>
      </div>
    );
  };

  if (0) console.log(galleryRefreshCount); 
  
  return (
    <Layout>
      <NFTExplorerRow>
        <Container>
        {galleryRefreshCount <= 4 ? (
          <>
          {galleryRefreshCount <= 1 ? (
            <Alert variant="dark">
              <b>Gallery</b><br/>
              Nothing to see here... or is there a hidden surprise awaiting?
            </Alert>
          ) : galleryRefreshCount <= 2 ? (
            <Alert variant="info">
              <b>Gallery</b><br/>
              hmmm...
            </Alert>
          ) : galleryRefreshCount <= 3 ? (
            <Alert variant="info">
              <b>Gallery</b><br/>
              Well...
            </Alert>
          ) : (
            <Alert variant="success">
              <b>Gallery</b><br/>
              Since you insist...
            </Alert>
          )}
          </>
        ) : (
        <>
          <h1>NFT Gallery</h1>

          <LayoutSection>
            <SidebarNft
              show={burgerMenu}
              hide={() => handleCloseMenu()}
              refreshNft={refreshNft}
            />

            <Content>
              {galleryRefreshCount <= 6 && (
                <Alert variant="dark">
                  <b>Gallery</b><br/>
                  Excellent, you found the way to view our gallery.
                </Alert>
              )}

              {!data || isLoading ? (
                <LoaderContainer>
                  <LoaderWithSparkles stroke="#fff909" fill="#ffffff" strokeWidth="5"/>
                </LoaderContainer>
              ) : (
              <div className={"contaier"}>
                <SelectNft>
                  <div className="select-nft">
                    <div className="select-nft__head">
                      <div className="select-nft__sortby">
                        <div className="select-nft__sortby__title">Sort by</div>

                        <div ref={ref} className="select-nft__sortby__select">
                          <div
                            onClick={() => setDropdownOpen(!dropdownOpen)}
                            className="select-nft__sortby__select__name"
                          >
                            {selectTitle}
                            <div className="select-nft__sortby__select__arrow">
                              {dropdownOpen ? (
                                <Icons.ChevronDown />
                              ) : (
                                <Icons.ChevronUp />
                              )}
                            </div>
                          </div>

                          <div
                            className={`${"select-nft__sortby__select__row"} ${
                              dropdownOpen ? "open" : ""
                            }`}
                          >
                            {orderOptions.map((__item, index) => {
                              return (
                                <div
                                  key={__item.id}
                                  onClick={() => {
                                    setSelectTitle(__item.name);
                                    changeOrder(__item.id);
                                  }}
                                  className="select-nft__sortby__select__item"
                                >
                                  {__item.name}
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        <Buttons.Connect 
                          title="Owned by me" 
                          onClick={() => navigate("/nfts")}
                          style={{marginLeft: 15, width: 130, fontSize: 14, lineHeight: "16px", padding: "9px 10px"}}
                        />
                      </div>

                      <form className="select-nft__form">
                        <div className="select-nft__searchby">
                          <button
                            type="submit"
                            className="select-nft__searchby__button"
                          >
                            <Icons.Search />
                          </button>
                          <input
                            type="text"
                            placeholder="Search by ID"
                            className="select-nft__searchby__input"
                            value={searchTerm}
                            onChange={(e) => {
                              onInputChange(e);
                            }}
                          />
                        </div>
                      </form>
                    </div>

                    <ImageGallery 
                      items={data} 
                      renderItem={renderItem} 
                      totalResults={totalResults}
                      loadMoreItems={fetchMoreData} 
                      defaultWidth={isMobile ? 160 : 200}
                      skipResize={modalIsOpen}
                    />
                    
                  </div>
                </SelectNft>
              </div>
              )}
            </Content>

            <FilterMobile onClick={() => handleOpenMenu()}>
              <div className="filter__label">Filters</div>
              {/*<div className="filter__count">2</div>*/}
            </FilterMobile>
          </LayoutSection>

          <NftDetailsModal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            id={viewId}
            queryBy={queryBy}
            showMintButton={true}
          />
        </>
        )}
        </Container>
      </NFTExplorerRow>
    </Layout>
  );
};

export default NFTExplorer;
