import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";
import styled, { keyframes } from "styled-components";
import { CheckCircle, ChevronDown } from "react-feather";


import BgPhoto1 from "../../assets/images/lolape-2577.jpg";
import BgPhoto2 from "../../assets/images/lolape-2646.jpg";
import BgPhoto3 from "../../assets/images/lolape-2678.jpg";
import BgPhoto4 from "../../assets/images/lolape-2679.jpg";
import BgPhoto5 from "../../assets/images/lolape-2685.jpg";

const Section = styled.section`
  padding-top: 90px; 
  padding-bottom: 90px;

  h1 {
  }
  h2 {
  }
`;

const PricingContainer = styled.div`
  margin: 0 auto;

  .price-plan-top-container {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .price-plan-top {
    background: rgba(0,0,0, 0.6);
    padding: 5px 0 10px 0;
  }
  .price-plan h2 {
    color: #eee !important;
    letter-spacing: 4px;
    font-size: 16px;
    padding: 0 0 10px;
    text-transform: uppercase;
  }
  .price-plan-link:hover,
  .price-breakdown {
    color: #f7f7f7;
  }
  .dollar {
    font-size: 22px;
    line-height: 22px;
    top: -55px;
    right: 3px;
    font-weight: 300;
    position: relative;
  }
  .price .amt {
    font-size: 110px;
    line-height: 84px;
    color: #f6bb00;
  }
  .price .mo {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  .price > p {
    font-size: 14px;
    line-height: 16px;
    margin-top: 14px;
    color: #bbb;
  }
  .info-wrapper {
    padding: 20px 20px 0;
    border: 1px solid #555;
    margin-bottom: 40px;
    background-color: #fff;
  }
  .info-wrapper ul li {
    font-size: 14px;
    border-bottom: 1px dashed #ddd;
    padding: 10px 0;
  }
  .list-group {
    list-style-type: none;
    color: #222;
    margin: 0;
    padding: 0;
  }
  .btn {
    border: 20px solid #f0f0f0;
    margin: 0 auto -40px;
    font-size: 14px;
    text-align: center;
  }
`;

const AccordionItem = ({ header, ...rest }) => (
  <Item
    {...rest}
    header={
      <>
        {header}
        <ChevronDown className="chevron" />
      </>
    }
    className="item"
    buttonProps={{
      className: ({ isEnter }) => `itemBtn ${isEnter && "itemBtnExpanded"}`,
    }}
    contentProps={{ className: "itemContent" }}
    panelProps={{ className: "itemPanel" }}
  />
);

export default function FAQ() {
  return (
    <Section id="roadmap">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-lg-12">
            <h1
              className="wow fadeInRightBig"
              data-wow-offset="80"
              data-wow-duration="2s"
            >
              LOLmap
            </h1>
          </div>
        </div>
      </div>
      <PricingContainer className="container">
        <div className="row">
          <div
            className="col-sm-6 col-lg-6  wow bounceIn"
            data-wow-offset="80"
            data-wow-duration="2s"
          >
            <div className="price-plan">
              <div className="price-plan-top-container" style={{backgroundImage: `url(${BgPhoto1})`}}>
              <div className="price-plan-top">
                <a className="price-plan-link">
                  <h2>2024</h2>
                  <div className="price">
                    <span className="amt">Q2</span>
                  </div>
                </a>
              </div>
              </div>
              <div className="info-wrapper">
                <ul className="list-group">
                  <li>
                    <CheckCircle color="#28822b" size={18} />
                    &nbsp;&nbsp;
                    <strong className="">List on every blockchain</strong>
                  </li>
                  <li>
                    <CheckCircle color="#28822b" size={18} />
                    &nbsp;&nbsp;
                    <strong className="">List on every DEX</strong>
                  </li>
                  <li>
                    <CheckCircle color="#28822b" size={18} />
                    &nbsp;&nbsp;
                    <strong className="">List on every CEX</strong>
                  </li>
                  <li>
                    <CheckCircle color="#28822b" size={18} />
                    &nbsp;&nbsp;
                    <strong className="">
                      Partner with every tech company
                    </strong>
                  </li>
                  <li>
                    <CheckCircle color="#28822b" size={18} />
                    &nbsp;&nbsp;
                    <strong className="">
                      Partner with every influencer
                    </strong>
                  </li>
                  <li>
                    <CheckCircle color="#28822b" size={18} />
                    &nbsp;&nbsp;
                    <strong className="">
                      Then we LOL at being filthy filthy rich, duh.
                    </strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
            <h2>
              JUST KIDDING!
            </h2>
        </div>

        <div className="gap-4"></div>
        <div className="row">
          <h1>Real Roadmap:</h1>
        </div>
        <div className="row">
          <div
            className="col-sm-6 col-lg-3  wow bounceIn"
            data-wow-offset="80"
            data-wow-duration="2s"
          >
            <div className="price-plan">
              <div className="price-plan-top-container" style={{backgroundImage: `url(${BgPhoto2})`}}>
              <div className="price-plan-top">
                <a className="price-plan-link">
                  <h2>2024</h2>
                  <div className="price">
                    <span className="amt">Q2</span>
                  </div>
                </a>
              </div>
              </div>
              <div className="info-wrapper">
                <ul className="list-group">
                  <li>
                    <i className="fa fa-check colour"></i> Reach a community of {" "}
                    <strong className="colour">1,000 members</strong>
                  </li>
                  <li>
                    <strong className="colour">Airdrop</strong>
                  </li>
                  <li>
                    Listing on <strong>CoinMarketCap</strong>
                  </li>
                  <li>
                    Listing on <strong>Coingecko</strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div
            className="col-sm-6 col-lg-3  wow bounceIn"
            data-wow-offset="80"
            data-wow-duration="2s"
          >
            <div className="price-plan">
              <div className="price-plan-top-container" style={{backgroundImage: `url(${BgPhoto3})`}}>
              <div className="price-plan-top">
                <a className="price-plan-link">
                  <h2>2024</h2>
                  <div className="price">
                    <span className="amt annual">Q3</span>
                  </div>
                </a>
              </div>
              </div>
              <div className="info-wrapper">
                <ul className="list-group">
                  <li>
                    <i className="fa fa-check colour"></i> Reach a community of {" "}
                    <strong className="colour">10,000 members</strong>
                  </li>
                  <li>
                    Launch <strong className="colour">NFT</strong>
                  </li>
                  <li>
                    <strong className="colour">Cross-chain</strong> Expansion
                  </li>
                  <li>
                    Release <strong className="colour">Music Video</strong>
                  </li>
                  <li>Partnership</li>
                  <li>
                    Listing on <strong className="colour">CEX</strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div
            className="col-sm-6 col-lg-3 wow bounceIn"
            data-wow-offset="80"
            data-wow-duration="2s"
          >
            <div className="price-plan">
              <div className="price-plan-top-container" style={{backgroundImage: `url(${BgPhoto4})`}}>
              <div className="price-plan-top">
                <a className="price-plan-link">
                  <h2>2024</h2>
                  <div className="price">
                    <span className="amt annual">Q4</span>
                  </div>
                </a>
              </div>
              </div>
              <div className="info-wrapper">
                <ul className="list-group">
                  <li>
                    <i className="fa fa-check colour"></i> Reach a community of {" "}
                    <strong className="colour">50,000 members</strong>
                  </li>
                  <li>
                    <strong className="colour">Cross-chain</strong> Expansion
                  </li>
                  <li>
                    Launch <strong>Merchandise</strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div
            className="col-sm-6 col-lg-3 wow bounceIn d-none"
            data-wow-offset="80"
            data-wow-duration="2s"
          >
            <div className="price-plan">
              <div className="price-plan-top-container" style={{backgroundImage: `url(${BgPhoto5})`}}>
              <div className="price-plan-top">
                <a className="price-plan-link">
                  <h2>2024</h2>
                  <div className="price">
                    <span className="amt annual">Q4</span>
                  </div>
                </a>
              </div>
              </div>
              <div className="info-wrapper">
                <ul className="list-group">
                  <li>Partnership</li>
                  <li>
                    More <strong className="colour">Music Video</strong>
                  </li>
                  <li>AMA Tour</li>
                  <li>Roadshow</li>
                  <li>Crypto Events</li>
                  <li>More Membership Privileges</li>
                  <li>
                    More <strong>Merchandise</strong>
                  </li>
                  <li>
                    Listing on <strong>CEX</strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </PricingContainer>
    </Section>
  );
}
