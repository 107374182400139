import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-modal";

import styled, { css } from "styled-components";

import { padNumber } from "../../../utils/helpers";
import { MouseoverTooltip } from '../../../components/Tooltip'
import ImageLoader from '../../../components/ImageLoader'
import { Buttons } from '../../../components'

import nft_placeholder from "../../../assets/images/nft/placeholder.jpg";
import backgroundImage from "../../../assets/images/001-rich.jpg";

const StyledModal = styled(Modal)`
  position: relative;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  overflow: visible;
  outline: none;
  max-width: 630px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border-radius: 10px;
  border: 15px #fff solid;

  @media only screen and (max-width: 767px) {
    width: 80vw;
  }

  @media only screen and (min-width: 768px) {
    max-width: 630px;
  }
  @media only screen and (min-width: 1024px) {
    max-width: 630px;
  }
  @media only screen and (min-width: 1280px) {
    max-width: 630px;
  }
`;

const Container = styled.div`
  width: 100%;
  height: auto; 
  max-height: 80vh; 
`;

const Card = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: auto;
  text-align: left;
  position: relative;

  .imageHolder {
    max-width: 600px; 
    padding: 0;

    .nftImage {
      width: 100%;
      border-top-left-radius: 10px;

      ${(props) =>
        props.mintButtonHidden &&
        css`
          border-bottom-left-radius: 10px;
        `}

      @media screen and (max-width: 768px) {
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .video-container {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 100%; /* 16:9 aspect ratio */
    }

    .video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;

      border-top-left-radius: 10px;

      ${(props) =>
        props.mintButtonHidden &&
        css`
          border-bottom-left-radius: 10px;
        `}

      @media screen and (max-width: 768px) {
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
`;

const NormalizedSpan = styled.span`
  font-weight: normal
`;

const MintButton = styled(Link)`
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #04aa6d;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
  margin: 0;
  position: absolute; 
  bottom: 0; 
  left: 0;
  text-decoration: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  &:hover {
    opacity: 0.9;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: -24px;
  right: -24px;
  border-radius: 15px;
  border: 1px solid #999;
  color: #666;
  height: 28px;
  width: 28px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  z-index: 1;

  @media only screen and (max-width: 767px) {
    top: -20px;
    right: -20px;
    font-size: 25px;
    border-radius: 20px;
    height: 40px;
    width: 40px;
    line-height: 25px;
  }
`;

const customStyles = {
  /*
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    margin: "0",
    padding: "0",
    overflow: "visible",
  },
  */
  overlay: {
    backgroundColor: "rgba(32, 40, 46, 0.5)",
  },
};

const NftDetailsModal = (props) => {
  const navigate = useNavigate();

  const { 
    showBridgeButton, 
    showMintButton, 
    isOpen, 
    onRequestClose, 
    notRevealed, 
    queryBy = 'tokenId', 
    id 
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else { 
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  const closeThisModal = () => {
    onRequestClose();
  };

  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={closeThisModal}
      ariaHideApp={false}
      style={customStyles}
    >
      <CloseButton onClick={closeThisModal}>
        ✕
      </CloseButton>

      <Card mintButtonHidden={!showMintButton}>
        <Container>
            <div className="imageHolder">
                <ImageLoader
                  src={backgroundImage}
                  alt="LOLApe"
                  imgClassName="nftImage"
                />
            </div>
        </Container>
        <MintButton onClick={closeThisModal}>
          Contribution is submitted successfully, updates might take up to 2 minutes
        </MintButton>

      </Card>
    </StyledModal>
  );
};

export default NftDetailsModal;
