import { SupportedChainId } from './chains'

export const MULTICALL_ADDRESSES = {
  [SupportedChainId.MAINNET]: '0x1F98415757620B543A52E61c46B32eB19261F984',
  [SupportedChainId.GOERLI]: '0x39d7e721c88b505d004819992bc27b364d74c8e9',
  [SupportedChainId.POLYGON]: '0x1F98415757620B543A52E61c46B32eB19261F984',
  [SupportedChainId.POLYGON_MUMBAI]: '0xbc8948A9764F75810879D40e0f4f7c4209588A81',
  [SupportedChainId.BNBCHAIN]: '0x963Df249eD09c358A4819E39d9Cd5736c3087184',
  //[SupportedChainId.KLAYTN]: '0x000',
  [SupportedChainId.SEPOLIA]: '0x39d7e721c88b505d004819992bc27b364d74c8e9',
  [SupportedChainId.ARBITRUM_ONE]: '0x1F98415757620B543A52E61c46B32eB19261F984',
  [SupportedChainId.BASE]: '0x1F98415757620B543A52E61c46B32eB19261F984',
  [SupportedChainId.AVAX]: '0x1F98415757620B543A52E61c46B32eB19261F984',
}

export const FINAPE_NFT_ADDRESSES = {
  [SupportedChainId.MAINNET]: '0x470bB2511eD1d2A8051A42E860C55F9432ef838F',
  [SupportedChainId.POLYGON]: '0x470bB2511eD1d2A8051A42E860C55F9432ef838F',
  [SupportedChainId.BNBCHAIN]: '0x470bB2511eD1d2A8051A42E860C55F9432ef838F',
  [SupportedChainId.ARBITRUM_ONE]: '0x470bB2511eD1d2A8051A42E860C55F9432ef838F',

  //[SupportedChainId.GOERLI]: '0xa7d94cd720e9Bf1e35EBb9fd3d4DB2d34149156A',
  //[SupportedChainId.SEPOLIA]: '0x3B785B0A89dc8A95C6e21E5874509E2049ffff9C',
  //[SupportedChainId.POLYGON_MUMBAI]: '0xe35C2E8824B3DCAD36c5668d8905eF62a7A24BF8',
}
