import styled from 'styled-components';
import { AlertTriangle } from 'react-feather'

import { ThemedText } from '../../theme';
import { flexColumnNoWrap, flexRowNoWrap } from '../../theme/styles';


import Loader from '../Loader';

const PendingSection = styled.div`
  ${flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  width: 100%;
  & > * {
    width: 100%;
  }
`;

const WaitingToConnectSection = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const AlertTriangleIcon = styled(AlertTriangle)`
  width: 25%;
  height: 25%;
  stroke-width: 1;
  padding-bottom: 2rem;
  color: ${({ theme }) => theme.accentCritical};
`;

const LoaderContainer = styled.div`
  ${flexRowNoWrap};
  margin: 16px 0;
  align-items: center;
  justify-content: center;
`;

const LoadingMessage = styled.div`
  ${flexRowNoWrap};
  align-items: center;
  justify-content: center;
  border-radius: 12px;

  & > * {
    padding: 1rem;
  }
`;

const ErrorGroup = styled.div`
  ${flexColumnNoWrap};
  align-items: center;
  justify-content: flex-start;
`;

const LoadingWrapper = styled.div`
  ${flexColumnNoWrap};
  align-items: center;
  justify-content: center;
`;

const ButtonPrimary = styled.button`
  background-color: rgb(76, 130, 251);
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  padding: 16px;
  color: #fff;
  border-radius: 12px;
  border: 1px solid transparent;
  cursor: pointer; 
`;

const ButtonEmpty = styled.button`
  background-color: transparent;
  color: rgb(76, 130, 251);
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-weight: 500;
  text-align: center;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  position: relative;
  z-index: 1;
  margin-top: 20px; 
`;

export default function PendingView({
  connector,
  error = false,
  tryActivation,
  openOptions,
}) {
  return (
    <PendingSection>
      <LoadingMessage>
        <LoadingWrapper>
          {error ? (
            <ErrorGroup>
              <AlertTriangleIcon />
              <h3 style={{marginTop: 0}}>
                Error connecting
              </h3>
              <span style={{marginBottom: 36, textAlign: "center"}}>
                  The connection attempt failed. Please click try again and follow the steps to connect in your wallet.
              </span>
              <ButtonPrimary
                onClick={() => {
                  tryActivation(connector);
                }}
              >
                Try Again
              </ButtonPrimary>
              <ButtonEmpty>
                <span onClick={openOptions}>
                  Back to wallet selection
                </span>
              </ButtonEmpty>
            </ErrorGroup>
          ) : (
            <>
              <WaitingToConnectSection>
                <LoaderContainer style={{ padding: '16px 0px' }}>
                  <Loader strokeWidth={0.8} size="100px" />
                </LoaderContainer>
                <span>
                  Waiting to connect
                </span>
                <span style={{ paddingTop: '8px' }}>
                  Confirm this connection in your wallet
                </span>
              </WaitingToConnectSection>
            </>
          )}
        </LoadingWrapper>
      </LoadingMessage>
    </PendingSection>
  );
}
