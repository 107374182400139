import { createSlice } from '@reduxjs/toolkit';
import { ConnectionType } from '../../connection';

const initialState = {
  errorByConnectionType: {
    [ConnectionType.INJECTED]: undefined,
    [ConnectionType.WALLET_CONNECT]: undefined,
    [ConnectionType.WALLET_CONNECT_V2]: undefined,
    [ConnectionType.COINBASE_WALLET]: undefined,
    [ConnectionType.NETWORK]: undefined,
    [ConnectionType.GNOSIS_SAFE]: undefined,
    [ConnectionType.UNISAT_CONNECT]: undefined,
    [ConnectionType.XVERSE_CONNECT]: undefined,
    [ConnectionType.PHANTOM_CONNECT]: undefined,
    [ConnectionType.SOLFLARE_CONNECT]: undefined,
  },
};

const connectionSlice = createSlice({
  name: 'connection',
  initialState,
  reducers: {
    updateConnectionError(state, { payload: { connectionType, error } }) {
      state.errorByConnectionType[connectionType] = error;
    },
  },
});

export const { updateConnectionError } = connectionSlice.actions;
export default connectionSlice.reducer;
