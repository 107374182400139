import { useCustomWeb3React } from '../../providers/CustomWeb3ReactProvider';
import useDebounce from '../../hooks/useDebounce';
import useIsWindowVisible from '../../hooks/useIsWindowVisible';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch } from '../../state/hooks';
import { supportedChainId } from '../../utils/supportedChainId';

import { useCloseModal } from './hooks';
import { updateChainId } from './reducer';

export default function Updater() {
  const { account, chainId, provider } = useCustomWeb3React();
  const dispatch = useAppDispatch();
  const windowVisible = useIsWindowVisible();

  const [activeChainId, setActiveChainId] = useState(chainId);

  const closeModal = useCloseModal();
  const previousAccountValue = useRef(account);
  useEffect(() => {
    if (account && account !== previousAccountValue.current) {
      previousAccountValue.current = account;
      closeModal();
    }
  }, [account, closeModal]);

  useEffect(() => {
    if (provider && chainId && windowVisible) {
      setActiveChainId(chainId);
    }
  }, [dispatch, chainId, provider, windowVisible]);

  const debouncedChainId = useDebounce(activeChainId, 100);

  useEffect(() => {
    const chainId = debouncedChainId ? supportedChainId(debouncedChainId) ?? null : null;
    dispatch(updateChainId({ chainId }));
  }, [dispatch, debouncedChainId]);

  return null;
}
