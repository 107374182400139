/*
 * SupportedChainId must be defined inline, without using @uniswap/sdk-core, so that its members are their own types
 * {@see https://www.typescriptlang.org/docs/handbook/enums.html#union-enums-and-enum-member-types}. This allows the
 * derived const arrays and their types (eg {@link L1_CHAIN_IDS}, {@link SupportedL1ChainId}) to be narrowed and used
 * to enforce chain typing.
 *
 * Because this is not explicitly derived from @uniswap/sdk-core, there is a unit test to enforce conformance.
 */
export const SupportedChainId = {
  BITCOIN: -1,
  MAINNET: 1,
  GOERLI: 5,
  BNBCHAIN: 56,
  ARBITRUM_ONE: 42161,
  //ARBITRUM_GOERLI: 421613,
  //OPTIMISM: 10,
  //OPTIMISM_GOERLI: 420,
  POLYGON: 137,
  POLYGON_MUMBAI: 80001,
  //KLAYTN: 8217,  
  SEPOLIA: 11155111,  
  //CELO: 42220,
  //CELO_ALFAJORES: 44787,
  SOLANA: 501424,
  BASE: 8453,
  AVAX: 43114,
};

export const CHAIN_IDS_TO_NAMES = {
  [SupportedChainId.BITCOIN]: 'bitcoin',
  [SupportedChainId.MAINNET]: 'mainnet',
  [SupportedChainId.GOERLI]: 'goerli',
  [SupportedChainId.BNBCHAIN]: 'bsc',
  [SupportedChainId.POLYGON]: 'polygon',
  [SupportedChainId.POLYGON_MUMBAI]: 'polygon_mumbai',
  [SupportedChainId.ARBITRUM_ONE]: 'arbitrum',
  [SupportedChainId.SEPOLIA]: 'sepolia',
  [SupportedChainId.SOLANA]: 'solana',
  [SupportedChainId.BASE]: 'base',
  [SupportedChainId.AVAX]: 'avalanche',
  //[SupportedChainId.CELO]: 'celo',
  //[SupportedChainId.KLAYTN]: 'klaytn',
  //[SupportedChainId.CELO_ALFAJORES]: 'celo_alfajores',
  //[SupportedChainId.ARBITRUM_GOERLI]: 'arbitrum_goerli',
};

export const ALL_SUPPORTED_CHAIN_IDS = Object.values(SupportedChainId).filter(
  (id) => typeof id === 'number'
).filter(Boolean);

export function isSupportedChain(chainId) {
  return !!chainId && Object.values(SupportedChainId).includes(chainId); // !!SupportedChainId[chainId];
}

export const SUPPORTED_GAS_ESTIMATE_CHAIN_IDS = [
  SupportedChainId.MAINNET,
  SupportedChainId.BNBCHAIN,
  SupportedChainId.POLYGON,
  SupportedChainId.ARBITRUM_ONE,
  SupportedChainId.BASE,
  SupportedChainId.AVAX,
  //SupportedChainId.CELO,
  //SupportedChainId.OPTIMISM,
  //SupportedChainId.KLAYTN,
];

export const UNSUPPORTED_V2POOL_CHAIN_IDS = [
  SupportedChainId.POLYGON,
  //SupportedChainId.OPTIMISM,
  //SupportedChainId.ARBITRUM_ONE,
  //SupportedChainId.ARBITRUM_GOERLI,
];

export const TESTNET_CHAIN_IDS = [
  SupportedChainId.GOERLI,
  SupportedChainId.SEPOLIA,
  SupportedChainId.POLYGON_MUMBAI,
  //SupportedChainId.ARBITRUM_GOERLI,
  //SupportedChainId.OPTIMISM_GOERLI,
];

export const L1_CHAIN_IDS = [
  SupportedChainId.MAINNET,
  SupportedChainId.GOERLI,
  SupportedChainId.SEPOLIA,
  SupportedChainId.BNBCHAIN,
  SupportedChainId.POLYGON,
  SupportedChainId.POLYGON_MUMBAI,
  SupportedChainId.AVAX,
  //SupportedChainId.KLAYTN,
  //SupportedChainId.CELO,
  //SupportedChainId.CELO_ALFAJORES,
];

export const L2_CHAIN_IDS = [
  SupportedChainId.ARBITRUM_ONE,
  SupportedChainId.BASE,
  //SupportedChainId.ARBITRUM_GOERLI,
  //SupportedChainId.OPTIMISM,
  //SupportedChainId.OPTIMISM_GOERLI,
];

