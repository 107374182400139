import { formatEther, parseEther, formatUnits, parseUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';

export const formatUsdPrice = (price) => {
  if (price > 1000000) {
    return `$${(price / 1000000).toFixed(1)}M`;
  } else if (price > 1000) {
    return `$${(price / 1000).toFixed(1)}K`;
  } else {
    return `$${price.toFixed(2)}`;
  }
};

export const formatEth = (price) => {
  if (price > 1000000) {
    return `${Math.round(price / 1000000)}M`;
  } else if (price > 1000) {
    return `${Math.round(price / 1000)}K`;
  } else if (price < 0.001) {
    return '<0.001';
  } else {
    return `${Math.round(price * 1000 + Number.EPSILON) / 1000}`;
  }
};

export const formatUSDPriceWithCommas = (price) => {
  return `$${Math.round(price)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
};

export const formatEthPrice = (price) => {
  if (!price) return 0;

  const formattedPrice = parseFloat(formatEther(String(price)));
  return (
    Math.round(formattedPrice * (formattedPrice >= 1 ? 100 : 1000) + Number.EPSILON) /
    (formattedPrice >= 1 ? 100 : 1000)
  );
};

// Stringify the `price` anyway because the `price` is being passed as any in some places
export const numberToWei = (amount) => {
  return parseEther(amount.toString());
};

export const ethNumberStandardFormatter = (
  amount,
  includeDollarSign = false,
  removeZeroes = false,
  roundToNearestWholeNumber = false
) => {
  if (!amount) return '-';

  const amountInDecimals = parseFloat(amount.toString());
  const conditionalDollarSign = includeDollarSign ? '$' : '';

  if (amountInDecimals <= 0) return '-';
  if (amountInDecimals < 0.0001) return `< ${conditionalDollarSign}0.00001`;
  if (amountInDecimals < 1) return `${conditionalDollarSign}${parseFloat(amountInDecimals.toFixed(3))}`;
  const formattedPrice = (
    removeZeroes
      ? parseFloat(amountInDecimals.toFixed(2))
      : roundToNearestWholeNumber
      ? Math.round(amountInDecimals)
      : amountInDecimals.toFixed(2)
  )
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return conditionalDollarSign + formattedPrice;
};

export const formatWeiToDecimal = (amount, removeZeroes = false) => {
  if (!amount) return '-';
  return ethNumberStandardFormatter(formatEther(amount), false, removeZeroes, false);
};

// prevent BigNumber overflow by properly handling scientific notation and comma delimited values
export function wrapScientificNotation(value) {
  return parseFloat(value.toString())
    .toLocaleString('fullwide', { useGrouping: false })
    .replace(',', '.')
    .replace(' ', '');
}

// BigNumber("123456789012345678901") -> 9 decimals -> string '123456789012.345678901'
export function formatBigNumber(bigNumber, bigNumberDecimals = 18, outputDecimals = 8) {
  let bigNumberInstance;
  if (typeof bigNumber === 'string') {
    bigNumberInstance = BigNumber.from(bigNumber);
  } else {
    bigNumberInstance = bigNumber;
  }
  const formatted = formatUnits(bigNumber, bigNumberDecimals);
  return formatted;
}

// string '123456789012.345678901' -> 9 decimals -> BigNumber("123456789012345678901")
export function parseNumberString(numberString, decimals = 18) {
  const parsed = parseUnits(numberString, decimals);
  return parsed;
}