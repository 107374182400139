import React from "react";
import PropTypes from "prop-types";

const Twitter = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="25"
      height="21"
      viewBox="0 0 25 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M25 2.42308C24.0625 2.90769 23.125 3.06923 22.0312 3.23077C23.125 2.58462 23.9062 1.61538 24.2188 0.323077C23.2812 0.96923 22.1875 1.29231 20.9375 1.61538C20 0.646154 18.5938 0 17.1875 0C13.9062 0 11.4062 3.23077 12.1875 6.46154C7.96875 6.3 4.21875 4.2 1.5625 0.969231C0.15625 3.39231 0.9375 6.46154 3.125 8.07692C2.34375 8.07692 1.5625 7.75385 0.78125 7.43077C0.78125 9.85385 2.5 12.1154 4.84375 12.7615C4.0625 12.9231 3.28125 13.0846 2.5 12.9231C3.125 15.0231 5 16.6385 7.34375 16.6385C5.46875 18.0923 2.65625 18.9 0 18.5769C2.34375 20.0308 5 21 7.8125 21C17.3438 21 22.6562 12.7615 22.3437 5.16923C23.4375 4.52308 24.375 3.55385 25 2.42308Z"
        fill={color}
      />
    </svg>
  );
};

Twitter.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export default Twitter;
