import React, { useState, useEffect } from "react";

import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";
import styled, { keyframes } from "styled-components";
import { ChevronDown } from "react-feather";

const Section = styled.section`
	padding-top: 90px;
	padding-bottom: 90px;
	text-align: center;

	h1 {
	}
	h2 {
	}
`;

const AccordionContainer = styled.div`
	border-top: 1px solid #ccc;
	margin-top: 1rem;

	.item {
		border-bottom: 1px solid #ccc;
		text-align: left;
	}

	.itemBtn {
		cursor: pointer;
		display: flex;
		align-items: center;
		width: 100%;
		margin: 0;
		padding: 1rem;
		font-size: 1rem;
		text-align: left;
		color: #000;
		background-color: transparent;
		border: none;
	}

	.itemBtn:hover {
		background-color: #f3f3f3;
	}

	.itemBtnExpanded {
		background-color: #e7e7e7;
	}

	.itemContent {
		transition: height 0.25s cubic-bezier(0, 0, 0, 1);
		background-color: #cecece;
		line-height: 1.4;
	}

	.itemPanel {
		padding: 1rem;
	}

	.chevron {
		margin-left: auto;
		transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
	}

	.itemBtnExpanded .chevron {
		transform: rotate(180deg);
	}
`;

const AccordionItem = ({ header, ...rest }) => (
	<Item
		{...rest}
		header={
			<>
				{header}
				<ChevronDown className="chevron" />
			</>
		}
		className="item"
		buttonProps={{
			className: ({ isEnter }) =>
				`itemBtn ${isEnter && "itemBtnExpanded"}`,
		}}
		contentProps={{ className: "itemContent" }}
		panelProps={{ className: "itemPanel" }}
	/>
);

export default function FAQ() {

	return (
		<Section id="faq">
			<div className="container">
				<h1>FAQ</h1>
				<h2>Frequently Asked Questions</h2>
				<div className="row justify-content-lg-center">
					<div className="col-sm-12 col-lg-6">
						<AccordionContainer>
							{/* `transitionTimeout` prop should be equal to the transition duration in CSS */}
							<Accordion transition transitionTimeout={250}>
								<AccordionItem
									header="Will there be a presale?"
									initialEntered
								>
									Indeed, a presale is in the works. Stay updated with our social media channels for the latest updates.
								</AccordionItem>

								<AccordionItem header="How to buy $LOL?">
									You can buy $LOL through Raydium (Solana) and all the major DEX in the supported chains.
								</AccordionItem>

								<AccordionItem header="Who is the team?">
									Team has multiple worked previously in succesful projects ranging in the millions to hundreds of millions in market cap and are very experienced in growing a project from zero to hero.
									<br /><br/>
									They are motivated to see this project succeed.
								</AccordionItem>

								<AccordionItem header="Is the contract safe?">
									Contract is audited by Coinsult and you can
									check their website at <a className="darkLink" href="https://coinsult.net/projects/lolape/" target="_blank">https://coinsult.net/projects/lolape</a>
								</AccordionItem>
							</Accordion>
						</AccordionContainer>
					</div>
				</div>
			</div>
		</Section>
	);
}
