import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import Popover from '../Popover';

export const TooltipContainer = styled.div`
  max-width: 256px;
  cursor: default;
  padding: 0.6rem 1rem;

  color: ${({ theme }) => theme.textPrimary};
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  word-break: break-word;

  background: ${({ theme }) => theme.backgroundSurface};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.backgroundInteractive};
  box-shadow: 0 4px 8px 0 ${({ theme }) => theme.shadow1};
`

function Tooltip({ text, open, close, noOp, disableHover, ...rest }) {
  return (
    <Popover
      content={
        text && (
          <TooltipContainer onMouseEnter={disableHover ? noOp : open} onMouseLeave={disableHover ? noOp : close}>
            {text}
          </TooltipContainer>
        )
      }
      {...rest}
    />
  );
}

function TooltipContent({ content, wrap = false, ...rest }) {
  return <Popover content={wrap ? <TooltipContainer>{content}</TooltipContainer> : content} {...rest} />;
}

export default Tooltip;

export function MouseoverTooltip({ text, disableHover, children, timeout, ...rest }) {
  const [show, setShow] = useState(false);
  const open = useCallback(() => text && setShow(true), [text, setShow]);
  const close = useCallback(() => setShow(false), [setShow]);

  useEffect(() => {
    if (show && timeout) {
      const tooltipTimer = setTimeout(() => {
        setShow(false);
      }, timeout);

      return () => {
        clearTimeout(tooltipTimer);
      };
    }
    return;
  }, [timeout, show]);

  const noOp = () => null;
  return (
    <Tooltip
      {...rest}
      open={open}
      close={close}
      noOp={noOp}
      disableHover={disableHover}
      show={show}
      text={disableHover ? null : text}
    >
      <div onMouseEnter={disableHover ? noOp : open} onMouseLeave={disableHover || timeout ? noOp : close}>
        {children}
      </div>
    </Tooltip>
  );
}

export function MouseoverTooltipContent({ content, children, onOpen = undefined, disableHover, ...rest }) {
  const [show, setShow] = useState(false);
  const open = useCallback(() => {
    setShow(true);
    onOpen?.();
  }, [onOpen]);
  const close = useCallback(() => setShow(false), [setShow]);
  return (
    <TooltipContent {...rest} show={show} content={disableHover ? null : content}>
      <div
        style={{ display: 'inline-block', lineHeight: 0, padding: '0.25rem' }}
        onMouseEnter={open}
        onMouseLeave={close}
      >
        {children}
      </div>
    </TooltipContent>
  );
}
