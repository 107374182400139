import styled from 'styled-components/macro'

export const MintingRow = styled.div`
  padding-bottom: 90px;
  background-image: url(../../images/minting/minting-bg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: calc(100vh - 95px);

  @media only screen and (min-width: 768px) {
    padding-bottom: 60px;
  }

  h1,
  .h1 {
    margin: 0 0 20px;
    font-family: var(--font-gilroy);
    font-size: 26px;
    line-height: 31px;
    font-weight: 700;
    letter-spacing: -0.02em;
    color: white;

    @media only screen and (min-width: 768px) {
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 50px;
    }
  }

  .minting {
    &__section {
      max-width: 1170px;
      width: 100%;
      margin: 0 auto;
    }

    &__row {
      display: flex;
      flex-wrap: wrap;
    }

    &__figure {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 520px;
      width: 465px;

      @media only screen and (max-width: 1439px) {
        flex: 0 0 50%;
        max-width: 50%;
      }

      @media only screen and (max-width: 1023px) {
        flex: 0 0 40%;
        max-width: 40%;
      }

      @media only screen and (max-width: 767px) {
        margin-bottom: 40px;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    &__details {
      flex: 1;
      padding-top: 0;
      padding-left: 80px;

      @media only screen and (max-width: 1439px) {
        flex: 0 0 50%;
        max-width: 50%;
        padding-top: 0;
        padding-left: 30px;
      }

      @media only screen and (max-width: 1023px) {
        flex: 0 0 60%;
        max-width: 60%;
      }

      @media only screen and (max-width: 767px) {
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: 0;
      }

      &__title {
        font-family: var(--font-gilroy);
        font-size: 30px;
        line-height: 42px;
        font-weight: 700;
        color: #ffffff;
        margin-bottom: 24px;

        @media only screen and (max-width: 1439px) {
          font-size: 22px;
          line-height: 34px;
          margin-bottom: 15px;
        }

        @media only screen and (max-width: 1023px) {
          font-size: 20px;
          line-height: 29px;
          margin-bottom: 20px;
        }
      }

      &__creator {
        display: flex;
        align-items: center;
        margin-bottom: 50px;

        @media only screen and (max-width: 1439px) {
          margin-bottom: 30px;
        }

        &__icon {
          margin-right: 12px;
          flex: 0 0 36px;
          width: 36px;
          height: 36px;
          border-radius: 50%;
          background-color: white;
        }

        &__info {
          flex: 1;
        }

        &__label {
          font-family: var(--font-gilroy);
          font-size: 13px;
          line-height: 16px;
          font-weight: 600;
          color: #ffffff;
        }

        &__text {
          font-family: var(--font-gilroy);
          font-size: 18px;
          line-height: 22px;
          font-weight: 700;
          color: #ffffff;
        }
      }

      &__sold {
        &__item {
          &:not(:last-child) {
            margin-bottom: 22px;

            @media only screen and (max-width: 1439px) {
              margin-bottom: 20px;
            }
          }
        }
 
        &__title {
          font-family: var(--font-gilroy);
          font-size: 16px;
          line-height: 18px;
          font-weight: normal;
          color: #ffffff;
          margin-bottom: 8px;
        }

        &__box {
          padding: 5px 18px;
          display: flex;
          align-items: flex-end;
          backdrop-filter: blur(32px);
          border-radius: 14px;
          background: linear-gradient(
            264.06deg,
            rgba(128, 138, 204, 0.22) -13.25%,
            rgba(126, 136, 196, 0.16) 98.94%
          );

          @media only screen and (max-width: 1023px) {
            padding: 5px 10px;
          }

          span {
            font-family: var(--font-gilroy);
            font-size: 18px;
            line-height: 30px;
            font-weight: 600;
            color: #ffffff;
            margin-right: 8px;

            @media only screen and (max-width: 1023px) {
              font-size: 16px;
              line-height: 26px;
            }
          }

          i {
            font-family: var(--font-gilroy);
            font-size: 16px;
            line-height: 36px;
            font-style: normal;
            font-weight: 600;
            letter-spacing: -0.02em;
            color: #ffffff;
            margin-bottom: 5px;
          }
        }

        &__timer {
          padding: 10px 15px 5px;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          backdrop-filter: blur(32px);
          border-radius: 14px;
          margin-bottom: 15px;
          background: linear-gradient(
            264.06deg,
            rgba(128, 138, 204, 0.22) -13.25%,
            rgba(126, 136, 196, 0.16) 98.94%
          );

          &__item {
            width: 40px;
            text-align: center;
          }

          span {
            display: block;
            font-family: var(--font-gilroy);
            font-size: 22px;
            line-height: 26px;
            font-weight: 500;
            color: #ffffff;
          }

          i {
            display: block;
            font-style: normal;
            font-family: var(--font-gilroy);
            font-size: 14px;
            line-height: 19px;
            font-weight: normal;
            letter-spacing: -0.02em;
            color: #ffffff;
          }
        }

        &__progress {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 28px;
          border-radius: 8px;
          z-index: 0;
          overflow: hidden;
          background: linear-gradient( 264.06deg,rgba(128,138,204,0.22) -13.25%,rgba(126,136,196,0.16) 98.94% );
          

          span {
            font-family: var(--font-gilroy);
            font-size: 16px;
            line-height: 19px;
            font-weight: 600;
            color: #ffffff;
          }
        }

        &__number__input {
          border: 2px solid #ddd;
          display: inline-flex;

          * {
            box-sizing: border-box;
          }

          input[type=number]::-webkit-inner-spin-button,
          input[type=number]::-webkit-outer-spin-button {
            -webkit-appearance: none;
          }

          button {
            outline:none;
            -webkit-appearance: none;
            border: none;
            align-items: center;
            justify-content: center;
            width: 2.5rem;
            height: 36px;
            cursor: pointer;
            margin: 0;
            position: relative;

            @media only screen and (max-width: 1023px) {
              height: 30px;
            }
          }

          button:after {
            display: inline-block;
            position: absolute;
            font-weight: 900;
            font-size: 20px;
            content: '-';
            transform: translate(-50%, -50%);
          }
          button.plus:after {
            content: '+';
            transform: translate(-50%, -50%);
          }

          input[type=number] {
            font-family: sans-serif;
            max-width: 5rem;
            padding: 12px;
            border: solid #ddd;
            border-width: 0 2px;
            border-radius: 0;
            font-size: 18px;
            height: 36px;
            font-weight: bold;
            text-align: center;
            margin: 0;

            @media only screen and (max-width: 1023px) {
              height: 30px;
            }
          }
        }
      }
    }
  }

  .u-btn-connect {
    width: 100%;
    color: #fff;
    background-color: #157bef;
    border: 0 solid;

    &:hover {
      background-color: #157bef;
      color: #fff;
      border: 1px solid;
    }
  }

  .u-btn-mint {
    width: 100%;
    color: #000;
    background-color: #41da7e;
    border: 0 solid;

    &:hover {
      background-color: #72e49f;
      color: #000;
    }
  }

  .u-btn-sold-out {
    width: 100%;
    height: 60px;
    border-radius: 14px;
    font-size: 18px;
    line-height: 21px;
    color: #fff;
    background-color: #e6818c;
    border: 0 solid;
  }

  .u-btn-disabled {
    color: #000;
    width: 100%;
    border: 0 solid;
    background-color: #ccc;

    &:hover {
      background-color: #ccc;
      color: #000;
    }
  }

  .u-btn {
    color: #fff;
    width: 100%;
    border: 0 solid;
  }
`;

export const MintingProgress = styled.i`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ progress }) => progress ? progress : "0"}%;
  height: 100%;
  background: linear-gradient(
    88.05deg,
    #1e7ff0 -0.88%,
    #1ee3f0 105.57%
  );
  border-radius: 8px;
  z-index: -1;
`;

export const MintingBlock = styled.div`
  padding-top: 18px;
  padding-bottom: 18px;
  background: linear-gradient(
    180deg,
    rgba(18, 7, 21, 0.91) 0%,
    #443d6f 113.26%
  );
  backdrop-filter: blur(42px);

  @media only screen and (max-width: 1023px) {
    padding-top: 0;
    padding-bottom: 6px;
  }

  .minting-block {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 1023px) {
      flex-direction: column;
    }

    .why-mint {
      margin: 10px 60px;
      font-family: var(--font-gilroy);
      font-size: 20px;
      line-height: 29px;
      font-weight: 700;
      color: #cdcbeb;

      &.mob-hidden {
        @media only screen and (max-width: 1023px) {
          display: none;
        }
      }
    }

    &__row {
      display: flex;
      align-items: center;

      @media only screen and (max-width: 1023px) {
        flex-direction: column;
      }
    }

    &__item {
      padding-left: 4px;
      padding-right: 4px;

      @media only screen and (max-width: 1023px) {
        padding-top: 4px;
        padding-bottom: 4px;
      }
    }

    &__article {
      border-radius: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 300px;
      height: 95px;
      padding: 6px;

      @media only screen and (max-width: 1023px) {
        height: auto;
        min-height: 75px;
      }

      @media only screen and (max-width: 767px) {
        padding: 5px;
      }

      &.one {
        background: linear-gradient(
          258.76deg,
          #ffb340 -23.81%,
          #cd40ff 102.61%
        );

        .minting-block__section {
          background: linear-gradient(
            79.16deg,
            #120715 0.14%,
            rgba(18, 7, 21, 0.64) 108.63%
          );
        }
      }

      &.two {
        background: linear-gradient(
          258.76deg,
          #ffc940 -23.81%,
          #40ffba 102.61%
        );

        .minting-block__section {
          background: linear-gradient(
            79.16deg,
            #120715 0.14%,
            rgba(18, 7, 21, 0.64) 108.63%
          );
        }
      }

      &.three {
        background: linear-gradient(
          258.76deg,
          #4f2bdd -23.81%,
          #33fff3 102.61%
        );

        .minting-block__section {
          background: linear-gradient(
            79.16deg,
            #120715 0.14%,
            rgba(18, 7, 21, 0.64) 108.63%
          );
        }
      }
    }

    &__section {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 5px;
      backdrop-filter: blur(42px);
      border-radius: 14px;
    }

    &__title {
      font-family: var(--font-gilroy);
      font-size: 18px;
      line-height: 27px;
      font-weight: normal;
      text-align: center;
      color: #ffffff;
    }
  }
`;

export const MintingSection = styled.div`
  padding-top: 20px;
`;

export const Container = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  padding-left: 15px;
  padding-right: 15px;

  @media only screen and (min-width: 1024px) {
    padding-left: 40px;
    padding-right: 40px;
  }
`;