import { useCallback, useMemo, useRef } from "react";
import { AlertTriangle, ChevronDown, ChevronUp } from "react-feather";
import { useCustomWeb3React } from '../../providers/CustomWeb3ReactProvider';
import styled, { useTheme } from "styled-components/macro";

import { getConnection } from "../../connection";
import Portal from "../../components/Portal";

import { useAppSelector } from "../../state/hooks";
import { colors } from "../../theme/colors";
import { flexRowNoWrap } from "../../theme/styles";

import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import {
  useCloseModal,
  useModalIsOpen,
  useToggleWalletDropdown,
  useToggleWalletModal,
} from "../../state/application/hooks";
import { ApplicationModal } from "../../state/application/reducer";
import { isTransactionRecent, useAllTransactions } from '../../state/transactions/hooks'
import { shortenAddress } from "../../utils";
import TempAvatar from "../../assets/icons/icon.png";
import Loader from "../Loader";
import WalletModal from "../WalletModal";
import { Buttons, Icons } from "../../components";
import WalletDropdown from "../../components/WalletDropdown";

// https://stackoverflow.com/a/31617326
const FULL_BORDER_RADIUS = 8;

const RowBetween = styled.div`
  width: ${({ width }) => width ?? "100%"};
  display: flex;
  padding: 0;
  align-items: ${({ align }) => align ?? "center"};
  justify-content: space-between;
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
`;

const ChevronWrapper = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 10px 16px 10px 4px;

  :hover {
    color: ${({ theme }) => theme.accentActionSoft};
  }
  :hover,
  :active,
  :focus {
    border: none;
  }
`;

const Web3StatusGeneric = styled.button`
  ${flexRowNoWrap};
  width: 100%;
  align-items: center;
  padding: 0.5rem;
  border-radius: ${FULL_BORDER_RADIUS}px;
  cursor: pointer;
  user-select: none;
  height: 36px;
  margin-right: 2px;
  margin-left: 2px;
  :focus {
    outline: none;
  }
`;
const Web3StatusError = styled(Web3StatusGeneric)`
  background-color: ${({ theme }) => theme.accentFailure};
  border: 1px solid ${({ theme }) => theme.accentFailure};
  color: ${({ theme }) => theme.white};
  font-weight: 500;
  :hover,
  :focus {
    background-color: ${({ theme }) => theme.accentFailure};
  }
`;

const Web3StatusConnectWrapper = styled.div(
  ({ faded }) => `
  ${flexRowNoWrap};
  align-items: center;
  border-radius: ${FULL_BORDER_RADIUS}px;
  padding: 0;
  height: 36px;
  color: #fff;
  padding: 10px 0px;
  background-color: #fff;
  border: 1px solid #c1cbd9;

  :hover,
  :active,
  :focus {
    border: 1px solid #244ca8;
    background-color: #fff;
  }
`
);

const IconWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 8px;

  & > img,
  span {
    height: ${({ size }) => (size ? size + "px" : "32px")};
    width: ${({ size }) => (size ? size + "px" : "32px")};
  }

  @media (max-width: 768px) {
    align-items: flex-end;
  }
`;

const Web3StatusConnected = styled(Web3StatusGeneric)`
  background-color: ${({ pending, theme }) =>
    pending ? theme.accentAction : theme.deprecated_bg1};
  border: 1px solid #c1cbd9;
  color: ${({ pending, theme }) => (pending ? theme.white : theme.textPrimary)};
  font-weight: 500;
  border: ${({ isClaimAvailable }) =>
    isClaimAvailable && `1px solid ${colors.purple300}`};
  :hover,
  :focus {
    border: 1px solid #244ca8;
    :focus {
      border: 1px solid
        ${({ pending, theme }) =>
          pending ? theme.accentAction : theme.backgroundInteractive};
    }
  }

  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.lg}px`}) {
    width: ${({ pending }) => !pending && "36px"};
    ${IconWrapper} {
      margin-right: 0;
    }
  }
`;

const AddressAndChevronContainer = styled.div`
  display: flex;
  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.lg}px`}) {
    display: none;
  }
`;

const Text = styled.p`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0.5rem 0 0.25rem;
  font-size: 1rem;
  width: fit-content;
  font-weight: 500;
`;

const NetworkIcon = styled(AlertTriangle)`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`;

function newTransactionsFirst(a, b) {
  return b.addedTime - a.addedTime;
}

const VerticalDivider = styled.div`
  height: 20px;
  margin: 0px;
  width: 1px;
  background-color: #fff;
`;

const StyledConnectButton = styled.button`
  background-color: transparent;
  border: none;
  border-top-left-radius: ${FULL_BORDER_RADIUS}px;
  border-bottom-left-radius: ${FULL_BORDER_RADIUS}px;
  color: #666;
  cursor: pointer;
  font-family: "Gilroy", sans-serif;
  font-weight: 600;
  font-size: 16px;
  padding: 10px 8px;

  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => `${duration.fast} color ${timing.in}`};

  :hover,
  :active,
  :focus {
    border: none;
  }
  :hover {
    color: #000;
  }
`;

const CHEVRON_PROPS = {
  height: 20,
  width: 20,
};

function Web3StatusInner() {
  const { account, connector, chainId, ENSName } = useCustomWeb3React();
  const theme = useTheme();
  const toggleWalletDropdown = useToggleWalletDropdown();
  const handleWalletDropdownClick = useCallback(() => {
    toggleWalletDropdown();
  }, [toggleWalletDropdown]);
  const toggleWalletModal = useToggleWalletModal();
  const walletIsOpen = useModalIsOpen(ApplicationModal.WALLET_DROPDOWN)

  const error = useAppSelector((state) => {
    if (connector) {
      const connectionType = getConnection(connector).type;
      return state.connection.errorByConnectionType[connectionType];
    }
    return null;
  });

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)

  const hasPendingTransactions = !!pending.length
  
  if (!chainId) {
    return null;
  } else if (error) {
    return (
      <Web3StatusError onClick={handleWalletDropdownClick}>
        <NetworkIcon />
        <Text>Error</Text>
      </Web3StatusError>
    );
  } else if (account) {
    const chevronProps = {
      ...CHEVRON_PROPS,
      color: theme.textSecondary,
      "aria-label": walletIsOpen
        ? `Close wallet connection options`
        : `Open wallet connection options`,
    };

    return (
      <Web3StatusConnected
        data-testid="web3-status-connected"
        onClick={handleWalletDropdownClick}
        pending={hasPendingTransactions}
        isClaimAvailable={false}
      >
        {!hasPendingTransactions && <img src={TempAvatar} alt="avatar" style={{width: 24, height: 24}} />}
        {hasPendingTransactions ? (
          <RowBetween>
            <Text>
              {pending?.length} Pending
            </Text>{' '}
            <Loader stroke="white" />
          </RowBetween>
        ) : (
          <AddressAndChevronContainer>
            <Text style={{fontSize: 13, marginTop: 2}}>{ENSName || shortenAddress(account)}</Text>
            {walletIsOpen ? (
              <ChevronUp {...chevronProps} />
            ) : (
              <ChevronDown {...chevronProps} />
            )}
          </AddressAndChevronContainer>
        )}
      </Web3StatusConnected>
    );
  } else {
    const chevronProps = {
      ...CHEVRON_PROPS,
      color: "#fff",
      "data-testid": "navbar-wallet-dropdown",
      "aria-label": walletIsOpen
        ? `Close wallet connection options`
        : `Open wallet connection options`,
    };
    return (
      <Web3StatusConnectWrapper faded={!account}>
        <StyledConnectButton
          data-testid="navbar-connect-wallet"
          onClick={toggleWalletModal}
        >
          Connect
        </StyledConnectButton>
        {/*
        <VerticalDivider />
        <ChevronWrapper
          onClick={handleWalletDropdownClick}
          data-testid="navbar-toggle-dropdown"
        >
          {walletIsOpen ? (
            <ChevronUp {...chevronProps} />
          ) : (
            <ChevronDown {...chevronProps} />
          )}
        </ChevronWrapper>
        */}
      </Web3StatusConnectWrapper>
    );
  }
}

export default function Web3Status({className}) {
  const { ENSName } = useCustomWeb3React();

  const allTransactions = useAllTransactions()
  const ref = useRef(null);
  const walletRef = useRef(null);
  const closeModal = useCloseModal();
  const isOpen = useModalIsOpen(ApplicationModal.WALLET_DROPDOWN);

  useOnClickOutside(ref, isOpen ? closeModal : undefined, [walletRef]);

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)
  const confirmed = sortedRecentTransactions.filter((tx) => tx.receipt).map((tx) => tx.hash)

  return (
    <span ref={ref} className={className}>
      <Web3StatusInner />
      <WalletModal ENSName={ENSName ?? undefined} pendingTransactions={pending} confirmedTransactions={confirmed} />
      <Portal>
        <span ref={walletRef}>
          <WalletDropdown />
        </span>
      </Portal>
    </span>
  );
}
