import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { Icons } from "../../../components";

const Button = styled.button`
  position: fixed;
  bottom: 10px;
  right: 10px;
  padding: 10px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 11px;
  cursor: pointer;
  z-index: 999;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &.show {
    opacity: 0.8;
  }
`;

const BackToTop = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 300) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Button
      className={`${showButton ? "show" : ""}`}
      onClick={handleClick}
    >
      <Icons.ChevronUp />
    </Button>
  );
};

export default BackToTop;
