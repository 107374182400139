import styled, { keyframes } from 'styled-components/macro'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
`

const TextToShow = styled.div`
  width: 80%;
  text-align: center;
`

const sparkle = keyframes`
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0);
  }
  70% {
    transform: scale(-1, 0);
  }
  80% {
    transform: scale(3);
  }
  100% {
    transform: scale(0);
  }
`

const StyledSVG = styled.svg`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: visible;

  path {
    fill: black;
    transform-origin: 50% 50%;
    transform-box: fill-box;
    animation: ${sparkle} var(--duration) var(--delay) infinite ease-in-out;
  }
`


/**
 * Takes in custom size and stroke for circle color, default to primary color as fill,
 * need ...rest for layered styles on top
 */
export default function LoaderWithSparkles({
  size = '16px',
  stroke,
  strokeWidth,
  ...rest
}) {
  return (
    <Container>
      <StyledSVG viewBox="0 0 106 34" fill="none" xmlns="http://www.w3.org/2000/svg" size={size} stroke={stroke} {...rest}>
        <path fillRule="evenodd" fill={rest.fill ? rest.fill : "#FFFFFF"} style={{"--duration": "2s", "--delay": "0s"}} d="M2.5740361 5.33344622s1.1875777-6.20179466 2.24320232 0c0 0 5.9378885 1.05562462 0 2.11124925 0 0-1.05562463 6.33374774-2.24320233 0-3.5627331-.6597654-3.29882695-1.31953078 0-2.11124925z" />
        <path fillRule="evenodd" fill={rest.fill ? rest.fill : "#FFFFFF"} style={{"--duration": "1.5s", "--delay": "0.9s"}} d="M33.5173993 29.97263826s1.03464615-5.40315215 1.95433162 0c0 0 5.17323078.91968547 0 1.83937095 0 0-.91968547 5.51811283-1.95433162 0-3.10393847-.57480342-2.8740171-1.14960684 0-1.83937095z" />
        <path fillRule="evenodd" fill={rest.fill ? rest.fill : "#FFFFFF"} style={{"--duration": "1.7s", "--delay": "0.4s"}} d="M69.03038108 1.71240809s.73779281-3.852918 1.39360864 0c0 0 3.68896404.65581583 0 1.31163166 0 0-.65581583 3.93489497-1.39360864 0-2.21337842-.4098849-2.04942447-.81976979 0-1.31163166z" />
        <path fillRule="evenodd" fill={rest.fill ? rest.fill : "#FFFFFF"} style={{"--duration": "2.1s", "--delay": "1.1s"}} d="M99.18160965 12.79394657s1.61168639-8.41658446 3.0442965 0c0 0 8.05843194 1.43261013 0 2.86522025 0 0-1.43261011 8.59566072-3.0442965 0-4.83505916-.89538133-4.47690663-1.79076265 0-2.86522025z" />
      </StyledSVG>
      <TextToShow>
        Loading...
      </TextToShow>
    </Container>
  )
}
