import styled from "styled-components";

export const ProgressContainer = styled.div`

  .main-container {
    padding: 16px;
    background: rgb(255, 255, 255);
    border-radius: 0px 0px 16px 16px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;

    @media only screen and (min-width: 768px) {
      padding: 26px 0px 82px;
    }
    @media only screen and (min-width: 1280px) {
      padding: 26px 0px 68px;
    }
  }

  .progress-line-holder {
    display: none;
    margin-top: 14px;

    @media only screen and (min-width: 1280px) {
      display: flex;
    }
  }

  .progress-line {
    width: 80px;
    height: 1px;
    background-color: #ccc;
    opacity: 0.4;

    &:not(:last-child) {
      margin-right: 84px;
    }
  }

  .status-container {
    width: 100%;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    position: relative;

    @media only screen and (min-width: 768px) {
      width: 388px;
      position: absolute;
    }
    @media only screen and (min-width: 1280px) {
      width: 522px;
    }
  }

  .pending {
    width: 32px;
    height: 32px;
    position: relative;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    background-color: rgba(198, 213, 234, 0.9);
    border-radius: 50%;
    margin-right: 0px;
    transition: all 0.3s ease 0s;

    @media only screen and (min-width: 768px) {
      margin-right: 0px;
    }

    .status-text {
      transition: all 0.3s ease 0s;
      width: 0;
      overflow: hidden;
      color: rgba(198, 213, 234, 0.9);

      @media only screen and (min-width: 768px) {
        display: block;
        width: auto;
      }
    }
  }

  .completed {
    width: 32px;
    height: 32px;
    position: relative;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    background-color: rgb(27, 43, 75);
    border-radius: 50%;
    margin-right: 0px;
    transition: all 0.3s ease 0s;

    @media only screen and (min-width: 768px) {
      margin-right: 0px;
    }

    .status-text {
      transition: all 0.3s ease 0s;
      width: 0;
      overflow: hidden;
      color: rgb(27, 43, 75);

      @media only screen and (min-width: 768px) {
        display: block;
        width: auto;
      }
    }
  }

  .current {
    width: 32px;
    height: 32px;
    position: relative;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    background-color: rgb(27, 43, 75);
    border-radius: 50%;
    margin-right: calc(100% - 164px);
    transition: all 0.3s ease 0s;

    @media only screen and (min-width: 768px) {
      margin-right: 0px;
    }

    .status-text {
      transition: all 0.3s ease 0s;
      width: calc(100vw - 230px);
      overflow: hidden;
      color: rgb(27, 43, 75);

      @media only screen and (min-width: 768px) {
        display: block;
        width: auto;
      }
    }

  }


  .step-icon {
    width: 20px;
    height: 24px;
    object-fit: contain;
    z-index: 1;
  }

  img {
    overflow-clip-margin: content-box;
    overflow: clip;
  }

  .step-label {
    font-family: Inter, sans-serif;
    font-feature-settings: "calt" 0;
    font-weight: 500;
    font-size: 11px;
    line-height: 120%;
    position: absolute;
    white-space: nowrap;
    left: 40px;

    @media only screen and (min-width: 768px) {
      display: block;
      bottom: -25px;
      font-size: 14px;
      line-height: 140%;
      left: initial;
    }

    div {
      @media only screen and (min-width: 768px) {
        display: none;
      }
    }
  }

  .flare-container {
    border-radius: 50%;
    pointer-events: none;
    position: absolute;
    width: 32px;
    height: 32px;
    overflow: hidden;
  }

  @keyframes flare {
    0% { left: -32px; }
    100% { left: 64px; }
  }
  .flare-animation {
    z-index: 1;
    position: absolute;
    border-radius: 50%;
    top: 0px;
    width: 32px;
    height: 32px;
    background: linear-gradient(90deg, rgb(27, 43, 75) 10%, rgb(67, 89, 134) 50%, rgb(27, 43, 75) 90%), rgba(225, 239, 248, 0);
    animation: 3s linear 0s infinite normal none running flare;
  }
`; 