import { useCallback } from 'react'
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../state/hooks';

import {
  increaseGalleryRefreshCount,
  updateSelectedNetwork,
  updatePartyMode,
} from './reducer'

export function useIsDarkMode() {
  const { userDarkMode, matchesDarkMode } = useAppSelector(
    ({ user: { matchesDarkMode, userDarkMode } }) => ({
      userDarkMode,
      matchesDarkMode,
    }),
    shallowEqual
  );

  return userDarkMode === null ? matchesDarkMode : userDarkMode;
}

export function useIsPartyMode() {
  return useAppSelector((state) => state.user.partyMode);
}

export function usePartyModeManager() {
  const dispatch = useAppDispatch()
  const isPartyMode = useIsPartyMode()

  const updateIsPartyMode = useCallback((partyMode) => {
    dispatch(updatePartyMode(partyMode))
  }, [isPartyMode, dispatch])

  return [isPartyMode, updateIsPartyMode]
}

export function useGalleryRefreshCount() {
  return useAppSelector((state) => state.user.galleryRefreshCount);
}

export function useGalleryRefreshManager() {
  const dispatch = useAppDispatch()
  const galleryRefreshCount = useGalleryRefreshCount()

  const increaseGalleryRefCount = useCallback(() => {
    dispatch(increaseGalleryRefreshCount())
  }, [galleryRefreshCount, dispatch])

  return [galleryRefreshCount, increaseGalleryRefCount]
}

export function useSelectedWallet() {
  return useAppSelector((state) => state.user.selectedWallet);
}

export function useBitcoinWallet() {
  return useAppSelector((state) => state.user.bitcoinWallet);
}

export function useSolanaWallet() {
  return useAppSelector((state) => state.user.solanaWallet);
}

export function useSelectedNetwork() {
  return useAppSelector((state) => state.user.selectedNetwork);
}

export function useNetworkManager() {
  const dispatch = useAppDispatch()
  const selectedNetwork = useSelectedNetwork()

  const updateSelectedChainId = useCallback((network) => {
    dispatch(updateSelectedNetwork(network))
  }, [selectedNetwork, dispatch])

  return [selectedNetwork, updateSelectedChainId]
}
