import React, { Component } from "react";
import styled from "styled-components";
import { Link } from 'react-router-dom';

import {
  ArrowRight,
  CheckCircle,
  DollarSign,
  Home,
  RotateCw,
  MoreVertical,
  Star,
  Send,
  Twitter,
} from "react-feather";

import "./party.css"; // Import CSS file

import { Layout, Buttons, MintSlider } from "../../components";
import Footer from "../../components/Footer";

import FAQ from "./Faq";
import Roadmap from "./Roadmap";
import Tokenomics from "./Tokenomics";
import Partners from "./Partners";
import Features from "./Features";
import Intro from "./Intro";
import Video from "./Video";

const SocialContainer = styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: col;
    text-align: left;
    padding: 30px 0 0;

  .menuItem {
    padding: 0 20px 0 30px; 
    display: flex;
    align-items: center;
  }
`;

class BoxTransition extends Component {
  render() {
    return (
      <div style={{ backgroundColor: "#000" }}>
          <main>
            <div id="partyBanner">
              <div className="left-content">
                <h1>
                  <span>Make Crypto Funny Again</span>
                </h1>
              </div>

              <div className="right-content">
                <p>
                  <span className="highlight">
                    <span className="d-none d-md-inline">Even blockchain can't resist a good laugh!&nbsp; </span>
                    Who knew comedy could be so decentralized?
                  </span>
                </p>
                <div className="gap-1"></div>
                {false && <Link
                  to="https://www.pinksale.finance/launchpad/0x6c40c19c9F4b982adf5B3E3a1A953aFBF0D48b6a?chain=BSC&refId=0x26ef400b9cd4105c7b0cb60CaaC53A5075b914E3" 
                  target="_blank"                    
                  className="button"      
                >
                  PinkSale Fair Launch
                </Link>}
                <SocialContainer>
                  <div className="menuItem">
                    <a
                      href="https://twitter.com/LolApeClub"
                      target="_blank"
                    >
                      <Twitter color="#fff" size={30} />
                    </a>
                  </div>
                  <div className="menuItem">
                    <a href="https://t.me/LolApeClub" target="_blank">
                      <Send color="#fff" size={30} />
                    </a>
                  </div>
                </SocialContainer>
              </div>

              <div className="ticker-wrapper">
                <div className="ticker" id="ticker">
                  $LOL&nbsp;&nbsp;-&nbsp;&nbsp;$LOL&nbsp;&nbsp;-&nbsp;&nbsp;$LOL&nbsp;&nbsp;-&nbsp;&nbsp;$LOL&nbsp;&nbsp;-&nbsp;&nbsp;$LOL&nbsp;&nbsp;-&nbsp;&nbsp;$LOL&nbsp;&nbsp;-&nbsp;&nbsp;$LOL&nbsp;&nbsp;-&nbsp;&nbsp;$LOL&nbsp;&nbsp;-&nbsp;&nbsp;$LOL&nbsp;&nbsp;-&nbsp;&nbsp;$LOL&nbsp;&nbsp;-&nbsp;&nbsp;$LOL&nbsp;&nbsp;-&nbsp;&nbsp;$LOL&nbsp;&nbsp;-&nbsp;&nbsp;$LOL&nbsp;&nbsp;-&nbsp;&nbsp;$LOL&nbsp;&nbsp;-&nbsp;&nbsp;$LOL&nbsp;&nbsp;-&nbsp;&nbsp;$LOL&nbsp;&nbsp;-&nbsp;&nbsp;$LOL&nbsp;&nbsp;-&nbsp;&nbsp;$LOL&nbsp;&nbsp;-&nbsp;&nbsp;$LOL&nbsp;&nbsp;-&nbsp;&nbsp;$LOL&nbsp;&nbsp;-&nbsp;&nbsp;$LOL&nbsp;&nbsp;-&nbsp;&nbsp;$LOL&nbsp;&nbsp;-&nbsp;&nbsp;$LOL&nbsp;&nbsp;-&nbsp;&nbsp;$LOL&nbsp;&nbsp;-&nbsp;&nbsp;$LOL&nbsp;&nbsp;-&nbsp;&nbsp;$LOL&nbsp;&nbsp;-&nbsp;&nbsp;$LOL&nbsp;&nbsp;-&nbsp;&nbsp;$LOL&nbsp;&nbsp;-&nbsp;&nbsp;$LOL&nbsp;&nbsp;-&nbsp;&nbsp;$LOL&nbsp;&nbsp;-&nbsp;&nbsp;$LOL&nbsp;&nbsp;-&nbsp;&nbsp;$LOL&nbsp;&nbsp;
                </div>
              </div>
            </div>

            <div className="outer-container">
              {1 && <Intro />}
              {1 && <Partners />}
              {1 && <Features />}
              {1 && <Tokenomics />}
              {1 && <Roadmap />}
              {1 && <Video />}
              {1 && <FAQ />}
            </div>
          </main>
          <Footer />
      </div>
    );
  }
}

export default BoxTransition;
