import React from "react";
import PropTypes from "prop-types";

const Wallet = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="36"
      height="26"
      viewBox="0 0 36 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M35 8.5H24.1818V16H35M4.09091 1H31.9091C33.6162 1 35 2.34315 35 4V22C35 23.6569 33.6162 25 31.9091 25H4.09091C2.38385 25 1 23.6569 1 22V4C1 2.34315 2.38385 1 4.09091 1Z"
        stroke="url(#paint0_linear_215_212)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_215_212"
          x1="-6.5"
          y1="-7.5"
          x2="50"
          y2="40"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color} />
        </linearGradient>
      </defs>
    </svg>
  );
};

Wallet.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export default Wallet;
