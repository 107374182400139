export function padNumber(number, length_required) {
  let paddedNumber = String(number);

  while (paddedNumber.length < length_required) {
    paddedNumber = "0" + paddedNumber;
  }

  return paddedNumber;
}

export function numberWithCommas(input) {
  const parsedInput = parseFloat(input);
  if (isNaN(parsedInput)) {
    return '';
  }
  return parsedInput.toLocaleString();
}