import styled, { keyframes } from "styled-components";

export const DashboardRow = styled.div`
  padding-top: 36px;
  padding-bottom: 60px;

  .dashboard {
    &__row {
      display: flex;
      flex-wrap: wrap;
      margin-left: -10px;
      margin-right: -10px;
    }

    &__item {
      padding-left: 10px;
      padding-right: 10px;
      flex: 0 0 100%;
      max-width: 100%;

      &:not(:last-child) {
        margin-bottom: 15px;

        @media only screen and (min-width: 1024px) {
          margin-bottom: 0;
        }
      }

      &:first-child {
        @media only screen and (min-width: 1024px) {
          flex: 0 0 398px;
        }
      }

      &:last-child {
        @media only screen and (min-width: 1024px) {
          flex: 1;
        }
      }

      a, a:hover {
        text-decoration: none;
      }
    }

    &__list {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        padding: 0;
        margin: 0;

        &:not(:last-child) {
          margin-bottom: 2px;
        }
      }
    }
  }

  .label-soon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 102px;
    height: 43px;
    font-family: var(--font-gilroy);
    font-weight: 600;
    font-size: 28px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #eaddff;
    border-radius: 30px;

    &.small {
      width: 90px;
      height: 38px;

      @media only screen and (max-width: 767px) {
        width: 80px;
        height: 34px;
      }
    }
  }
`;

export const Container = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1125px;
  padding-left: 15px;
  padding-right: 15px;

  h1,
  .h1 {
    margin: 0 0 18px;
    font-family: var(--font-gilroy);
    font-size: 28px;
    line-height: 1.2;
    font-weight: 700;
    color: #323232;
  }

  p {
    font-family: var(--font-gilroy);
    font-weight: 500;
    font-size: 18px;
    line-height: 17px;
    color: #717171;
    margin: 0 0 36px;
  }

  a, a:hover {
    color: #000;
  }
`;

export const DashboardHero = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../images/dashboard/hero-mob.jpg);
  border-radius: 15px;

  @media only screen and (min-width: 768px) {
    background-image: url(../images/dashboard/hero.jpg);
    border-radius: 20px;
  }

  h3,
  .h3 {
    font-family: var(--font-gilroy);
    font-size: 18px;
    line-height: 21px;
    font-weight: 600;
    letter-spacing: -0.02em;
    color: #ffffff;
    margin: 0 0 10px;
  }

  .hero {
    &__item {
      position: relative;
      flex: 0 0 calc(100% / 4);
      max-width: calc(100% / 4);
      padding: 56px 40px;

      @media only screen and (max-width: 1023px) {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 25px 50px;
      }

      @media only screen and (max-width: 767px) {
        padding: 28px 28px 0;
      }

      &:not(:first-child) {
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 1px;
          pointer-events: none;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
          background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAIcCAYAAADc9X/mAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGsSURBVHgBrVVRlsQgCENf739k3d3ZZ1uGEMC2P+NUwRBC2uacTX6fLv9P+1t83hzfC7LFFiaq2a11e+/rj3qjb19n2gG3BG9tIXQy96AukhmX3FTm4/sMAQ9oMSTI64tMTxVCuUMtCelhB53DDlGG8HYK0i8HJPSvqA0RIVPpUDDUoK6gwKgFHWZO1/X5VfO+13fLRoO0sF5gPG90sCTRil1sznsmqovbL9+jNC3OGSIb7OrCwfe9XrxpgwKBEbUAG9xr5V5PrdQTlpv2BGwp5Pve/PCpVOdcSqSVVUvnRBHRRiTIqmLew+ex3jxcmCs+mYeBqqLImQtqGcY0eTSwDqOGYiPBjyYTR82V2SGqfClj/uB3BZUOAtUSRaCShP5hy+pFnUr4llYdqJW+W4QA875WB9xiOnw2KXoqg+l22Ijma3mUHvygHFK7w8Z5ePi1i9CRiQrEUTWJYlYzI2OrsI4UzWkFoRijZmwQA9kztIokHP04022JqtgpQJhRgt+dGUQJv3Q+6zv5oo1MpcHHhfTifNPueYAjnX1PVFozRtJ3O3rYsbNdvs6czw95+ECHX+IGqQAAAABJRU5ErkJggg==");
        }
      }
    }

    &__icon {
      display: flex;
      align-items: center;
      height: 34px;
      color: var(--color-white);
      margin-bottom: 30px;

      @media only screen and (max-width: 767px) {
      	height: 24px;
      	margin-bottom: 20px;
      }
    }

    &__count {
      font-family: var(--font-gilroy);
      font-size: 26px;
      line-height: 33px;
      font-weight: 600;
      letter-spacing: -0.02em;
      color: #ffffff;
      margin-bottom: 20px;
      display: inline-block;

      a, a:hover {
        color: #fff;
        text-decoration: none;
      }
      a:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const DashboardMinting = styled.div`
  padding: 26px 26px 26px 35px;
  display: flex;
  justify-content: space-between;
  height: 526px;
  border-radius: 20px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../images/dashboard/fft-minting.jpg);

  @media only screen and (max-width: 767px) {
    height: 470px;
  }

  .title {
    margin-top: 15px;
    font-family: var(--font-gilroy);
    font-size: 53px;
    line-height: 46px;
    font-weight: 700;
    letter-spacing: -0.02em;
    background: -webkit-linear-gradient(
      180deg,
      #ffffff 45.12%,
      rgba(255, 255, 255, 0) 121.95%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media only screen and (max-width: 1023px) {
      font-size: 40px;
      line-height: 50px;
    }

    @media only screen and (max-width: 767px) {
      margin-top: 5px;
    }
  }
`;

export const BlinkingHighlighter = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 38px;
	font-family: var(--font-gilroy);
	font-weight: 600;
	font-size: 28px;
	line-height: 28px;
	letter-spacing: -0.02em;
	color: #eaddff;
	border-radius: 30px;
	padding: 0 10px;

	background-color: #0f7fff;
	animation: animateBackground 1s ease-in-out infinite;

  @media only screen and (max-width: 1023px) {
    font-size: 24px;
  }

	@keyframes animateBackground {
		0% {
		  background-color: #0f7fff;
		}
		50% {
		  background-color: rgba(173, 122, 255, 0.5);
		}
		100% {
		  background-color: #0f7fff;
		}
	}

	@media only screen and (max-width: 767px) {
		height: 34px;
	}
`;

export const TokenLlaunching = styled.div`
  padding: 36px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  height: 526px;
  border-radius: 20px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../images/dashboard/token-llaunching-mob.jpg);

  @media only screen and (min-width: 768px) {
    background-image: url(../images/dashboard/token-llaunching.jpg);
  }

  @media only screen and (max-width: 767px) {
    justify-content: center;
  }

  .title {
    font-family: var(--font-gilroy);
    font-size: 73px;
    line-height: 76px;
    font-weight: 700;
    text-align: center;
    letter-spacing: -0.02em;
    margin-bottom: 35px;
    background: -webkit-linear-gradient(
      180deg,
      #d8e2ff 68.46%,
      rgba(216, 226, 255, 0) 104.93%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media only screen and (max-width: 1023px) {
      font-size: 50px;
      line-height: 60px;
    }
  }
`;