import { useEffect } from 'react';
import { useAppDispatch } from '../../state/hooks';

import { updateMatchesDarkMode } from './reducer';

export default function Updater() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const darkHandler = (match) => {
      dispatch(updateMatchesDarkMode({ matchesDarkMode: match.matches }));
    };

    const match = window?.matchMedia('(prefers-color-scheme: dark)');
    dispatch(updateMatchesDarkMode({ matchesDarkMode: match.matches }));

    if (match?.addListener) {
      match.addListener(darkHandler);
    } else if (match?.addEventListener) {
      match.addEventListener('change', darkHandler);
    }

    return () => {
      if (match?.removeListener) {
        match.removeListener(darkHandler);
      } else if (match?.removeEventListener) {
        match.removeEventListener('change', darkHandler);
      }
    };
  }, [dispatch]);

  return null;
}
