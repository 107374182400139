import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { load, save } from 'redux-localstorage-simple';

import { updateVersion } from './global/actions';
import sentryEnhancer from './logging';
import reducer from './reducer';

const PERSISTED_KEYS = [];

const store = configureStore({
  reducer,
  enhancers: (defaultEnhancers) => defaultEnhancers.concat(sentryEnhancer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: true })
      .concat(save({ states: PERSISTED_KEYS, debounce: 1000 })),
  preloadedState: load({ states: PERSISTED_KEYS, disableWarnings: true }),
});

store.dispatch(updateVersion());

setupListeners(store.dispatch);

export default store;
