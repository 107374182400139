import { useCustomWeb3React } from '../providers/CustomWeb3ReactProvider';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { CHAIN_IDS_TO_NAMES } from '../constants/chains';
import useParsedQueryString from './useParsedQueryString';
import usePrevious from './usePrevious';
import useSelectChain from './useSelectChain';

function getChainIdFromName(name) {
  const entry = Object.entries(CHAIN_IDS_TO_NAMES).find(([, n]) => n === name);
  const chainId = entry?.[0];
  return chainId ? parseInt(chainId) : undefined;
}

function getParsedChainId(parsedQs) {
  const chain = parsedQs?.chain;
  if (!chain || typeof chain !== 'string') return;

  return getChainIdFromName(chain);
}

export default function useSyncChainQuery() {
  const { chainId, isActive } = useCustomWeb3React();
  const parsedQs = useParsedQueryString();

  const urlChainId = getParsedChainId(parsedQs);
  const previousUrlChainId = usePrevious(urlChainId);

  const selectChain = useSelectChain();

  const [previousChainId, setPreviousChainId] = useState(undefined);
  useEffect(() => {
    if (chainId && chainId !== previousChainId) {
      setPreviousChainId(chainId);
    }
  }, [chainId, previousChainId]);

  const [searchParams, setSearchParams] = useSearchParams();

  const chainQueryManuallyUpdated =
    urlChainId && urlChainId !== previousUrlChainId && isActive;

  useEffect(() => {
    if (chainQueryManuallyUpdated) {
      selectChain(urlChainId);
      searchParams.delete('chain');
      setSearchParams(searchParams);
    }
  }, [
    chainQueryManuallyUpdated,
    urlChainId,
    selectChain,
    searchParams,
    setSearchParams,
  ]);
}
