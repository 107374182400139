import { useMemo } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { useCustomWeb3React } from '../providers/CustomWeb3ReactProvider';

import { L2_CHAIN_IDS } from '../constants/chains';
import { DEFAULT_DEADLINE_FROM_NOW, L2_DEADLINE_FROM_NOW } from '../constants/misc';
import { useAppSelector } from '../state/hooks';

import useCurrentBlockTimestamp from './useCurrentBlockTimestamp';

export default function useTransactionDeadline() {
  const { chainId } = useCustomWeb3React();
  let ttl = useAppSelector((state) => state.user.userDeadline);
  if (!ttl) {
    ttl = DEFAULT_DEADLINE_FROM_NOW
  }
  const blockTimestamp = useCurrentBlockTimestamp();

  return useMemo(() => {
    if (blockTimestamp && chainId && L2_CHAIN_IDS.includes(chainId)) {
      return blockTimestamp.add(L2_DEADLINE_FROM_NOW);
    }
    if (blockTimestamp && ttl) {
      return blockTimestamp.add(ttl);
    }
    return undefined;
  }, [blockTimestamp, chainId, ttl]);
}
