import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useCustomWeb3React } from '../../providers/CustomWeb3ReactProvider';
import styled from "styled-components";

import { Layout, Footer, Icons } from "../../components";
import { useFinapeNftContract } from "../../hooks/useContract";
import { formatBigNumber } from "../../utils/currency";
import { SupportedChainId } from '../../constants/chains'

import {
  BlinkingHighlighter,
  DashboardRow,
  Container,
  DashboardHero,
  DashboardMinting,
  TokenLlaunching,
} from "./styled";

export const MiniManageButton = styled.button`
  width: 60px;
  color: #000;
  background-color: #41da7e;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  border-radius: 10px;
  border: 0 solid;
  padding: 4px;
  display: inline-block;
  margin-top: 2px;
  margin-left: 20px;
  vertical-align: top;
  cursor: pointer;

  &:hover {
    background-color: #72e49f;
    color: #000;
  }
`;

export const MiniMintButton = styled.button`
  width: 60px;
  color: #000;
  background-color: #41da7e;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  border-radius: 10px;
  border: 0 solid;
  padding: 4px;
  display: inline-block;
  margin-top: 2px;
  margin-left: 10px;
  vertical-align: top;
  cursor: pointer;

  &:hover {
    background-color: #72e49f;
    color: #000;
  }
`;

const Dashboard = () => {
  const navigate = useNavigate();
  const { account, chainId } = useCustomWeb3React();
  const [nftOwned, setNftOwned] = useState(0);

  const finapeNftContract = useFinapeNftContract();

  useEffect(() => {
    const fetchData = async () => {
      let _balanceOf = await finapeNftContract.balanceOf(account);
      setNftOwned(parseFloat(formatBigNumber(_balanceOf, 0)).toFixed(0));
    };

    if (finapeNftContract && account) {
      fetchData();
    }
    if (!account) {
      setNftOwned(0);
    }
  }, [finapeNftContract, account]);

  return (
    <Layout footer={<Footer />}>
      <DashboardRow>
        <Container>
          <h1>Greetings, adventurous explorer!</h1>

          <p style={{lineHeight: "26px"}}>
            What awaits your discovery today? Surprises? Fortunes?
          </p>

          <DashboardHero>
            <div className="hero__item">
              <div className="hero__icon">
                <Icons.Nft width={40} />
              </div>

              <h3>FINAPE NFT owned</h3>

              <div className="hero__count">
                {account ? (
                  <a
                    href={
                      (chainId == SupportedChainId.GOERLI || chainId == SupportedChainId.POLYGON_MUMBAI)
                        ? "https://testnets.opensea.io/" +
                          account +
                          "?search[query]=FINAPE"
                        : "https://www.opensea.io/" +
                          account +
                          "?search[query]=FINAPE"
                    }
                    rel="noreferrer"
                    target="_blank"
                    style={{paddingTop: 6}}
                  >
                    {nftOwned}
                  </a>
                ) : (
                  <>{ nftOwned }</>
                )}
                <MiniManageButton onClick={()=> navigate('/nfts')}>Manage</MiniManageButton>
                <MiniMintButton onClick={()=> navigate('/minting')}>Mint</MiniMintButton>
              </div>
            </div>

            <div className="hero__item">
              <div className="hero__icon">
                <Icons.Wallet />
              </div>

              <h3>FINAPE token balance</h3>

              <div className="hero__count">-</div>
            </div>

            <div className="hero__item">
              <div className="hero__icon">
                <Icons.Suitcase />
              </div>

              <h3>Staked FINAPE</h3>

              <div className="hero__count">-</div>
            </div>

            <div className="hero__item">
              <div className="hero__icon">
                <Icons.Rewards />
              </div>

              <h3>Rewards earned</h3>

              <div className="hero__count">-</div>
            </div>
          </DashboardHero>

          <div className="dashboard__row">
            <div className="dashboard__item">
              <a href="/minting">
                <DashboardMinting>
                  <div className="title">
                    NFT
                    <br /> minting
                  </div>

                  <ul className="dashboard__list">
                    <li>
                      <BlinkingHighlighter>Minting</BlinkingHighlighter>
                    </li>
                    <li>
                      <BlinkingHighlighter>coming</BlinkingHighlighter>
                    </li>
                    <li>
                      <BlinkingHighlighter>soon!</BlinkingHighlighter>
                    </li>
                  </ul>
                </DashboardMinting>
              </a>
            </div>

            <div className="dashboard__item">
              <TokenLlaunching>
                <div className="title">
                  Token
                  <br /> launching
                </div>

                <ul className="dashboard__list">
                  <li>
                    <div
                      className="label-soon"
                      style={{ backgroundColor: "rgba(36, 76, 168, 0.2)" }}
                    >
                      soon
                    </div>
                  </li>
                  <li>
                    <div
                      className="label-soon"
                      style={{ backgroundColor: "#244CA8" }}
                    >
                      soon
                    </div>
                  </li>
                  <li>
                    <div
                      className="label-soon"
                      style={{ backgroundColor: "#5085FE" }}
                    >
                      soon
                    </div>
                  </li>
                </ul>
              </TokenLlaunching>
            </div>
          </div>
        </Container>
      </DashboardRow>
    </Layout>
  );
};

export default Dashboard;
