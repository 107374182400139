import Slider from "react-slick";
import styled from "styled-components";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const MintSliderBlock = styled.div`
  display: block;
  width: 100%;
  background: linear-gradient(
    286.65deg,
    rgba(104, 113, 226, 0.65) 25.9%,
    rgba(55, 68, 220, 0) 84.76%
  );
  border: 1px solid #ebedf2;
  backdrop-filter: blur(32px);
  border-radius: 14px;

  .minting {
    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 18px;

      @media only screen and (max-width: 1023px) {
        padding: 10px;
      }
    }

    &__photo {
      border-radius: 12px;
      width: 100%;
      height: 465px;
      overflow: hidden;

      @media only screen and (max-width: 1439px) {
        height: 465px;
      }

      @media only screen and (max-width: 1023px) {
        height: 400px;
      }

      @media only screen and (max-width: 767px) {
        height: 300px;
      }

      @media only screen and (max-width: 320px) {
        height: 257px;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .viewMore {
    text-align: center;
    margin: 0 auto 10px;
    color: #fff;

    a,
    a:hover {
      color: #fff;
    }
  }
`;

const MintSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    cssEase: "linear",
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const generateRandomNumber = (min, max) => {
    // The maximum is exclusive and the minimum is inclusive
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  };

  const imageUrlPrefix = "https://file.finape.com/nft/";

  return (
    <MintSliderBlock>
      <Slider {...settings}>
        <div className="minting__item">
          <div className="minting__photo">
            <img src={imageUrlPrefix + "finape-1877.jpg"} alt="minting" />
          </div>
        </div>
        <div className="minting__item">
          <div className="minting__photo">
            <img src={imageUrlPrefix + "finape-4602.jpg"} alt="minting" />
          </div>
        </div>
        <div className="minting__item">
          <div className="minting__photo">
            <img src={imageUrlPrefix + "finape-1748.jpg"} alt="minting" />
          </div>
        </div>
        <div className="minting__item">
          <div className="minting__photo">
            <img src={imageUrlPrefix + "finape-2.jpg"} alt="minting" />
          </div>
        </div>
        <div className="minting__item">
          <div className="minting__photo">
            <img src={imageUrlPrefix + "finape-1451.jpg"} alt="minting" />
          </div>
        </div>
      </Slider>
      <div className="viewMore">
        <a href="/gallery" rel="noreferrer" target="_blank">
          Explore Gallery
        </a>
      </div>
    </MintSliderBlock>
  );
};

export default MintSlider;
