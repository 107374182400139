export const DASHBOARD = `/dashboard`;
export const STAKE = `/stake`;
export const NFT_EXPLORER = `/gallery`;
export const PRESALE = `/presale`;
export const BRIDGE = `/bridge`;
export const MINTING = `/minting`;
export const PROVENANCE = `/provenance`;
export const NFTS = `/nfts`;
export const BRIDGE_NFT = `/nft-bridge`;
export const NFT_READER = `/nft-reader`;
export const ORDINAL_MINTING = `/minting`;

export const LOL_APE_HOME = `/`;
export const LOL_APE_PARTY = `/party`;