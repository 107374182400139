import { getConnectionName, walletConnectConnection } from '../../connection';
import Option from './Option';
import SOLFLARE_ICON_URL from '../../assets/svg/solflare-logo.svg';

const BASE_PROPS = {
  color: '#4196FC',
  icon: SOLFLARE_ICON_URL,
  id: 'solflare-connect',
};

export function SolflareOption({ tryActivation }) {
  const isActive = walletConnectConnection.hooks.useIsActive();
  return (
    <Option
      {...BASE_PROPS}
      isActive={isActive}
      onClick={() => tryActivation()}
      header={getConnectionName('SOLFLARE_CONNECT')}
    />
  );
}
