import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

import GroupPhoto from "../../assets/images/group.png";

const JokeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4.5px;
  line-height: 1.2;
  position: relative;

  .face {
    right: 0;
    width: 20em;
    height: 20em;
    background-color: hsl(52, 100%, 50%);
    border-radius: 50%;
    position: relative;
  }

  .face::before {
    content: "";
    position: absolute;
    background-color: hsl(22, 47%, 18%);
    width: 3em;
    height: 3em;
    border-radius: 50%;
    top: 5.5em;
    left: 4.5em;
    box-shadow: 8em 0 0 hsl(22, 47%, 18%);
  }

  .face::before {
    animation: eyes 1s ease-in-out infinite;
  }

  .face::after {
    animation: mouth 1s ease-in-out infinite;
  }

  .face::after {
    content: "";
    position: absolute;
    width: 10em;
    height: 5em;
    left: 5em;
    bottom: 4em;
    background-color: hsl(0, 100%, 32%);
    border-radius: 25% 25% 50% 50% / 25% 25% 75% 75%;
  }

  @keyframes eyes {
    0%,
    100% {
      height: 3em;
      border-radius: 50%;
      top: 5.5em;
    }
    10%,
    90% {
      height: 1.5em;
      top: 4em;
      border-radius: 50% 50% 25% 25% / 75% 75% 25% 25%;
    }
  }

  @keyframes mouth {
    0%,
    100% {
      height: 5em;
    }
    10%,
    30%,
    50%,
    70%,
    90% {
      height: 7em;
    }
    20%,
    40%,
    60%,
    80% {
      height: 8em;
    }
  }

  .canvas {
    position: relative;
    width: 960px;
    height: 480px;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 10em;
  }

  @media only screen and (min-width: 768px) {
    .canvas {
      height: 360px;
    }
  }
  @media only screen and (min-width: 1200px) {
    .canvas {
      height: 360px;
    }
  }

  .canvas::after {
    display: none;
    content: "";
    position: absolute;
    bottom: 20px; /* Adjust bottom position */
    right: 20px; /* Adjust right position */
    width: 600px; /* Adjust width */
    height: 350px; /* Adjust height */
    background-image: url(${GroupPhoto});
    background-size: cover;
    z-index: 0;
  }

  .canvas #joke {
    color: #fff;
    text-align: center;
    font-size: 8em;
    z-index: 1;
  }

  .face-bar {
    position: absolute;
    height: 20em;
    border-radius: 10em;
    background-color: hsl(52, 100%, 50%);
    width: 20em;
    left: -20em;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 3;
  }

  .shut {
    width: 20em;
    left: -20em;
    justify-content: flex-end;
    animation: bar-shut 2s ease-in-out forwards;
  }

  .open {
    width: calc(960px + 20em * 2);
    left: -20em;
    justify-content: flex-start;
    animation: bar-open 2s ease-in-out forwards;
  }

  @keyframes bar-shut {
    0% {
      width: 20em;
      left: -20em;
    }
    100% {
      width: calc(960px + 20em * 2);
      left: -20em;
    }
  }

  @keyframes bar-open {
    0% {
      width: calc(960px + 20em * 2);
      left: -20em;
    }
    100% {
      width: 20em;
      left: 960px;
    }
  }

  .face-bar:nth-child(1) {
    top: 0px;
    animation-delay: 0.5s;
  }

  .face-bar:nth-child(2) {
    top: 90px;
    animation-delay: 0.1s;
  }

  .face-bar:nth-child(3) {
    top: 180px;
    animation-delay: 0.2s;
  }

  .face-bar:nth-child(4) {
    top: 270px;
    animation-delay: 0s;
  }

  .face-bar:nth-child(5) {
    top: 360px;
    animation-delay: 0.3s;
  }

  .face-bar:nth-child(6) {
    top: 450px;
    animation-delay: 0.4s;
  }
`;

const JokeComponent = () => {
  const [joke, setJoke] = useState("");
  const [punline, setPunline] = useState("");
  const [curtainsOpen, setCurtainsOpen] = useState(true);

  const questions = [
    "Why do crypto investors want a Lambo?",
    "How can you tell who owns crypto at a party?",
    "How much did it cost to invent bitcoin?",
    "Why did the cryptocurrency go to therapy?",
    "What's a cryptocurrency's favorite dance move?",
    "Why don't cryptocurrency traders ever get lost?",
    "How do you make a small fortune in crypto?",
    "Why was the blockchain artist so successful?",
    "Why don't cryptocurrencies ever get lonely?",
    "Why was the cryptocurrency investor always calm?",
    "Why did the cryptocurrency cross the road?",
    "What do you call a group of cryptocurrency enthusiasts?",
    "Why don't cryptocurrencies ever get tired?",
    "How does a cryptocurrency say goodbye?",
    "Why did the cryptocurrency break up with its girlfriend?",
    "How does a cryptocurrency like its coffee?",
    "Why did the cryptocurrency go to school?",
    "What's a cryptocurrency's favorite subject in school?",
    "Why did the cryptocurrency refuse to play poker?",
    "How do you make a cryptocurrency laugh?",
    "Why did the cryptocurrency become a musician?",
    "What do you call a cryptocurrency that's afraid of the dark?",
    "Why did the cryptocurrency go to the gym?",
    "How does a cryptocurrency stay safe?",
    "Why was the cryptocurrency investor always late?",
    "What do you call a cryptocurrency's favorite movie?",
    "Why did the cryptocurrency get a job at the bank?",
    "How does a cryptocurrency stay healthy?",
    "Why did the cryptocurrency get into a fight?",
    "What do you call a cryptocurrency that's feeling down?"
  ];

  const punLines = [
    "Because they know Ferarri is owned by Fiat.",
    "Don’t worry, they’ll tell you.",
    "One Satoshi.",
    "Because it had too many ups and downs!",
    "The blockchain shuffle!",
    "Because they always follow the crypto trail!",
    "Start with a large fortune!",
    "Because they knew how to draw attention!",
    "Because they're always in a peer-to-peer network!",
    "Because they had a stablecoin temperament!",
    "To get to the blockchain.",
    "A blockchain party.",
    "Because they're always on the move.",
    '"Byte" for now!',
    "It wanted more space.",
    "Decentralized.",
    "To learn the basics of blockchain.",
    "Crypto-nomics.",
    "It didn't want to risk being dealt a bad hand.",
    "Tell it a bit-coin joke.",
    "It wanted to mine its own beats.",
    "A Litecoin.",
    "To pump its blockchain.",
    "By using strong encryption.",
    "Because they were waiting for the next bull run.",
    "Bitflix and Chill.",
    "It wanted to make some digital deposits.",
    "By mining its own transactions.",
    "It couldn't agree on the block size.",
    "Ethereum-otional."
  ];

  const [currentJokeIndex, setCurrentJokeIndex] = useState(Math.floor(Math.random() * questions.length) + 1);

  const showJoke = () => {
    setCurrentJokeIndex((prevIndex) => (prevIndex + 1) % questions.length); // Increment currentJokeIndex
  };

  useEffect(() => {
    setCurtainsOpen(false); // Close curtains
    setTimeout(() => {
      setJoke(questions[currentJokeIndex]);
      setPunline(punLines[currentJokeIndex]);
      setCurtainsOpen(true); // Open curtains
    }, 2000);
  }, [currentJokeIndex]);

  useEffect(() => {
    const intervalId = setInterval(showJoke, 7000); 
    return () => clearInterval(intervalId); 
  }, []);

  return (
    <JokeContainer>
      <div className="canvas">
        <div className={`face-bar ${curtainsOpen ? "open" : "shut"}`}>
          <div className="face"></div>
        </div>
        <div className={`face-bar ${curtainsOpen ? "open" : "shut"}`}>
          <div className="face"></div>
        </div>
        <div className={`face-bar ${curtainsOpen ? "open" : "shut"}`}>
          <div className="face"></div>
        </div>
        <div className={`face-bar ${curtainsOpen ? "open" : "shut"}`}>
          <div className="face"></div>
        </div>
        <div className={`face-bar ${curtainsOpen ? "open" : "shut"}`}>
          <div className="face"></div>
        </div>
        <div className={`face-bar ${curtainsOpen ? "open" : "shut"}`}>
          <div className="face"></div>
        </div>
        <span id="joke">{joke}<br/><span className="color">{punline}</span></span>
      </div>
    </JokeContainer>
  );
};

export default JokeComponent;
