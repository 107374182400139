import React from "react";

import styled from "styled-components";

const Content = styled.div`
  position: relative;
  float: left;

  /* Styles for selected tags */
  .tag {
    border: 0 solid #ddd;
    background: #e3165b;
    color: white;
    font-size: 12px;
    display: inline-block;
    padding: 4px 10px;
    margin: 0 5px;
    border-radius: 12px;
  }
`;

const Tag = (props) => {
  return (
    <Content>
      <div className="tag" {...props}>
        {props.text}
      </div>
    </Content>
  );
};

export default Tag;
