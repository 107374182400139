import { getChainInfo } from '../../constants/chainInfo';
import { SupportedChainId } from '../../constants/chains';
import { AlertTriangle } from 'react-feather';
import styled, { useTheme } from 'styled-components/macro';

const RowNoFlex = styled.div`
  flex-wrap: nowrap;
`;

export default function FailedNetworkSwitchPopup({ chainId }) {
  const chainInfo = getChainInfo(chainId);
  const theme = useTheme();

  return (
    <RowNoFlex>
      <div gap="sm">
        <RowNoFlex style={{ alignItems: 'center', display: 'flex' }}>
          <div style={{ paddingRight: 13 }}>
            <AlertTriangle color={theme.accentWarning} size={24} display="flex" />
          </div>
          <div style={{ fontWeight: "bold" }}>
            <>Failed to switch networks</>
          </div>
        </RowNoFlex>

        <div style={{ marginTop: 10 }}>
          <>To use {chainInfo.label}, switch the network in your wallet’s settings.</>
        </div>
      </div>
    </RowNoFlex>
  );
}
