import { Link } from "react-router-dom";
import { Icons } from "../../components";
import styled from "styled-components";

const SocialBlock = styled.div`
  border-top: 1px solid #edeff3;
  text-align: center;
  margin-top: 35px;
  padding-top: 35px;

  @media only screen and (max-width: 1023px) {
    padding-top: 15px;
  }

  h3 {
    padding: 0;
    margin: 0 0 27px;
    font-family: var(--font-sforotext);
    font-size: 26px;
    line-height: 31px;
    font-weight: 600;
    letter-spacing: -0.02em;
    color: var(--color-black);

    @media only screen and (max-width: 1023px) {
      font-size: 22px;
      line-height: 25px;
      margin: 0 0 15px;
    }
  }

  .social {
    &__nav {
      display: flex;
      justify-content: center;

      ul {
        display: flex;
        align-items: center;
        list-style: none;
        padding: 0;
        margin: 0;
      }

      li {
        padding: 0 5px;
        margin: 0;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 12px;
        color: var(--color-white);
        background-color: #1e7ff0;
        transition: all 0.2s ease;

        &:hover {
          background-color: #244ca8;
        }
      }
    }
  }
`;

const dataSocial = [
  {
    title: "Telegram",
    src: "https://t.me/finapeOfficial",
  },
  {
    title: "Twitter",
    src: "https://twitter.com/LolApeClub",
  },
  {
    title: "Instagram",
    src: "https://instagram.com/finape_official",
  },
  {
    title: "Discord",
    src: "https://discord.gg/finape",
  },
];

const choseIcons = (item) => {
  switch (item) {
    case "Telegram":
      return <Icons.Telegram />;
    case "Twitter":
      return <Icons.Twitter />;
    case "Instagram":
      return <Icons.Instagram />;
    case "Facebook":
      return <Icons.Facebook />;
    case "YouTube":
      return <Icons.Youtube />;
    case "Discord":
      return <Icons.Discord />;
    default:
      return <></>;
  }
};

const Social = () => {
  return (
    <SocialBlock>
      <h3>Join FINAPE Community</h3>

      <nav className="social__nav">
        <ul>
          {dataSocial &&
            dataSocial.map((item, index) => {
              return (
                <li key={index}>
                  <Link to={item?.src}>{choseIcons(item?.title)}</Link>
                </li>
              );
            })}
        </ul>
      </nav>
    </SocialBlock>
  );
};

export default Social;
