import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { ChevronDown } from "react-feather";

import TokenChart from "../../assets/images/chart.png";

const Section = styled.section`
  background-color: #000;
  padding-top: 90px;
  padding-bottom: 90px;
  color: #fff;
  text-align: center;

  h1 {
    color: #f6bb00;
  }
  h2 {
    color: #fff;
  }
`;

const YoutubeContainer = styled.div`
  display: flex;
  justify-content: center;

  iframe {
    aspect-ratio: 16 / 9;
    width: 100% !important;
  }
`;

export default function FAQ() {
  return (
    <Section id="tokenomics">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-lg-12">
            <h2>
              Take a moment to relax and indulge in our music video experience.
            </h2>
          </div>
        </div>
      </div>

      <div className="gap-2"></div>
      <div className="container">
        <div className="row g-3">
          <div className="col-12 ">
            <YoutubeContainer className="text-center mx-auto d-block">
              <iframe
                src="https://www.youtube.com/embed/CEGFQqCwfZg?si=-y0JHHc6jBeAWCVA"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </YoutubeContainer>
          </div>
        </div>
      </div>
    </Section>
  );
}
