import ReactGA from 'react-ga4';

class GoogleAnalyticsProvider {
  sendEvent(event, params) {
    ReactGA.event(event, params);
  }

  initialize(GA_MEASUREMENT_ID, options) {
    ReactGA.initialize(GA_MEASUREMENT_ID, options);
  }

  set(fieldsObject) {
    ReactGA.set(fieldsObject);
  }

  outboundLink({ label }, hitCallback) {
    ReactGA.outboundLink({ label }, hitCallback);
  }

  pageview(path, _, title) {
    ReactGA.pageview(path, _, title);
  }

  ga(...args) {
    ReactGA.ga(...args);
  }

  gaCommandSendTiming(timingCategory, timingVar, timingValue, timingLabel) {
    ReactGA._gaCommandSendTiming(timingCategory, timingVar, timingValue, timingLabel);
  }
}

export default GoogleAnalyticsProvider;
