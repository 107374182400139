import React, { Component } from "react";
import styled from "styled-components";

import "./ptw.css"; // Import CSS file

import HeadLogo from "../../assets/images/logo-head.png";

import { Layout, Buttons, MintSlider, Footer } from "../../components";

import {
  ArrowRight,
  CheckCircle,
  DollarSign,
  Home,
  RotateCw,
  MoreVertical,
  Star,
  Send,
  Twitter,
} from "react-feather";

const Content = styled.div``;

class BoxTransition extends Component {
  render() {
    return (
      <Content>
        <aside id="sidebar" className="nano d-none d-md-block">
          <div className="nano-content">
            <a className="compose-button">Compose</a>
            <menu className="menu-segment">
              <ul>
                <li className="active">
                  <a href="#">
                    <Home color="#666" size={14} />
                    &nbsp;&nbsp;&nbsp;Home<span> (9999+)</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    Important <span className="hover-content-1">Really?</span>
                  </a>
                </li>
                <li>
                  <a href="#">Sent</a>
                </li>
                <li>
                  <a href="#">Trash</a>
                </li>
              </ul>
            </menu>
            <div className="separator"></div>
            <div className="menu-segment">
              <ul className="labels">
                <li className="title">
                  Labels <span className="icon">+</span>
                </li>
                <li>
                  <a href="#">
                    Dribbble <span className="ball pink"></span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    Roommates <span className="ball green"></span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    Bills <span className="ball blue"></span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="separator"></div>
            <div className="bottom-padding"></div>
          </div>
        </aside>
        <main id="ptwMain" className="d-none d-md-block">
          <div className="css-dk6dna">
            <div className="css-auxbdp">
              <div className="css-17plqtq">
                <div className="css-70qvj9">
                  <label className="css-towvoq" data-checked="">
                    <input
                      className="chakra-checkbox__input"
                      type="checkbox"
                      value=""
                    />
                    <span
                      className="chakra-checkbox__control css-1ydjfm6"
                      aria-hidden="true"
                      data-checked=""
                    >
                      <div className="css-0"></div>
                    </span>
                  </label>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <RotateCw color="#666" size={16} />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <MoreVertical color="#666" size={16} />
                </div>
                <div className="css-1sgs9t6">
                  <p className="css-1bn4k88">1-20 of 9999</p>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &lt;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &gt;
                </div>
              </div>
            </div>
            <div className="css-176643u">
              <div
                className="d-md-none"
                style={{
                  fontSize: 16,
                  color: "#666",
                  marginLeft: 10,
                  marginTop: 30,
                }}
              >
                Primary
              </div>
              <div
                className="email-line d-md-none"
                style={{
                  paddingInlineStart: "0.5rem",
                  paddingInlineEnd: "0.5rem",
                }}
              >
                <div
                  className="email-title"
                  style={{
                    width: "100%",
                    border: "1px solid #ccc",
                    borderRadius: 8,
                    padding: 10,
                  }}
                >
                  <div className="css-84zodg">
                    <img src={HeadLogo} height="50" />
                  </div>
                  <div className="css-0">
                    <p className="css-17erj9h">Pretend to Work Mode</p>
                    <br />
                    <p className="css-ppre3">
                      We prioritize your welfare – Introducing a "Pretend to Work" Mode, ensuring safe browsing during office hours.
                    </p>
                  </div>
                </div>
              </div>
              <div className="email-line">
                <div className="email-title">
                  <div className="css-84zodg">
                    <label className="css-1577qb8" data-checked="">
                      <input
                        className="chakra-checkbox__input"
                        type="checkbox"
                        value=""
                      />
                      <span
                        className="chakra-checkbox__control css-1ydjfm6"
                        aria-hidden="true"
                        data-checked=""
                        data-active=""
                      >
                        <div className="css-0"></div>
                      </span>
                    </label>
                    <label className="css-14xdbal">
                      <Star color="#F7931A" fill="#F7931A" size={14} />
                      <div
                        data-checked=""
                        aria-hidden="true"
                        className="css-k008qs"
                      ></div>
                    </label>
                  </div>
                  <div className="css-0">
                    <span className="css-17erj9h" style={{ fontWeight: 400 }}>
                      Welcome to{" "}
                      <span className="italic">
                        <span className="color">LOL</span>Ape
                      </span>
                      !
                    </span>
                    <h1 className="resetH1">
                      Make Crypto Funny Again now lives on Solana and BNB Chain.
                    </h1>
                  </div>
                </div>
                <div className="email-body">
                  <div className="css-1f69nz6">
                    <p className="css-ppre2">
                      Even blockchain can't resist a good laugh! Who knew comedy
                      could be so decentralized?
                    </p>
                  </div>
                  <div className="css-v1pv54">
                    <div className="css-6su6fj">
                      <p className="css-76nbfm">10:28am</p>
                    </div>
                  </div>
                  <div className="css-18mfjtm">
                    <a
                      className="rounded-button"
                      rel="noopener"
                      target="_blank"
                      href="https://docs.lolape.com/whitepaper/"
                    >
                      <span className="chakra-button__icon css-1wh2kri"></span>
                      <p className="rounded-button-content">Whitepaper</p>
                    </a>
                  </div>
                  <div className="css-v1pv54"></div>
                </div>
              </div>
              
              <div className="email-line">
                <div className="email-title">
                  <div className="css-84zodg">
                    <label className="css-1577qb8" data-checked="">
                      <input
                        className="chakra-checkbox__input"
                        type="checkbox"
                        value=""
                      />
                      <span
                        className="chakra-checkbox__control css-1ydjfm6"
                        aria-hidden="true"
                        data-checked=""
                        data-active=""
                      >
                        <div className="css-0"></div>
                      </span>
                    </label>
                    <label className="css-14xdbal">
                      <Star color="#666" size={14} />
                      <div
                        data-checked=""
                        aria-hidden="true"
                        className="css-k008qs"
                      ></div>
                    </label>
                  </div>
                  <div className="css-0">
                    <p className="css-17erj9h">Join our Community!</p>
                  </div>
                </div>
                <div className="email-body">
                  <div className="css-1f69nz6">
                    <p className="css-ppre2">
                      <span className="rounded-static-span red-span">
                        CHAT NOW
                      </span>
                      &nbsp;&nbsp;Join us to connect and stay updated with the
                      latest news!
                    </p>
                  </div>
                  <div className="css-v1pv54">
                    <div className="css-6su6fj">
                      <p className="css-76nbfm">9:03am</p>
                    </div>
                  </div>
                  <div className="css-18mfjtm">
                    <a
                      className="rounded-button"
                      rel="noopener"
                      target="_blank"
                      href="https://twitter.com/LolApeClub"
                    >
                      <span className="chakra-button__icon css-1wh2kri"></span>
                      <p className="rounded-button-content">
                        <Twitter color="#666" size={16} />
                        &nbsp;&nbsp;X/Twitter
                      </p>
                    </a>
                    <a
                      className="rounded-button"
                      rel="noopener"
                      target="_blank"
                      href="https://t.me/LolApeClub"
                    >
                      <span className="chakra-button__icon css-1wh2kri"></span>
                      <p className="rounded-button-content">
                        <Send color="#666" size={16} />
                        &nbsp;&nbsp;Telegram
                      </p>
                    </a>
                  </div>
                  <div className="css-v1pv54"></div>
                </div>
              </div>
              <div className="email-line">
                <div className="email-title">
                  <div className="css-84zodg">
                    <label className="css-1577qb8" data-checked="">
                      <input
                        className="chakra-checkbox__input"
                        type="checkbox"
                        value=""
                      />
                      <span
                        className="chakra-checkbox__control css-1ydjfm6"
                        aria-hidden="true"
                        data-checked=""
                        data-active=""
                      >
                        <div className="css-0"></div>
                      </span>
                    </label>
                    <label className="css-14xdbal">
                      <Star color="#F7931A" fill="#F7931A" size={14} />
                      <div
                        data-checked=""
                        aria-hidden="true"
                        className="css-k008qs"
                      ></div>
                    </label>
                  </div>
                  <div className="css-0">
                    <p className="css-17erj9h">Tokenomics (Solana)</p>
                  </div>
                </div>
                <div className="email-body">
                  <div className="css-1f69nz6">
                    <p className="css-ppre2">
                      No Trading Tax 0/0
                    </p>
                    <p className="css-ppre3">
                      LP locked
                      <br />
                      <br />
                      <span className="rounded-static-span green-span">
                        Presale 40%
                      </span>
                      &nbsp;&nbsp;
                      <span className="rounded-static-span green-span">
                        Liquidity 40%
                      </span>
                      &nbsp;&nbsp;
                      <span className="rounded-static-span green-span">
                        Marketing & CEX & Airdrop 20%
                      </span>
                      <br />
                      <a
                        href="https://docs.lolape.com/whitepaper/tokenomics/operations-and-funding"
                        target="_blank"
                        className="rounded-static-button"
                        style={{color: "#000"}}
                      >
                        <span>Operations and Funding</span>&nbsp;&nbsp;
                        <span>
                          <svg
                            loading="eager"
                            fill="CurrentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 48 48"
                            width="16px"
                            height="16px"
                          >
                            <path d="M 40.960938 4.9804688 A 2.0002 2.0002 0 0 0 40.740234 5 L 28 5 A 2.0002 2.0002 0 1 0 28 9 L 36.171875 9 L 22.585938 22.585938 A 2.0002 2.0002 0 1 0 25.414062 25.414062 L 39 11.828125 L 39 20 A 2.0002 2.0002 0 1 0 43 20 L 43 7.2460938 A 2.0002 2.0002 0 0 0 40.960938 4.9804688 z M 12.5 8 C 8.3826878 8 5 11.382688 5 15.5 L 5 35.5 C 5 39.617312 8.3826878 43 12.5 43 L 32.5 43 C 36.617312 43 40 39.617312 40 35.5 L 40 26 A 2.0002 2.0002 0 1 0 36 26 L 36 35.5 C 36 37.446688 34.446688 39 32.5 39 L 12.5 39 C 10.553312 39 9 37.446688 9 35.5 L 9 15.5 C 9 13.553312 10.553312 12 12.5 12 L 22 12 A 2.0002 2.0002 0 1 0 22 8 L 12.5 8 z"></path>
                          </svg>
                        </span>
                      </a>
                      <br />
                      <br />
                      Name:{" "}
                      <span className="italic">
                        <span className="color">LOL</span>Ape
                      </span>
                      <br />
                      Symbol: LOL
                      <br />
                      Decimals: 9
                      <br />
                      Total Supply: 69 Billion
                      <br />
                      Tax: 0/0/0
                      <br />
                    </p>
                  </div>
                  <div className="css-v1pv54">
                    <div className="css-6su6fj">
                      <p className="css-76nbfm">8:05am</p>
                    </div>
                  </div>
                  <div className="css-18mfjtm"></div>
                  <div className="css-v1pv54"></div>
                </div>
              </div>
              <div className="email-line">
                <div className="email-title">
                  <div className="css-84zodg">
                    <label className="css-1577qb8" data-checked="">
                      <input
                        className="chakra-checkbox__input"
                        type="checkbox"
                        value=""
                      />
                      <span
                        className="chakra-checkbox__control css-1ydjfm6"
                        aria-hidden="true"
                        data-checked=""
                        data-active=""
                      >
                        <div className="css-0"></div>
                      </span>
                    </label>
                    <label className="css-14xdbal">
                      <Star color="#F7931A" fill="#F7931A" size={14} />
                      <div
                        data-checked=""
                        aria-hidden="true"
                        className="css-k008qs"
                      ></div>
                    </label>
                  </div>
                  <div className="css-0">
                    <p className="css-17erj9h">Tokenomics (Base, Ethereum, BSC, Arbitrum, Polygon, Avalanche)</p>
                  </div>
                </div>
                <div className="email-body">
                  <div className="css-1f69nz6">
                    <p className="css-ppre2">
                      No Team Token <br />
                    </p>
                    <p className="css-ppre3">
                      LP locked
                      <br />
                      <br />
                      <span className="rounded-static-span green-span">
                        Presale 40%
                      </span>
                      &nbsp;&nbsp;
                      <span className="rounded-static-span green-span">
                        Liquidity 40%
                      </span>
                      &nbsp;&nbsp;
                      <span className="rounded-static-span green-span">
                        Airdrop 20%
                      </span>
                      <br />
                      <br />
                      Name:{" "}
                      <span className="italic">
                        <span className="color">LOL</span>Ape
                      </span>
                      <br />
                      Symbol: LOL
                      <br />
                      Decimals: 18
                      <br />
                      Total Supply: 69 Billion
                      <br />
                      Tax: 3/0/3
                      <br />
                      <a
                        href="https://docs.lolape.com/whitepaper/tokenomics/operations-and-funding"
                        target="_blank"
                        className="rounded-static-button"
                        style={{color: "#000"}}
                      >
                        <span>Operations and Funding</span>&nbsp;&nbsp;
                        <span>
                          <svg
                            loading="eager"
                            fill="CurrentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 48 48"
                            width="16px"
                            height="16px"
                          >
                            <path d="M 40.960938 4.9804688 A 2.0002 2.0002 0 0 0 40.740234 5 L 28 5 A 2.0002 2.0002 0 1 0 28 9 L 36.171875 9 L 22.585938 22.585938 A 2.0002 2.0002 0 1 0 25.414062 25.414062 L 39 11.828125 L 39 20 A 2.0002 2.0002 0 1 0 43 20 L 43 7.2460938 A 2.0002 2.0002 0 0 0 40.960938 4.9804688 z M 12.5 8 C 8.3826878 8 5 11.382688 5 15.5 L 5 35.5 C 5 39.617312 8.3826878 43 12.5 43 L 32.5 43 C 36.617312 43 40 39.617312 40 35.5 L 40 26 A 2.0002 2.0002 0 1 0 36 26 L 36 35.5 C 36 37.446688 34.446688 39 32.5 39 L 12.5 39 C 10.553312 39 9 37.446688 9 35.5 L 9 15.5 C 9 13.553312 10.553312 12 12.5 12 L 22 12 A 2.0002 2.0002 0 1 0 22 8 L 12.5 8 z"></path>
                          </svg>
                        </span>
                      </a>
                    </p>
                  </div>
                  <div className="css-v1pv54">
                    <div className="css-6su6fj">
                      <p className="css-76nbfm">8:05am</p>
                    </div>
                  </div>
                  <div className="css-18mfjtm"></div>
                  <div className="css-v1pv54"></div>
                </div>
              </div>
              <div className="email-line">
                <div className="email-title">
                  <div className="css-84zodg">
                    <label className="css-1577qb8" data-checked="">
                      <input
                        className="chakra-checkbox__input"
                        type="checkbox"
                        value=""
                      />
                      <span
                        className="chakra-checkbox__control css-1ydjfm6"
                        aria-hidden="true"
                        data-checked=""
                        data-active=""
                      >
                        <div className="css-0"></div>
                      </span>
                    </label>
                    <label className="css-14xdbal">
                      <Star color="#666" size={14} />
                      <div
                        data-checked=""
                        aria-hidden="true"
                        className="css-k008qs"
                      ></div>
                    </label>
                  </div>
                  <div className="css-0">
                    <p className="css-17erj9h">Roadmap</p>
                  </div>
                </div>
                <div className="email-body">
                  <div className="css-1f69nz6">
                    <p className="css-ppre2">OK, here's the plan:</p>
                  </div>
                  <div className="css-v1pv54">
                    <div className="css-6su6fj">
                      <p className="css-76nbfm">8 Mar</p>
                    </div>
                  </div>
                  <div className="css-1f69nz6">
                    <span className="rounded-static-button">
                      <ArrowRight color="#666" size={16} />
                      <div className="css-1w8diwj">
                        <p>List on every blockchain</p>
                      </div>
                    </span>
                  </div>
                  <div className="css-1f69nz6">
                    <span className="rounded-static-button">
                      <ArrowRight color="#666" size={16} />
                      <div className="css-1w8diwj">
                        <p>List on every DEX</p>
                      </div>
                    </span>
                  </div>
                  <div className="css-1f69nz6">
                    <span className="rounded-static-button">
                      <ArrowRight color="#666" size={16} />
                      <div className="css-1w8diwj">
                        <p>List on every CEX</p>
                      </div>
                    </span>
                  </div>
                  <div className="css-1f69nz6">
                    <span className="rounded-static-button">
                      <ArrowRight color="#666" size={16} />
                      <div className="css-1w8diwj">
                        <p>Partner with every tech company</p>
                      </div>
                    </span>
                  </div>
                  <div className="css-1f69nz6">
                    <span className="rounded-static-button">
                      <ArrowRight color="#666" size={16} />
                      <div className="css-1w8diwj">
                        <p>Partner with every influencer</p>
                      </div>
                    </span>
                  </div>
                  <div className="css-1f69nz6">
                    <span className="rounded-static-button">
                      <ArrowRight color="#666" size={16} />
                      <div className="css-1w8diwj">
                        <p>Then we LOL at being filthy filthy rich, duh.</p>
                      </div>
                    </span>
                  </div>
                  <div className="css-1f69nz6">
                    <p className="css-ppre2">JUST KIDDING!</p>
                  </div>
                  <div className="css-v1pv54"></div>
                </div>
              </div>

              <div className="email-line email-line-highlight">
                <div className="email-title">
                  <div className="css-84zodg">
                    <label className="css-1577qb8" data-checked="">
                      <input
                        className="chakra-checkbox__input"
                        type="checkbox"
                        value=""
                      />
                      <span
                        className="chakra-checkbox__control css-1ydjfm6"
                        aria-hidden="true"
                        data-checked=""
                        data-active=""
                      >
                        <div className="css-0"></div>
                      </span>
                    </label>
                    <label className="css-14xdbal">
                      <Star color="#F7931A" fill="#F7931A" size={14} />
                      <div
                        data-checked=""
                        aria-hidden="true"
                        className="css-k008qs"
                      ></div>
                    </label>
                  </div>
                  <div className="css-0">
                    <p className="css-17erj9h">Real Roadmap - Q2 2024</p>
                  </div>
                </div>
                <div className="email-body">
                  <div className="css-1f69nz6">
                    <p className="css-ppre2">Building a strong community:</p>
                  </div>
                  <div className="css-v1pv54">
                    <div className="css-6su6fj">
                      <p className="css-76nbfm">9 Mar</p>
                    </div>
                  </div>
                  <div className="css-1f69nz6">
                    <span className="rounded-static-button">
                      <CheckCircle color="#28822b" size={16} />
                      <div className="css-1w8diwj">
                        <p>Launch Website</p>
                      </div>
                    </span>
                  </div>
                  <div className="css-v1pv54">
                    <div className="css-6su6fj">
                      <p className="css-76nbfm">
                        <span className="rounded-static-span red-span">
                          1 done!
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="css-1f69nz6">
                    <span className="rounded-static-button">
                      <ArrowRight color="#666" size={16} />
                      <div className="css-1w8diwj">
                        <p>Reach a community of 1,000 members</p>
                      </div>
                    </span>
                  </div>
                  <div className="css-1f69nz6">
                    <span className="rounded-static-button">
                      <ArrowRight color="#666" size={16} />
                      <div className="css-1w8diwj">
                        <p>Airdrop</p>
                      </div>
                    </span>
                  </div>
                  <div className="css-1f69nz6">
                    <span className="rounded-static-button">
                      <ArrowRight color="#666" size={16} />
                      <div className="css-1w8diwj">
                        <p>Listing on CoinMarketCap</p>
                      </div>
                    </span>
                  </div>
                  <div className="css-1f69nz6">
                    <span className="rounded-static-button">
                      <ArrowRight color="#666" size={16} />
                      <div className="css-1w8diwj">
                        <p>Listing on Coingecko</p>
                      </div>
                    </span>
                  </div>
                  <div className="css-v1pv54"></div>
                </div>
              </div>
              <div className="email-line email-line-highlight">
                <div className="email-title">
                  <div className="css-84zodg">
                    <label className="css-1577qb8" data-checked="">
                      <input
                        className="chakra-checkbox__input"
                        type="checkbox"
                        value=""
                      />
                      <span
                        className="chakra-checkbox__control css-1ydjfm6"
                        aria-hidden="true"
                        data-checked=""
                        data-active=""
                      >
                        <div className="css-0"></div>
                      </span>
                    </label>
                    <label className="css-14xdbal">
                      <Star color="#F7931A" fill="#F7931A" size={14} />
                      <div
                        data-checked=""
                        aria-hidden="true"
                        className="css-k008qs"
                      ></div>
                    </label>
                  </div>
                  <div className="css-0">
                    <p className="css-17erj9h">Real Roadmap - Q3 2024</p>
                  </div>
                </div>
                <div className="email-body">
                  <div className="css-1f69nz6">
                    <p className="css-ppre2">Expanding LOL to the universe:</p>
                  </div>
                  <div className="css-v1pv54">
                    <div className="css-6su6fj">
                      <p className="css-76nbfm">9 Mar</p>
                    </div>
                  </div>

                  <div className="css-1f69nz6">
                    <span className="rounded-static-button">
                      <ArrowRight color="#666" size={16} />
                      <div className="css-1w8diwj">
                        <p>Reach a community of 10,000 members</p>
                      </div>
                    </span>
                  </div>
                  <div className="css-1f69nz6">
                    <span className="rounded-static-button">
                      <ArrowRight color="#666" size={16} />
                      <div className="css-1w8diwj">
                        <p>Launch NFT</p>
                      </div>
                    </span>
                  </div>
                  <div className="css-1f69nz6">
                    <span className="rounded-static-button">
                      <ArrowRight color="#666" size={16} />
                      <div className="css-1w8diwj">
                        <p>Cross-chain Expansion</p>
                      </div>
                    </span>
                  </div>
                  <div className="css-1f69nz6">
                    <span className="rounded-static-button">
                      <ArrowRight color="#666" size={16} />
                      <div className="css-1w8diwj">
                        <p>Release Music Video</p>
                      </div>
                    </span>
                  </div>
                  <div className="css-1f69nz6">
                    <span className="rounded-static-button">
                      <ArrowRight color="#666" size={16} />
                      <div className="css-1w8diwj">
                        <p>Partnership</p>
                      </div>
                    </span>
                  </div>
                  <div className="css-1f69nz6">
                    <span className="rounded-static-button">
                      <ArrowRight color="#666" size={16} />
                      <div className="css-1w8diwj">
                        <p>Listing on CEX</p>
                      </div>
                    </span>
                  </div>
                  <div className="css-v1pv54"></div>
                </div>
              </div>

              <div className="email-line email-line-highlight">
                <div className="email-title">
                  <div className="css-84zodg">
                    <label className="css-1577qb8" data-checked="">
                      <input
                        className="chakra-checkbox__input"
                        type="checkbox"
                        value=""
                      />
                      <span
                        className="chakra-checkbox__control css-1ydjfm6"
                        aria-hidden="true"
                        data-checked=""
                        data-active=""
                      >
                        <div className="css-0"></div>
                      </span>
                    </label>
                    <label className="css-14xdbal">
                      <Star color="#F7931A" fill="#F7931A" size={14} />
                      <div
                        data-checked=""
                        aria-hidden="true"
                        className="css-k008qs"
                      ></div>
                    </label>
                  </div>
                  <div className="css-0">
                    <p className="css-17erj9h">Real Roadmap - Q4 2024</p>
                  </div>
                </div>
                <div className="email-body">
                  <div className="css-1f69nz6">
                    <p className="css-ppre2">Expanding LOL to the universe:</p>
                  </div>
                  <div className="css-v1pv54">
                    <div className="css-6su6fj">
                      <p className="css-76nbfm">9 Mar</p>
                    </div>
                  </div>

                  <div className="css-1f69nz6">
                    <span className="rounded-static-button">
                      <ArrowRight color="#666" size={16} />
                      <div className="css-1w8diwj">
                        <p>Reach a community of 50,000 members</p>
                      </div>
                    </span>
                  </div>
                  <div className="css-1f69nz6">
                    <span className="rounded-static-button">
                      <ArrowRight color="#666" size={16} />
                      <div className="css-1w8diwj">
                        <p>Cross-chain Expansion</p>
                      </div>
                    </span>
                  </div>
                  <div className="css-1f69nz6">
                    <span className="rounded-static-button">
                      <ArrowRight color="#666" size={16} />
                      <div className="css-1w8diwj">
                        <p>Launch Merchandise</p>
                      </div>
                    </span>
                  </div>
                  <div className="css-v1pv54"></div>
                </div>
              </div>

              <Footer className="d-md-none" />
            </div>
          </div>
        </main>

        <div className="d-md-none">
          <div className="css-176643u">
            <div
              className="d-md-none"
              style={{
                fontSize: 16,
                color: "#666",
                marginLeft: 10,
                marginTop: 30,
              }}
            >
              Primary
            </div>
            <div
              className="email-line d-md-none"
              style={{
                paddingInlineStart: "0.5rem",
                paddingInlineEnd: "0.5rem",
              }}
            >
              <div
                className="email-title"
                style={{
                  width: "100%",
                  border: "1px solid #ccc",
                  borderRadius: 8,
                  padding: 10,
                }}
              >
                <div className="css-84zodg">
                  <img src={HeadLogo} height="50" />
                </div>
                <div className="css-0">
                  <p className="css-17erj9h">Pretend to Work Mode</p>
                  <br />
                  <p className="css-ppre3">
                    We prioritize your welfare – Introducing a "Pretend to Work" Mode, ensuring safe browsing during office hours.
                  </p>
                </div>
              </div>
            </div>
            <div className="email-line">
              <div className="email-title">
                <div className="css-84zodg">
                  <label className="css-1577qb8" data-checked="">
                    <input
                      className="chakra-checkbox__input"
                      type="checkbox"
                      value=""
                    />
                    <span
                      className="chakra-checkbox__control css-1ydjfm6"
                      aria-hidden="true"
                      data-checked=""
                      data-active=""
                    >
                      <div className="css-0"></div>
                    </span>
                  </label>
                  <label className="css-14xdbal">
                    <Star color="#F7931A" fill="#F7931A" size={14} />
                    <div
                      data-checked=""
                      aria-hidden="true"
                      className="css-k008qs"
                    ></div>
                  </label>
                </div>
                <div className="css-0">
                  <span className="css-17erj9h" style={{ fontWeight: 400 }}>
                    Welcome to{" "}
                    <span className="italic">
                      <span className="color">LOL</span>Ape
                    </span>
                    !
                  </span>
                  <h1 className="resetH1">
                    Make Crypto Funny Again now lives on Solana and BNB Chain.
                  </h1>
                </div>
              </div>
              <div className="email-body">
                <div className="css-1f69nz6">
                  <p className="css-ppre2">
                    Even blockchain can't resist a good laugh! Who knew comedy
                    could be so decentralized?
                  </p>
                </div>
                <div className="css-v1pv54">
                  <div className="css-6su6fj">
                    <p className="css-76nbfm">10:28am</p>
                  </div>
                </div>
                <div className="css-18mfjtm">
                  <a
                    className="rounded-button"
                    rel="noopener"
                    target="_blank"
                    href="https://docs.lolape.com/whitepaper/"
                  >
                    <span className="chakra-button__icon css-1wh2kri"></span>
                    <p className="rounded-button-content">Whitepaper</p>
                  </a>
                </div>
                <div className="css-v1pv54"></div>
              </div>
            </div>
            
            <div className="email-line">
              <div className="email-title">
                <div className="css-84zodg">
                  <label className="css-1577qb8" data-checked="">
                    <input
                      className="chakra-checkbox__input"
                      type="checkbox"
                      value=""
                    />
                    <span
                      className="chakra-checkbox__control css-1ydjfm6"
                      aria-hidden="true"
                      data-checked=""
                      data-active=""
                    >
                      <div className="css-0"></div>
                    </span>
                  </label>
                  <label className="css-14xdbal">
                    <Star color="#666" size={14} />
                    <div
                      data-checked=""
                      aria-hidden="true"
                      className="css-k008qs"
                    ></div>
                  </label>
                </div>
                <div className="css-0">
                  <p className="css-17erj9h">Join our Community!</p>
                </div>
              </div>
              <div className="email-body">
                <div className="css-1f69nz6">
                  <p className="css-ppre2">
                    <span className="rounded-static-span red-span">
                      CHAT NOW
                    </span>
                    &nbsp;&nbsp;Join us to connect and stay updated with the
                    latest news!
                  </p>
                </div>
                <div className="css-v1pv54">
                  <div className="css-6su6fj">
                    <p className="css-76nbfm">9:03am</p>
                  </div>
                </div>
                <div className="css-18mfjtm">
                  <a
                    className="rounded-button"
                    rel="noopener"
                    target="_blank"
                    href="https://twitter.com/LolApeClub"
                  >
                    <span className="chakra-button__icon css-1wh2kri"></span>
                    <p className="rounded-button-content">
                      <Twitter color="#666" size={16} />
                      &nbsp;&nbsp;X/Twitter
                    </p>
                  </a>
                  <a
                    className="rounded-button"
                    rel="noopener"
                    target="_blank"
                    href="https://t.me/LolApeClub"
                  >
                    <span className="chakra-button__icon css-1wh2kri"></span>
                    <p className="rounded-button-content">
                      <Send color="#666" size={16} />
                      &nbsp;&nbsp;Telegram
                    </p>
                  </a>
                </div>
                <div className="css-v1pv54"></div>
              </div>
            </div>
            <div className="email-line">
              <div className="email-title">
                <div className="css-84zodg">
                  <label className="css-1577qb8" data-checked="">
                    <input
                      className="chakra-checkbox__input"
                      type="checkbox"
                      value=""
                    />
                    <span
                      className="chakra-checkbox__control css-1ydjfm6"
                      aria-hidden="true"
                      data-checked=""
                      data-active=""
                    >
                      <div className="css-0"></div>
                    </span>
                  </label>
                  <label className="css-14xdbal">
                    <Star color="#F7931A" fill="#F7931A" size={14} />
                    <div
                      data-checked=""
                      aria-hidden="true"
                      className="css-k008qs"
                    ></div>
                  </label>
                </div>
                <div className="css-0">
                  <p className="css-17erj9h">Tokenomics (Solana)</p>
                </div>
              </div>
              <div className="email-body">
                <div className="css-1f69nz6">
                  <p className="css-ppre2">
                    No Trading Tax 0/0
                  </p>
                  <p className="css-ppre3">
                    LP locked
                    <br />
                    <br />
                    <span className="rounded-static-span green-span">
                      Presale 40%
                    </span>
                    &nbsp;&nbsp;
                    <span className="rounded-static-span green-span">
                      Liquidity 40%
                    </span>
                    &nbsp;&nbsp;
                    <span className="rounded-static-span green-span">
                      Marketing & CEX & Airdrop 20%
                    </span>
                    <br />
                    <a
                      href="https://docs.lolape.com/whitepaper/tokenomics/operations-and-funding"
                      target="_blank"
                      className="rounded-static-button"
                      style={{color: "#000"}}
                    >
                      <span>Operations and Funding</span>&nbsp;&nbsp;
                      <span>
                        <svg
                          loading="eager"
                          fill="CurrentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 48 48"
                          width="16px"
                          height="16px"
                        >
                          <path d="M 40.960938 4.9804688 A 2.0002 2.0002 0 0 0 40.740234 5 L 28 5 A 2.0002 2.0002 0 1 0 28 9 L 36.171875 9 L 22.585938 22.585938 A 2.0002 2.0002 0 1 0 25.414062 25.414062 L 39 11.828125 L 39 20 A 2.0002 2.0002 0 1 0 43 20 L 43 7.2460938 A 2.0002 2.0002 0 0 0 40.960938 4.9804688 z M 12.5 8 C 8.3826878 8 5 11.382688 5 15.5 L 5 35.5 C 5 39.617312 8.3826878 43 12.5 43 L 32.5 43 C 36.617312 43 40 39.617312 40 35.5 L 40 26 A 2.0002 2.0002 0 1 0 36 26 L 36 35.5 C 36 37.446688 34.446688 39 32.5 39 L 12.5 39 C 10.553312 39 9 37.446688 9 35.5 L 9 15.5 C 9 13.553312 10.553312 12 12.5 12 L 22 12 A 2.0002 2.0002 0 1 0 22 8 L 12.5 8 z"></path>
                        </svg>
                      </span>
                    </a>
                    <br />
                    <br />
                    Name:{" "}
                    <span className="italic">
                      <span className="color">LOL</span>Ape
                    </span>
                    <br />
                    Symbol: LOL
                    <br />
                    Decimals: 9
                    <br />
                    Total Supply: 69 Billion
                    <br />
                    Tax: 0/0/0
                    <br />
                  </p>
                </div>
                <div className="css-v1pv54">
                  <div className="css-6su6fj">
                    <p className="css-76nbfm">8:05am</p>
                  </div>
                </div>
                <div className="css-18mfjtm"></div>
                <div className="css-v1pv54"></div>
              </div>
            </div>
            <div className="email-line">
              <div className="email-title">
                <div className="css-84zodg">
                  <label className="css-1577qb8" data-checked="">
                    <input
                      className="chakra-checkbox__input"
                      type="checkbox"
                      value=""
                    />
                    <span
                      className="chakra-checkbox__control css-1ydjfm6"
                      aria-hidden="true"
                      data-checked=""
                      data-active=""
                    >
                      <div className="css-0"></div>
                    </span>
                  </label>
                  <label className="css-14xdbal">
                    <Star color="#F7931A" fill="#F7931A" size={14} />
                    <div
                      data-checked=""
                      aria-hidden="true"
                      className="css-k008qs"
                    ></div>
                  </label>
                </div>
                <div className="css-0">
                  <p className="css-17erj9h">Tokenomics (Base, Ethereum, BSC, Arbitrum, Polygon, Avalanche)</p>
                </div>
              </div>
              <div className="email-body">
                <div className="css-1f69nz6">
                  <p className="css-ppre2">
                    No Team Token <br />
                  </p>
                  <p className="css-ppre3">
                    LP locked
                    <br />
                    <br />
                    <span className="rounded-static-span green-span">
                      Presale 40%
                    </span>
                    &nbsp;&nbsp;
                    <span className="rounded-static-span green-span">
                      Liquidity 40%
                    </span>
                    &nbsp;&nbsp;
                    <span className="rounded-static-span green-span">
                      Marketing & CEX & Airdrop 20%
                    </span>
                    <br />
                    <br />
                    Name:{" "}
                    <span className="italic">
                      <span className="color">LOL</span>Ape
                    </span>
                    <br />
                    Symbol: LOL
                    <br />
                    Decimals: 18
                    <br />
                    Total Supply: 69 Billion
                    <br />
                    Tax: 3/0/3
                    <br />
                    <a
                      href="https://docs.lolape.com/whitepaper/tokenomics/operations-and-funding"
                      target="_blank"
                      className="rounded-static-button"
                      style={{color: "#000"}}
                    >
                      <span>Operations and Funding</span>&nbsp;&nbsp;
                      <span>
                        <svg
                          loading="eager"
                          fill="CurrentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 48 48"
                          width="16px"
                          height="16px"
                        >
                          <path d="M 40.960938 4.9804688 A 2.0002 2.0002 0 0 0 40.740234 5 L 28 5 A 2.0002 2.0002 0 1 0 28 9 L 36.171875 9 L 22.585938 22.585938 A 2.0002 2.0002 0 1 0 25.414062 25.414062 L 39 11.828125 L 39 20 A 2.0002 2.0002 0 1 0 43 20 L 43 7.2460938 A 2.0002 2.0002 0 0 0 40.960938 4.9804688 z M 12.5 8 C 8.3826878 8 5 11.382688 5 15.5 L 5 35.5 C 5 39.617312 8.3826878 43 12.5 43 L 32.5 43 C 36.617312 43 40 39.617312 40 35.5 L 40 26 A 2.0002 2.0002 0 1 0 36 26 L 36 35.5 C 36 37.446688 34.446688 39 32.5 39 L 12.5 39 C 10.553312 39 9 37.446688 9 35.5 L 9 15.5 C 9 13.553312 10.553312 12 12.5 12 L 22 12 A 2.0002 2.0002 0 1 0 22 8 L 12.5 8 z"></path>
                        </svg>
                      </span>
                    </a>
                  </p>
                </div>
                <div className="css-v1pv54">
                  <div className="css-6su6fj">
                    <p className="css-76nbfm">8:05am</p>
                  </div>
                </div>
                <div className="css-18mfjtm"></div>
                <div className="css-v1pv54"></div>
              </div>
            </div>
            <div className="email-line">
              <div className="email-title">
                <div className="css-84zodg">
                  <label className="css-1577qb8" data-checked="">
                    <input
                      className="chakra-checkbox__input"
                      type="checkbox"
                      value=""
                    />
                    <span
                      className="chakra-checkbox__control css-1ydjfm6"
                      aria-hidden="true"
                      data-checked=""
                      data-active=""
                    >
                      <div className="css-0"></div>
                    </span>
                  </label>
                  <label className="css-14xdbal">
                    <Star color="#666" size={14} />
                    <div
                      data-checked=""
                      aria-hidden="true"
                      className="css-k008qs"
                    ></div>
                  </label>
                </div>
                <div className="css-0">
                  <p className="css-17erj9h">Roadmap</p>
                </div>
              </div>
              <div className="email-body">
                <div className="css-1f69nz6">
                  <p className="css-ppre2">OK, here's the plan:</p>
                </div>
                <div className="css-v1pv54">
                  <div className="css-6su6fj">
                    <p className="css-76nbfm">8 Mar</p>
                  </div>
                </div>
                <div className="css-1f69nz6">
                  <span className="rounded-static-button">
                    <ArrowRight color="#666" size={16} />
                    <div className="css-1w8diwj">
                      <p>List on every blockchain</p>
                    </div>
                  </span>
                </div>
                <div className="css-1f69nz6">
                  <span className="rounded-static-button">
                    <ArrowRight color="#666" size={16} />
                    <div className="css-1w8diwj">
                      <p>List on every DEX</p>
                    </div>
                  </span>
                </div>
                <div className="css-1f69nz6">
                  <span className="rounded-static-button">
                    <ArrowRight color="#666" size={16} />
                    <div className="css-1w8diwj">
                      <p>List on every CEX</p>
                    </div>
                  </span>
                </div>
                <div className="css-1f69nz6">
                  <span className="rounded-static-button">
                    <ArrowRight color="#666" size={16} />
                    <div className="css-1w8diwj">
                      <p>Partner with every tech company</p>
                    </div>
                  </span>
                </div>
                <div className="css-1f69nz6">
                  <span className="rounded-static-button">
                    <ArrowRight color="#666" size={16} />
                    <div className="css-1w8diwj">
                      <p>Partner with every influencer</p>
                    </div>
                  </span>
                </div>
                <div className="css-1f69nz6">
                  <span className="rounded-static-button">
                    <ArrowRight color="#666" size={16} />
                    <div className="css-1w8diwj">
                      <p>Then we LOL at being filthy filthy rich, duh.</p>
                    </div>
                  </span>
                </div>
                <div className="css-v1pv54"></div>
              </div>
            </div>

            <div className="email-line email-line-highlight">
              <div className="email-title">
                <div className="css-84zodg">
                  <label className="css-1577qb8" data-checked="">
                    <input
                      className="chakra-checkbox__input"
                      type="checkbox"
                      value=""
                    />
                    <span
                      className="chakra-checkbox__control css-1ydjfm6"
                      aria-hidden="true"
                      data-checked=""
                      data-active=""
                    >
                      <div className="css-0"></div>
                    </span>
                  </label>
                  <label className="css-14xdbal">
                    <Star color="#F7931A" fill="#F7931A" size={14} />
                    <div
                      data-checked=""
                      aria-hidden="true"
                      className="css-k008qs"
                    ></div>
                  </label>
                </div>
                <div className="css-0">
                  <p className="css-17erj9h">Real Roadmap - Q2 2024</p>
                </div>
              </div>
              <div className="email-body">
                <div className="css-1f69nz6">
                  <p className="css-ppre2">Building a strong community:</p>
                </div>
                <div className="css-v1pv54">
                  <div className="css-6su6fj">
                    <p className="css-76nbfm">9 Mar</p>
                  </div>
                </div>
                <div className="css-1f69nz6">
                  <span className="rounded-static-button">
                    <CheckCircle color="#28822b" size={16} />
                    <div className="css-1w8diwj">
                      <p>Launch Website</p>
                    </div>
                  </span>
                </div>
                <div className="css-v1pv54">
                  <div className="css-6su6fj">
                    <p className="css-76nbfm">
                      <span className="rounded-static-span red-span">
                        1 done!
                      </span>
                    </p>
                  </div>
                </div>
                <div className="css-1f69nz6">
                  <span className="rounded-static-button">
                    <ArrowRight color="#666" size={16} />
                    <div className="css-1w8diwj">
                      <p>Reach a community of 1,000 members</p>
                    </div>
                  </span>
                </div>
                <div className="css-1f69nz6">
                  <span className="rounded-static-button">
                    <ArrowRight color="#666" size={16} />
                    <div className="css-1w8diwj">
                      <p>Airdrop</p>
                    </div>
                  </span>
                </div>
                <div className="css-1f69nz6">
                  <span className="rounded-static-button">
                    <ArrowRight color="#666" size={16} />
                    <div className="css-1w8diwj">
                      <p>Listing on CoinMarketCap</p>
                    </div>
                  </span>
                </div>
                <div className="css-1f69nz6">
                  <span className="rounded-static-button">
                    <ArrowRight color="#666" size={16} />
                    <div className="css-1w8diwj">
                      <p>Listing on Coingecko</p>
                    </div>
                  </span>
                </div>
                <div className="css-v1pv54"></div>
              </div>
            </div>
            <div className="email-line email-line-highlight">
              <div className="email-title">
                <div className="css-84zodg">
                  <label className="css-1577qb8" data-checked="">
                    <input
                      className="chakra-checkbox__input"
                      type="checkbox"
                      value=""
                    />
                    <span
                      className="chakra-checkbox__control css-1ydjfm6"
                      aria-hidden="true"
                      data-checked=""
                      data-active=""
                    >
                      <div className="css-0"></div>
                    </span>
                  </label>
                  <label className="css-14xdbal">
                    <Star color="#F7931A" fill="#F7931A" size={14} />
                    <div
                      data-checked=""
                      aria-hidden="true"
                      className="css-k008qs"
                    ></div>
                  </label>
                </div>
                <div className="css-0">
                  <p className="css-17erj9h">Real Roadmap - Q3 2024</p>
                </div>
              </div>
              <div className="email-body">
                <div className="css-1f69nz6">
                  <p className="css-ppre2">Expanding LOL to the universe:</p>
                </div>
                <div className="css-v1pv54">
                  <div className="css-6su6fj">
                    <p className="css-76nbfm">9 Mar</p>
                  </div>
                </div>

                <div className="css-1f69nz6">
                  <span className="rounded-static-button">
                    <ArrowRight color="#666" size={16} />
                    <div className="css-1w8diwj">
                      <p>Reach a community of 10,000 members</p>
                    </div>
                  </span>
                </div>
                <div className="css-1f69nz6">
                  <span className="rounded-static-button">
                    <ArrowRight color="#666" size={16} />
                    <div className="css-1w8diwj">
                      <p>Launch NFT</p>
                    </div>
                  </span>
                </div>
                <div className="css-1f69nz6">
                  <span className="rounded-static-button">
                    <ArrowRight color="#666" size={16} />
                    <div className="css-1w8diwj">
                      <p>Cross-chain Expansion</p>
                    </div>
                  </span>
                </div>
                <div className="css-1f69nz6">
                  <span className="rounded-static-button">
                    <ArrowRight color="#666" size={16} />
                    <div className="css-1w8diwj">
                      <p>Release Music Video</p>
                    </div>
                  </span>
                </div>
                <div className="css-1f69nz6">
                  <span className="rounded-static-button">
                    <ArrowRight color="#666" size={16} />
                    <div className="css-1w8diwj">
                      <p>Partnership</p>
                    </div>
                  </span>
                </div>
                <div className="css-1f69nz6">
                  <span className="rounded-static-button">
                    <ArrowRight color="#666" size={16} />
                    <div className="css-1w8diwj">
                      <p>Listing on CEX</p>
                    </div>
                  </span>
                </div>
                <div className="css-v1pv54"></div>
              </div>
            </div>
            <div className="email-line email-line-highlight">
              <div className="email-title">
                <div className="css-84zodg">
                  <label className="css-1577qb8" data-checked="">
                    <input
                      className="chakra-checkbox__input"
                      type="checkbox"
                      value=""
                    />
                    <span
                      className="chakra-checkbox__control css-1ydjfm6"
                      aria-hidden="true"
                      data-checked=""
                      data-active=""
                    >
                      <div className="css-0"></div>
                    </span>
                  </label>
                  <label className="css-14xdbal">
                    <Star color="#F7931A" fill="#F7931A" size={14} />
                    <div
                      data-checked=""
                      aria-hidden="true"
                      className="css-k008qs"
                    ></div>
                  </label>
                </div>
                <div className="css-0">
                  <p className="css-17erj9h">Real Roadmap - Q4 2024</p>
                </div>
              </div>
              <div className="email-body">
                <div className="css-1f69nz6">
                  <p className="css-ppre2">Expanding LOL to the universe:</p>
                </div>
                <div className="css-v1pv54">
                  <div className="css-6su6fj">
                    <p className="css-76nbfm">9 Mar</p>
                  </div>
                </div>

                <div className="css-1f69nz6">
                  <span className="rounded-static-button">
                    <ArrowRight color="#666" size={16} />
                    <div className="css-1w8diwj">
                      <p>Reach a community of 50,000 members</p>
                    </div>
                  </span>
                </div>
                <div className="css-1f69nz6">
                  <span className="rounded-static-button">
                    <ArrowRight color="#666" size={16} />
                    <div className="css-1w8diwj">
                      <p>Cross-chain Expansion</p>
                    </div>
                  </span>
                </div>
                <div className="css-1f69nz6">
                  <span className="rounded-static-button">
                    <ArrowRight color="#666" size={16} />
                    <div className="css-1w8diwj">
                      <p>Launch Merchandise</p>
                    </div>
                  </span>
                </div>
                <div className="css-v1pv54"></div>
              </div>
            </div>

            <Footer className="d-md-none" />
          </div>
        </div>
      </Content>
    );
  }
}

export default BoxTransition;
