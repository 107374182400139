import styled from "styled-components";

import { Send, Twitter } from "react-feather";

import logo_sm from "../../assets/images/logo-dark.png";

const FooterTag = styled.footer``;

const NavContainer = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: col;
  text-align: left;
  padding: 10px 0;

  a {
    padding-right: 40px; 
    display: flex;
    align-items: left;
    font-size: 15px;
    color: #666;
  }
`;

const SocialContainer = styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: col;
    text-align: left;
    padding: 10px 0;

  .menuItem {
    padding-right: 40px; 
    display: flex;
    align-items: left;
  }
`;

const Footer = ({className}) => {
  return (
    <div className={className} style={{zIndex: 10}}>
    <div className="container" style={{padding: "20px 20px 10px", backgroundColor: "#fff", maxWidth: "100%", borderTop: "1px solid #999"}}>
      <div className="row">
        <div className="col-sm-12 col-md-2">
          <img src={logo_sm} alt={"Lmail"} width={120} height={36} />
          <div className="divider"></div>
        </div>
        <div className="col-sm-12 col-md-4">
          <NavContainer>
            <a href="https://docs.lolape.com/whitepaper/" target="_blank">
              Whitepaper
            </a>
          </NavContainer>
        </div>
        <div className="col-sm-12 col-md-3">
            <SocialContainer>
              <div className="menuItem">
                <a href="https://twitter.com/LolApeClub" target="_blank">
                  <Twitter color="#666" size={20} />
                </a>
              </div>
              <div className="menuItem">
                <a href="https://t.me/LolApeClub" target="_blank">
                  <Send color="#666" size={20} />
                </a>
              </div>
          </SocialContainer>
        </div>
        <div className="col-sm-12 col-md-3">
          <div style={{ fontSize: 12, color: "#666", padding: "10px 0" }}>
            Copyright © LOLApe. All rights reserved.
          </div>
        </div>
        <div className="gap-3 d-md-none">
        </div>
      </div>
    </div>
    </div>
  );
};

export default Footer;
