import { ChevronLeft } from 'react-feather';
import styled, { css } from 'styled-components/macro';

const Menu = styled.div`
  width: 100%;
  font-size: 16px;
  overflow: auto;
  max-height: 450px;

  // Firefox scrollbar styling
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => `${theme.backgroundOutline} transparent`};
  height: 100%;

  // safari and chrome scrollbar styling
  ::-webkit-scrollbar {
    background: transparent;

    // Set height for horizontal scrolls
    ${({ $isHorizontalScroll }) => {
      return $isHorizontalScroll
        ? css`
            height: 4px;
            overflow-x: scroll;
          `
        : css`
            width: 4px;
            overflow-y: scroll;
          `
    }}
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.backgroundOutline};
    border-radius: 8px;
  }

  ::-webkit-scrollbar-track {
    margin-top: 40px;
  }
`;

const Header = styled.span`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const ClearAll = styled.div`
  display: inline-block;
  cursor: pointer;
  color: ${({ theme }) => theme.accentAction};
  font-weight: 600;
  font-size: 14px;
  margin-top: auto;
  margin-bottom: auto;

  :hover {
    opacity: ${({ theme }) => theme.opacity.hover};
    transition: ${({ theme: { transition: { duration, timing } } }) => `${duration.fast} opacity ${timing.in}`};
  }
`;

const StyledChevron = styled(ChevronLeft)`
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.textPrimary};
    transition: ${({ theme: { transition: { duration, timing } } }) => `${duration.fast} color ${timing.in}`};
  }
`;

const BackSection = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.backgroundSurface};
  width: fill-available;
  margin: 0px 2vw 0px 0px;
  padding: 0px 0px 2vh 0px;
  color: ${({ theme }) => theme.textSecondary};
  cursor: default;
  display: flex;
  justify-content: space-between;
  z-index: 1;
`;

const BackSectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
`;

const ChildrenContainer = styled.div`
  margin-top: 40px;
`;

const SlideOutMenu = ({
  children,
  onClose,
  title,
  onClear,
}) => (
  <Menu>
    <BackSection>
      <BackSectionContainer>
        <StyledChevron data-testid="wallet-back" onClick={onClose} size={24} />
        <Header data-testid="wallet-header">{title}</Header>
        {onClear && <ClearAll onClick={onClear}>Clear All</ClearAll>}
      </BackSectionContainer>
    </BackSection>

    <ChildrenContainer>{children}</ChildrenContainer>
  </Menu>
);

export default SlideOutMenu;
