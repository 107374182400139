import styled from "styled-components";
import { Home, Book } from "react-feather";

import ChainSelector from "../Header/ChainSelector";

const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 0;
  min-width: 320px;
  margin: 0 auto;
`;

const MobileTabbar = () => {

  return (
    <div className="mobileTabbar">
      <a className="tab current" href="/">
        Home
        <Home color="#666" size={14} className="icon" />
      </a>
      <div className="tab">
      </div>
      <div className="tab">
      </div>
      <div className="tab">
        
        <ChainSelector className="chainSelectorButton" style={{flex: 1}} />
      </div>
    </div>
  );
};

export default MobileTabbar;
