import React, { useState } from 'react';
import styled, { keyframes } from "styled-components";

const TabsContainter = styled.div`
  position: relative;

  .tabContainer {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tabs {
    display: flex;
    position: relative;
    background-color: #fff;
    color: #000;
    box-shadow: 0 0 1px 0 rgba(#185ee0, 0.15), 0 6px 12px 0 rgba(#185ee0, 0.15);
    padding: 0.75rem;
    border-radius: 99px; // just a high number to create pill effect
    * {
      z-index: 2;
    }
  }

  input[type="radio"] {
    display: none;
  }

  .tab {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 54px;
    width: 200px;
    font-size: 1.25rem;
    font-weight: 500;
    border-radius: 99px; // just a high number to create pill effect
    cursor: pointer;
    transition: color 0.15s ease-in;
  }

  input[type="radio"] {
    &:checked {
      & + label {
        color: #185ee0;
        & > .notification {
          background-color: #185ee0;
          color: #fff;
        }
      }
    }
  }

  input[id="tab1"] {
    &:checked {
      & ~ .glider {
        transform: translateX(0);
      }
    }
  }

  input[id="tab2"] {
    &:checked {
      & ~ .glider {
        transform: translateX(100%);
      }
    }
  }

  .glider {
    position: absolute;
    display: flex;
    height: 54px;
    width: 200px;
    background-color: #e6eef9;
    z-index: 1;
    border-radius: 99px; // just a high number to create pill effect
    transition: 0.25s ease-out;
  }

  @media (max-width: 700px) {
    .tabs {
      transform: scale(0.6);
    }
    .tab {
      font-size: 2rem;
    }
  }



.tab-content {
  padding: 10px;
}

.tab-pane {
  display: none;
}

.tab-pane.active {
  display: block;
}

`; 

const TabBar = ({ tabs, defaultTab, children }) => {
  const [activeTab, setActiveTab] = useState(defaultTab);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <TabsContainter>
      <div className="tabContainer">
        <div className="tabs">
          {tabs.map((tab, index) => (
            <React.Fragment key={tab}>
              <input
                type="radio"
                id={`tab${index + 1}`}
                name="tabs"
                checked={tab === activeTab}
                onChange={() => handleTabClick(tab)}
              />
              <label htmlFor={`tab${index + 1}`} className="tab">
                {tab}
              </label>
            </React.Fragment>
          ))}
          <span className="glider"></span>
        </div>
      </div>

      
      <div className="gap-4"></div>
      <div className="tab-content">
        {children.map((child, index) => (
          <div
            key={index}
            className={`tab-pane ${tabs[index] === activeTab ? 'active' : ''}`}
          >
            {child}
          </div>
        ))}
      </div>
    </TabsContainter>
  );
};

export default TabBar;
