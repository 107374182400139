import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";
import styled, { keyframes } from "styled-components";
import { Link } from 'react-router-dom';
import { ChevronDown } from "react-feather";

import BgPhoto1 from "../../assets/images/lolape-222.jpg";
import BgPhoto2 from "../../assets/images/lolape-463.jpg";
import BgPhoto3 from "../../assets/images/lolape-2437.jpg";
import BgPhoto4 from "../../assets/images/lolape-4932.jpg";

const Section = styled.section`
  padding-top: 90px;
  padding-bottom: 90px;
  position: relative;

  h1 {
  }
  h2 {
  }
`;
const FeatureContainer = styled.div`
  z-index: 1;

  .colorgradient-card-1,
  .colorgradient-card-2,
  .colorgradient-card-3,
  .colorgradient-card-4,
  .colorgradient-card-5,
  .colorgradient-card-6 {
    color: #fff;
    border-radius: 0.625rem;
    padding: 1.5625rem;
    display: block;
    width: 100%;
    will-change: transform;
    -webkit-transition: -webkit-transform 0.35s
      cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: -webkit-transform 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: transform 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: transform 0.35s cubic-bezier(0.215, 0.61, 0.355, 1),
      -webkit-transform 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
    text-decoration: none;
    margin-bottom: 20px;
    height: 180px;
  }

  .colorgradient-card-1 {
    background-image: linear-gradient(153deg, #e64c4c 0%, #ef32fb 95%);
    -webkit-box-shadow: 0 3px 15px 0 rgba(231, 74, 89, 0.4);
    box-shadow: 0 3px 15px 0 rgba(231, 74, 89, 0.4);
  }
  .colorgradient-card-2 {
    background-image: linear-gradient(153deg, #ef33f2 0%, #3544dc 95%);
    -webkit-box-shadow: 0 3px 15px 0 rgba(92, 64, 224, 0.5);
    box-shadow: 0 3px 15px 0 rgba(92, 64, 224, 0.5);
  }
  .colorgradient-card-3 {
    background-image: linear-gradient(153deg, #8adc32 0%, #25c7a2 95%);
    -webkit-box-shadow: 0 3px 15px 0 rgba(59, 203, 137, 0.4);
    box-shadow: 0 3px 15px 0 rgba(59, 203, 137, 0.4);
  }
  .colorgradient-card-4 {
    background-image: linear-gradient(153deg, #e64c4c 0%, #fb8332 95%);
    -webkit-box-shadow: 0 3px 15px 0 rgba(227, 46, 46, 0.4);
    box-shadow: 0 3px 15px 0 rgba(227, 46, 46, 0.4);
  }
  .colorgradient-card-5 {
    background-image: linear-gradient(153deg, #fb8332 0%, #f8c332 95%);
    -webkit-box-shadow: 0 3px 15px 0 rgba(250, 151, 50, 0.4);
    box-shadow: 0 3px 15px 0 rgba(250, 151, 50, 0.4);
  }
  .colorgradient-card-6 {
    background-image: linear-gradient(153deg, #f5515f 0%, #9f046c 95%);
    -webkit-box-shadow: 0 3px 15px 0 rgba(180, 23, 105, 0.35);
    box-shadow: 0 3px 15px 0 rgba(180, 23, 105, 0.35);
  }
  .colorgradient-card-7 {
    background-image: linear-gradient(153deg, #8e2ef7 0%, #61dec7 95%);
    -webkit-box-shadow: 0 3px 15px 0 rgba(111, 167, 214, 0.4);
    box-shadow: 0 3px 15px 0 rgba(111, 167, 214, 0.4);
  }
`;

const BackgroundImageDiv = ({
  containerClassName,
  imageUrl,
  gradientClass,
  children,
}) => {
  const containerStyle = {
    position: "relative", // Ensure proper positioning of gradient layer
    overflow: "hidden", // Clip the gradient layer and children within the container
    marginBottom: 20,
  };

  const divStyle = {
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    padding: "20px",
    height: 180,
    color: "white",
    position: "relative", // Ensure proper positioning of gradient layer
  };

  const gradientLayerStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 1, // Place gradient layer above background image
    opacity: 0.8, // Adjust opacity as needed
  };

  const contentStyle = {
    position: "relative", // Ensure children are positioned relative to this div
    zIndex: 2, // Place children above the gradient layer
  };

  return (
    <div className={containerClassName} style={containerStyle}>
      <div style={{ width: "100%", borderRadius: 16, overflow: "hidden" }}>
        <div style={divStyle}>
          <div className={gradientClass} style={gradientLayerStyle}></div>
          <div style={contentStyle}>{children}</div>
        </div>
      </div>
    </div>
  );
};

const AccordionItem = ({ header, ...rest }) => (
  <Item
    {...rest}
    header={
      <>
        {header}
        <ChevronDown className="chevron" />
      </>
    }
    className="item"
    buttonProps={{
      className: ({ isEnter }) => `itemBtn ${isEnter && "itemBtnExpanded"}`,
    }}
    contentProps={{ className: "itemContent" }}
    panelProps={{ className: "itemPanel" }}
  />
);

export default function FAQ() {
  return (
    <Section>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <i className="block-badge"></i>
            <h2 className="h1">Features</h2>
            <h2><span className="italic"><span className="white-text">LOL</span>Ape</span> has the time to write his jokes and do these...</h2>

            <div className="gap-1"></div>
            <div className="d-none d-lg-block">
              <h3>Important information:</h3>
              <Link
                to="https://docs.lolape.com/whitepaper/"
                target="_blank"
                className="button"
              >
                Whitepaper
              </Link>
            </div>
          </div>
          <FeatureContainer className="col-lg-6">
            <div className="row">
              <BackgroundImageDiv
                containerClassName="col-lg-6 flex"
                imageUrl={BgPhoto1}
                gradientClass="colorgradient-card-1"
              >
                <h3 style={{marginTop: 10}}><span className="italic"><span className="color">LOL</span>Ape</span> Swap</h3>
                <p className="card-title link-heading-arroow arroow-light">
                  Swap and trade any coins in the supported chains 
                </p>
              </BackgroundImageDiv>

              <BackgroundImageDiv
                containerClassName="col-lg-6 flex"
                imageUrl={BgPhoto2}
                gradientClass="colorgradient-card-2"
              >
                <h3 style={{marginTop: 10}}>Stake LOL</h3>
                <p className="card-title link-heading-arroow arroow-light">
                  Stake $LOL and laugh your way to the bank
                </p>
              </BackgroundImageDiv>

              <BackgroundImageDiv
                containerClassName="col-lg-6 flex"
                imageUrl={BgPhoto3}
                gradientClass="colorgradient-card-3"
              >
                <h3 style={{marginTop: 10}}><span className="italic"><span className="color">LOL</span>Ape</span> NFTs</h3>
                <p className="card-title link-heading-arroow arroow-light">
                  Full collection of 5,323 Unique <span className="italic"><span className="color">LOL</span>Ape</span>s to be released  &lt;3
                </p>
              </BackgroundImageDiv>

              <BackgroundImageDiv
                containerClassName="col-lg-6 flex"
                imageUrl={BgPhoto4}
                gradientClass="colorgradient-card-4"
              >
                <h3 style={{marginTop: 10}}><span className="italic"><span className="color">LOL</span>Ape</span> DAO</h3>
                <p className="card-title link-heading-arroow arroow-light">
                  Join the community of fun laughing people who are always down for a good pun!
                </p>
              </BackgroundImageDiv>
            </div>
          </FeatureContainer>
          <div className="d-block d-lg-none">
            <h2>Important information:</h2>
            <Link
              to="https://docs.lolape.com/whitepaper/"
              target="_blank"
              className="button"
            >
              Whitepaper
            </Link>
          </div>
        </div>
      </div>
    </Section>
  );
}
