import { useEffect, useState, useCallback } from "react";
import * as ethers from 'ethers'
import { toast } from "react-toastify";
import { Connection, PublicKey, clusterApiUrl } from "@solana/web3.js";
import * as solanaWeb3 from '@solana/web3.js';

import { BigNumber } from '@ethersproject/bignumber';

import { useCustomWeb3React } from '../../providers/CustomWeb3ReactProvider';
import { Footer, Layout, Buttons, MintSlider } from "../../components";
import { sendEvent } from '../../components/Analytics'
import {
  useToggleWalletModal,
} from "../../state/application/hooks";
import { useNetworkManager } from '../../state/user/hooks';

import { TOTAL_FINAPE_NFT } from '../../constants'
import { FINAPE_NFT_ADDRESSES } from '../../constants/addresses'
import { SupportedChainId } from '../../constants/chains'
import { getChainInfo } from '../../constants/chainInfo'
import { useCountdown } from "../../hooks/useCountdown";
import useSelectChain from '../../hooks/useSelectChain'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import { useFinapeNftContract } from '../../hooks/useContract'
import { calculateGasMargin } from '../../utils/calculateGasMargin'
import { formatBigNumber } from "../../utils/currency";
import { numberWithCommas } from "../../utils/helpers";
import ChainSelector from "../../components/ChainSelector";
import Alert from "../../components/Alert";

import Loader from '../../components/Loader';

import Welcome from './Welcome'; 

import { useTransactionAdder } from '../../state/transactions/hooks'
import { TransactionType } from '../../state/transactions/types'

import {
  Container,
  MintingBlock,
  MintingRow,
  MintingSection,
  MintingProgress,
  AmountWrapperInput,
} from './styled'

const PRESALE_AVAIL_NETWORKS = [
  SupportedChainId.SOLANA,
  //SupportedChainId.BITCOIN,
  //SupportedChainId.MAINNET,
  //SupportedChainId.BNBCHAIN,
  //SupportedChainId.POLYGON,
  //SupportedChainId.ARBITRUM_ONE,
  //SupportedChainId.KLAYTN,
  //SupportedChainId.OPTIMISM,
  //SupportedChainId.CELO,
]


function isChainIdSupported(chainId) {
  return PRESALE_AVAIL_NETWORKS.includes(chainId);
}

const Minting = () => {

  const [selectedNetwork, updateSelectedChainId] = useNetworkManager()
  const { activate, account, library, chainId, provider, solanaConnection } = useCustomWeb3React();
  const [isLoading, setIsLoading] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [error, setError] = useState(null);
  const [txHash, setTxHash] = useState("");
  const [countdownTarget, setCountdownTarget] = useState(0);
  const [minContribution, setMinContribution] = useState(10000000); // min contribution = 0.01 sol
  const [contributorCount, setContributorCount] = useState(0); 
  const [totalContribution, setTotalContribution] = useState(0);
  const [myContribution, setMyContribution] = useState(0);
  const [toContribute, setToContribute] = useState('')
  const [accountBalance, setAccountBalance] = useState(BigNumber.from("0"))

  const [sufficientFund, setSufficientFund] = useState(false);
  const [fulfilledMinimum, setFulfilledMinimum] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const selectChain = useSelectChain()
  const toggleWalletModal = useToggleWalletModal();
  
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const addTransaction = useTransactionAdder()
  const [txnHash, setTxnHash] = useState()
  const deadline = useTransactionDeadline() // custom from users settings

  const chainInfo = chainId ? getChainInfo(chainId) : undefined

  useEffect(() => {
    const fetchData = async () => {
      if (!isLoading) {
        setIsLoading(true);

        try {
          let url = "https://api.lolape.com/presale/stats";
          const response = await fetch(url);
          const json = await response.json();

          setContributorCount(json.data.contributors);
          setTotalContribution(parseFloat(json.data.contribution));
          setCountdownTarget(json.data.countdown)

          //console.log(json.data);
        } catch (error) {
          console.log(error);
          setError(error);
        }
        setIsLoading(false);
      }
    };

    fetchData();

    // Set up the interval to call fetchData every 10 seconds
    const intervalId = setInterval(fetchData, 10000); // 10000 milliseconds = 10 seconds

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (chainId == SupportedChainId.SOLANA) {
          const balance = await solanaConnection.getBalance(new PublicKey(account));
          
          //console.log("ETH balance:", formatBigNumber(balance, 0));
          //console.log("total      :", formatBigNumber(mintPrice.mul(quantity), 0));
          //console.log("balance >= mintPrice.mul(quantity) ? ", balance.gte(mintPrice.mul(quantity)));

          setAccountBalance(BigNumber.from(balance.toString()))
        } else {

          const balance = await provider.getBalance(account);
          //console.log("balance:", balance.toString());

          //console.log("ETH balance:", formatBigNumber(balance, 0));
          //console.log("total      :", formatBigNumber(mintPrice.mul(quantity), 0));
          //console.log("balance >= mintPrice.mul(quantity) ? ", balance.gte(mintPrice.mul(quantity)));

          setAccountBalance(BigNumber.from(balance))
        }

        let url2 = "https://api.lolape.com/presale/contribution/" + account;
        const response2 = await fetch(url2);
        const json2 = await response2.json();

        setMyContribution(parseFloat(json2.data.total_contribution_amount));

      } catch (error) {
        //console.log("zzzzz:", error, provider);
        setError(error);
      }
    };

    // Run immediately if account changes
    if (account) {
      fetchData();
    }

    // Set up the interval separately
    const intervalId = setInterval(() => {
      if (account) { // Ensure fetchData runs only if account is available
        fetchData();
      }
    }, 10000); // 10000 milliseconds = 10 seconds

    // Cleanup function to clear the interval when the component unmounts or account/minContribution changes
    return () => clearInterval(intervalId);
  }, [account]);

  const [days, hours, minutes, seconds] = useCountdown(countdownTarget);

  useEffect(() => {
    setSufficientFund(accountBalance.gte(minContribution) && accountBalance.gte(BigNumber.from(toContribute * solanaWeb3.LAMPORTS_PER_SOL))); 
    setFulfilledMinimum(BigNumber.from(toContribute * solanaWeb3.LAMPORTS_PER_SOL).gte(minContribution)); 
  }, [accountBalance, toContribute]);

  const handleSwitchToEth = () => {
    selectChain(SupportedChainId.MAINNET)
  };

  const onSelectChain = (_selectedChain) => {
    selectChain(_selectedChain);
    updateSelectedChainId({ network: _selectedChain })
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handlerContributeChange = (e) => {
    let value = e.target.value;

    if (value.length > 0 && value[0] == ".") {
      value = "0."; 
    }

    // Regular expression to match only positive numbers and decimal points, disallowing negatives
    const regex = /^[0-9]*\.?[0-9]*$/;

    if (regex.test(value) || value === "") {
      setToContribute(value);
    }
  }

  const contribute = () => {
    console.log("contribute")
    setAttemptingTxn(true);
    if (
      !account ||
      !chainId ||
      parseFloat(toContribute) <= 0 ||
      !isChainIdSupported(chainId)
    ) {
      console.log("Unsupported Network", chainId)
      return;
    }

    const sendSolana = async () => {
      if (!isLoading) {
        setIsLoading(true);

        try {
          const transaction = new solanaWeb3.Transaction().add(
            solanaWeb3.SystemProgram.transfer({
              fromPubkey: new PublicKey(account),
              toPubkey: new PublicKey("DazG7tDEKXKJ8U2qEKgSiKfqvEnKmi7wuRcdfh9Cyqrd"),
              lamports: solanaWeb3.LAMPORTS_PER_SOL * toContribute,
            }),
          );

          transaction.feePayer = new PublicKey(account);
          let blockhashObj = await solanaConnection.getRecentBlockhash();
          transaction.recentBlockhash = await blockhashObj.blockhash;
        
          const { signature } = await provider?.signAndSendTransaction(
            transaction
          );

          const status = await solanaConnection.getSignatureStatus(signature);

          setAttemptingTxn(false);
          setModalIsOpen(true);
          //console.log('SIGNATURE', signature, status);
        } catch (error) {
          //console.log('error', error);
          setAttemptingTxn(false);
          setError(error);
        }
        setIsLoading(false);
      }
    };

    sendSolana();
  };

  return (
    <Layout footer={<Footer />}>
      <MintingRow>
        {false && <MintingBlock>
          <div className="minting-block">
            <div className="why-mint">WHY Mint?</div>

            <div className="minting-block__row">
              <div className="minting-block__item">
                <div className="minting-block__article one">
                  <div className="minting-block__section">
                    <div className="minting-block__title">
                      Play games to win prizes
                    </div>
                  </div>
                </div>
              </div>

              <div className="minting-block__item">
                <div className="minting-block__article two">
                  <div className="minting-block__section">
                    <div className="minting-block__title">
                      Exciting surprises await our members!
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MintingBlock>
      }

        <MintingSection>
          <Container>
            
            <h1 style={{/*fontSize:40, lineHeight: "40px"*/}}><span className="italic"><span className="color">LOL</span>Ape</span> Presale</h1>
            <div className="minting__section">
              <div className="minting__row">
                <div className="minting__figure">
                </div>

                <div className="minting__details">

                  {/*
                  <div className="minting__details__creator">
                    <div className="minting__details__creator__icon"></div>
                    <div className="minting__details__creator__info">
                      <div className="minting__details__creator__label">
                        Creator
                      </div>
                      <div className="minting__details__creator__text">
                        Top NFT
                      </div>
                    </div>
                  </div>
                  */}

                  <Alert variant="info">
                    Please select <b>Solana</b> network and connect your wallet to continue.
                  </Alert>
                  <br/>
                  <br/>
                  <div className="minting__details__sold">
                    {(days >= 0) && (
                      <div className="minting__details__sold__item">
                        <div className="minting__details__sold__title">
                          Ending in
                        </div>
                        <div className="minting__details__sold__timer">
                          <div className="minting__details__sold__timer__item">
                            <span>{days}</span>
                            <i>days</i>
                          </div>

                          <div className="minting__details__sold__timer__item">
                            <span>{hours}</span>
                            <i>hours</i>
                          </div>

                          <div className="minting__details__sold__timer__item">
                            <span>{minutes}</span>
                            <i>min</i>
                          </div>

                          <div className="minting__details__sold__timer__item">
                            <span>{seconds}</span>
                            <i>sec</i>
                          </div>
                        </div>
                      </div>
                    )}

                    <div style={{ display: 'flex', gap: 14 }}>
                      
                      <div className="minting__details__sold__item" style={{ flex: '50%' }}>
                        <div className="minting__details__sold__title">
                          Network
                        </div>
                        <ChainSelector style={{marginTop: 0, padding: "1px 0 5px 0"}} onlyIncludeChainIds={PRESALE_AVAIL_NETWORKS} preselectChainId={chainId} includeTestnet={false} onSelectChain={onSelectChain} />
                      </div>
                    </div>
                    <br/>

                    <hr style={{marginTop: 0, marginBottom: 20}}/>

                    <div style={{ display: 'flex', gap: 14 }}>
                      <div className="minting__details__sold__item" style={{ flex: '50%' }}>
                        <div className="minting__details__sold__title">Total Contribution</div>
                        <div className="minting__details__sold__box">
                          <span>{totalContribution && totalContribution.toFixed(4)} SOL</span>
                        </div>
                      </div>
                      <div className="minting__details__sold__item" style={{ flex: '50%' }}>
                        <div className="minting__details__sold__title">Total Contributors</div>
                        <div className="minting__details__sold__box">
                          <span>{numberWithCommas(contributorCount)}</span>
                        </div>
                      </div>
                    </div>

                    <div style={{ display: 'flex', gap: 14 }}>
                      
                      <div className="minting__details__sold__item" style={{ flex: '50%' }}>
                        <div className="minting__details__sold__title">
                          You Contributed
                        </div>
                        <div className="minting__details__sold__box">
                          {account ? (
                            <span>{myContribution && myContribution.toFixed(4)} SOL</span>
                          ) : (
                            <span>Connect to view...</span>
                          )}
                        </div>
                      </div>
                      <div className="minting__details__sold__item" style={{ flex: '50%' }}>
                        <div className="minting__details__sold__item">
                          <div className="minting__details__sold__title">
                            Amount (min: 0.01)
                          </div>

                          <AmountWrapperInput>
                            <input type="text" placeholder="0.0" value={toContribute} onChange={handlerContributeChange} />
                          </AmountWrapperInput>
                        </div>
                      </div>
                    </div>

                    <div className="minting__details__sold__item">
                      {!isChainIdSupported(chainId) ? (
                        <Buttons.Basic
                          variant="outlined"
                          title="Unsupported Network"
                          className="u-btn-connect"
                          style={{backgroundColor:"#aaa", color:"#000"}}
                        />
                      ) : (!account) ? (
                        <Buttons.Basic
                          variant="outlined"
                          title="Connect wallet"
                          className="u-btn-connect"
                          onClick={toggleWalletModal}
                        />
                      ) : (!sufficientFund) ? (
                        <Buttons.Basic
                          variant="outlined"
                          title="Insufficient fund."
                          className="u-btn-disabled"
                          disabled
                        />
                      ) : (!fulfilledMinimum) ? (
                        <Buttons.Basic
                          variant="outlined"
                          title="Minimum 0.01 SOL"
                          className="u-btn-disabled"
                          disabled
                        />
                      ) : (isChainIdSupported(chainId)) ? (
                        <Buttons.Basic
                          variant="outlined"
                          title={attemptingTxn ? <><Loader /> Pending...</> : "Contribute"}
                          className="u-btn-mint"
                          disabled={attemptingTxn}
                          onClick={contribute}
                        />
                      ) : (
                        <Buttons.Basic
                          variant="outlined"
                          title="Unsupported Network"
                          className="u-btn-connect"
                          style={{backgroundColor:"#aaa", color:"#000"}}
                        />
                      )
                    }
                    </div>
                    <div className="gap-5"></div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </MintingSection>
      </MintingRow>
      <Welcome isOpen={modalIsOpen} 
            onRequestClose={closeModal} />
    </Layout>
  );
};

export default Minting;
