import styled from "styled-components";

export const NFTExplorerRow = styled.div`
  padding-top: 20px;
  padding-bottom: 30px;

  @media only screen and (min-width: 768px) {
    padding-top: 33px;
    padding-bottom: 60px;
  }

  h1,
  .h1 {
    margin: 0 0 16px;
    font-family: var(--font-gilroy);
    font-size: 26px;
    line-height: 31px;
    font-weight: 700;
    letter-spacing: -0.02em;
    color: #2d2d2d;

    @media only screen and (min-width: 768px) {
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 20px;
    }
  }
`;

export const Container = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  padding-left: 15px;
  padding-right: 15px;

  @media only screen and (min-width: 1360px) {
    padding-left: 40px;
    padding-right: 40px;
  }
`;

export const LayoutSection = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 60px;
  width: 100%;

  @media only screen and (max-width: 1199px) {
    padding-left: 30px;
  }

  @media only screen and (max-width: 1023px) {
    padding-left: 0;
  }

  .contaier {
    margin: 0 auto;
    max-width: 1000px;
    width: 100%;

    @media only screen and (max-width: 1439px) {
      max-width: 100%;
    }
  }
`;

export const FilterMobile = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 30px;
  z-index: 10;
  display: none;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 50px;
  background: linear-gradient(
    63.03deg,
    rgba(255, 255, 255, 0.81) 10.69%,
    rgba(255, 255, 255, 0.36) 121.06%
  );
  border: 1px solid #e8eaef;
  backdrop-filter: blur(62px);
  border-radius: 40px;
  cursor: pointer;

  @media only screen and (max-width: 1023px) {
    display: flex;
  }

  .filter {
    &__label {
      //margin-right: 15px;
      font-family: var(--font-gilroy);
      font-size: 18px;
      line-height: 18px;
      font-weight: 600;
      color: #282828;
    }

    &__count {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      font-family: var(--font-gilroy);
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;
      color: white;
      text-align: center;
      background-color: #191c24;
    }
  }
`;

export const SelectNft = styled.div`
  .select-nft {
    position: relative;

    &__row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: -10px;
      margin-right: -10px;
      overflow-y: auto;
      padding-top: 3px;

      @media only screen and (min-width: 1200px) {
        &::-webkit-scrollbar {
          width: 20px;
        }

        &::-webkit-scrollbar-track {
          border: 1px solid #89a5e6;
          border-radius: 40px;
        }

        &::-webkit-scrollbar-thumb {
          background: linear-gradient(
            182.71deg,
            #bed7ff -11.61%,
            #4f8ef5 103.76%
          );
          border-radius: 40px;
        }
      }
    }

    &__item {
      padding-left: 10px;
      padding-right: 10px;
      //flex: 0 0 calc(100% / 4);
      //max-width: calc(100% / 4);
      margin-bottom: 20px;

      @media only screen and (max-width: 1023px) {
        //flex: 0 0 calc(100% / 3);
        //max-width: calc(100% / 3);
      }

      @media only screen and (max-width: 567px) {
        //flex: 0 0 calc(100% / 2);
        //max-width: calc(100% / 2);
        //flex: 0 0 100%;
        //max-width: 100%;
      }
    }

    &__head {
      padding-top: 20px;
      padding-bottom: 28px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media only screen and (max-width: 767px) {
        padding: 0;
        align-items: stretch;
        flex-direction: column-reverse;
      }
    }

    &__sortby {
      display: flex;
      align-items: center;

      @media only screen and (max-width: 767px) {
        margin-bottom: 20px;
        justify-content: space-between;
      }

      &__title {
        font-family: var(--font-gilroy);
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        color: #2d2d2d;
        margin-right: 20px;
      }

      &__select {
        position: relative;
        cursor: pointer;

        &__name {
          width: 168px;
          height: 38px;
          display: flex;
          align-items: center;
          padding: 9px 12px;
          font-family: var(--font-gilroy);
          font-size: 16px;
          line-height: 18px;
          font-weight: 600;
          color: #2d2d2d;
          border: 1px solid #e8eaef;
          border-radius: 12px;
        }

        &__arrow {
          position: absolute;
          top: 50%;
          transform: translateY(-50%) rotate(180deg);
          right: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
          color: var(--color-blue-400);
        }

        &__row {
          position: absolute;
          top: 100%;
          width: 100%;
          z-index: 1;
          margin-top: 7px;
          background: white;
          border: 1px solid #e8eaef;
          backdrop-filter: blur(74px);
          border-radius: 12px;
          opacity: 0;
          visibility: hidden;
          z-index: -1;

          &.open {
            opacity: 1;
            visibility: visible;
            z-index: 10;
          }
        }

        &__item {
          padding: 10px 16px;
          flex-direction: column;
          font-family: var(--font-gilroy);
          font-size: 16px;
          line-height: 18px;
          font-weight: 600;
          color: #2d2d2d;
          transition: all 0.2s ease;
          user-select: none;

          &:not(:last-child) {
            border-bottom: 1px solid #e8eaef;
          }

          &:hover {
            color: rgba(45, 45, 45, 0.5);
          }
        }
      }
    }

    &__form {
    }

    &__searchby {
      position: relative;
      max-width: 240px;
      width: 100%;

      @media only screen and (max-width: 767px) {
        max-width: 100%;
        margin-bottom: 20px;
      }

      &__button {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        left: 12px;
        width: 18px;
        height: 18px;
        background-color: transparent;
        border: 0;
        outline: 0;
        color: #2d2d2d;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          opacity: 0.7;
        }
      }

      &__input {
        padding: 5px 15px 5px 40px;
        width: 100%;
        height: 38px;
        font-family: var(--font-gilroy);
        font-size: 16px;
        line-height: 18px;
        font-weight: 500;
        color: rgba(45, 45, 45, 1);
        background-color: white;
        border: 1px solid #e8eaef;
        border-radius: 12px;
        outline: none;
        transition: border-color 0.2s ease;

        &:focus {
          border-color: rgba(210, 235, 255, 0.6);
        }

        &::placeholder {
          font-family: var(--font-gilroy);
          font-size: 16px;
          line-height: 18px;
          font-weight: 500;
          color: rgba(45, 45, 45, 0.5);
        }
      }
    }
  }

  .card {
    &__article {
      display: flex;
      flex-direction: column;
      background: white;
      border-radius: 12px;
      height: 100%;

      cursor: pointer;
    }

    &__figure {
      display: block;
      background-color: #d9d9d9;
      overflow: hidden;
      border-radius: 12px;
      box-shadow: 0 1px 1px rgba(0,0,0,.075), 0 2px 2px rgba(0,0,0,.075), 2px 4px 8px 2px rgba(16, 23, 30, 0.24);
      //box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;

        transition: all 0.2s ease-in-out;

        &:hover {
          transform: scale(1.05);
        }
      }
    }

    &__body {
      padding: 12px 14px 10px 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__name {
      padding-right: 5px;
      font-family: var(--font-gilroy);
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      color: #999;
    }

    &__code {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 68px;
      padding: 5px 10px;
      height: 28px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: var(--font-gilroy);
      font-size: 16px;
      line-height: 18px;
      font-weight: 600;
      color: #061330;
      background-color: #ebedf2;
      border-radius: 10px;

      @media only screen and (max-width: 767px) {
        min-width: 82px;
        height: 34px;
      }
    }
  }
`;

export const LoaderContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 300px;
  height: 100%;

  @media only screen and (max-width: 1023px) {
    margin-top: 100px;
  }
`;

