import styled from "styled-components";
import { AlertTriangle, AlertCircle, Info, CheckCircle } from "react-feather";

const AlertWrapper = styled.div`
  min-height: 67px;
  border-radius: 12px;
  padding: 4px 12px;
  display: flex;
  align-items: flex-start;

  ${({ variant }) => {
    switch (variant) {
      case "info":
        return "border: 1px solid rgb(188,232,241); background-color: rgb(217,237,247);"; // Replace with primary color code
      case "success":
        return "border: 1px solid rgb(214,233,198); background-color: rgb(223,240,216);"; // Replace with success color code
      case "error":
        return "border: 1px solid rgb(238,211,215); background-color: rgb(242,222,222);"; // Replace with error color code
      case "warning":
        return "background-color: rgb(252,248,227); border: 1px solid rgb(251,238,213);"; // Replace with warning color code
      case "dark":
        return "background: #343a40;"; // Replace with dark color code
      default:
        return "border: 1px solid rgb(180,180,180); background-color: rgb(212,212,212);"; // Replace with primary color code
    }
  }}
`;

const IconWrapper = styled.div`
  display: block;
  margin-block-start: 10px;
  padding: 5px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: flex-start; 
  justify-content: center;
`;

const Content = styled.p`
  margin-left: 10px;
  font-size: 16px;
  line-height: 1.3;

  a,
  a:visited,
  a:active {
  }

  ${({ variant }) => {
    switch (variant) {
      case "info":
        return "color: #007bff;";
      case "success":
        return "color: #28a745;";
      case "error":
        return "color: #dc3545;";
      case "warning":
        return "color: rgb(192,152,83);";
      case "dark":
        return "color: #fff;";
      default:
        return "color: #007bff;";
    }
  }}
`;

const OpenButton = styled.span`
  margin-left: auto;
  margin-right: 5px;
  color: #fff;
  transition: transform 0.5s;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    transform: scale(1.3);
  }
`;

const CloseButton = styled(OpenButton)``;

const Alert = ({ variant, children }) => {
  return (
    <AlertWrapper variant={variant}>
      {variant == "info" ? (
        <IconWrapper>
          <Info size={18} color="#007bff" />
        </IconWrapper>
      ) : variant == "success" ? (
        <IconWrapper>
          <CheckCircle size={18} color="#28a745" />
        </IconWrapper>
      ) : variant == "error" ? (
        <IconWrapper>
          <AlertTriangle size={18} color="#dc3545" />
        </IconWrapper>
      ) : variant == "warning" ? (
        <IconWrapper>
          <AlertCircle size={18} color="rgb(192,152,83)" />
        </IconWrapper>
      ) : variant == "dark" ? (
        <IconWrapper>
          <Info size={18} color="#fff" />
        </IconWrapper>
      ) : (
        <IconWrapper>
          <Info size={18} color="#007bff" />
        </IconWrapper>
      )}

      <Content variant={variant}>{children}</Content>
      {/*
      <OpenButton className="mdi mdi-open-in-new"></OpenButton>
      <CloseButton className="mdi mdi-close"></CloseButton>
      */}
    </AlertWrapper>
  );
};

export default Alert;
