import styled from "styled-components";

export const RoundedContainer = styled.div`
  margin-top: 20px;
  padding: 16px;
  background: rgb(255, 255, 255);
  border-radius: 16px;
  display: block;

  h4 {
    margin: 10px 0;
  }
`; 
