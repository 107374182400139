import React, {
  forwardRef,
  HTMLProps,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react'
import {
  ArrowLeft,
  CheckCircle,
  Copy,
  Link as LinkIconFeather,
} from 'react-feather'
import { Link } from 'react-router-dom'


import styled, { css, keyframes } from 'styled-components/macro'
import { flexRowNoWrap } from '../../theme/styles'
import { Z_INDEX } from '../../theme/zIndex'

import useCopyClipboard from '../../hooks/useCopyClipboard'
import { ReactComponent as TooltipTriangle } from '../../assets/svg/tooltip_triangle.svg'


const TOOLTIP_WIDTH = 60
const MOBILE_MEDIA_BREAKPOINT = 768;


// A button that triggers some onClick result, but looks like a link.
export const LinkStyledButton = styled.button`
  border: none;
  text-decoration: none;
  background: none;

  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  color: ${({ theme, disabled }) => (disabled ? theme.textSecondary : theme.accentAction)};
  font-weight: 500;

  :hover {
    text-decoration: ${({ disabled }) => (disabled ? null : 'underline')};
  }

  :focus {
    outline: none;
    text-decoration: ${({ disabled }) => (disabled ? null : 'underline')};
  }

  :active {
    text-decoration: none;
  }
`;

export const ClickableStyle = css`
  text-decoration: none;
  cursor: pointer;
  transition-duration: ${({ theme }) => theme.transition.duration.fast};

  :hover {
    opacity: ${({ theme }) => theme.opacity.hover};
  }
  :active {
    opacity: ${({ theme }) => theme.opacity.click};
  }
`

const CopyIcon = styled(Copy)`
  ${ClickableStyle}
  height: 16px;
  width: 18px;
  margin-left: 10px;
  
  color: ${({ theme }) => theme.accentAction};
  font-weight: 500;
  stroke: ${({ theme }) => theme.accentAction};
`

const ToolTipWrapper = styled.div(({ isCopyContractTooltip = false, tooltipX }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'absolute',
  left: isCopyContractTooltip && (tooltipX ? `${tooltipX - TOOLTIP_WIDTH / 2}px` : '50%'),
  transform: 'translate(5px, 32px)',
  zIndex: Z_INDEX.tooltip,
}));

const StyledTooltipTriangle = styled(TooltipTriangle)(({ theme }) => ({
  path: {
    fill: theme.black,
  },
}));

const CopiedTooltip = styled.div(({ isCopyContractTooltip = false }) => ({
  backgroundColor: '#000',
  textAlign: 'center',
  justifyContent: 'center',
  width: !isCopyContractTooltip && `${TOOLTIP_WIDTH}px`,
  height: !isCopyContractTooltip && '32px',
  lineHeight: !isCopyContractTooltip && '32px',
  padding: isCopyContractTooltip && '8px',
  borderRadius: '8px',
  color: '#fff',
  fontSize: '12px',
}));

function Tooltip({ isCopyContractTooltip, tooltipX }) {
  return (
    <ToolTipWrapper isCopyContractTooltip={isCopyContractTooltip} tooltipX={tooltipX}>
      <StyledTooltipTriangle />
      <CopiedTooltip isCopyContractTooltip={isCopyContractTooltip}>Copied!</CopiedTooltip>
    </ToolTipWrapper>
  );
}

const CopyIconWrapper = styled.div`
  text-decoration: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export function CopyToClipboard({ toCopy, children }) {
  const [isCopied, setCopied] = useCopyClipboard();
  const copy = useCallback(() => {
    setCopied(toCopy);
  }, [toCopy, setCopied]);
  return (
    <CopyIconWrapper onClick={copy}>
      {children}
      {isCopied && <Tooltip isCopyContractTooltip={false} />}
    </CopyIconWrapper>
  );
}

export function CopyLinkIcon({ toCopy }) {
  return (
    <CopyToClipboard text={toCopy}>
      <CopyIcon />
    </CopyToClipboard>
  );
}

const FullAddress = styled.span`
  @media only screen and (max-width: ${MOBILE_MEDIA_BREAKPOINT}) {
    display: none;
  }
`
const TruncatedAddress = styled.span`
  display: none;
  @media only screen and (max-width: ${MOBILE_MEDIA_BREAKPOINT}) {
    display: flex;
  }
`

const CopyAddressRow = styled.div`
  ${props => props.isClicked ? 'opacity: ${props.theme.opacity.click} !important' : ''}
  
  text-decoration: none;
  transition-duration: ${({ theme }) => theme.transition.duration.fast};

  :hover {
    opacity: ${({ theme }) => theme.opacity.hover};
  }
  :active {
    opacity: ${({ theme }) => theme.opacity.click};
  }
  color: inherit;
  stroke: inherit;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 6px;
`;

const CopyContractAddressWrapper = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
`

export function CopyContractAddress({ address }) {
  const [isCopied, setCopied] = useCopyClipboard();
  const [tooltipX, setTooltipX] = useState();
  const copy = useCallback(
    (e) => {
      setTooltipX(e.clientX);
      setCopied(address);
    },
    [address, setCopied]
  );

  const truncated = `${address.slice(0, 4)}...${address.slice(-3)}`;
  return (
    <CopyContractAddressWrapper onClick={copy}>
      <CopyAddressRow isClicked={isCopied}>
        <FullAddress>{address}</FullAddress>
        <TruncatedAddress>{truncated}</TruncatedAddress>
        <Copy size={14} />
      </CopyAddressRow>
      {isCopied && <Tooltip isCopyContractTooltip tooltipX={tooltipX} />}
    </CopyContractAddressWrapper>
  );
}

const CopyHelperContainer = styled(LinkStyledButton)(({ clicked }) => `
  ${!clicked && ClickableStyle};
  color: ${({ color, theme }) => color || theme.accentAction};
  padding: 0;
  flex-shrink: 0;
  display: flex;
  text-decoration: none;
  :hover,
  :active,
  :focus {
    text-decoration: none;
    color: ${({ color, theme }) => color || theme.accentAction};
  }
`);

const CopyHelperText = styled.span(({ fontSize }) => `
  ${flexRowNoWrap};
  font-size: ${fontSize}px;
  font-weight: 400;
  align-items: center;
`);

const CopiedIcon = styled(CheckCircle)(`
  color: ${({ theme }) => theme.accentSuccess};
  stroke-width: 1.5px;
`);

export const CopyHelper = forwardRef((
  {
    link,
    toCopy,
    color,
    fontSize = 16,
    iconSize = 20,
    gap = 12,
    iconPosition = 'left',
    iconColor,
    children,
  },
  ref
) => {
  const [isCopied, setCopied] = useCopyClipboard();
  const copy = useCallback(() => {
    setCopied(toCopy);
  }, [toCopy, setCopied]);

  useImperativeHandle(ref, () => ({
    forceCopy() {
      copy();
    },
  }));

  const BaseIcon = isCopied ? CopiedIcon : link ? LinkIconFeather : Copy;

  return (
    <CopyHelperContainer onClick={copy} color={color} clicked={isCopied}>
      <div style={{ display: 'flex', flexDirection: 'row', gap }}>
        {iconPosition === 'left' && <BaseIcon size={iconSize} strokeWidth={1.5} color={iconColor} />}
        <CopyHelperText fontSize={fontSize}>{isCopied ? <span>Copied!</span> : children}</CopyHelperText>
        {iconPosition === 'right' && <BaseIcon size={iconSize} strokeWidth={1.5} color={iconColor} />}
      </div>
    </CopyHelperContainer>
  );
});

CopyHelper.displayName = 'CopyHelper'
