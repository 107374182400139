import { useCustomWeb3React } from '../../providers/CustomWeb3ReactProvider';
import useBlockNumber from '../../lib/hooks/useBlockNumber';
import multicall from '../../lib/state/multicall';

//export { NEVER_RELOAD } from '@uniswap/redux-multicall'; // re-export for convenience
//export { CallStateResult } from '@uniswap/redux-multicall'; // re-export for convenience

export function useMultipleContractSingleData(...args) {
  const { chainId, latestBlock } = useCallContext();
  return multicall.hooks.useMultipleContractSingleData(chainId, latestBlock, ...args);
}

export function useSingleCallResult(...args) {
  const { chainId, latestBlock } = useCallContext();
  return multicall.hooks.useSingleCallResult(chainId, latestBlock, ...args);
}

export function useSingleContractMultipleData(...args) {
  const { chainId, latestBlock } = useCallContext();
  return multicall.hooks.useSingleContractMultipleData(chainId, latestBlock, ...args);
}

export function useSingleContractWithCallData(...args) {
  const { chainId, latestBlock } = useCallContext();
  return multicall.hooks.useSingleContractWithCallData(chainId, latestBlock, ...args);
}

function useCallContext() {
  const { chainId } = useCustomWeb3React();
  const latestBlock = useBlockNumber();
  return { chainId, latestBlock };
}
