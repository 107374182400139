import { useCallback, useRef, useState } from 'react'
import { useTheme } from 'styled-components/macro'
import styled from 'styled-components'

import { getChainInfo } from '../../constants/chainInfo'
import { SupportedChainId } from '../../constants/chains'
import { useIsMobile } from '../../hooks/useIsMobile'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import useSelectChain from '../../hooks/useSelectChain'
import useSyncChainQuery from '../../hooks/useSyncChainQuery'
import { useNetworkManager } from '../../state/user/hooks';
import { updateSelectedWallet } from '../../state/user/reducer';
import { useAppDispatch } from '../../state/hooks';

import { Portal, Icons } from "../../components";
import { useCustomWeb3React } from '../../providers/CustomWeb3ReactProvider';

import ChainSelectorRow from './ChainSelectorRow'

const NETWORK_SELECTOR_CHAINS = [
  SupportedChainId.SOLANA,
  SupportedChainId.BASE,
  SupportedChainId.BITCOIN,
  SupportedChainId.MAINNET,
  SupportedChainId.BNBCHAIN,
  SupportedChainId.POLYGON,
  SupportedChainId.ARBITRUM_ONE,
  SupportedChainId.AVAX,
  //SupportedChainId.KLAYTN,
  //SupportedChainId.OPTIMISM,
  //SupportedChainId.CELO,
]

const Container = styled.div`
  position: relative;

  @media only screen and (max-width: 767px) {
  }
`;

const StyledRow = styled.div`
  border: 1px solid #c1cbd9;
  border-radius: 8px; 
  padding: 4px 10px;
  height: 36px;
  cursor: pointer; 
  background-color: #fff; 
  font-size: 16px;
  display: flex; 
  flex-direction: row; 
  align-items: center;
  row-gap: 8px;
  gap: 8px;
  vertical-align: baseline;

  @media only screen and (max-width: 767px) {
    width: 100%;
    height: 36px;
  }

  &:hover {
    border-color: #244ca8;
  }

  .image {
    width: 20px; 
    height: 20px;
  }

  .network-label {
    line-height: 20px; 
    flex: 1; 
    display: none;

    @media only screen and (min-width: 1280px) {
      display: block;
    }
  }
`;

const NavDropDownContainer = styled.div`
  background-color: #fff;
  border: 1px solid #e8eaef;
  box-shadow: 0px 12px 14px rgba(16, 23, 30, 0.24);
  padding: 8px;

  &.navDropdown {
    position: absolute;
    border-radius: 12px;
    top: 56px; 
    right: 0;
    z-index: 1060;
  }

  &.mobileNavDropdown {
    position: fixed;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    top: unset;
    bottom: 0; 
    left: 0; 
    right: 0;
    width: 100%;
    border-right-width: 0; 
    border-left-width: 0; 
  }
`;

/*
export const ChainSelector = style([
  lightGrayOverlayOnHover,
  sprinkles({
    borderRadius: '8',
    height: '40',
    cursor: 'pointer',
    border: 'none',
    color: 'textPrimary',
    background: 'none',
  }),
]);

export const Image = style([
  sprinkles({
    width: '20',
    height: '20',
  }),
]);

*/

const ChainSelector = ({ leftAlign, className }) => {
  const { chainId, connector } = useCustomWeb3React()
  const [isOpen, setIsOpen] = useState(false)
  const isMobile = useIsMobile()
  const dispatch = useAppDispatch();
  const [selectedNetwork, updateSelectedChainId] = useNetworkManager()

  const theme = useTheme()

  const ref = useRef(null)
  const modalRef = useRef(null)
  useOnClickOutside(ref, () => setIsOpen(false), [modalRef])

  const info = chainId ? getChainInfo(chainId) : undefined

  const selectChain = useSelectChain()
  useSyncChainQuery()

  const [pendingChainId, setPendingChainId] = useState(undefined)

  const onSelectChain = useCallback(
    async (targetChainId) => {
      setPendingChainId(targetChainId)
      await selectChain(targetChainId)
      setPendingChainId(undefined)
      setIsOpen(false)

      if (targetChainId == SupportedChainId.BITCOIN) {
        //if (connector && connector.deactivate) {
        //  connector.deactivate();
        //}
        //connector.resetState();
        //dispatch(updateSelectedWallet({ wallet: undefined }));
      } else {
      }
      updateSelectedChainId({ network: targetChainId })
    },
    [selectChain, setIsOpen]
  )

  if (!chainId) {
    return null
  }

  const isSupported = !!info

  const dropdown = (
    <NavDropDownContainer 
      style={{ left: leftAlign ? '0' : 'auto', right: leftAlign ? 'auto' : '0' }} 
      ref={modalRef}
      className={isMobile ? "mobileNavDropdown" : "navDropdown"}
    >
        {NETWORK_SELECTOR_CHAINS.map((chainId) => (
          <ChainSelectorRow
            onSelectChain={onSelectChain}
            targetChain={chainId}
            key={chainId}
            isPending={chainId === pendingChainId}
          />
        ))}
    </NavDropDownContainer>
  )

  const chevronProps = {
    height: 20,
    width: 20,
    color: theme.textSecondary,
  }

  return (
    <Container ref={ref} className={className}>
      <StyledRow
        data-testid="chain-selector"
        onClick={() => setIsOpen(!isOpen)}
      >
        {!isSupported ? (
          <>
            <Icons.TokenWarningRedIcon fill="#999999" width={24} height={24} />
            <span style={{ lineHeight: '20px' }}>
              Unsupported
            </span>
          </>
        ) : (
          <>
            <img src={info.logoUrl} alt={info.label} className="image" />
            <span className="network-label">
              {info.label}
            </span>
          </>
        )}
        {isOpen ? <Icons.ChevronUp {...chevronProps} /> : <Icons.ChevronDown {...chevronProps} />}
      </StyledRow>
      {isOpen && (isMobile ? <Portal>{dropdown}</Portal> : <>{dropdown}</>)}
    </Container>
  )
}

export default ChainSelector;
