import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import * as keys from "./utils/keys";
import {
  LolApe,
  LolApeHome,
  LolApeParty,
  Dashboard,
  Stake,
  NFTExplorer,
  GalleryHidden,
  Presale,
  Bridge,
  Minting,
  Provenance,
  MyNFTs,
  BridgeNFT,
  NFTReader,
  LolPresale,
  OrdinalMinting,
} from "./pages";

import Test from "./pages/Test"

import { Buttons } from "./components";
import Popups from './components/Popups'

import "./styles/index.scss";

function App() {
  return (
    <>
      <Popups />
      <BrowserRouter>
        <Routes>
          <Route path={keys.NFT_READER} element={<NFTReader />} />
          <Route path={keys.NFTS} element={<MyNFTs />} />
          <Route path={keys.NFT_EXPLORER} element={<NFTExplorer />} />
          <Route path={"/presale"} element={<LolPresale />} />
          <Route path={"/test"} element={<Test />} />
          {/*
          <Route path={keys.MINTING} element={<Minting />} />
          <Route path={keys.NFT_EXPLORER} element={<Navigate replace to={keys.ORDINAL_MINTING} />} />
          <Route path={keys.NFT_EXPLORER} element={<NFTExplorer />} />
          <Route path={keys.DASHBOARD} element={<Dashboard />} />
          <Route path={keys.STAKE} element={<Stake />} />
          <Route path={keys.NFT_EXPLORER} element={<NFTExplorer />} />
          <Route path={keys.PRESALE} element={<Presale />} />
          <Route path={keys.BRIDGE} element={<Bridge />} />
          <Route path={keys.PROVENANCE} element={<Provenance />} />
          <Route path={keys.BRIDGE_NFT} element={<BridgeNFT />} />
          <Route path="/" element={<Navigate replace to={keys.ORDINAL_MINTING} />} />
          <Route index path={keys.ORDINAL_MINTING} element={<OrdinalMinting />} />
          */}
          <Route path={keys.LOL_APE_PARTY} element={<LolApeParty />} />

          <Route path="/" element={<LolApe />} />
        </Routes>
        <Buttons.BackToTop />
      </BrowserRouter>
    </>
  );
}

export default App;
