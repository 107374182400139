import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";

import { Sliders, Search } from "react-feather";

import * as keys from "../../utils/keys";
import { Buttons, Social } from "../../components";
import Icons from "../../components/Icons";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useCustomWeb3React } from "../../providers/CustomWeb3ReactProvider";

import { usePartyModeManager } from '../../state/user/hooks';

import { HeaderTag, Container, ConnectButton } from "./styled";


import DiscoBall from "../../assets/images/discoball.png";
import lmail_logo from "../../assets/images/Lmail.png";

const Toggle = styled.div`
.switch {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 34px;
  outline: none;
}
.switch input {
  position: absolute;
  top: -99999px;
  left: -99999px;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 40px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}
input:checked + .slider {
  background-image: linear-gradient(
    90deg,
    oklch(85% 0.1 190),
    oklch(66% 0.2 306)
  );;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(-36px);
  -ms-transform: translateX(-36px);
  transform: translateX(-36px);
}

.toggle-label, .toggle-label-party {
  margin-left: 5px;
  position: relative;
  top: 8px;
  left: -50px;
}
.toggle-label-party {
  color: #fff;
  left: -70px;

  background-image: linear-gradient(90deg, oklch(0.7 0.15 348), oklch(0.7 0.15 306));
  background-clip: text;
  color: transparent;
  transition: oklch(70% 0.15 348) 0.5s, oklch(70% 0.15 306) 0.5s;
}
.toggle-label-party:hover {
  background-image: linear-gradient(90deg, oklch(85% 0.1 190), oklch(85% 0.1 108));
  background-clip: text;
  color: transparent;
  transition: oklch(85% 0.1 190) 0.5s, oklch(85% 0.1 108) 0.5s;
}

  .logo {
    position: absolute;
    top: 2px; 
    left: 43px;
    -webkit-transition: .4s;
    transition: .4s;
    width: 30px;
  }

  input:checked + .slider .logo {
    left: 2px; 
  }


@media only screen and (max-width: 599px) {
  .toggle-label, .toggle-label-party {
    font-size: 16px;
  }
  .toggle-label {
    left: -40px;
  }
  .toggle-label-party {
    left: -55px;
  }
}
`;

const Header = ({ isParty }) => {
  const navigate = useNavigate();

  const [isPartyMode, setIsPartyMode] = usePartyModeManager()

  useEffect(() => {
  }, []);

  const handleChange = () => {
    if (isPartyMode) {
      //navigate('/')
    } else {
      //navigate('/party')
    }
    setIsPartyMode({ partyMode: !isPartyMode });
  };

  return (
    <Toggle>
      <label className="switch">
        <input type="checkbox" checked={!!isPartyMode} onChange={handleChange} />
        <div className="slider">
          <img className="logo" src={!!isPartyMode ? DiscoBall : lmail_logo} alt="Lmail Logo" />
        </div>
        <span className={!!isPartyMode ? "toggle-label-party" : "toggle-label"}>{!!isPartyMode ? "PARTY" : "PTW"}</span>
      </label>
    </Toggle>
  );
};

export default Header;
