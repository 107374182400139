import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useCustomWeb3React } from '../../providers/CustomWeb3ReactProvider';
import LazyLoad from "react-lazyload";
import { useNavigate } from 'react-router-dom';

import { Layout, LoaderWithSparkles } from "../../components";
import { useFinapeNftContract } from "../../hooks/useContract";
import { useToggleWalletModal } from "../../state/application/hooks";
import { formatBigNumber } from "../../utils/currency";
import { MouseoverTooltip } from '../../components/Tooltip'
import { padNumber } from "../../utils/helpers";
import { isMobile } from '../../utils/userAgent';
import { Buttons } from '../../components';
import Alert from "../../components/Alert";
import ImageGallery from "../../components/ImageGallery";

import NftDetailsModal from "../NFTExplorer/NftDetailsModal"; 

import nft_placeholder from "../../assets/images/nft/placeholder.jpg";

import {
  Container,
  GalleryContainer,
  NotFound,
  MintButton,
  LoaderContainer,
} from "./styled";

const MyNFTs = () => {
  const { account, chainId } = useCustomWeb3React();
  const toggleWalletModal = useToggleWalletModal();
  const finapeNftContract = useFinapeNftContract();
  const navigate = useNavigate();


  const [notRevealed, setNotRevealed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [viewTokenId, setViewTokenId] = useState(null);
  const [data, setData] = useState([]);
  const [ownedTokenIds, setOwnedTokenIds] = useState([]);
  const [totalResults, setTotalResults] = useState(0);

  const [nftOwned, setNftOwned] = useState(0);


  const addTokenId = (tokenId) => {
    setOwnedTokenIds((prevIds) => [...prevIds, tokenId]);
  };
  const clearTokenIds = () => {
    setOwnedTokenIds([]);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!isLoading) {
        setIsLoading(true); 

        let _balanceOf = await finapeNftContract.balanceOf(account);
        let _count = parseFloat(formatBigNumber(_balanceOf, 0)).toFixed(0);
        setNftOwned(_count);

        setData([]);
        let _ownedTokenIds = []; 
        for (let i = 0; i < _count; i++) {
          let _tokenId = await finapeNftContract.tokenOfOwnerByIndex(account, i);
          _tokenId = parseFloat(formatBigNumber(_tokenId, 0)).toFixed(0);
          _ownedTokenIds.push(_tokenId);
        }

        let _ownedTokenUris = []; 
        for (let i = 0; i < _ownedTokenIds.length; i++) {
          let _tokenUri = await finapeNftContract.tokenURI(_ownedTokenIds[i]);
          _tokenUri = _tokenUri.replace("ipfs://", "https://nft.finape.com/ipfs/");
          _ownedTokenUris.push(_tokenUri);
        }

        let _tokenToSequenceIds = []; 
        for (let i = 0; i < _ownedTokenUris.length; i++) {
          let _tokenId = await finapeNftContract.tokenOfOwnerByIndex(account, i);
          _tokenId = parseFloat(formatBigNumber(_tokenId, 0)).toFixed(0);
          _ownedTokenIds.push(_tokenId);
        }

        let _metadatas = []; 
        Promise.all(
          _ownedTokenUris.map((tokenUri, i) => {
            return fetch(tokenUri)
              .then(response => response.json())
              .then(metadata => {
                metadata.tokenId = _ownedTokenIds[i];

                if (metadata.animation_url != null) {
                  metadata.notRevealed = true
                } else {
                  metadata.notRevealed = false
                }

                /*
                if (tokenUri.endsWith('QmNayf4taUzVkdVnuhUaTkUPx8w2LCUNwjxufVi3F99v9P')) {
                  metadata.sequenceId = -1;
                } else {
                  // get sequence Id 
                  const parts = tokenUri.split('/');
                  const number = parts[parts.length - 1];
                  metadata.sequenceId = number;
                }
                */

                return metadata;
              })
              .catch(error => {
                console.error(`Error fetching metadata for token URI ${tokenUri}:`, error);
              });
          })
        ).then(metadataArray => {
          metadataArray.sort((a, b) => a.tokenId - b.tokenId);
          setData(metadataArray.filter(metadata => metadata !== undefined));
        });

        setIsLoading(false); 
      }
    };

    if (finapeNftContract && account) {
      fetchData();
    }
    if (!account) {
      setNftOwned(0);
    }
  }, [finapeNftContract, account]);

  if (false) console.log(error);

  const viewNftDetails = (tokenId, _notRevealed) => {
    setModalIsOpen(true);
    setViewTokenId(tokenId);
    setNotRevealed(_notRevealed)
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const renderItem = (item) => {
    let formattedNumber = String(item?.tokenId).padStart(4, '0');
    let formattedName = `LOLApe #${formattedNumber}`;

    return (
      <div key={item.id} className="select-nft__item">
        <div
          className="card__article"
          onClick={() => viewNftDetails(item.tokenId, item.notRevealed)}
        >
          <div className="card__figure">
            <LazyLoad
              key={item.id}
              height={200}
              once
              placeholder={
                <img
                  src={nft_placeholder}
                  alt={"pl" + item?.tokenId}
                />
              }
            >
              {item?.animation_url ? (
                <video autoPlay loop width="200" height="200">
                  <source src={item?.animation_url.replace("ipfs://", "https://nft.finape.com/ipfs/")} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  src={item?.image.replace("ipfs://", "https://nft.finape.com/ipfs/") || nft_placeholder}
                  //src={item?.tokenId >= 0 ? `https://file.finape.com/nft-compressed/finape-${item?.tokenId}.jpg` : nft_placeholder}
                  alt={item?.tokenId}
                />
              )}
            </LazyLoad>
          </div>
          <div className="card__body">

            {item?.tokenId >= 0 ? (
              <MouseoverTooltip
                text={
                  <span>
                    The actual NFT will be revealed after each stage is sold out. Please refer to the provenance page for more information.
                  </span>
                }
                placement="bottom"
              >
                <div className="card__code">
                  {formattedName}
                </div>
              </MouseoverTooltip>
            ) : (
              <MouseoverTooltip
                text={
                  <span>
                    The sequence ID has not revealed. The final sequence ID will be assigned randomly.
                  </span>
                }
                placement="bottom"
              >
                <div className="card__code">
                  LOLApe #????
                </div>
              </MouseoverTooltip>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Layout>
      <Container>
        <div className="gallery__section">
          <h1>Your LOLApe NFTs{nftOwned > 0 && (<>{' '}({nftOwned})</>)}</h1>
          
          <Alert variant="info">
            Please note that it may take some time for newly minted NFTs to appear, depending on network conditions.
          </Alert>
          <br/>


          {!account ? (
            <GalleryContainer>
              <NotFound>
                Connect your wallet
                <br/>
                <br/>
                <Buttons.Connect onClick={toggleWalletModal}>Connect</Buttons.Connect>
              </NotFound>
            </GalleryContainer>
          ) : nftOwned <= 0 ? (
            <GalleryContainer>
              <NotFound>
                No items found. 
                <br/>
                <br/>
                {false && <MintButton onClick={() => navigate("/minting")}>Mint a LOLApe NFT</MintButton> }
              </NotFound>
            </GalleryContainer>
          ) : (!data || isLoading) ? (
            <GalleryContainer>
              <LoaderContainer>
                <LoaderWithSparkles stroke="#fff909" fill="#ffffff" strokeWidth="5"/>
              </LoaderContainer>
            </GalleryContainer>
          ) : (
            <ImageGallery 
              items={data} 
              renderItem={renderItem} 
              totalResults={totalResults}
              defaultWidth={isMobile ? 160 : 200}
              skipResize={modalIsOpen}
              style={{paddingBottom: 50}}
            />
          )}
        </div>

        <NftDetailsModal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          queryBy={"tokenId"}
          id={viewTokenId}
          notRevealed={notRevealed}
          showBridgeButton={true}
          showMintButton={true}
        />
      </Container>
    </Layout>
  );
};

export default MyNFTs;
