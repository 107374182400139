import styled from "styled-components";

const Button = styled.button`
  width: 200px;
  background-color: #157bef;
  border: 1px solid #157bef; 
  color: #fff;
  text-align: center;
  font-size: 18px;
  line-height: 18px;
  border-radius: 10px;
  padding: 10px;
  display: inline-block;
  margin: 0 auto;
  vertical-align: top;
  cursor: pointer;

  &[data-variant="mint"] {
    color: #000;
    width: 120px; 
    font-size: 14px; 
    margin-right: 10px; 
    margin-top: 10px; 
    background-color: rgb(65, 218, 126);
    border: none; 

    &:hover {
      color: #000;
      background-color: rgb(65, 218, 126);
      border: none; 
    }
  }

  &[data-variant="fullWidth"] {
    width: 100%;
  }

  &[data-variant="back"] {
    background-color: #ccc;
    border: 1px solid #999; 
    color: #000;
    border-radius: 0;

    &:hover {
      background-color: #ddd;
      color: #000;
    }
  }

  &:hover {
    background-color: #157bef;
    border: 1px solid #000; 
    color: #fff;
  }
`;

const BasicV2 = ({ children, ...props}) => {
  const {
    title = "Connect",
    onClick,
    disabled,
    type = "button",
    className,
    variant,
    style,
    icon,
  } = props;

  return (
    <Button
      className={`${className}`}
      style={style}
      data-variant={variant}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {icon ? (
        <>
          <span className="title-icon">{title}</span>
          <span className="box-icon">{icon}</span>
        </>
      ) : title ? (
        <span>{title}</span>
      ) : (
        {children}
      )}
    </Button>
  );
};

export default BasicV2;
