import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useCustomWeb3React } from '../../providers/CustomWeb3ReactProvider';

import useIsWindowVisible from '../../hooks/useIsWindowVisible';
import { SupportedChainId } from "../../constants/chains";

const MISSING_PROVIDER = Symbol();
const BlockNumberContext = createContext(
  MISSING_PROVIDER
);

function useBlockNumberContext() {
  const blockNumber = useContext(BlockNumberContext);
  if (blockNumber === MISSING_PROVIDER) {
    throw new Error('BlockNumber hooks must be wrapped in a <BlockNumberProvider>');
  }
  return blockNumber;
}

/** Requires that BlockUpdater be installed in the DOM tree. */
export default function useBlockNumber() {
  return useBlockNumberContext().value;
}

export function useFastForwardBlockNumber() {
  return useBlockNumberContext().fastForward;
}

export function BlockNumberProvider({ children }) {
  const { chainId: activeChainId, provider } = useCustomWeb3React();
  const [{ chainId, block }, setChainBlock] = useState({ chainId: activeChainId });

  const onBlock = useCallback(
    (block) => {
      setChainBlock((chainBlock) => {
        if (chainBlock.chainId === activeChainId) {
          if (!chainBlock.block || chainBlock.block < block) {
            return { chainId: activeChainId, block };
          }
        }
        return chainBlock;
      });
    },
    [activeChainId, setChainBlock]
  );

  const windowVisible = useIsWindowVisible();
  useEffect(() => {
    let stale = false;

    if (chainId != SupportedChainId.BITCOIN && chainId != SupportedChainId.SOLANA && provider && activeChainId && windowVisible) {
      // If chainId hasn't changed, don't clear the block. This prevents re-fetching still valid data.
      setChainBlock((chainBlock) => (chainBlock.chainId === activeChainId ? chainBlock : { chainId: activeChainId }));

      try {
        provider
          .getBlockNumber()
          .then((block) => {
            if (!stale) onBlock(block);
          })
          .catch((error) => {
            console.error(`Failed to get block number for chainId ${activeChainId}`, error);
          });

        provider.on('block', onBlock);
      } catch(err) {

      }
      return () => {
        stale = true;
        provider.removeListener('block', onBlock);
      };
    }

    return;
  }, [activeChainId, provider, onBlock, setChainBlock, windowVisible]);

  const value = useMemo(
    () => ({
      value: chainId === activeChainId ? block : undefined,
      fastForward: (update) => {
        if (block && update > block) {
          setChainBlock({ chainId: activeChainId, block: update });
        }
      },
    }),
    [activeChainId, block, chainId]
  );

  return <BlockNumberContext.Provider value={value}>{children}</BlockNumberContext.Provider>;
}
