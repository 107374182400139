import React, {
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import {
	InfiniteLoader,
	Grid,
	AutoSizer,
	WindowScroller,
	ColumnSizer,
	CellMeasurerCache,
	CellMeasurer,
	Index,
} from "react-virtualized";
import useMeasure from "react-use-measure";
import { ResizeObserver } from "@juggle/resize-observer";
import styled from "styled-components";

import LoaderWithSparkles from "../LoaderWithSparkles";


const LoaderContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 300px;
  height: 100%;

  @media only screen and (max-width: 1023px) {
    margin-top: 100px;
  }
`;

const InfiniteGridItem = ({
  renderItem,
  gridItem,
  reCalculateGrid,
  rowIndex,
  columnIndex,
  parent,
  measure
}) => {
  const [rowRef, { height }] = useMeasure({ polyfill: ResizeObserver });

  useLayoutEffect(() => {
    reCalculateGrid(
      rowIndex,
      columnIndex,
      parent._scrollingContainer ? measure : () => {}
    );
  }, [
    height,
    columnIndex,
    measure,
    parent._scrollingContainer,
    reCalculateGrid,
    rowIndex
  ]);

  return <div ref={rowRef}>{renderItem(gridItem, rowIndex)}</div>;
};

const InfiniteGrid = ({
	items,
	defaultHeight = 300,
	defaultWidth = 200,
	loadMoreItems,
	totalResults,
	overscanRowCount = 10,
	defaultColumnCount = 4,
	renderItem,
	style,
	skipResize = false,
}) => {
  const cache = useMemo(
    () =>
      new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight
      }),
    [defaultHeight]
  );

  const onResize = () => {
  	if (!skipResize) cache.clearAll();
  };

	const isRowLoaded = ({ index }) => {
		//console.log("--->", index, !!items[index])
		return !!items[index];
	};

	const loadMoreRows = ({ startIndex, stopIndex }) => {
		//console.log("loadMoreRows", startIndex, stopIndex);
		if (loadMoreItems) {
			//console.log("loadMoreRows now")
			loadMoreItems();
		}
	};

  const reCalculateGrid = (
    rowIndex,
    columnIndex,
    measure
  ) => {
    cache.clear(rowIndex, columnIndex);
    measure();
  };

  const cellRenderer = (
    { rowIndex, columnIndex, style, key, parent },
    columnCount
  ) => {
    const index = rowIndex * columnCount + columnIndex;
    const gridItem = items?.[index];

    if (!gridItem || !renderItem) return null;

    return (
      <CellMeasurer
        key={key}
        cache={cache}
        parent={parent}
        columnIndex={columnIndex}
        rowIndex={rowIndex}
      >
        {({ registerChild, measure }) => (
          <div
            ref={registerChild}
            style={{
              ...style,
            }}
            key={key}
          >
            <InfiniteGridItem
              renderItem={renderItem}
              gridItem={gridItem}
              reCalculateGrid={reCalculateGrid}
              rowIndex={rowIndex}
              columnIndex={columnIndex}
              parent={parent}
              measure={measure}
            />
          </div>
        )}
      </CellMeasurer>
    );
  };

	const infiniteLoaderRender = () => (
		<InfiniteLoader
      isRowLoaded={isRowLoaded}
      loadMoreRows={loadMoreRows}
      rowCount={totalResults}
      threshold={10}
    >
      {({ onRowsRendered, registerChild }) => (
        <WindowScroller>
          {({ height, isScrolling, scrollTop }) => (
            <AutoSizer onResize={onResize}>
              {({ width }) => {
              	const columnCount = Math.min(Math.max(Math.floor(width / defaultWidth), 1), defaultColumnCount);
              	return (
	                <Grid
	                	autoHeight
	                	cellRenderer={(gridCellProps) =>
                      cellRenderer(gridCellProps, columnCount)
                    }
									  columnCount={columnCount}
									  columnWidth={width / columnCount}
									  height={height}
									  rowCount={Math.ceil(items.length / columnCount)}
									  rowHeight={width / columnCount + 55}
									  width={width}
									  style={style}
									  scrollTop={scrollTop}
									  ref={registerChild}
									  onSectionRendered={({
	                    rowStartIndex,
	                    rowStopIndex,
	                    columnStartIndex,
	                    columnStopIndex,
	                  }) => {
	                    const startIndex =
	                      rowStartIndex * columnCount +
	                      columnStartIndex;
	                    const stopIndex =
	                      rowStopIndex * columnCount +
	                      columnStopIndex;
	                    return onRowsRendered({
	                      startIndex,
	                      stopIndex,
	                    });
	                  }}
									/>
	              )
              }}
            </AutoSizer>
          )}
        </WindowScroller>
      )}
    </InfiniteLoader>
	);

	const shouldRenderLoader =
		!(items && items.length === totalResults) &&
		loadMoreItems &&
		items &&
		items.length > 0;

	const renderBottom = () => {
		if (shouldRenderLoader) {
			return (
				<LoaderContainer>
          <LoaderWithSparkles stroke="#fff909" fill="#ffffff" strokeWidth="5"/>
        </LoaderContainer>
			);
		}
		return null;
	};

	return (
		<>
			{infiniteLoaderRender()}
		</>
	);
};

export default InfiniteGrid;
