import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Web3ReactProvider } from '@web3-react/core';
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  //MathWalletAdapter,
} from "@solana/wallet-adapter-wallets";

import { getConnectionName } from '../../connection';
import useEagerlyConnect from '../../hooks/useEagerlyConnect';
import useOrderedConnections from '../../hooks/useOrderedConnections';

import { SupportedChainId } from '../../constants/chains';
import { RPC_URLS } from '../../constants/networks';

export default function Web3Provider({ children }) {
  useEagerlyConnect();
  const connections = useOrderedConnections();
  const connectors = connections.map(({ hooks, connector }) => [connector, hooks]);

  const key = useMemo(() => connections.map(({ type }) => getConnectionName(type)).join('-'), [connections]);

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter(),
      //new MathWalletAdapter(),
    ],
    []
  );
  const endpoint = useMemo(() => RPC_URLS[SupportedChainId.SOLANA][0], []);

  return (
    <Web3ReactProvider connectors={connectors} key={key}>
      <ConnectionProvider endpoint={endpoint}>
          <WalletProvider wallets={wallets} autoConnect>
            {children}
          </WalletProvider>
      </ConnectionProvider>
    </Web3ReactProvider>
  );
}

Web3Provider.propTypes = {
  children: PropTypes.node,
};

Web3Provider.defaultProps = {
  children: null,
};
