import React from "react";
import PropTypes from "prop-types";

const Repeat = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19.8333 1.16675L24.4999 5.83341L19.8333 10.5001"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 12.8333V10.4999C3.5 9.26224 3.99167 8.07526 4.86683 7.20009C5.742 6.32492 6.92899 5.83325 8.16667 5.83325H24.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.16667 26.8333L3.5 22.1667L8.16667 17.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.5 15.1667V17.5001C24.5 18.7378 24.0083 19.9247 23.1332 20.7999C22.258 21.6751 21.071 22.1667 19.8333 22.1667H3.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

Repeat.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export default Repeat;
