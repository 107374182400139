import styled, { keyframes } from "styled-components";

export const HeaderTag = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  z-index: 9;

  @media only screen and (min-width: 1024px) {
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .header {
    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media only screen and (min-width: 768px) {
        margin-bottom: 0;
      }
    }

    &__logo {
      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
      @media only screen and (max-width: 599px) {
        max-width: 140px;
      }
      @media only screen and (max-width: 1023px) {
        width: 180px;
      }
    }

    &__menu {
      display: none;
      align-items: center;

      @media only screen and (max-width: 767px) {
        position: fixed;
        flex-direction: column;
        top: 103px;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
        padding: 32px 22px;
        background-color: var(--color-white);
        z-index: 15;
      }

      &.open {
        overflow-y: auto;
        @media only screen and (max-width: 1023px) {
          display: flex;
        }
      }

      @media only screen and (min-width: 768px) {
        display: flex;
      }

      @media only screen and (min-width: 1440px) {
        margin-left: 0;
      }

      &__mint {
        position: relative;
        cursor: pointer;
        margin-right: 5px;

        @media only screen and (min-width: 1440px) {
          margin-right: 15px;
        }

        @media only screen and (max-width: 1023px) {
          margin-bottom: 28px;
        }

        &:hover {
          .header__menu__mint__list {
            display: block;
          }
        }

        &__link {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px 15px;
          font-family: var(--font-sforotext);
          font-size: 16px;
          line-height: 17px;
          font-weight: 600;
          color: var(--color-white);
          letter-spacing: -0.02em;
          background-color: #41da7e;
          border-radius: 8px;
          width: 98px;
          height: 50px;
          color: #000;

          @media only screen and (max-width: 1023px) {
            min-width: 155px;
            height: 54px;
            font-size: 28px;
            line-height: 28px;
          }

          span {
            margin-right: 8px;
          }
        }

        &__list {
          display: none;
          position: absolute;
          padding-top: 7px;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          width: 146px;
          z-index: 1;

          @media only screen and (max-width: 1023px) {
            width: 206px;
          }

          ul {
            list-style: none;
            padding: 0;
            margin: 0;
            background-color: var(--color-white);
            border: 1px solid #e8eaef;
            box-shadow: 0px 12px 14px rgba(16, 23, 30, 0.24);
            border-radius: 8px;
          }

          li {
            padding: 14px;
            margin: 0;
            text-align: center;

            @media only screen and (max-width: 1023px) {
              padding: 17px;
            }

            &:not(:first-child) {
              border-top: 1px solid #e8eaef;
            }
          }

          a {
            font-family: var(--font-sforotext);
            font-size: 16px;
            line-height: 17px;
            font-weight: 600;
            letter-spacing: -0.02em;
            color: #323232;
            text-decoration: none;
            transition: all 0.2s ease;

            @media only screen and (max-width: 1023px) {
              font-size: 20px;
              line-height: 20px;
            }

            &:hover {
              color: #1880ff;
            }
          }
        }

        &__link2 {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px 15px;
          font-family: var(--font-sforotext);
          font-size: 16px;
          line-height: 17px;
          font-weight: 600;
          color: var(--color-white);
          letter-spacing: -0.02em;
          background-color: #41da7e;
          border-radius: 8px;
          width: 98px;
          height: 50px;
          text-decoration: none; 
          //border: 1px solid #ccc;

          @media only screen and (max-width: 1023px) {
            min-width: 155px;
            height: 54px;
            font-size: 28px;
            line-height: 28px;
          }

          span {
            color: #000;
            text-decoration: none; 
          }

          &:before {
            content: '';
            background: linear-gradient(45deg, #ff5252, #f73d6c, #9b59b6, #2980b9, #0077b6, #0047ab, #f1c40f, #e67e22, #c0392b);
            position: absolute;
            top: -1px;
            left:-1px;
            background-size: 400%;
            z-index: -1;
            filter: blur(5px);
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            animation: glowing 20s linear infinite;
            opacity: 0;
            transition: opacity .3s ease-in-out;
            border-radius: 8px;
          }

          &:active {
            color: #000
          }

          &:active:after {
            background: transparent;
          }

          &:hover:before {
            opacity: 0;
          }

          &:after {
            z-index: -1;
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            border-radius: 8px;
          }

          @keyframes glowing {
            0% { background-position: 0 0; }
            50% { background-position: 400% 0; }
            100% { background-position: 0 0; }
          }
        }
      }
    }

    &__nav {
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;

        @media only screen and (max-width: 1023px) {
          flex-direction: column;
        }

        li {
          margin: 0;
          padding: 0 10px;

          @media only screen and (min-width: 1440px) {
            padding-left: 20px;
            padding-right: 20px;
          }

          @media only screen and (max-width: 1023px) {
            &:not(:last-child) {
              margin-bottom: 28px;
            }
          }
        }
      }

      a {
        font-family: var(--font-gilroy);
        font-size: 20px;
        line-height: 20px;
        font-weight: 600;
        color: #fff;
        text-decoration: none;
        transition: all 0.2s ease;
        padding: 0.25em 0.5em;

        @media only screen and (max-width: 1023px) {
          font-size: 28px;
          line-height: 33px;
        }

        &.active {
          color: #1880ff;
          font-weight: 700;
        }

        &:hover {
          color: #1880ff;
        }
      }
    }

    &__social {
      display: none;
      margin-top: auto;

      @media only screen and (max-width: 767px) {
        display: block;
      }
    }

    &__btns {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: space-between;
      margin-left: -4px;
      margin-right: -4px;
      gap: 10px;

      @media only screen and (min-width: 768px) {
        justify-content: center;
      }

      @media only screen and (min-width: 1440px) {
        margin-left: -7px;
        margin-right: -7px;
      }

      &__item {
        padding-left: 4px;
        padding-right: 4px;
        flex: 0 0 50%;
        max-width: 50%;

        @media only screen and (min-width: 768px) {
          flex: 0 0 auto;
          max-width: none;
        }

        @media only screen and (min-width: 1440px) {
          padding-left: 7px;
          padding-right: 7px;
        }
      }
    }
  }

  .burger {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    cursor: pointer;
    transition: all 0.2s ease-in;

    @media only screen and (min-width: 1024px) {
      display: none;
    }

    &.active {
      .burger__row {
        height: 16px;

        span {
          &:nth-child(1) {
            left: 2px;
            transform: rotate(45deg);
            transform-origin: left;
          }

          &:nth-child(2) {
            left: -100%;
          }

          &:nth-child(3) {
            left: 2px;
            transform: rotate(-45deg);
            transform-origin: left;
          }
        }
      }
    }

    &__row {
      position: relative;
      flex: 0 0 20px;
      max-width: 20px;
      height: 14px;
      overflow: hidden;

      span {
        position: absolute;
        left: 0;
        width: 100%;
        height: 2px;
        border-radius: 1px;
        background-color: #323030;
        transition: all 0.3s ease-in;

        &:nth-child(1) {
          top: 0;
        }

        &:nth-child(2) {
          top: 6px;
        }

        &:nth-child(3) {
          bottom: 0;
        }
      }
    }
  }

  .u-btn {
    @media only screen and (max-width: 767px) {
      width: 100%;
      height: 40px;
    }
  }

  @media only screen and (max-width: 599px) {
    .chainSelectorButton {
      display: none;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
  }

  @media only screen and (min-width: 1440px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

export const ConnectButton = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 21px;
  height: 48px;
  font-family: "Gilroy", sans-serif;
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  letter-spacing: -0.02em;
  color: #fff;
  box-shadow: none;
  background-color: #157bef;
  text-transform: none;
  text-decoration: none;
  outline: none;
  appearance: none;
  transition: all 0.3s ease;
  border: none;
  border-radius: 8px;
  cursor: pointer;

  @media only screen and (max-width: 767px) {
    padding: 11px 16px;
    width: 100%;
    height: 40px;
  }

  &:hover {
    background-color: #244ca8;
  }
`; 