import Telegram from "./Telegram";
import Twitter from "./Twitter";
import Instagram from "./Instagram";
import Discord from "./Discord";
import Facebook from "./Facebook";
import Youtube from "./Youtube";
import Wallet from "./Wallet";
import Suitcase from "./Suitcase";
import Rewards from "./Rewards";
import CheckMark from "./CheckMark";
import ChevronDown from "./ChevronDown";
import ChevronUp from "./ChevronUp";
import Search from "./Search";
import Sparkles from "./Sparkles";
import Close from "./Close";
import Sphere from "./Sphere";
import Link from "./Link";
import Lock from "./Lock";
import Repeat from "./Repeat";
import TokenWarningRedIcon from "./TokenWarningRedIcon";
import Nft from "./Nft";
import RightArrow from "./RightArrow";

const Icons = {
  Telegram,
  Twitter,
  Instagram,
  Discord,
  Facebook,
  Youtube,
  Wallet,
  Suitcase,
  Rewards,
  CheckMark,
  ChevronDown,
  ChevronUp,
  Search,
  Close,
  Sphere,
  Link,
  Lock,
  Repeat,
  TokenWarningRedIcon,
  Nft,
  Sparkles,
  RightArrow,
};

export default Icons;
