import { useCallback, useEffect, useRef, useState } from 'react'
import { useCustomWeb3React } from '../../providers/CustomWeb3ReactProvider';
import { useTheme } from 'styled-components/macro'
import styled from 'styled-components'

import { getChainInfo } from '../../constants/chainInfo'
import { SupportedChainId } from '../../constants/chains'
import { useIsMobile } from '../../hooks/useIsMobile'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'

import { Portal, Icons } from "../../components";

import ChainSelectorRow from './ChainSelectorRow'

const NETWORK_SELECTOR_CHAINS = [
  SupportedChainId.BITCOIN,
  SupportedChainId.SOLANA,
  SupportedChainId.BASE,
  SupportedChainId.MAINNET,
  SupportedChainId.BNBCHAIN,
  SupportedChainId.POLYGON,
  SupportedChainId.ARBITRUM_ONE,
  SupportedChainId.AVAX,
  //SupportedChainId.KLAYTN,
  //SupportedChainId.OPTIMISM,
  //SupportedChainId.CELO,
]

const Container = styled.div`
  position: relative;

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;

const StyledRow = styled.div`
  border: 1px solid #c1cbd9;
  border-radius: 8px; 
  padding: 4px 21px;
  height: 36px;
  cursor: pointer; 
  background-color: #fff; 
  font-size: 16px;
  display: flex; 
  flex-direction: row; 
  align-items: center;
  row-gap: 8px;
  gap: 8px;
  vertical-align: baseline;

  @media only screen and (max-width: 767px) {
    width: 100%;
    height: 36px;
  }

  &:hover {
    border-color: #244ca8;
  }

  .image {
    width: 20px; 
    height: 20px;
  }
`;

const NavDropDownContainer = styled.div`
  background-color: #fff;
  border: 1px solid #e8eaef;
  box-shadow: 0px 12px 14px rgba(16, 23, 30, 0.24);
  padding: 8px;

  &.navDropdown {
    position: absolute;
    border-radius: 12px;
    top: 40px; 
    right: 0;
    z-index: 1060;
  }

  &.mobileNavDropdown {
    position: fixed;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    top: unset;
    bottom: 0; 
    left: 0; 
    right: 0;
    width: 100%;
    border-right-width: 0; 
    border-left-width: 0; 
  }
`;

/*
export const ChainSelector = style([
  lightGrayOverlayOnHover,
  sprinkles({
    borderRadius: '8',
    height: '40',
    cursor: 'pointer',
    border: 'none',
    color: 'textPrimary',
    background: 'none',
  }),
]);

export const Image = style([
  sprinkles({
    width: '20',
    height: '20',
  }),
]);

*/

const ChainSelector = ({ leftAlign, preselectChainId, onSelectChain, excludeChainId, onlyIncludeChainIds,  includeTestnet, returnFirstChoice, style }) => {
  const { chainId } = useCustomWeb3React()
  const isMobile = useIsMobile()
  const theme = useTheme()
  const ref = useRef(null)
  const modalRef = useRef(null)
  useOnClickOutside(ref, () => setIsOpen(false), [modalRef])

  const [isOpen, setIsOpen] = useState(false)
  const [info, setInfo] = useState(null)
  const [availableChains, setAvailableChains] = useState(NETWORK_SELECTOR_CHAINS)
  const [selectedChainId, setSelectedChainId] = useState(0);

  useEffect(() => {
    setSelectedChainId(availableChains[0]); 
  }, []);

  useEffect(() => {
    if (includeTestnet) {
      if (!availableChains.includes(SupportedChainId.POLYGON_MUMBAI)) {
        availableChains.push(SupportedChainId.POLYGON_MUMBAI);
      }
      if (!availableChains.includes(SupportedChainId.SEPOLIA)) {
        availableChains.push(SupportedChainId.SEPOLIA);
      }
      setAvailableChains(availableChains)
    } else {
      setAvailableChains(NETWORK_SELECTOR_CHAINS)
    }
  }, [includeTestnet]);

  useEffect(() => {
    if (excludeChainId) {
      setAvailableChains(availableChains.filter(chainId => chainId !== excludeChainId));
    }
  }, [excludeChainId]);

  useEffect(() => {
    if (onlyIncludeChainIds && onlyIncludeChainIds.length > 0) {
      setAvailableChains(onlyIncludeChainIds);

      const info = onlyIncludeChainIds.includes(chainId) ? getChainInfo(chainId) : undefined
      setInfo(info)
    } else {
      const info = selectedChainId ? getChainInfo(selectedChainId) : undefined
      setInfo(info)
    }
  }, [onlyIncludeChainIds, selectedChainId, chainId]);

  useEffect(() => {
    setSelectedChainId(preselectChainId); 
  }, [preselectChainId]);

  useEffect(() => {
    if (returnFirstChoice && availableChains) {
      setSelectedChainId(availableChains[0]); 
      onSelectChain(availableChains[0]);
    }
  }, [returnFirstChoice, availableChains]);
  
  useEffect(() => {
  }, [selectedChainId, chainId]);

  const handleSelectChain = (_selectedChain) => {
    setSelectedChainId(_selectedChain)
    setIsOpen(false)
    onSelectChain(_selectedChain)
  }

  if (!chainId) {
    return null
  }

  const dropdown = (
    <NavDropDownContainer 
      style={{ left: leftAlign ? '0' : 'auto', right: leftAlign ? 'auto' : '0' }} 
      ref={modalRef}
      className={isMobile ? "mobileNavDropdown" : "navDropdown"}
    >
        {availableChains.map((_chainId) => (
          <ChainSelectorRow
            onSelectChain={handleSelectChain}
            targetChain={_chainId}
            key={_chainId}
          />
        ))}
    </NavDropDownContainer>
  )

  const chevronProps = {
    height: 20,
    width: 20,
    color: theme.textSecondary,
  }

  return (
    <Container ref={ref} style={style}>
      <StyledRow
        data-testid="chain-selector"
        onClick={() => setIsOpen(!isOpen)}
      >
        {!(!!info) ? (
          <>
            <Icons.TokenWarningRedIcon fill="#999999" width={24} height={24} />
            <span style={{ lineHeight: '20px' }}>
              Unsupported
            </span>
          </>
        ) : (
          <>
            <img src={info?.logoUrl} alt={info?.label} className="image" />
            <span style={{ lineHeight: '20px', flex: 1 }}>
              {info?.label}
            </span>
          </>
        )}
        {isOpen ? <Icons.ChevronUp {...chevronProps} /> : <Icons.ChevronDown {...chevronProps} />}
      </StyledRow>
      {isOpen && (isMobile ? <Portal>{dropdown}</Portal> : <>{dropdown}</>)}
    </Container>
  )
}

export default ChainSelector;
