import { useCallback, useMemo } from 'react';
import { useCustomWeb3React } from '../../providers/CustomWeb3ReactProvider';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { addTransaction } from './reducer';
import { TransactionType } from './types';

// helper that can take a ethers library transaction response and add it to the list of transactions
export function useTransactionAdder() {
  const { chainId, account } = useCustomWeb3React();
  const dispatch = useAppDispatch();

  return useCallback((response, info) => {
    if (!account) return;
    if (!chainId) return;

    const { hash } = response;
    if (!hash) {
      throw Error('No transaction hash found.');
    }
    dispatch(addTransaction({ hash, from: account, info, chainId }));
  }, [account, chainId, dispatch]);
}

// returns all the transactions for the current chain
export function useAllTransactions() {
  const { chainId } = useCustomWeb3React();
  const state = useAppSelector((state) => state.transactions);
  return chainId ? state[chainId] ?? {} : {};
}

export function useTransaction(transactionHash) {
  const allTransactions = useAllTransactions();

  if (!transactionHash) {
    return undefined;
  }

  return allTransactions[transactionHash];
}

export function useIsTransactionPending(transactionHash) {
  const transactions = useAllTransactions();

  if (!transactionHash || !transactions[transactionHash]) return false;

  return !transactions[transactionHash].receipt;
}

export function useIsTransactionConfirmed(transactionHash) {
  const transactions = useAllTransactions();

  if (!transactionHash || !transactions[transactionHash]) return false;

  return Boolean(transactions[transactionHash].receipt);
}

/**
 * Returns whether a transaction happened in the last day (86400 seconds * 1000 milliseconds / second)
 * @param tx to check for recency
 */
export function isTransactionRecent(tx) {
  return new Date().getTime() - tx.addedTime < 86400000;
}

// returns whether a token has a pending approval transaction
export function useHasPendingApproval(token, spender) {
  const allTransactions = useAllTransactions();
  return useMemo(() => typeof token?.address === 'string' &&
    typeof spender === 'string' &&
    Object.keys(allTransactions).some((hash) => {
      const tx = allTransactions[hash];
      if (!tx) return false;
      if (tx.receipt) {
        return false;
      } else {
        if (tx.info.type !== TransactionType.APPROVAL) return false;
        return tx.info.spender === spender && tx.info.tokenAddress === token.address && isTransactionRecent(tx);
      }
    }), [allTransactions, spender, token?.address]);
}
