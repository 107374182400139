import { useCustomWeb3React } from '../../providers/CustomWeb3ReactProvider';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getCLS, getFCP, getFID, getLCP, Metric } from 'web-vitals';

import { isMobile } from '../../utils/userAgent';

import GoogleAnalyticsProvider from './GoogleAnalyticsProvider';

const GOOGLE_ANALYTICS_CLIENT_ID_STORAGE_KEY = 'ga_client_id';
const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

const storedClientId = window.localStorage.getItem(
  GOOGLE_ANALYTICS_CLIENT_ID_STORAGE_KEY
);

const googleAnalytics = new GoogleAnalyticsProvider();

function sendEvent(event, params) {
  return googleAnalytics.sendEvent(event, params);
}

function outboundLink({ label }, hitCallback) {
  return googleAnalytics.outboundLink({ label }, hitCallback);
}

function sendTiming(timingCategory, timingVar, timingValue, timingLabel) {
  return googleAnalytics.gaCommandSendTiming(
    timingCategory,
    timingVar,
    timingValue,
    timingLabel
  );
}

if (typeof GOOGLE_ANALYTICS_ID === 'string') {
  googleAnalytics.initialize(GOOGLE_ANALYTICS_ID, {
    gaOptions: {
      storage: 'none',
      storeGac: false,
      clientId: storedClientId ?? undefined,
    },
  });
  googleAnalytics.set({
    anonymizeIp: true,
    customBrowserType: !isMobile
      ? 'desktop'
      : 'web3' in window || 'ethereum' in window
      ? 'mobileWeb3'
      : 'mobileRegular',
  });
} else {
  googleAnalytics.initialize('test', { gtagOptions: { debug_mode: true } });
}

const installed = Boolean(window.navigator.serviceWorker?.controller);
const hit = Boolean((window).__isDocumentCached);
const action = installed ? (hit ? 'Cache hit' : 'Cache miss') : 'Not installed';
sendEvent({ category: 'Service Worker', action, nonInteraction: true });

function reportWebVitals({ name, delta, id }) {
  sendTiming(
    'Web Vitals',
    name,
    Math.round(name === 'CLS' ? delta * 1000 : delta),
    id
  );
}

function useAnalyticsReporter() {
  const { pathname, search } = useLocation();
  useEffect(() => {
    getFCP(reportWebVitals);
    getFID(reportWebVitals);
    getLCP(reportWebVitals);
    getCLS(reportWebVitals);
  }, []);

  const { chainId } = useCustomWeb3React();
  useEffect(() => {
    // cd1 - custom dimension 1 - chainId
    googleAnalytics.set({ cd1: chainId ?? 0 });
  }, [chainId]);

  useEffect(() => {
    googleAnalytics.pageview(`${pathname}${search}`);
  }, [pathname, search]);

  useEffect(() => {
    googleAnalytics.ga((tracker) => {
      if (!tracker) return;

      const clientId = tracker.get('clientId');
      window.localStorage.setItem(
        GOOGLE_ANALYTICS_CLIENT_ID_STORAGE_KEY,
        clientId
      );
    });
  }, []);
}

export {
  sendEvent,
  outboundLink,
  sendTiming,
  useAnalyticsReporter,
  GOOGLE_ANALYTICS_CLIENT_ID_STORAGE_KEY,
};
