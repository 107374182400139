import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation } from 'react-router-dom';

import { Icons, Checkbox, Buttons } from "../../../components";

const SidebarBlock = styled.div`
  flex: 0 0 280px;
  max-width: 280px;

  @media only screen and (max-width: 1023px) {
    display: none;
    flex: 0 0 100%;
    max-width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 11;
    padding: 100px 18px;

    &.show {
      display: block;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .sidebar {
    position: sticky;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow-x: auto;

    @media only screen and (max-width: 1023px) {
      position: relative;
      height: 100%;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__close {
      position: absolute;
      top: 46px;
      right: 20px;
      width: 30px;
      height: 30px;
      display: none;
      align-items: center;
      justify-content: center;
      color: #000000;
      z-index: 1;
      cursor: pointer;
      transition: all 0.2s ease;

      @media only screen and (max-width: 1023px) {
        display: flex;
      }

      &:hover {
        color: #2d2d2d;
      }
    }

    &__apply {
      position: absolute;
      bottom: 45px;
      left: 18px;
      right: 18px;
      display: none;
      z-index: 1;

      @media only screen and (max-width: 1023px) {
        display: flex;
      }
    }

    &__head {
      position: relative;
      padding-top: 20px;
      padding-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #e8eaef;

      &__label {
        font-family: var(--font-gilroy);
        font-size: 18px;
        line-height: 21px;
        font-weight: 600;
        color: #2d2d2d;

        @media only screen and (max-width: 1023px) {
          font-size: 26px;
          line-height: 31px;
        }
      }

      &__btn {
        display: flex;
        align-items: center;
        font-family: var(--font-gilroy);
        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
        color: rgba(45, 45, 45, 0.5);
        transition: all 0.2s ease;
        outline: none;
        padding: 0;
        margin: 0;
        background-color: transparent;
        border: 0;
        cursor: pointer;

        @media only screen and (max-width: 1023px) {
          font-size: 18px;
          line-height: 21px;
        }

        &:hover {
          color: rgba(45, 45, 45, 1);
        }
      }
    }
  }
`;

const AccordionBlock = styled.div`
  .accordion {
    &__section {
      position: relative;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #e8eaef;
    }

    &__head {
      padding: 14px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      &.open {
        .accordion__head__arrow {
          transform: rotate(180deg);
        }
      }

      &__link {
        display: flex;
        align-items: center;
      }

      &__text {
        font-family: var(--font-gilroy);
        font-size: 18px;
        line-height: 21px;
        font-weight: 600;
        color: #2d2d2d;
      }

      &__arrow {
        color: #2d2d2d;
        transition: transform 0.5s ease;
        transform: rotate(0deg);
      }
    }

    &__item {
      overflow: hidden;
      transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
      height: auto;
      max-height: 9999px;

      &.collapsed {
        max-height: 0;
        transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
      }
    }

    &__content {
      padding: 0 15px 10px;
    }

    &__list {
      flex-direction: column;

      &__item {
        text-transform: capitalize;

        &:not(:last-child) {
          margin-bottom: 6px;
        }
      }
    }
  }
`;

var filterOptions = [
  /*
  {
    name: "rarity",
    label: "Rarity",
    items: [
      { id: 1001, name: "common" },
      { id: 1002, name: "uncommon" },
      { id: 1003, name: "rare" },
      { id: 1004, name: "mythical" },
      { id: 1005, name: "legendary" },
    ],
  },
  */
  {
    name: "background",
    label: "Background",
    items: [
      { id: 2001, name: "black" },
      { id: 2002, name: "black-blue" },
      { id: 2003, name: "black-brown" },
      { id: 2004, name: "black-green" },
      { id: 2005, name: "black-orange" },
      { id: 2006, name: "black-pink" },
      { id: 2007, name: "black-purple" },
      { id: 2008, name: "black-red" },
      { id: 2009, name: "black-white" },
      { id: 2010, name: "black-yellow" },
      { id: 2011, name: "blue" },
      { id: 2012, name: "blue-green" },
      { id: 2013, name: "blue-orange" },
      { id: 2014, name: "blue-pink" },
      { id: 2015, name: "blue-purple" },
      { id: 2016, name: "blue-white" },
      { id: 2017, name: "blue-yellow" },
      { id: 2018, name: "brown" },
      { id: 2019, name: "brown-blue" },
      { id: 2020, name: "brown-green" },
      { id: 2021, name: "brown-orange" },
      { id: 2022, name: "brown-pink" },
      { id: 2023, name: "brown-red" },
      { id: 2024, name: "brown-yellow" },
      { id: 2025, name: "colorful" },
      { id: 2026, name: "green" },
      { id: 2027, name: "green-orange" },
      { id: 2028, name: "green-pink" },
      { id: 2029, name: "green-purple" },
      { id: 2030, name: "green-yellow" },
      { id: 2031, name: "grey" },
      { id: 2032, name: "grey-blue" },
      { id: 2033, name: "grey-brown" },
      { id: 2034, name: "grey-green" },
      { id: 2035, name: "grey-orange" },
      { id: 2036, name: "grey-pink" },
      { id: 2037, name: "grey-purple" },
      { id: 2038, name: "grey-red" },
      { id: 2039, name: "grey-yellow" },
      { id: 2040, name: "orange" },
      { id: 2041, name: "orange-pink" },
      { id: 2042, name: "orange-yellow" },
      { id: 2043, name: "pink" },
      { id: 2044, name: "pink-red" },
      { id: 2045, name: "pink-yellow" },
      { id: 2046, name: "purple" },
      { id: 2047, name: "purple-brown" },
      { id: 2048, name: "purple-green" },
      { id: 2049, name: "purple-orange" },
      { id: 2050, name: "purple-pink" },
      { id: 2051, name: "purple-red" },
      { id: 2052, name: "purple-white" },
      { id: 2053, name: "purple-yellow" },
      { id: 2054, name: "red" },
      { id: 2055, name: "red-blue" },
      { id: 2056, name: "red-green" },
      { id: 2057, name: "red-orange" },
      { id: 2058, name: "red-white" },
      { id: 2059, name: "red-yellow" },
      { id: 2060, name: "white" },
      { id: 2061, name: "white-brown" },
      { id: 2062, name: "white-pink" },
      { id: 2063, name: "yellow" },
      { id: 2064, name: "yellow-orange" },
    ],
  },
  {
    name: "background_effect",
    label: "Background Effect",
    items: [
      { id: 3001, name: "brick wall" },
      { id: 3002, name: "cactus field" },
      { id: 3003, name: "cloudy" },
      { id: 3004, name: "gradient" },
      { id: 3005, name: "illusions" },
      { id: 3006, name: "knife light" },
      { id: 3007, name: "lighting effect" },
      { id: 3008, name: "matrix" },
      { id: 3009, name: "mesh" },
      { id: 3010, name: "mosaic" },
      { id: 3011, name: "polka dot" },
      { id: 3012, name: "radiant rays" },
      { id: 3013, name: "repeating lines" },
      { id: 3014, name: "repeating pattern" },
      { id: 3015, name: "shimmering hazy" },
      { id: 3016, name: "warp speed" },
      { id: 3017, name: "wooden wall" },
    ],
  },
  {
    name: "body",
    label: "Body",
    items: [
      { id: 4001, name: "accessories" },
      { id: 4002, name: "alchemy eye pendant" },
      { id: 4003, name: "apron" },
      { id: 4004, name: "arm strap" },
      { id: 4005, name: "armor" },
      { id: 4006, name: "au naturel" },
      { id: 4007, name: "bearhide armor" },
      { id: 4008, name: "black diamond embossed armor" },
      { id: 4009, name: "blouse" },
      { id: 4010, name: "blue and gold dress" },
      { id: 4011, name: "bodysuit tattoos" },
      { id: 4012, name: "bomb suit" },
      { id: 4013, name: "boxing robe" },
      { id: 4014, name: "bronze armor" },
      { id: 4015, name: "bubbles" },
      { id: 4016, name: "bullet belt" },
      { id: 4017, name: "camo ski jacket" },
      { id: 4018, name: "cape" },
      { id: 4019, name: "celebrity shirt" },
      { id: 4020, name: "censor cover" },
      { id: 4021, name: "cepken" },
      { id: 4022, name: "chainmail" },
      { id: 4023, name: "championship belt" },
      { id: 4024, name: "cloak" },
      { id: 4025, name: "coat" },
      { id: 4026, name: "cobra armor" },
      { id: 4027, name: "combat suit" },
      { id: 4028, name: "combat uniform" },
      { id: 4029, name: "combat vest" },
      { id: 4030, name: "costume" },
      { id: 4031, name: "court suit" },
      { id: 4032, name: "crimson shadow hoodie" },
      { id: 4033, name: "crow feather armor" },
      { id: 4034, name: "crystal" },
      { id: 4035, name: "cubic body" },
      { id: 4036, name: "dark armor" },
      { id: 4037, name: "denim jacket" },
      { id: 4038, name: "diamond" },
      { id: 4039, name: "diving suit" },
      { id: 4040, name: "dreamland" },
      { id: 4041, name: "dungaree" },
      { id: 4042, name: "ephemeral ember" },
      { id: 4043, name: "epic cape" },
      { id: 4044, name: "fiery flames" },
      { id: 4045, name: "fluorescent hoodie" },
      { id: 4046, name: "fluorescent jacket" },
      { id: 4047, name: "fluorescent tank top" },
      { id: 4048, name: "fur clothing" },
      { id: 4049, name: "general uniform" },
      { id: 4050, name: "ghost armor" },
      { id: 4051, name: "ghost hunter suit" },
      { id: 4052, name: "giant beads necklace" },
      { id: 4053, name: "giant soda pop" },
      { id: 4054, name: "glowing jacket" },
      { id: 4055, name: "god of fortune" },
      { id: 4056, name: "gold" },
      { id: 4057, name: "gold chainmail" },
      { id: 4058, name: "gold collar" },
      { id: 4059, name: "gold general uniform" },
      { id: 4060, name: "gold plate armor" },
      { id: 4061, name: "golden robe" },
      { id: 4062, name: "gown" },
      { id: 4063, name: "grass and bloom robe" },
      { id: 4064, name: "grass top" },
      { id: 4065, name: "habit" },
      { id: 4066, name: "harlequin suit" },
      { id: 4067, name: "he chang" },
      { id: 4068, name: "holographic royal robe" },
      { id: 4069, name: "honey" },
      { id: 4070, name: "hoodie" },
      { id: 4071, name: "inferno red armor" },
      { id: 4072, name: "jacket" },
      { id: 4073, name: "jelly" },
      { id: 4074, name: "jumpsuit" },
      { id: 4075, name: "kao" },
      { id: 4076, name: "kimono" },
      { id: 4077, name: "kung fu robe" },
      { id: 4078, name: "kung fu shirt" },
      { id: 4079, name: "leather armor" },
      { id: 4080, name: "linen wrapping" },
      { id: 4081, name: "liquefying" },
      { id: 4082, name: "machine" },
      { id: 4083, name: "magician jacket" },
      { id: 4084, name: "manifest cloth" },
      { id: 4085, name: "merman body" },
      { id: 4086, name: "metallic" },
      { id: 4087, name: "military cape" },
      { id: 4088, name: "musketeer tabard" },
      { id: 4089, name: "newspaper barber cape" },
      { id: 4090, name: "ninja suit" },
      { id: 4091, name: "nova armor" },
      { id: 4092, name: "o-yoroi" },
      { id: 4093, name: "orange tribe robe" },
      { id: 4094, name: "paint splattered" },
      { id: 4095, name: "paladin plate" },
      { id: 4096, name: "patriotic shirt" },
      { id: 4097, name: "patriotic vest" },
      { id: 4098, name: "pink jacket" },
      { id: 4099, name: "pirate barrel" },
      { id: 4100, name: "placard" },
      { id: 4101, name: "polo shirt" },
      { id: 4102, name: "poncho" },
      { id: 4103, name: "professional uniform" },
      { id: 4104, name: "punk fashion" },
      { id: 4105, name: "puppet" },
      { id: 4106, name: "puzzlelight armor" },
      { id: 4107, name: "pyjamas" },
      { id: 4108, name: "quilted jacket" },
      { id: 4109, name: "rainbow tank top" },
      { id: 4110, name: "raincoat" },
      { id: 4111, name: "rattan" },
      { id: 4112, name: "red reaper jacket" },
      { id: 4113, name: "red tunic" },
      { id: 4114, name: "roadmaster armor" },
      { id: 4115, name: "robe" },
      { id: 4116, name: "robot arm" },
      { id: 4117, name: "robot armor" },
      { id: 4118, name: "robot vest" },
      { id: 4119, name: "royal robe" },
      { id: 4120, name: "royal suit" },
      { id: 4121, name: "ruby protector armor" },
      { id: 4122, name: "samurai armor" },
      { id: 4123, name: "sequin bodysuit" },
      { id: 4124, name: "sequin jacket" },
      { id: 4125, name: "shadowed gold mail" },
      { id: 4126, name: "shifting glitch" },
      { id: 4127, name: "shiny glitter suit" },
      { id: 4128, name: "shirt" },
      { id: 4129, name: "silver plate armor" },
      { id: 4130, name: "snow" },
      { id: 4131, name: "spacesuit" },
      { id: 4132, name: "spacesuit with cape" },
      { id: 4133, name: "spartan chlamys" },
      { id: 4134, name: "sports uniform" },
      { id: 4135, name: "sticky" },
      { id: 4136, name: "striped mantle" },
      { id: 4137, name: "suit" },
      { id: 4138, name: "superhero suit" },
      { id: 4139, name: "sweater" },
      { id: 4140, name: "swimsuit" },
      { id: 4141, name: "t-shirt" },
      { id: 4142, name: "talon robe" },
      { id: 4143, name: "tang suit" },
      { id: 4144, name: "tank top" },
      { id: 4145, name: "tattoo" },
      { id: 4146, name: "terracotta armor" },
      { id: 4147, name: "the emperor new jacket" },
      { id: 4148, name: "tiger claw armor" },
      { id: 4149, name: "titanium plate armor" },
      { id: 4150, name: "toddler clothing" },
      { id: 4151, name: "towel" },
      { id: 4152, name: "tracksuit" },
      { id: 4153, name: "traje de luces" },
      { id: 4154, name: "translucent" },
      { id: 4155, name: "trendy fashion" },
      { id: 4156, name: "tunic sculpture" },
      { id: 4157, name: "uniform" },
      { id: 4158, name: "usekh collar" },
      { id: 4159, name: "vampire cape" },
      { id: 4160, name: "vest" },
      { id: 4161, name: "viridian armor" },
      { id: 4162, name: "wooden" },
      { id: 4163, name: "x-ray" },
    ],
  },
  {
    name: "eyes",
    label: "Eyes",
    items: [
      { id: 5001, name: "closed" },
      { id: 5002, name: "confident" },
      { id: 5003, name: "determined" },
      { id: 5004, name: "diamond" },
      { id: 5005, name: "exhausted" },
      { id: 5006, name: "fire" },
      { id: 5007, name: "glowing" },
      { id: 5008, name: "gold" },
      { id: 5009, name: "jelly" },
      { id: 5010, name: "laser" },
      { id: 5011, name: "lightning" },
      { id: 5012, name: "liquefying" },
      { id: 5013, name: "plaster" },
      { id: 5014, name: "robot" },
      { id: 5015, name: "sculpture" },
      { id: 5016, name: "shifting glitch" },
      { id: 5017, name: "sincere" },
      { id: 5018, name: "wink" },
      { id: 5019, name: "wooden" },
      { id: 5020, name: "zombie" },
    ],
  },
  {
    name: "face",
    label: "Face",
    items: [
      { id: 6001, name: "abyssal" },
      { id: 6002, name: "band aid" },
      { id: 6003, name: "black glasses" },
      { id: 6004, name: "black sunglasses" },
      { id: 6005, name: "blue combat glasses" },
      { id: 6006, name: "blue glasses" },
      { id: 6007, name: "blue goggles" },
      { id: 6008, name: "brown glasses" },
      { id: 6009, name: "burglar mask" },
      { id: 6010, name: "camouflage" },
      { id: 6011, name: "capsule toys" },
      { id: 6012, name: "clown nose" },
      { id: 6013, name: "combed beard" },
      { id: 6014, name: "diamond" },
      { id: 6015, name: "donuts" },
      { id: 6016, name: "earphone" },
      { id: 6017, name: "ephemeral ember" },
      { id: 6018, name: "eye band" },
      { id: 6019, name: "eye patch" },
      { id: 6020, name: "face art" },
      { id: 6021, name: "face bandana" },
      { id: 6022, name: "face guard" },
      { id: 6023, name: "face scarf" },
      { id: 6024, name: "false beards" },
      { id: 6025, name: "fiery flames" },
      { id: 6026, name: "ghost visor" },
      { id: 6027, name: "glass visor" },
      { id: 6028, name: "glowing" },
      { id: 6029, name: "glowing scouter" },
      { id: 6030, name: "glowing vr headset" },
      { id: 6031, name: "gold" },
      { id: 6032, name: "gold glasses" },
      { id: 6033, name: "gold sunglasses" },
      { id: 6034, name: "green combat glasses" },
      { id: 6035, name: "green glasses" },
      { id: 6036, name: "green goggles" },
      { id: 6037, name: "half robot" },
      { id: 6038, name: "hua dan zhuang" },
      { id: 6039, name: "inscribed" },
      { id: 6040, name: "jackpot led" },
      { id: 6041, name: "jelly" },
      { id: 6042, name: "joker" },
      { id: 6043, name: "led glasses" },
      { id: 6044, name: "linen wrapping" },
      { id: 6045, name: "liquefying" },
      { id: 6046, name: "long beard" },
      { id: 6047, name: "makeup" },
      { id: 6048, name: "mask" },
      { id: 6049, name: "metallic" },
      { id: 6050, name: "mole" },
      { id: 6051, name: "monocle" },
      { id: 6052, name: "moustache" },
      { id: 6053, name: "moustache stubble" },
      { id: 6054, name: "mouth guard" },
      { id: 6055, name: "movie glasses" },
      { id: 6056, name: "nose guard" },
      { id: 6057, name: "old fashion sunglasses" },
      { id: 6058, name: "orange glasses" },
      { id: 6059, name: "orange goggles" },
      { id: 6060, name: "paperbag" },
      { id: 6061, name: "party mask" },
      { id: 6062, name: "pierced" },
      { id: 6063, name: "pink glasses" },
      { id: 6064, name: "pink goggles" },
      { id: 6065, name: "polarized glasses" },
      { id: 6066, name: "polarized goggles" },
      { id: 6067, name: "pure gold glasses" },
      { id: 6068, name: "purple combat glasses" },
      { id: 6069, name: "purple glasses" },
      { id: 6070, name: "purple goggles" },
      { id: 6071, name: "puzzlelight visor" },
      { id: 6072, name: "racing goggles" },
      { id: 6073, name: "rainbow" },
      { id: 6074, name: "red glasses" },
      { id: 6075, name: "red sunglasses" },
      { id: 6076, name: "robot visor" },
      { id: 6077, name: "scarred" },
      { id: 6078, name: "scouter" },
      { id: 6079, name: "sculpture" },
      { id: 6080, name: "shadowed" },
      { id: 6081, name: "shattered glasses" },
      { id: 6082, name: "shaving foam" },
      { id: 6083, name: "shifting glitch" },
      { id: 6084, name: "sinister hollow" },
      { id: 6085, name: "sleep mask" },
      { id: 6086, name: "sticky" },
      { id: 6087, name: "stripes painting" },
      { id: 6088, name: "sugar skull painting" },
      { id: 6089, name: "superhero eye mask" },
      { id: 6090, name: "tactical gas mask" },
      { id: 6091, name: "talisman" },
      { id: 6092, name: "tattoo" },
      { id: 6093, name: "television glass" },
      { id: 6094, name: "terracotta" },
      { id: 6095, name: "titanium visored helmet" },
      { id: 6096, name: "translucent" },
      { id: 6097, name: "tree rooted" },
      { id: 6098, name: "visored helmet" },
      { id: 6099, name: "white glasses" },
      { id: 6100, name: "wooden" },
      { id: 6101, name: "wounded" },
      { id: 6102, name: "yellow combat glasses" },
      { id: 6103, name: "yellow glasses" },
      { id: 6104, name: "yellow goggles" },
    ],
  },
  {
    name: "hair",
    label: "Hair",
    items: [
      { id: 7001, name: "abyssal" },
      { id: 7002, name: "bald" },
      { id: 7003, name: "black bob" },
      { id: 7004, name: "black braided" },
      { id: 7005, name: "black bun" },
      { id: 7006, name: "black curly" },
      { id: 7007, name: "black curtain" },
      { id: 7008, name: "black cut" },
      { id: 7009, name: "black flat top" },
      { id: 7010, name: "black knight errant" },
      { id: 7011, name: "black long" },
      { id: 7012, name: "black messy" },
      { id: 7013, name: "black perm" },
      { id: 7014, name: "black pompadour" },
      { id: 7015, name: "black ponytail" },
      { id: 7016, name: "black short" },
      { id: 7017, name: "black slick back" },
      { id: 7018, name: "black spiky" },
      { id: 7019, name: "blue braided" },
      { id: 7020, name: "blue cut" },
      { id: 7021, name: "brown long" },
      { id: 7022, name: "brown ponytail" },
      { id: 7023, name: "brown queue" },
      { id: 7024, name: "brown spiky" },
      { id: 7025, name: "brown spiky" },
      { id: 7026, name: "colorful spiky" },
      { id: 7027, name: "diamond" },
      { id: 7028, name: "electrified" },
      { id: 7029, name: "ephemeral ember" },
      { id: 7030, name: "fiery spiky" },
      { id: 7031, name: "gold" },
      { id: 7032, name: "gold braided" },
      { id: 7033, name: "gold elvish" },
      { id: 7034, name: "gold flowy" },
      { id: 7035, name: "gold long" },
      { id: 7036, name: "gold perm" },
      { id: 7037, name: "gold pompadour" },
      { id: 7038, name: "gold ponytail" },
      { id: 7039, name: "gold side part" },
      { id: 7040, name: "gold slick back" },
      { id: 7041, name: "gold spiky" },
      { id: 7042, name: "green long" },
      { id: 7043, name: "green slick back" },
      { id: 7044, name: "grey slick back" },
      { id: 7045, name: "grey streak" },
      { id: 7046, name: "half robot" },
      { id: 7047, name: "jelly" },
      { id: 7048, name: "jheri curl" },
      { id: 7049, name: "liquefying" },
      { id: 7050, name: "metallic" },
      { id: 7051, name: "orange mohawk" },
      { id: 7052, name: "orange perm" },
      { id: 7053, name: "orange ponytail" },
      { id: 7054, name: "orange spiky" },
      { id: 7055, name: "orange streak" },
      { id: 7056, name: "orange witchy" },
      { id: 7057, name: "pink braided" },
      { id: 7058, name: "pink witchy" },
      { id: 7059, name: "purple cut" },
      { id: 7060, name: "purple ponytail" },
      { id: 7061, name: "rainbow" },
      { id: 7062, name: "red mohawk" },
      { id: 7063, name: "sculpture" },
      { id: 7064, name: "shaggy" },
      { id: 7065, name: "shifting glitch" },
      { id: 7066, name: "sticky" },
      { id: 7067, name: "super flat top" },
      { id: 7068, name: "terracotta" },
      { id: 7069, name: "tough spiky" },
      { id: 7070, name: "translucent" },
      { id: 7071, name: "tree rooted" },
      { id: 7072, name: "wet" },
      { id: 7073, name: "white braided" },
      { id: 7074, name: "white curtain" },
      { id: 7075, name: "white flowy" },
      { id: 7076, name: "white long" },
      { id: 7077, name: "white perm" },
      { id: 7078, name: "white ponytail" },
      { id: 7079, name: "white slick back" },
      { id: 7080, name: "white spiky" },
      { id: 7081, name: "wooden" },
    ],
  },
  {
    name: "handheld",
    label: "Handheld",
    items: [
      { id: 8001, name: "alarm clock" },
      { id: 8002, name: "amethyst staff" },
      { id: 8003, name: "art supplies" },
      { id: 8004, name: "assault rifle" },
      { id: 8005, name: "axe" },
      { id: 8006, name: "backpack" },
      { id: 8007, name: "ball" },
      { id: 8008, name: "balloon" },
      { id: 8009, name: "barbell" },
      { id: 8010, name: "bat" },
      { id: 8011, name: "bath tools" },
      { id: 8012, name: "bazooka" },
      { id: 8013, name: "bell" },
      { id: 8014, name: "beverages" },
      { id: 8015, name: "blazeblade" },
      { id: 8016, name: "blessing charm" },
      { id: 8017, name: "boards" },
      { id: 8018, name: "bokken" },
      { id: 8019, name: "bolster" },
      { id: 8020, name: "book" },
      { id: 8021, name: "boomerang" },
      { id: 8022, name: "bow" },
      { id: 8023, name: "camera" },
      { id: 8024, name: "candles" },
      { id: 8025, name: "cards" },
      { id: 8026, name: "cash" },
      { id: 8027, name: "chrono broadsword" },
      { id: 8028, name: "claw staff" },
      { id: 8029, name: "cleaning tools" },
      { id: 8030, name: "coin" },
      { id: 8031, name: "cross" },
      { id: 8032, name: "crystal" },
      { id: 8033, name: "crystal staff" },
      { id: 8034, name: "crystal sword" },
      { id: 8035, name: "da sword" },
      { id: 8036, name: "dice shaker" },
      { id: 8037, name: "dragon slayer sword" },
      { id: 8038, name: "drill" },
      { id: 8039, name: "drumstick" },
      { id: 8040, name: "egg" },
      { id: 8041, name: "energy cannon" },
      { id: 8042, name: "energy ring" },
      { id: 8043, name: "exhaust" },
      { id: 8044, name: "explosives" },
      { id: 8045, name: "face scarf" },
      { id: 8046, name: "face sketch" },
      { id: 8047, name: "feather fan" },
      { id: 8048, name: "fiery guitar" },
      { id: 8049, name: "fiery katana" },
      { id: 8050, name: "fiery skateboard" },
      { id: 8051, name: "fire" },
      { id: 8052, name: "fire claw hammer" },
      { id: 8053, name: "firecrackers" },
      { id: 8054, name: "fish" },
      { id: 8055, name: "fishing gear" },
      { id: 8056, name: "flagpole" },
      { id: 8057, name: "flame torch" },
      { id: 8058, name: "flower" },
      { id: 8059, name: "footwear" },
      { id: 8060, name: "ghost blaster" },
      { id: 8061, name: "ghost cannon" },
      { id: 8062, name: "giant axe" },
      { id: 8063, name: "girlish sword" },
      { id: 8064, name: "glass" },
      { id: 8065, name: "glitch sword" },
      { id: 8066, name: "glitter vapor" },
      { id: 8067, name: "glow stick" },
      { id: 8068, name: "glowing book" },
      { id: 8069, name: "glowing flower" },
      { id: 8070, name: "gold" },
      { id: 8071, name: "gold microphone" },
      { id: 8072, name: "golden banana knife" },
      { id: 8073, name: "golden eagle sword" },
      { id: 8074, name: "golden gun" },
      { id: 8075, name: "golden ladle" },
      { id: 8076, name: "golden monk staff" },
      { id: 8077, name: "golden spatula" },
      { id: 8078, name: "golden trident" },
      { id: 8079, name: "green venom sword" },
      { id: 8080, name: "gun" },
      { id: 8081, name: "hairdryer" },
      { id: 8082, name: "hammer" },
      { id: 8083, name: "handcuffs" },
      { id: 8084, name: "handlebar" },
      { id: 8085, name: "hardware tools" },
      { id: 8086, name: "hobby horse" },
      { id: 8087, name: "hologram" },
      { id: 8088, name: "horn" },
      { id: 8089, name: "ice cream stick" },
      { id: 8090, name: "inferno mask" },
      { id: 8091, name: "inferno red skull sword" },
      { id: 8092, name: "instruments" },
      { id: 8093, name: "jingu bang" },
      { id: 8094, name: "katana" },
      { id: 8095, name: "keyboard" },
      { id: 8096, name: "keys" },
      { id: 8097, name: "kitchenware" },
      { id: 8098, name: "knife" },
      { id: 8099, name: "ladder" },
      { id: 8100, name: "lantern" },
      { id: 8101, name: "laser" },
      { id: 8102, name: "lighter" },
      { id: 8103, name: "lightning" },
      { id: 8104, name: "lightning spear" },
      { id: 8105, name: "lightsaber" },
      { id: 8106, name: "magic wand" },
      { id: 8107, name: "magnifier" },
      { id: 8108, name: "mallet" },
      { id: 8109, name: "manifestations" },
      { id: 8110, name: "map scroll" },
      { id: 8111, name: "megaphone" },
      { id: 8112, name: "microphone" },
      { id: 8113, name: "mini crown" },
      { id: 8114, name: "monk staff" },
      { id: 8115, name: "muleta" },
      { id: 8116, name: "nova blaster" },
      { id: 8117, name: "nunchucks" },
      { id: 8118, name: "office tools" },
      { id: 8119, name: "ossuary blade" },
      { id: 8120, name: "photon edge sword" },
      { id: 8121, name: "pinwheel" },
      { id: 8122, name: "pocket watch" },
      { id: 8123, name: "poisonous apple" },
      { id: 8124, name: "pom poms" },
      { id: 8125, name: "portable player" },
      { id: 8126, name: "power block" },
      { id: 8127, name: "props" },
      { id: 8128, name: "puppet" },
      { id: 8129, name: "qiang" },
      { id: 8130, name: "racket" },
      { id: 8131, name: "rapier" },
      { id: 8132, name: "road sign" },
      { id: 8133, name: "rope" },
      { id: 8134, name: "scarlet spear" },
      { id: 8135, name: "seashell" },
      { id: 8136, name: "selfie frame" },
      { id: 8137, name: "selfie stick" },
      { id: 8138, name: "shaker" },
      { id: 8139, name: "shaver" },
      { id: 8140, name: "shield" },
      { id: 8141, name: "shock whip" },
      { id: 8142, name: "shuriken" },
      { id: 8143, name: "skulls staff" },
      { id: 8144, name: "slingshot" },
      { id: 8145, name: "smoke" },
      { id: 8146, name: "snacks" },
      { id: 8147, name: "solar system" },
      { id: 8148, name: "spear" },
      { id: 8149, name: "spirit" },
      { id: 8150, name: "staff" },
      { id: 8151, name: "steel sword" },
      { id: 8152, name: "sword" },
      { id: 8153, name: "talisman" },
      { id: 8154, name: "talon fan" },
      { id: 8155, name: "taser" },
      { id: 8156, name: "telescope" },
      { id: 8157, name: "terracotta sword" },
      { id: 8158, name: "thunder cruiser" },
      { id: 8159, name: "titanium lance" },
      { id: 8160, name: "tornado" },
      { id: 8161, name: "toy gun" },
      { id: 8162, name: "toys" },
      { id: 8163, name: "tribe fan" },
      { id: 8164, name: "trophy" },
      { id: 8165, name: "twilight cleaver" },
      { id: 8166, name: "umbrella" },
      { id: 8167, name: "vinyl disk" },
      { id: 8168, name: "viridian shadow" },
      { id: 8169, name: "water gourd" },
      { id: 8170, name: "whip" },
      { id: 8171, name: "white staff" },
      { id: 8172, name: "xiphos sword" },
    ],
  },
  {
    name: "head",
    label: "Head",
    items: [
      { id: 9001, name: "backwards cap" },
      { id: 9002, name: "bamboo hat" },
      { id: 9003, name: "bandana" },
      { id: 9004, name: "barrister wig" },
      { id: 9005, name: "bear hood" },
      { id: 9006, name: "bearskin hat" },
      { id: 9007, name: "bomb squad helmet" },
      { id: 9008, name: "bone" },
      { id: 9009, name: "bounding box" },
      { id: 9010, name: "boxing headgear" },
      { id: 9011, name: "bronze helmet" },
      { id: 9012, name: "bucket hat" },
      { id: 9013, name: "burglar mask" },
      { id: 9014, name: "camo cover" },
      { id: 9015, name: "cap" },
      { id: 9016, name: "captain hat" },
      { id: 9017, name: "cat earmuffs" },
      { id: 9018, name: "chainmail coif" },
      { id: 9019, name: "circlet" },
      { id: 9020, name: "cobra helmet" },
      { id: 9021, name: "combat earpiece" },
      { id: 9022, name: "combat helmet" },
      { id: 9023, name: "commander helmet" },
      { id: 9024, name: "commando beret" },
      { id: 9025, name: "corinthian helmet" },
      { id: 9026, name: "cosmo comm gear" },
      { id: 9027, name: "costume" },
      { id: 9028, name: "crow hat" },
      { id: 9029, name: "crown" },
      { id: 9030, name: "crystal" },
      { id: 9031, name: "crystal helmet" },
      { id: 9032, name: "crystal powered helm" },
      { id: 9033, name: "cylindrical head" },
      { id: 9034, name: "diamond" },
      { id: 9035, name: "diving helmet" },
      { id: 9036, name: "dreamland" },
      { id: 9037, name: "fencing mask" },
      { id: 9038, name: "fes" },
      { id: 9039, name: "fiery flames" },
      { id: 9040, name: "fiery horns" },
      { id: 9041, name: "fish tank" },
      { id: 9042, name: "fluorescent headphone" },
      { id: 9043, name: "flying helmet" },
      { id: 9044, name: "football helmet" },
      { id: 9045, name: "gashapon machine" },
      { id: 9046, name: "ghost helmet" },
      { id: 9047, name: "glasses" },
      { id: 9048, name: "glowing headphone" },
      { id: 9049, name: "glowing sombrero hat" },
      { id: 9050, name: "goggles" },
      { id: 9051, name: "gold horns" },
      { id: 9052, name: "gold skull emblem" },
      { id: 9053, name: "golden cap" },
      { id: 9054, name: "golden cross crown" },
      { id: 9055, name: "golden eagle helmet" },
      { id: 9056, name: "golden guan jin" },
      { id: 9057, name: "golden hat" },
      { id: 9058, name: "golden wu sha mao" },
      { id: 9059, name: "grass crown" },
      { id: 9060, name: "halo" },
      { id: 9061, name: "hat" },
      { id: 9062, name: "headband" },
      { id: 9063, name: "headlamp" },
      { id: 9064, name: "headphone" },
      { id: 9065, name: "hood" },
      { id: 9066, name: "horn" },
      { id: 9067, name: "imperial king crown" },
      { id: 9068, name: "improvised" },
      { id: 9069, name: "inferno red helm" },
      { id: 9070, name: "jelly" },
      { id: 9071, name: "jeweled king crown" },
      { id: 9072, name: "kabuto helmet" },
      { id: 9073, name: "laser scanner" },
      { id: 9074, name: "led hat" },
      { id: 9075, name: "magician hat" },
      { id: 9076, name: "mask" },
      { id: 9077, name: "montera" },
      { id: 9078, name: "mushroom hat" },
      { id: 9079, name: "music box" },
      { id: 9080, name: "music hat" },
      { id: 9081, name: "musketeer hat" },
      { id: 9082, name: "nemes crown" },
      { id: 9083, name: "newspaper helmet" },
      { id: 9084, name: "ninja hood" },
      { id: 9085, name: "novalink headgear" },
      { id: 9086, name: "paperbag" },
      { id: 9087, name: "patriotic hat" },
      { id: 9088, name: "pink backwards cap" },
      { id: 9089, name: "posing decorations" },
      { id: 9090, name: "puzzlelight helmet" },
      { id: 9091, name: "pyjamas hat" },
      { id: 9092, name: "racing helmet" },
      { id: 9093, name: "rainbow wig" },
      { id: 9094, name: "rattan" },
      { id: 9095, name: "regal purple horns" },
      { id: 9096, name: "ribbon headband" },
      { id: 9097, name: "riding helmet" },
      { id: 9098, name: "roadmaster helmet" },
      { id: 9099, name: "robot helmet" },
      { id: 9100, name: "safety helmet" },
      { id: 9101, name: "scarf" },
      { id: 9102, name: "shuai hui" },
      { id: 9103, name: "silk hat" },
      { id: 9104, name: "silver thorn crown" },
      { id: 9105, name: "skull bucket hat" },
      { id: 9106, name: "soaring talon mask" },
      { id: 9107, name: "spaceship headset" },
      { id: 9108, name: "spacesuit helmet" },
      { id: 9109, name: "striped headdress" },
      { id: 9110, name: "superhero mask" },
      { id: 9111, name: "tang hat" },
      { id: 9112, name: "television" },
      { id: 9113, name: "tengu mask" },
      { id: 9114, name: "terracotta ze" },
      { id: 9115, name: "thorned bear helm" },
      { id: 9116, name: "tiger gaze helmet" },
      { id: 9117, name: "top hat" },
      { id: 9118, name: "towel" },
      { id: 9119, name: "translucent costume" },
      { id: 9120, name: "underpants" },
      { id: 9121, name: "veil" },
      { id: 9122, name: "vr headset" },
      { id: 9123, name: "war bonnet" },
      { id: 9124, name: "warrior helmet" },
      { id: 9125, name: "wolf ears cape" },
      { id: 9126, name: "wooden" },
    ],
  },
  {
    name: "mouth",
    label: "Mouth",
    items: [
      { id: 10001, name: "abyssal" },
      { id: 10002, name: "big bubble" },
      { id: 10003, name: "biscuit sticks" },
      { id: 10004, name: "bite smile" },
      { id: 10005, name: "bleh" },
      { id: 10006, name: "colorful teeth" },
      { id: 10007, name: "covered" },
      { id: 10008, name: "cracking up" },
      { id: 10009, name: "diamond" },
      { id: 10010, name: "gentle smile" },
      { id: 10011, name: "gold" },
      { id: 10012, name: "gold teeth" },
      { id: 10013, name: "grass" },
      { id: 10014, name: "jelly" },
      { id: 10015, name: "robot" },
      { id: 10016, name: "shifting glitch" },
      { id: 10017, name: "smoking" },
      { id: 10018, name: "straw" },
      { id: 10019, name: "thermometer" },
      { id: 10020, name: "translucent" },
      { id: 10021, name: "unimpressed" },
      { id: 10022, name: "wide smile" },
      { id: 10023, name: "wooden" },
      { id: 10024, name: "zipper" },
    ],
  },
  {
    name: "pet",
    label: "Pet",
    items: [
      { id: 11001, name: "ape" },
      { id: 11002, name: "bat" },
      { id: 11003, name: "bear" },
      { id: 11004, name: "bees" },
      { id: 11005, name: "beetle" },
      { id: 11006, name: "bird" },
      { id: 11007, name: "biting blossom" },
      { id: 11008, name: "cat" },
      { id: 11009, name: "caterpillar" },
      { id: 11010, name: "chameleon" },
      { id: 11011, name: "cobra" },
      { id: 11012, name: "cockroach" },
      { id: 11013, name: "deer" },
      { id: 11014, name: "dolphin" },
      { id: 11015, name: "dragon" },
      { id: 11016, name: "dragonfly" },
      { id: 11017, name: "ephemeral ember crows" },
      { id: 11018, name: "fairy" },
      { id: 11019, name: "fish" },
      { id: 11020, name: "fox" },
      { id: 11021, name: "frog" },
      { id: 11022, name: "imp " },
      { id: 11023, name: "ladybug" },
      { id: 11024, name: "mantis" },
      { id: 11025, name: "monkey" },
      { id: 11026, name: "monster" },
      { id: 11027, name: "mouse" },
      { id: 11028, name: "octopus" },
      { id: 11029, name: "racoon" },
      { id: 11030, name: "robot" },
      { id: 11031, name: "skeleton" },
      { id: 11032, name: "smiley flower" },
      { id: 11033, name: "snail" },
      { id: 11034, name: "snake" },
      { id: 11035, name: "spider" },
      { id: 11036, name: "squirrel" },
      { id: 11037, name: "starfish" },
      { id: 11038, name: "tortoise" },
      { id: 11039, name: "ufo" },
    ],
  },
  {
    name: "special",
    label: "Special",
    items: [
      { id: 12001, name: "8 bit" },
      { id: 12002, name: "ape dreamland" },
      { id: 12003, name: "aquatic adventures" },
      { id: 12004, name: "bananaholic" },
      { id: 12005, name: "battlefield" },
      { id: 12006, name: "bubbles" },
      { id: 12007, name: "cockroach invasion" },
      { id: 12008, name: "crosshair" },
      { id: 12009, name: "dancing lines" },
      { id: 12010, name: "ephemeral ember" },
      { id: 12011, name: "falling leaves" },
      { id: 12012, name: "fiery flames" },
      { id: 12013, name: "filtered" },
      { id: 12014, name: "fireflies" },
      { id: 12015, name: "flying cat" },
      { id: 12016, name: "flying fluffs" },
      { id: 12017, name: "friendship" },
      { id: 12018, name: "ghostglass" },
      { id: 12019, name: "gold rush" },
      { id: 12020, name: "intense shadows" },
      { id: 12021, name: "lightning" },
      { id: 12022, name: "luminous halos" },
      { id: 12023, name: "melodic dreams" },
      { id: 12024, name: "misty magic" },
      { id: 12025, name: "moonlit night" },
      { id: 12026, name: "noteworthy notes" },
      { id: 12027, name: "rainbow drips" },
      { id: 12028, name: "rainy days" },
      { id: 12029, name: "soda pop" },
      { id: 12030, name: "soundscapes" },
      { id: 12031, name: "strings" },
      { id: 12032, name: "thunderstorms" },
      { id: 12033, name: "twinkling moments" },
      { id: 12034, name: "universe adventures" },
      { id: 12035, name: "wanted" },
      { id: 12036, name: "winter wonderland" },
      { id: 12037, name: "yue ju da xi" },
    ],
  },
  {
    name: "type",
    label: "Type",
    items: [
      { id: 13001, name: "alien" },
      { id: 13002, name: "ape" },
      { id: 13003, name: "demon" },
      { id: 13004, name: "elemental" },
      { id: 13005, name: "monster" },
      { id: 13006, name: "mummy" },
      { id: 13007, name: "mutant" },
      { id: 13008, name: "robot" },
      { id: 13009, name: "skeleton" },
      { id: 13010, name: "superape" },
      { id: 13011, name: "supernatural" },
      { id: 13012, name: "vampire" },
      { id: 13013, name: "zombie" },
    ],
  },
];

/*
 * React checkbox group with "select all" for each group and filter options from
 * a multilevel array with all options selected by default. It constructs a query
 * parameter whenever a checkbox is changed, grouping the selected options by group
 * index, but excludes the selected options if the "select all" for the group is
 * checked. The query parameter uses the item names instead of the item IDs:
 */
const SidebarNft = (props) => {
  const { show, hide } = props;
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState([]);

  useEffect(() => {
    handleReset();
  }, []);

  useEffect(() => {
    const groups = filterOptions.map((option, index) => {
      const selectedItems = selected.filter((itemId) =>
        option.items.find((item) => item.id === itemId)
      );
      const selectedAll = selectedItems.length === option.items.length;

      return {
        groupName: option.name,
        groupId: index,
        selectedAll: selectedAll,
        items: selectedAll
          ? []
          : selectedItems.map((itemId) =>
              option.items.find((item) => item.id === itemId)
            ),
      };
    });

    // update selectAll here whenever selected is updated
    setSelectAll(groups.map((group) => group.selectedAll));

    const queryString = groups
      .filter((group) => group.items.length > 0)
      .map(
        (group) =>
          `${group.groupName}=${group.items.map((item) => item.name).join(",")}`
      )
      .join("&");

    props.refreshNft(queryString);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const handleReset = () => {
    setSelectAll(filterOptions.map((option, groupIndex) => true));

    setSelected(
      filterOptions.flatMap((option) => option.items.map((item) => item.id))
    );
  };

  const handleCheckboxChange = (event, groupIndex) => {
    const value = event.target.value;
    const checked = event.target.checked;

    if (value === "all") {
      const newSelectAll = [...selectAll];
      newSelectAll[groupIndex] = checked;
      setSelectAll(newSelectAll);

      if (checked) {
        let newSelected = [
          ...selected,
          ...filterOptions[groupIndex].items.map((item) => item.id),
        ];

        const removeDuplicate = new Set(newSelected);
        const uniqueArray = Array.from(removeDuplicate);

        setSelected(uniqueArray);
      } else {
        let newSelected = selected.filter(
          (selectedId) =>
            !filterOptions[groupIndex].items
              .map((item) => item.id)
              .includes(selectedId)
        );

        setSelected(newSelected);
      }
    } else {
      setSelected((prevSelected) =>
        checked
          ? [...prevSelected, parseInt(value)]
          : prevSelected.filter((selectedId) => selectedId !== parseInt(value))
      );

      // leave it to setSelected useEffect to update the selectAll data
    }
  };

  return (
    <SidebarBlock className={`${show ? "show" : ""}`}>
      <div className="sidebar__close" onClick={hide}>
        <Icons.Close />
      </div>

      <div className="sidebar">
        <div className="sidebar__head">
          <div className="sidebar__head__label">Filter</div>
          <button className="sidebar__head__btn" onClick={handleReset}>
            Reset filter
          </button>
        </div>

        <div className="sidebar__body">
          {filterOptions.map((__item, groupIndex) => {
            return (
              <Accordion key={groupIndex} title={__item.label}>
                <div className="accordion__list">
                  <div key={groupIndex + "0"} className="accordion__list__item">
                    <Checkbox
                      title="All"
                      onChange={(event) =>
                        handleCheckboxChange(event, groupIndex)
                      }
                      value="all"
                      checked={selectAll[groupIndex] || false}
                    />
                  </div>
                  {__item.items.map((__optionItem, itemIndex) => {
                    return (
                      <div key={itemIndex} className="accordion__list__item">
                        <Checkbox
                          title={__optionItem.name}
                          onChange={(event) =>
                            handleCheckboxChange(event, groupIndex)
                          }
                          value={__optionItem.id}
                          checked={selected.includes(__optionItem.id)}
                        />
                      </div>
                    );
                  })}
                </div>
              </Accordion>
            );
          })}
        </div>
      </div>

      <Buttons.Basic
        variant="contained"
        title="Apply"
        className="sidebar__apply"
        onClick={hide}
      />
    </SidebarBlock>
  );
};

const Accordion = (props) => {
  const { title, children } = props;
  const [isOpen, setOpen] = useState(false);

  return (
    <AccordionBlock>
      <div className="accordion__section">
        <div
          className={`accordion__head ${isOpen ? "open" : ""}`}
          onClick={() => setOpen(!isOpen)}
        >
          <div className="accordion__head__link">
            <div className="accordion__head__text">{title}</div>
          </div>

          <div className="accordion__head__arrow">
            <Icons.ChevronDown />
          </div>
        </div>

        <div className={`accordion__item ${!isOpen ? "collapsed" : ""}`}>
          <div className="accordion__content">{children}</div>
        </div>
      </div>
    </AccordionBlock>
  );
};

export default SidebarNft;
