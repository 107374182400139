import React from "react";
import PropTypes from "prop-types";

const Suitcase = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="33"
      height="30"
      viewBox="0 0 33 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M22.7 29V4.11111C22.7 3.28599 22.3734 2.49467 21.792 1.91122C21.2107 1.32778 20.4222 1 19.6 1H13.4C12.5778 1 11.7893 1.32778 11.208 1.91122C10.6266 2.49467 10.3 3.28599 10.3 4.11111V29M4.1 7.22222H28.9C30.6121 7.22222 32 8.61511 32 10.3333V25.8889C32 27.6071 30.6121 29 28.9 29H4.1C2.38792 29 1 27.6071 1 25.8889V10.3333C1 8.61511 2.38792 7.22222 4.1 7.22222Z"
        stroke="url(#paint0_linear_215_211)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_215_211"
          x1="-5.83823"
          y1="-8.91667"
          x2="55.5755"
          y2="31.4336"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color} />
        </linearGradient>
      </defs>
    </svg>
  );
};

Suitcase.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export default Suitcase;
