import React from "react";
import PropTypes from "prop-types";

const Rewards = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="24"
      height="36"
      viewBox="0 0 24 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.04429 20.9209L4.14286 35L12 30.3636L19.8571 35L17.9557 20.9055M23 11.8182C23 17.7929 18.0751 22.6364 12 22.6364C5.92487 22.6364 1 17.7929 1 11.8182C1 5.84346 5.92487 1 12 1C18.0751 1 23 5.84346 23 11.8182Z"
        stroke="url(#paint0_linear_215_213)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
      />
      <defs>
        <linearGradient
          id="paint0_linear_215_213"
          x1="-3.85294"
          y1="-11.0417"
          x2="50.527"
          y2="9.83975"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color} />
        </linearGradient>
      </defs>
    </svg>
  );
};

Rewards.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export default Rewards;
