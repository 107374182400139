import styled from "styled-components";

const Button = styled.button`
  width: 200px;
  background-color: #157bef;
  border: 1px solid #157bef; 
  color: #fff;
  text-align: center;
  font-size: 18px;
  line-height: 18px;
  border-radius: 10px;
  padding: 10px;
  display: inline-block;
  margin: 0 auto;
  vertical-align: top;
  cursor: pointer;

  &:hover {
    background-color: #157bef;
    border: 1px solid #000; 
    color: #fff;
  }
`;

const Basic = ({ children, ...props}) => {
  const {
    title = "Connect",
    onClick,
    disabled,
    type = "button",
    className,
    variant,
    style,
    icon,
  } = props;

  return (
    <Button
      className={`${className}`}
      style={style}
      data-variant={variant}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {icon ? (
        <>
          <span className="title-icon">{title}</span>
          <span className="box-icon">{icon}</span>
        </>
      ) : title ? (
        <span>{title}</span>
      ) : (
        {children}
      )}
    </Button>
  );
};

export default Basic;
