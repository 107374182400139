import { getAddress } from '@ethersproject/address';
import { AddressZero } from '@ethersproject/constants';
import { Contract } from '@ethersproject/contracts';
import * as bitcoinjs from "bitcoinjs-lib";
import * as ecc from "tiny-secp256k1";
import { PublicKey } from "@solana/web3.js";

import { SupportedChainId } from '../constants/chains';

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value) {
  try {
    // Check if the address is a valid Ethereum address
    const ethereumAddress = getAddress(value.toLowerCase());
    if (ethereumAddress) {
      return ethereumAddress;
    }
  } catch {
    // Ignore the error if it's not a valid Ethereum address
  }

  try {
    // Check if the address is a valid Bitcoin address
    bitcoinjs.initEccLib(ecc);
    const bitcoinAddress = !!bitcoinjs.address.toOutputScript(value, bitcoinjs.networks.bitcoin);
    if (bitcoinAddress) {
      return value;
    }
  } catch (err) {
    //console.log(err)
  }

  // Invalid address
  return false;
}

export function isSolanaAddress(value) {
  try {
    // Check if the address is a valid Solana address
    const solanaAddress = new PublicKey(value);
    if (solanaAddress) {
      return value;
    }
  } catch (err) {
    // Ignore the error if it's not a valid Solana address
  }

  // Invalid address
  return false;
}

export function isBitcoinAddress(value) {
  const bitcoinAddressRegex = /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/;
  return bitcoinAddressRegex.test(value);
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address, chars = 4) {
  const parsed1 = isAddress(address);

  const parsed2 = isBitcoinAddress(address);
  const parsed3 = isSolanaAddress(address);

  if (!parsed1 && !parsed2 && !parsed3) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  let parsed = parsed1 || parsed2 || parsed3;
  if (parsed?.length == 42) {
    return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`;
    //return `${parsed.substring(0, chars)}...${parsed.substring(42 - chars)}`;
  } else if (parsed.length >= 26) {
    return `${parsed.substring(0, 5)}...${parsed.substring(parsed.length - 5)}`;
  } else {
    return parsed;
  }
}

// account is not optional
function getSigner(provider, account) {
  return provider.getSigner(account).connectUnchecked();
}

// account is optional
function getProviderOrSigner(provider, account) {
  return account ? getSigner(provider, account) : provider;
}

// account is optional
export function getContract(address, ABI, provider, account) {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(address, ABI, getProviderOrSigner(provider, account));
}

export function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function isChainIdSupportedByFinape(chainId) {
  const supportedChainIds = Object.values(SupportedChainId);
  return supportedChainIds.includes(chainId);
}