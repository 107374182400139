import { useCallback, useEffect } from 'react';
import { TransactionReceipt } from '@ethersproject/abstract-provider';
import ms from 'ms.macro';
import { SupportedChainId } from '../../../constants/chains';
import useBlockNumber, { useFastForwardBlockNumber } from '../../../lib/hooks/useBlockNumber';
import { retry, RetryableError } from '../../../utils/retry';
import { useCustomWeb3React } from '../../../providers/CustomWeb3ReactProvider';

export function shouldCheck(lastBlockNumber, tx) {
  if (tx.receipt) return false;
  if (!tx.lastCheckedBlockNumber) return true;
  const blocksSinceCheck = lastBlockNumber - tx.lastCheckedBlockNumber;
  if (blocksSinceCheck < 1) return false;
  const minutesPending = (new Date().getTime() - tx.addedTime) / ms`1m`;
  if (minutesPending > 60) {
    // every 10 blocks if pending longer than an hour
    return blocksSinceCheck > 9;
  } else if (minutesPending > 5) {
    // every 3 blocks if pending longer than 5 minutes
    return blocksSinceCheck > 2;
  } else {
    // otherwise every block
    return true;
  }
}

const RETRY_OPTIONS_BY_CHAIN_ID = {
  [SupportedChainId.ARBITRUM_ONE]: { n: 10, minWait: 250, maxWait: 1000 },
  [SupportedChainId.BASE]: { n: 10, minWait: 250, maxWait: 1000 },
  [SupportedChainId.AVAX]: { n: 10, minWait: 250, maxWait: 1000 },
  [SupportedChainId.POLYGON]: { n: 10, minWait: 250, maxWait: 1000 },
  [SupportedChainId.POLYGON_MUMBAI]: { n: 10, minWait: 250, maxWait: 1000 },
  //[SupportedChainId.ARBITRUM_GOERLI]: { n: 10, minWait: 250, maxWait: 1000 },
  //[SupportedChainId.OPTIMISM]: { n: 10, minWait: 250, maxWait: 1000 },
  //[SupportedChainId.OPTIMISM_GOERLI]: { n: 10, minWait: 250, maxWait: 1000 },
};
const DEFAULT_RETRY_OPTIONS = { n: 1, minWait: 0, maxWait: 0 };

export default function Updater({ pendingTransactions, onCheck, onReceipt }) {
  const { chainId, provider } = useCustomWeb3React();

  const lastBlockNumber = useBlockNumber();
  const fastForwardBlockNumber = useFastForwardBlockNumber();

  const getReceipt = useCallback(
    (hash) => {
      if (!provider || !chainId) throw new Error('No provider or chainId');
      const retryOptions = RETRY_OPTIONS_BY_CHAIN_ID[chainId] ?? DEFAULT_RETRY_OPTIONS;
      return retry(
        () =>
          provider.getTransactionReceipt(hash).then((receipt) => {
            if (receipt === null) {
              console.debug(`Retrying tranasaction receipt for ${hash}`);
              throw new RetryableError();
            }
            return receipt;
          }),
        retryOptions
      );
    },
    [chainId, provider]
  );

  useEffect(() => {
    if (!chainId || !provider || !lastBlockNumber) return;

    const cancels = Object.keys(pendingTransactions)
      .filter((hash) => shouldCheck(lastBlockNumber, pendingTransactions[hash]))
      .map((hash) => {
        const { promise, cancel } = getReceipt(hash);
        promise
          .then((receipt) => {
            if (receipt) {
              fastForwardBlockNumber(receipt.blockNumber);
              onReceipt({ chainId, hash, receipt });
            } else {
              onCheck({ chainId, hash, blockNumber: lastBlockNumber });
            }
          })
          .catch((error) => {
            if (!error.isCancelledError) {
              console.warn(`Failed to get transaction receipt for ${hash}`, error)
            }
          })
        return cancel
      })

    return () => {
      cancels.forEach((cancel) => cancel())
    }
  }, [chainId, provider, lastBlockNumber, getReceipt, onReceipt, onCheck, pendingTransactions, fastForwardBlockNumber])

  return null
}
