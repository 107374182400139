import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { Sliders, Search } from "react-feather";

import * as keys from "../../utils/keys";
import { Buttons, Social } from "../../components";
import Icons from "../../components/Icons";

import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useCustomWeb3React } from "../../providers/CustomWeb3ReactProvider";
import { usePartyModeManager } from "../../state/user/hooks";

import ChainSelector from "./ChainSelector";
import Web3Status from "../Web3Status";
import PtwToggle from "../Toggle/PtwToggle";

import Logo from "../../assets/images/logo-white.png";
import LogoDark from "../../assets/images/logo-dark.png";
import PtwLogo from "../../assets/images/logo-ptw.png";

import { HeaderTag, Container, ConnectButton } from "./styled";

const sparkle = keyframes`
  0% {
    transform: scale(0.1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0.1);
  }
`;

const SparklesIcon = styled(Icons.Sparkles)`
  animation: ${sparkle} 1s ease-in-out infinite;
  position: absolute;
  top: -8px;
  right: -3px;
  width: 36px;
  height: 42px;
  margin-top: 0;
`;

const ModeContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  input[type="checkbox"] {
    display: none;
  }
  .triggers {
    display: flex;
  }
  .content {
    position: absolute;
    display: none;
    z-index: 2;
    top: 100%;
    border-radius: 8px;
    border: 1px solid #ddd;
  }
  .label {
    font-size: 16px;
    color: #666;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px 25px;
    cursor: pointer;
    position: relative;
    background: rgba(212, 212, 212, 0.3);
    &:hover {
      background: #fff;
    }

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .triggers > input:checked {
    & + label {
      background: #fff;
    }
    & + label > div.content {
      display: flex;
      top: 100%;
      padding: 0.5em;
      background: #fff;
      left: 0;
      div {
        padding: 0em 0.5em;
      }
      div + div {
        border-left: 1px solid #7d7d7d;
      }
    }
  }
`;

const Header = ({ isParty }) => {
  const [openMenu, toggleOpenMenu] = useState(false);
  const { activate, account } = useCustomWeb3React();
  const { width } = useWindowDimensions();
  const [isPartyMode] = usePartyModeManager();

  useEffect(() => {
    if (openMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    window.addEventListener("resize", handleResize, false);
  }, [openMenu]);

  const handleResize = () => {
    toggleOpenMenu(false);
    document.body.style.overflow = "unset";
  };

  const handlerMobileMenu = () => {
    toggleOpenMenu(!openMenu);
  };

  const closeMobileMenu = () => {
    if (width < 1024) {
      toggleOpenMenu(!openMenu);
    }
  };

  return (
    <HeaderTag
      style={{
        borderBottom: "none",
        backgroundColor: isPartyMode ? "#000" : "#FAF9FA",
      }}
    >
      <Container>
        <div className="header__item">
          <Link to="/" className="header__logo">
            <img className="d-none d-md-block" src={isPartyMode ? Logo : PtwLogo} alt="" />
            <img className="d-md-none" src={isPartyMode ? Logo : LogoDark} alt="" />
          </Link>

          <div
            onClick={handlerMobileMenu}
            className={`d-none ${"burger"} ${openMenu ? "active" : ""}`}
          >
            <div className="burger__row">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>

        <div className={`header__menu ${openMenu ? "open" : ""}`}>
          <nav className="header__nav">
            {isPartyMode ? (
              <ul>
                {/*
                <li>
                  <NavLink onClick={() => closeMobileMenu()} to={keys.DASHBOARD}>
                    Dashboard
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={()=>closeMobileMenu()} to={keys.PRESALE}>Presale</NavLink>
                </li>
                <li>
                  <NavLink onClick={()=>closeMobileMenu()} to={keys.BRIDGE}>Bridge</NavLink>
                </li>
                <li>
                  <NavLink onClick={()=>closeMobileMenu()} to={keys.STAKE}>Stake</NavLink>
                </li>
                */}
                {false && (
                  <li>
                    <NavLink
                      onClick={() => closeMobileMenu()}
                      to={keys.NFT_EXPLORER}
                    >
                      Gallery
                    </NavLink>
                  </li>
                )}
                {false && (
                  <li>
                    <NavLink
                      onClick={() => closeMobileMenu()}
                      to={keys.NFT_READER}
                    >
                      NFT Reader
                    </NavLink>
                  </li>
                )}
                {false && (
                  <li>
                    <NavLink
                      onClick={() => closeMobileMenu()}
                      to={keys.PROVENANCE}
                    >
                      Provenance
                    </NavLink>
                  </li>
                )}
              </ul>
            ) : (
              <ModeContainer>
                <div className="triggers">
                  <input id="first" type="checkbox" />
                  <label className="label" htmlFor="first">
                    <Search color="#666" size={14} />
                    &nbsp; Pretend to Work Mode
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Sliders color="#666" size={16} />
                    <div className="content">
                      <div className="inner-section">
                        <div>
                          <div
                            style={{
                              paddingLeft: 0,
                              paddingTop: 10,
                              paddingBottom: 10,
                              fontWeight: 600,
                              fontSize: 16,
                            }}
                          >
                            Pretend to Work Mode{" "}
                          </div>
                          <span>We prioritize your welfare – </span>
                          <br />
                          <span>
                            Introducing a "Pretend to Work" Mode, ensuring safe browsing during office hours.
                          </span>
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </ModeContainer>
            )}
          </nav>

          <div className="header__social">
            <Social />
          </div>
        </div>

        <div className="header__btns">
          <Web3Status className="connectButton" />
          {true && <ChainSelector className="chainSelectorButton" />}
          <PtwToggle />
        </div>
      </Container>
    </HeaderTag>
  );

  /*
  return ( 
          <header className="site-header">
            <img src={Logo} alt="" width="200" />

    <label className="mobile-menu-button hamburger-icon">
      <input type="radio" name="toggle-navigation" className="open-navigation navigation-closed" autocomplete="off" />
      <span className="visually-hidden">Open Navigation</span>
    </label>

    <nav className="site-navigation">
      <div className="navigation-animation-wrapper">
        <label className="mobile-menu-button close-icon">
          <input type="radio" name="toggle-navigation" className="close-navigation navigation-opened" autocomplete="off" />
          <span className="visually-hidden">Close navigation</span>
        </label>

        <ul className="nav-list">
          <li><a href="#">Home</a></li>
          <li><a href="#">Portfolio</a></li>
          <li><a href="#">Projects</a></li>
          <li><a href="#">About</a></li>
          <li><a href="#">Blog</a></li>
          <li><a href="#" className="button"><PtwToggle /></a></li>
          <li><a href="#" className="button"><Web3Status /></a></li>

        </ul>
      </div>
    </nav>
  </header>

  );
  */
};

export default Header;
