import React from "react";
import PropTypes from "prop-types";

const Youtube = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="25"
      height="18"
      viewBox="0 0 25 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M24.375 2.73214C24.0625 1.60714 23.2813 0.803572 22.1875 0.482143C20.3125 2.8738e-07 12.3437 0 12.3437 0C12.3437 0 4.53126 2.8738e-07 2.50001 0.482143C1.40626 0.803572 0.624995 1.60714 0.312495 2.73214C-4.77303e-06 4.82143 0 9 0 9C0 9 4.74975e-06 13.1786 0.468755 15.2679C0.781255 16.3929 1.5625 17.1964 2.65625 17.5179C4.53125 18 12.5 18 12.5 18C12.5 18 20.3125 18 22.3437 17.5179C23.4375 17.1964 24.2188 16.3929 24.5313 15.2679C25 13.1786 25 9 25 9C25 9 25 4.82143 24.375 2.73214ZM9.99999 12.8571V5.14286L16.5625 9L9.99999 12.8571Z"
        fill={color}
      />
    </svg>
  );
};

Youtube.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export default Youtube;
