import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  padding: 20px 15px 30px;

  h1,
  .h1 {
    margin: 40px 0;
    font-family: var(--font-gilroy);
    font-size: 26px;
    line-height: 31px;
    font-weight: 700;
    letter-spacing: -0.02em;
    color: #2d2d2d;

    @media only screen and (min-width: 768px) {
      font-size: 30px;
      line-height: 36px;
    }
  }

  h2,
  .h2 {
    margin: 40px 0;
    font-family: var(--font-gilroy);
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    letter-spacing: -0.02em;
    color: #2d2d2d;

    @media only screen and (min-width: 768px) {
      font-size: 24px;
      line-height: 29px;
    }
  }

  h3,
  .h3 {
    margin: 40px 0;
    font-family: var(--font-gilroy);
    font-size: 18px;
    line-height: 21px;
    font-weight: 600;
    letter-spacing: -0.02em;
    color: #2d2d2d;

    @media only screen and (min-width: 768px) {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 14px;
    }
  }

  p {
    line-height: 1.5;
  }

  hr {
    margin: 40px 0;
  }

  @media only screen and (min-width: 1024px) {
    padding-left: 40px;
    padding-right: 40px;
  }

  .gallery__section {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;

    .centered {
      text-align: center;
      width: 100%;
      margin: 0 auto;
    }

    .label {
      font-size: 14px;
      font-weight: bold;
    }

    .code {
      font-family: "Courier New", monospace;
      font-size: 14px;
      word-break: break-word;
    }

    .hash__string {
      font-family: "Courier New", monospace;
      font-size: 9px;
      overflow-x: auto;
      overflow-y: scroll;
      height: 10vw;
      width: 100%;
      line-height: 1.1;

      display: inline-block;
      word-break: break-all;
      word-wrap: break-word;
      line-break: strict;
      overflow-wrap: break-all;
      hyphens: none;
      -webkit-hyphens: none;
      -moz-hyphens: none;
    }

    a,
    a:hover {
      color: #1e7ff0;
    }
  }

  .select-nft__item {
    padding-left: 10px;
    padding-right: 10px;
    //flex: 0 0 calc(100% / 4);
    //max-width: calc(100% / 4);
  }

  .card {
    &__article {
      display: flex;
      flex-direction: column;
      background: white;
      border-radius: 12px;
      height: 100%;

      cursor: pointer;
    }

    &__figure {
      display: block;
      background-color: #d9d9d9;
      overflow: hidden;
      border-radius: 12px;
      box-shadow: 0 1px 1px rgba(0,0,0,.075), 0 2px 2px rgba(0,0,0,.075), 2px 4px 8px 2px rgba(16, 23, 30, 0.24);
      //box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

      img, video {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;

        transition: all 0.2s ease-in-out;

        &:hover {
          transform: scale(1.05);
        }
      }
    }

    &__body {
      padding: 12px 14px 10px 14px;
      display: block;
      align-items: center;
      justify-content: space-between;
    }

    &__name {
      padding: 0 5px 3px 10px;
      font-family: var(--font-gilroy);
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      color: #999;
    }

    &__code {
      display: flex;
      align-items: center;
      justify-content: start;
      min-width: 68px;
      padding: 5px 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: var(--font-gilroy);
      font-size: 16px;
      line-height: 18px;
      font-weight: 600;
      color: #061330;
      background-color: #ebedf2;
      border-radius: 10px;

      @media only screen and (max-width: 767px) {
        min-width: 82px;
      }
    }
  }

`;

export const GalleryContainer = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  border: 1px solid #ccc; 
  min-height: 100px;
  border-radius: 12px;
`;

export const NotFound = styled.div`
  position: relative;
  margin: 40px auto 10px;
  width: 100%;
  font-size: 24px;
  min-height: 100px;
  text-align: center;
`;

export const MintButton = styled.button`
  width: 200px;
  color: #000;
  background-color: #41da7e;
  text-align: center;
  font-size: 18px;
  line-height: 18px;
  border-radius: 10px;
  border: 0 solid;
  padding: 10px;
  display: inline-block;
  margin: 0 auto;
  vertical-align: top;
  cursor: pointer;

  &:hover {
    background-color: #72e49f;
    color: #000;
  }
`;

export const LoaderContainer = styled.div`
  margin: 40px auto 0;
  width: 100%;
  max-width: 300px;
  height: 100%;

`;

