import React from "react";
import PropTypes from "prop-types";

const Telegram = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="25"
      height="21"
      viewBox="0 0 25 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1.71862 9.04045C8.42949 6.07689 12.9045 4.12312 15.1435 3.17916C21.5365 0.483959 22.8649 0.0157745 23.7307 0.000149878C23.9212 -0.00308548 24.347 0.0447505 24.6228 0.271594C24.8557 0.463136 24.9197 0.721882 24.9504 0.903485C24.9811 1.08509 25.0192 1.49878 24.9889 1.82203C24.6424 5.51156 23.1434 14.4651 22.3808 18.5974C22.0581 20.3459 21.4227 20.9322 20.8076 20.9896C19.4707 21.1143 18.4556 20.0941 17.1608 19.2338C15.1348 17.8877 13.9901 17.0497 12.0235 15.736C9.75066 14.2179 11.224 13.3835 12.5193 12.0199C12.8583 11.6631 18.7484 6.23274 18.8624 5.74013C18.8767 5.67852 18.8899 5.44886 18.7553 5.3276C18.6207 5.20633 18.422 5.2478 18.2787 5.28078C18.0754 5.32753 14.8388 7.49594 8.56858 11.786C7.64986 12.4255 6.8177 12.737 6.07212 12.7207C5.25018 12.7027 3.6691 12.2496 2.49372 11.8624C1.05207 11.3874 -0.0937162 11.1363 0.00605648 10.3296C0.0580242 9.9094 0.628879 9.47969 1.71862 9.04045Z"
        fill={color}
      />
    </svg>
  );
};

Telegram.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export default Telegram;
