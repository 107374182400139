import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";
import styled, { keyframes } from "styled-components";
import { ChevronDown } from "react-feather";

import GroupPhoto from "../../assets/images/group.png";
import Pinksale from "../../assets/images/partners/Pinksale.png";
import dexview from "../../assets/images/partners/dexview.png";
import coinsult from "../../assets/images/partners/coinsult.png";
import polygonLogo from "../../assets/images/partners/polygon.png";
import binance from "../../assets/images/partners/binance.svg";
import solanaLogo from "../../assets/images/partners/solana.svg";
import avalancheLogo from "../../assets/images/partners/avalanche.svg";
import baseLogo from "../../assets/images/partners/Base_Wordmark_White.svg";
import ethereumLogo from "../../assets/images/partners/ethereum.png";
import arbitrumLogo from "../../assets/images/partners/arbitrum.png";

const Section = styled.section`
  background-color: #000;
  padding-top: 90px;
  padding-bottom: 90px;
  color: #fff;
  position: relative;

  h1 {
    color: #f6bb00;
  }
  h2 {
    color: #fff;
  }

  ::after {
    content: "";
    position: absolute;
    bottom: 0; /* Adjust bottom position */
    right: 0; /* Adjust right position */
    width: 700px; /* Adjust width */
    height: 393px; /* Adjust height */
    background-image: url(${GroupPhoto});
    background-size: cover;
    z-index: 1;
  }

  .bordered {
    max-width: 250px;
    margin: 10px; 
    padding: 10px; 
    border: 2px solid #fff; 
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .groupImg {
    display: none;
    padding-top: 30px; 
    width: 100%;
  }

  @media only screen and (max-width: 991px) {
    ::after {
      display: none;
    }
    .groupImg {
      display: block;
    }
  }
  @media only screen and (min-width: 1400px) {
    ::after {
      width: 800px;
      height: 473px;
    }
  }
  @media only screen and (min-width: 1800px) {
    ::after {
      width: 900px;
      height: 600px;
    }
  }
  @media only screen and (max-width: 600px) {
    .bordered {
      max-width: 175px;
    }
  }
`;

const AccordionItem = ({ header, ...rest }) => (
  <Item
    {...rest}
    header={
      <>
        {header}
        <ChevronDown className="chevron" />
      </>
    }
    className="item"
    buttonProps={{
      className: ({ isEnter }) => `itemBtn ${isEnter && "itemBtnExpanded"}`,
    }}
    contentProps={{ className: "itemContent" }}
    panelProps={{ className: "itemPanel" }}
  />
);

export default function FAQ() {
  return (
    <Section id="partners">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-lg-6">
            <h1>Partners</h1>

            <h2>
              <span className="italic"><span className="color">LOL</span>Ape</span> strives to build friendships with everyone that laughs at his jokes...
            </h2>
          </div>
        </div>
      </div>
      <div className="gap-2"></div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-lg-6">
            <div className="row">
              <div className="col-12 bordered">
                <img src={solanaLogo} style={{ width: "80%" }} />
              </div>
              <div className="col-12 bordered">
                <img src={baseLogo} style={{ width: "50%" }} />
              </div>
              <div className="col-12 bordered">
                <img src={ethereumLogo} style={{ width: "80%" }} />
              </div>
              <div className="col-12 bordered">
                <img src={binance} style={{ width: "80%" }} />
              </div>
              <div className="col-12 bordered">
                <img src={arbitrumLogo} style={{ width: "80%" }} />
              </div>
              <div className="col-12 bordered">
                <img src={polygonLogo} style={{ width: "70%" }} />
              </div>
              <div className="col-12 bordered">
                <img src={avalancheLogo} style={{ width: "80%" }} />
              </div>
              <div className="col-12 bordered">
                <img src={dexview} style={{ width: "80%" }} />
              </div>
              <div className="col-12 bordered">
                <img src={coinsult} style={{ width: "70%" }} />
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-lg-6">
            <img src={GroupPhoto} className="groupImg" />
          </div>
        </div>
      </div>
    </Section>
  );
}
